import React, { createContext, useState, useEffect, useCallback } from "react";
import { ThemeProvider } from "styled-components";
import {
  darkTheme,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";
import { ThemeWrapper } from "../components/themeWrapper";
import { consoleNonProd } from "../utils/utilityBeltUtils";

// Theme Options
export const DEFAULT = "default";
export const DARK = "dark";
export type ThemeType = typeof DEFAULT | typeof DARK;

export type ThemeContextType = {
  theme: ThemeType; // TODO: add high contrast
  setTheme: (theme: ThemeType) => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  theme: DEFAULT,
  setTheme: (theme: ThemeType) => null,
});

/**
 * ThemeContextProvider component manages the application theme and provides it to child components
 * @prop {JSX.Element} props.children - The child components to be wrapped with the theme context
 */

export const ThemeContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  // Initialize the theme state, checking local storage and browser preferences
  const [theme, setTheme] = useState<ThemeType>(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      consoleNonProd("Theme loaded from local storage:", storedTheme);
      return storedTheme === DARK ? DARK : DEFAULT;
    } else {
      consoleNonProd("No theme found in local storage.");
      // Check if dark mode is supported by the browser
      const isDarkModeSupported =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme)").media !== "not all";
      if (isDarkModeSupported) {
        const darkModeMediaQuery = window.matchMedia(
          "(prefers-color-scheme: dark)"
        );
        if (darkModeMediaQuery.matches) {
          consoleNonProd("Dark mode preference detected in browser settings.");
          localStorage.setItem("theme", DARK);
          return DARK;
        }
      }
      consoleNonProd("No theme set based on browser settings.");
    }
    return DEFAULT;
  });

  // Check if browser supports media queries for dark mode detection
  const isDarkModeSupported =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme)").media !== "not all";

  // useEffect to set up an event listener for changes in local storage
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "theme") {
        consoleNonProd("Theme changed in another tab:", e.newValue);
        // Update the theme state based on changes in local storage.
        setTheme(e.newValue === DARK ? DARK : DEFAULT);
      }
    };

    // Add the event listener.
    window.addEventListener("storage", handleStorageChange);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // useEffect to check and set the theme based on browser dark mode preference
  useEffect(() => {
    // If dark mode is supported and media query is allowed, check if the user prefers dark
    if (isDarkModeSupported && !localStorage.getItem("theme")) {
      const darkModeMediaQuery = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );
      if (darkModeMediaQuery.matches) {
        consoleNonProd("Dark mode preference detected in browser settings.");
        localStorage.setItem("theme", DARK);
        // Update the theme state
        setTheme(DARK);
      }
    }
  }, []);

  // Update local storage while updating the theme state
  const updateTheme = useCallback((newTheme: ThemeType) => {
    consoleNonProd("Theme updated:", newTheme);
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  }, []);

  // Provide the theme context to child components
  const providerValue: ThemeContextType = {
    theme,
    setTheme: updateTheme,
  };

  // Sets the body's background on theme change
  useEffect(() => {
    if (document?.body) {
      document.body.style.backgroundColor =
        theme === DARK ? "#1f1f1f" : "#f7f7f7";
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={providerValue}>
      <ThemeProvider theme={theme === DARK ? darkTheme : lightTheme}>
        <ThemeWrapper elementType="main">{children}</ThemeWrapper>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
