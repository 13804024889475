import classnames from "classnames";
import React from "react";

type ArtObjectCaptionProps = {
  title?: string;
  caption?: string;
  isThumbnail?: boolean;
};

export const ArtObjectCaption: React.FC<ArtObjectCaptionProps> = ({
  title,
  caption,
  isThumbnail,
}) => {
  return (
    <div
      className={classnames("art-object-caption", {
        "art-object-caption--thumbnail": isThumbnail,
      })}
    >
      {caption && <h2 className="h2 font-simple-heading">{caption}</h2>}
      {title && <h3 className="h3 color-light">{title}</h3>}
    </div>
  );
};
