import { ApolloError } from "@apollo/client";
import React from "react";

type ApolloErrorsListType = {
  error: ApolloError;
};

export const ApolloErrorsList: React.FC<ApolloErrorsListType> = ({ error }) => {
  return (
    <ul>
      {error.graphQLErrors[0].extensions.errors ? (
        // Display errors coming from the server side
        (
          error.graphQLErrors[0].extensions.errors as {
            message: string;
          }[]
        ).map((e, i) => <li key={i}>{e.message}</li>)
      ) : error.graphQLErrors[0].message ? (
        // Display errors coming from the client side
        <li>{error.graphQLErrors[0].message}</li>
      ) : error.graphQLErrors ? (
        // Default to printing out all items in the GQLErrors object
        Object.values(error.graphQLErrors[0]).map((e, i) => (
          <li key={i}>{e}</li>
        ))
      ) : (
        // Fall back to error.message
        <li>{error.message && error.message}</li>
      )}
    </ul>
  );
};
