import { useMutation } from "@apollo/client";
import { googleLogout } from "@react-oauth/google";
import React, { createContext, useContext, useState } from "react";
import { LOG_IN, LOG_OUT } from "../graphql/mutations";
import { LoginInput, LoginResponse, LogoutResponse, Mutation } from "../types";
import { useAppContext } from "./appContext";
import { MeetingRole } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../constants";
import { StorageKeys, useLocalStorage } from "../hooks/useLocalStorage";
import { consoleNonProd } from "../utils/utilityBeltUtils";

export type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  handleLogin: (input: LoginInput) => Promise<LoginResponse>;
  handleLogout: () => Promise<LogoutResponse>;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const {
    getLocalStorage,
    clearLocalStorage,
    removeLocalStorageItem,
    setLocalStorage,
  } = useLocalStorage();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    Boolean(getLocalStorage(StorageKeys.sid))
  );
  const navigate = useNavigate();
  const redirect = new URLSearchParams(useLocation().search).get("r");

  const {
    handleSetMeetingRole,
    setFirstName,
    setLastName,
    firstName,
    lastName,
  } = useAppContext();

  const [logoutMutation] = useMutation(LOG_OUT, {
    onCompleted({ logout }: Pick<Mutation, "logout">) {
      consoleNonProd(logout.message);
    },
  });

  const handleLogout = async (): Promise<LogoutResponse> => {
    googleLogout();
    const resp = await logoutMutation({
      variables: { firstName: firstName, lastName: lastName },
    });
    handleSetMeetingRole(MeetingRole.Audience);
    setFirstName(null);
    setLastName(null);
    setIsAuthenticated(false);
    clearLocalStorage();

    return resp.data.logout;
  };

  const [loginMutation] = useMutation(LOG_IN, {
    onCompleted({ login }: Pick<Mutation, "login">) {
      consoleNonProd("completed login mutation");

      // If user is not authenticated, set meeting role as Audience
      handleSetMeetingRole(
        login.isAuthenticated ? MeetingRole.Presenter : MeetingRole.Audience
      );
      setIsAuthenticated(login.isAuthenticated);
      setFirstName(login.firstName);
      setLastName(login.lastName);

      if (!login.isAuthenticated) {
        consoleNonProd("Error logging in user:", login.message);
        // remove SID from local storage
        removeLocalStorageItem(StorageKeys.sid);
        // Navigate to home page when user was not authenticated
        navigate("/");
      } else {
        // Set SID in local storage to indicate that the user is authenticated and has a SID
        setLocalStorage(StorageKeys.sid, "sid");
        // Navigate to admin dashboard on success
        const path = redirect ? redirect : "/" + routes.admin;
        navigate(path);
      }
    },
  });

  const handleLogin = async (input: LoginInput): Promise<LoginResponse> => {
    const resp = await loginMutation({ variables: { input } });
    return resp.data.login;
  };

  const providerValue: AuthContextType = {
    isAuthenticated,
    setIsAuthenticated,
    handleLogin,
    handleLogout,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("Use useAuthContext hook inside AuthContextProvider.");
  }

  return context;
};
