import React from "react";
import { IconButton } from "../buttons/iconButton";
import { Button, PRIMARY, SMALL } from "../buttons/button";
import { useScreenContext } from "../../contexts/screenContext";

type HelpProps = {
  useMobileTools: boolean;
  toggleInstructions: () => void;
  component: "BUTTON" | "ICON_BUTTON";
};

export const Help: React.FC<HelpProps> = ({
  useMobileTools,
  toggleInstructions,
  component,
}) => {
  const { screenWidth } = useScreenContext();
  const popAboveBar = screenWidth < 750;
  return (
    <div className="icon-tool__wrapper">
      {component === "ICON_BUTTON" ? (
        <div
          className="icon-tool__inner"
          style={
            useMobileTools
              ? { top: "4px", right: "4px" }
              : {
                  zIndex: "12",
                  bottom: popAboveBar ? "60px" : "8px",
                  left: "4px",
                }
          }
        >
          <IconButton
            onClick={toggleInstructions}
            iconName="help"
            desc="Help"
            toolTip="top"
            btnId="toggle-instructions-button"
          />
        </div>
      ) : (
        <Button
          btnType={PRIMARY}
          text="Help"
          size={SMALL}
          id="toggle-instructions-button"
          onClick={toggleInstructions}
          icon="help"
        />
      )}
    </div>
  );
};
