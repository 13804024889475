import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";
import { browserName, isTablet } from "react-device-detect";
import { ReplayPreview } from "./replayPreview";
import { Container } from "../container";
import { useScreenContext } from "../../contexts/screenContext";
import classNames from "classnames";

type ChapterListProps = {
  chapters: VTTCue[];
  onDismiss: () => void;
  activeChapter: number;
  handleSelectChapter: (timestamp: number, chapter: number) => void;
  height?: string;
};

export const ChapterList: React.FC<ChapterListProps> = ({
  chapters,
  onDismiss,
  activeChapter,
  handleSelectChapter,
  height,
}) => {
  const { useMobileTools } = useScreenContext();

  /** Scroll to active chapter */
  useEffect(() => {
    const activeChapterEl = document.getElementById(`chapter-${activeChapter}`);
    const scrollOptions: ScrollIntoViewOptions =
      browserName === "Firefox"
        ? {
            block: "start",
            inline: "start",
            behavior: "smooth",
          }
        : {
            block: "start",
            inline: "start",
          };
    setTimeout(() => activeChapterEl?.scrollIntoView(scrollOptions), 100);
  }, [activeChapter]);

  return (
    <Container
      isDismissable={!useMobileTools}
      onDismiss={onDismiss}
      className="chapter-list-container"
      xToCloseId="close-chapter-list"
    >
      <div
        className="chapter-list-container__content"
        id="chapter-list-container__content"
      >
        {!useMobileTools && (
          <h1 className="chapter-list-container__title">Chapters</h1>
        )}
        <SimpleBar
          forceVisible="y"
          autoHide={true}
          className="chapter-list-container__simplebar"
          style={{ maxHeight: height ? height : "40vh" }}
        >
          <div
            id="chapter-list"
            className={classNames("chapter-list", {
              "--mobile": useMobileTools,
              "--desktop": !useMobileTools,
            })}
            role="log"
            style={{
              overflow: isTablet ? "scroll" : "none",
              height: height,
              maxHeight: height,
            }}
          >
            {chapters?.map((chapter, index) => (
              <div
                key={index}
                className="chapter-list__row--wrapper"
                id={`chapter-${index + 1}`}
                onClick={(e) =>
                  handleSelectChapter(chapter.startTime, index + 1)
                }
              >
                <div
                  className={classNames("chapter-list__row", {
                    "chapter-list__row-active": index === activeChapter - 1,
                  })}
                >
                  <ReplayPreview
                    chapter={chapter}
                    className="chapter-list__preview"
                    includeStartTime={true}
                    hoverState={true}
                  />
                </div>
              </div>
            ))}
            <div className="chapter-list__row--buffer" />
          </div>
        </SimpleBar>
      </div>
    </Container>
  );
};
