import React, { useContext, useEffect, useState } from "react";
import { StorageKeys, useLocalStorage } from "../../../hooks/useLocalStorage";
import {
  DataEmotesActionType,
  EmoteTypes,
  useDataEmotes,
} from "../../../providers/dataEmotes";
import { IconButton } from "../../buttons/iconButton";
import { ThemeContext } from "../../../contexts/themeContext";
import variables from "../../../styles/variables.scss";
import { Button, PRIMARY, SMALL } from "../../buttons/button";

type HandRaiseProps = {
  component: "BUTTON" | "ICON_BUTTON";
};

export const HandRaise: React.FC<HandRaiseProps> = ({ component }) => {
  const { sendEmote, raisedHands } = useDataEmotes();
  const [handRaised, setHandRaised] = useState(false);
  const { getLocalStorage } = useLocalStorage();
  const { theme } = useContext(ThemeContext);
  const strokeColor = variables[`${theme}_color__copy--dark`];
  const fillColor = variables[`${theme}_color__copy--dark`];

  const raiseYourHand = () => {
    if (handRaised) {
      sendEmote({
        type: EmoteTypes.HAND_LOWER,
        leader: false,
        action: DataEmotesActionType.REMOVE,
      });
    } else {
      sendEmote({
        type: EmoteTypes.HAND_RAISE,
        leader: false,
        action: DataEmotesActionType.ADD,
      });
    }
    setHandRaised(!handRaised);
  };

  useEffect(() => {
    setHandRaised(
      raisedHands.has(getLocalStorage(StorageKeys.attendee)?.AttendeeId)
    );
  }, [raisedHands, raisedHands.size, getLocalStorage]);

  return component === "BUTTON" ? (
    <Button
      onClick={() => {
        raiseYourHand();
      }}
      btnType={PRIMARY}
      text={handRaised ? "Lower hand" : "Raise hand"}
      customWidth="100%"
      size={SMALL}
      fill={handRaised ? fillColor : "none"}
      icon="hand"
      id="raise-your-hand-button"
    />
  ) : (
    <IconButton
      onClick={() => {
        raiseYourHand();
      }}
      iconName="hand"
      desc={handRaised ? "Lower hand" : "Raise hand"}
      fill={handRaised ? fillColor : "none"}
      stroke={strokeColor}
      toolTip="top"
      btnId="raise-your-hand-button"
      selected={handRaised}
    />
  );
};
