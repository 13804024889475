import React, { Suspense, useEffect, useRef } from "react";
import {
  EventPayload,
  EventTypes,
  CanvasDataEvent,
} from "../../providers/types";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useFocusCheck } from "../../hooks/useFocusCheck";
import { Canvas } from "@react-three/fiber";
import { useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import Spinner from "../spinner";
import * as THREE from "three";
import { CameraControls } from "./threedeeCameraControls";
import { useAppContext } from "../../contexts/appContext";
import { CanvasMode, NavEvents, State } from "../../contexts/types";
import { ROTATIONVALUE, VALID_KEYS_WITH_ZOOM } from "../../constants";
import {
  exploreToggleEventCheck,
  isThreeDeeEventCheck,
} from "../../utils/eventUtils";

type ThreeDeeViewerProps = {
  sourceUrl: string;
  leader: boolean;
  scale: number;
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  events?: CanvasDataEvent[];
  mode: CanvasMode;
};

/**
 * Setup for 3DViewer.
 * This will use a ref to drop out of react.
 * Will have to revisit once we have object uploads
 */

const ThreeDeeViewer: React.FC<ThreeDeeViewerProps> = ({
  sourceUrl,
  leader,
  scale,
  sendEvent,
  mode,
  events = [],
}: ThreeDeeViewerProps) => {
  const { generateEventHandlers, state } = useAppContext();

  const { height } = useWindowDimensions();
  const { playerHasFocus } = useFocusCheck();
  const gltf = useGLTF(sourceUrl);
  const inputRef = useRef();
  const timer = useRef(null);

  const CameraController = (props: any) => {
    const { camera } = useThree();
    const cameraControls = useRef<CameraControls>(null);
    const box = new THREE.Box3().setFromObject(gltf.scene);

    /**
     * The brains for key commands and 3D manipulation checks the standard key controls
     * and uses the camera controller to either rotate the azimuth and polar angles, move the
     * "dolly" or resets everything to the starting positions
     *
     * @param inputKey the key entered by the users event.key
     */
    const keyCommander = (inputKey: string) => {
      if (!playerHasFocus()) {
        if (VALID_KEYS_WITH_ZOOM.indexOf(inputKey) < 0) return;
        // w or up arrow
        if (inputKey == "w" || inputKey == "ArrowUp") {
          cameraControls.current?.rotate(0, ROTATIONVALUE, true);
        }
        // s or down arrow
        else if (inputKey == "s" || inputKey == "ArrowDown") {
          cameraControls.current?.rotate(0, -ROTATIONVALUE, true);
        }
        // a or left arrow
        else if (inputKey == "a" || inputKey == "ArrowLeft") {
          cameraControls.current?.rotate(ROTATIONVALUE, 0, true);
        }
        // d or right arrow
        else if (inputKey == "d" || inputKey == "ArrowRight") {
          cameraControls.current?.rotate(-ROTATIONVALUE, 0, true);
        }
        // z key or -
        else if (inputKey == "z" || inputKey == "-") {
          cameraControls.current?.dolly(-1, true);
        }
        // x key or +
        else if (inputKey == "x" || inputKey == "=" || inputKey == "+") {
          cameraControls.current?.dolly(1, true);
        }
        // space bar to reset
        else if (inputKey == " ") {
          cameraControls.current
            ?.rotateTo(0, 1.5, true)
            .then(() => cameraControls.current?.dollyTo(1, true))
            .finally(() => sendThreeDeeEvent());
        }

        // below is the "throttle" for the keyboard events if we want to send less events I would
        // suggest removing the throttle below and adding ".finally(() => sendThreeDeeEvent());" to the individual
        // key checks. Similar to the "spacebar" check above
        if (inputKey != " ") {
          sendThreeDeeEvent();
        }
      }
    };

    function sendThreeDeeEvent(inputTime: number = 200) {
      const timeout = inputTime;
      if (timer !== null) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(function () {
        if (cameraControls.current?.azimuthAngle !== undefined) {
          sendThreeDeeView(
            cameraControls.current?.azimuthAngle,
            cameraControls.current?.polarAngle,
            cameraControls.current?.distance,
            leader,
            state.exploreMode
          );
        }
      }, timeout);
    }

    function handleFit() {
      cameraControls.current
        ?.rotateTo(0, 1.5, true)
        .then(() => cameraControls.current?.dollyTo(10, true))
        .finally(() => sendThreeDeeEvent());
    }

    useEffect(() => {
      if (cameraControls.current) {
        // all of these might have to become inputs when we get further into different models
        // but they are the "limits" and other properties of the camera controller
        cameraControls.current.minDistance = 3;
        cameraControls.current.maxDistance = 6;
        cameraControls.current.enabled = leader || state.exploreMode;
        cameraControls.current.mouseButtons.right = 0;
        cameraControls.current.restThreshold = 0.0005;
        cameraControls.current.dampingFactor = 0.1;
        cameraControls.current.polarRotateSpeed = 0.5;
        cameraControls.current.azimuthRotateSpeed = 0.5;
        cameraControls.current.dollySpeed = 1.5;
      }
    }, [box, inputRef]);

    useEffect(() => {
      document.addEventListener("keydown", onDocumentKeyDown, false);
      cameraControls.current?.addEventListener("rest", () => {
        // this bit handles the leader to leader events and makes sure
        // they don't just fire events at each other over and over and over
        if (events?.length) {
          const canvasDataEv = events[events.length - 1];
          if (canvasDataEv?.isSelf) {
            sendThreeDeeEvent(500);
          }
        }
      });
      cameraControls.current?.addEventListener("controlend", () => {
        sendThreeDeeEvent(50);
      });

      function onDocumentKeyDown(event) {
        if (leader || state.exploreMode) {
          keyCommander(event?.key);
        }
      }

      return function () {
        document.removeEventListener("keydown", onDocumentKeyDown, false);
        cameraControls.current?.removeAllEventListeners();
      };
    }, [box, gltf.scene, inputRef]);

    const sendThreeDeeView = (
      x: number,
      y: number,
      z: number,
      leader: boolean,
      exploreMode: boolean
    ) => {
      if (sendEvent && leader && !exploreMode) {
        sendEvent(
          {
            type: EventTypes.THREE_DEE_DRAG,
            leader: leader,
            threeDeeData: {
              x: x,
              y: y,
              z: z,
            },
            exploreMode: exploreMode,
          },
          state
        );
      }
    };

    useEffect(() => {
      // Set event handlers for canvas button interactions via slide manager
      generateEventHandlers({
        [NavEvents.FIT]: handleFit,
        [NavEvents.ZOOM_IN]: () =>
          cameraControls.current
            ?.dolly(1, true)
            .finally(() => sendThreeDeeEvent()),
        [NavEvents.ZOOM_OUT]: () =>
          cameraControls.current
            ?.dolly(-1, true)
            .finally(() => sendThreeDeeEvent()),
      });
    }, []);

    useEffect(() => {
      // this useEffect handles the new events coming in for the student
      // the first top bit handles the explore model toggle return to instructor view
      if (events?.length) {
        const canvasDataEv = events[events.length - 1];
        const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);

        if (
          sendEvent &&
          leader &&
          exploreToggleEventCheck(eventPayload).isExploreEvent &&
          !exploreToggleEventCheck(eventPayload).exploreModeValue &&
          canvasDataEv.isSelf
        ) {
          sendEvent(
            {
              type: EventTypes.THREE_DEE_DRAG,
              leader: leader,
              threeDeeData: {
                x: cameraControls.current?.azimuthAngle,
                y: cameraControls.current?.polarAngle,
                z: cameraControls.current?.distance,
              },
            },
            state
          );
          return;
        }
        // on replay we don't have an instructor to ask for their view so
        // we have to cycle back through the events to find one that has
        // 3d view attached to it and then we set our replay view to that
        if (
          mode === CanvasMode.REPLAY &&
          exploreToggleEventCheck(eventPayload).isExploreEvent &&
          !exploreToggleEventCheck(eventPayload).exploreModeValue
        ) {
          for (let i = events.length - 1; i >= 0; i--) {
            const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
            if (eventPayload.type === EventTypes.THREE_DEE_DRAG) {
              cameraControls.current.rotateAzimuthTo(
                eventPayload.threeDeeData.x,
                true
              );
              cameraControls.current.rotatePolarTo(
                eventPayload.threeDeeData.y,
                true
              );
              cameraControls.current.dollyTo(eventPayload.threeDeeData.z, true);
              break;
            }
          }
          return;
        }

        if (
          isThreeDeeEventCheck(eventPayload) &&
          !state.exploreMode &&
          !canvasDataEv.isSelf
        ) {
          if (eventPayload) {
            switch (eventPayload.type) {
              case EventTypes.THREE_DEE_DRAG:
                cameraControls.current.rotateAzimuthTo(
                  eventPayload.threeDeeData.x,
                  true
                );
                cameraControls.current.rotatePolarTo(
                  eventPayload.threeDeeData.y,
                  true
                );
                cameraControls.current.dollyTo(
                  eventPayload.threeDeeData.z,
                  true
                );
                break;
              case EventTypes.THREE_DEE_HOME:
                handleFit();
                break;
            }
          }
        }
      }
    }, [events.length, leader, state.exploreMode, handleFit]);

    return (
      <>
        <CameraControls ref={cameraControls} />
      </>
    );
  };

  return (
    <Canvas
      style={{ height: height, touchAction: "none", cursor: "grab" }}
      id="threeDeeViewer"
      tabIndex={0}
    >
      {/* @ts-ignore */}
      <ambientLight intensity={1} />
      <spotLight
        // @ts-ignore
        intensity={0.5}
        angle={0.1}
        penumbra={1}
        position={[5, 25, -15]}
        castShadow
      />
      <Suspense fallback={<Spinner />}>
        <group dispose={null}>
          <primitive
            ref={inputRef}
            position={[0, -1, 0]}
            key={gltf.scene}
            scale={scale}
            object={gltf.scene}
          />
        </group>
      </Suspense>
      <CameraController />
    </Canvas>
  );
};

export default ThreeDeeViewer;
