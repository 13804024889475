import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AudioInputVFControl,
  useDeviceLabelTriggerStatus,
  useLocalAudioOutput,
} from "amazon-chime-sdk-component-library-react";
import { useAppContext } from "../../contexts/appContext";
import { MeetingMode, MeetingRole } from "../../contexts/types";
import { ToolBar } from "./toolBar";
import { Icon } from "../icon";
import { CameraToggle } from "./controls/cameraToggle";
import { CanvasNav } from "./controls/canvasNav";
import { ChatToggle } from "./controls/chatToggle";
import { MicToggle } from "./controls/micToggle";
import { EndMeetingControl } from "./controls/endMeetingControl";
import { DARK, ThemeContext } from "../../contexts/themeContext";
import { HandRaise } from "./controls/handRaise";
import { ClosedCaptionsButton } from "./controls/closedCaptionsButton";
import { MoreActionsButton } from "./controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "./controls/moreActions/moreActionsWrapper";
import { MobileParticipantsToggle } from "./mobile/mobileParticipantsToggle";
import { useScreenContext } from "../../contexts/screenContext";
import { isDesktop, isTablet } from "react-device-detect";
import Spinner from "../spinner";
import { IconButton } from "../buttons/iconButton";
import { MoreActionsItem } from "./controls/moreActions/moreActionsItem";
import { FullScreenToggle } from "./controls/fullScreenToggle";
import { Button, PRIMARY, SMALL } from "../buttons/button";
import { HelperPopUp } from "../popUps/helperPopUp";
import { ChatReplayMessage } from "../../providers/replayDataMessages";
import { ChatDataMessage } from "../../providers/dataMessages";
import { ClosedCaptionsSize } from "./controls/closedCaptionsSize";

type StudentToolsProps = {
  showClosedCaptions: boolean;
  toggleClosedCaptions: () => void;
  showDeviceSettings: boolean;
  toggleDeviceSettings: () => void;
  showInstructors: boolean;
  toggleInstructors: () => void;
  messages: ChatReplayMessage[] | ChatDataMessage[];
  messageCount: number;
};

/** Toolbar component for the bottom toolbar visible for students using desktop devices */
export const StudentTools: React.FC<StudentToolsProps> = ({
  showClosedCaptions,
  toggleClosedCaptions,
  showDeviceSettings,
  toggleDeviceSettings,
  showInstructors,
  toggleInstructors,
  messages,
  messageCount,
}) => {
  const {
    isWebAudioEnabled,
    meetingMode,
    meetingRole,
    simpleView,
    isStudentAVMeeting,
    isInstructorAVMeeting,
    showParticipants,
    state,
    meetingHasChat,
  } = useAppContext();
  const {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isExtraExtraLarge,
    useMobileTools,
  } = useScreenContext();
  const { theme } = useContext(ThemeContext);
  const includeAVControls = meetingMode === MeetingMode.Attendee;
  const [isLeader] = useState(meetingRole === MeetingRole.Presenter);
  const status = useDeviceLabelTriggerStatus();
  const [showMoreActionsMenu, setShowMoreActionsMenu] = useState(false);
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const [useMoreActionsButton, setUseMoreActionsButton] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [captionsSize, setCaptionsSize] = useState(1);
  const [showCcSizePopup, setCcSizePopup] = useState(false);

  useEffect(() => {
    // if not large screen or not desktop, or if tablet, use moreOptionsMenu
    if (!isLarge || !isDesktop || isTablet) {
      setUseMoreActionsButton(true);
    } else {
      setUseMoreActionsButton(false);
    }
  }, [
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isExtraExtraLarge,
    isDesktop,
    isTablet,
  ]);

  // if you are a student and in the meeting you should have audio
  useEffect(() => {
    if (!isAudioOn) {
      toggleAudio();
    }
  }, [isAudioOn, toggleAudio]);

  return (
    <div className="student-tools-wrapper">
      {!simpleView && (
        <ToolBar
          orientation="horizontal"
          className="student-tools"
          isMinimizable
        >
          <Suspense fallback={<Spinner />}>
            {!useMoreActionsButton && (
              <IconButton
                onClick={toggleDeviceSettings}
                iconName="settings"
                desc="View settings"
                toolTip="top"
                btnId="toggle-device-settings"
              />
            )}
            <CanvasNav isLeader={isLeader} exploreMode={state.exploreMode} />
            <HandRaise component="ICON_BUTTON" />
            {meetingHasChat && (
              <ChatToggle
                toolTip="top"
                emitClick={(e) => {}}
                messages={messages}
                chatMessagesCount={messageCount}
                component="ICON_BUTTON"
              />
            )}
            <span className="tool-divider" />
            {state.exploreMode ? (
              <div className="mode">
                <Icon
                  name={theme === DARK ? "exploring-dark" : "exploring"}
                  desc="Exploring this board independently"
                  toolTip="none"
                  stroke="none"
                  hoverState={false}
                  height="16px"
                  width="100%"
                />
                <p>Exploring</p>
              </div>
            ) : (
              <div className="mode">
                <Icon
                  name={theme === DARK ? "focused-dark" : "focused"}
                  desc="Following along with presenter"
                  toolTip="none"
                  stroke="none"
                  hoverState={false}
                  height="16px"
                  width="100%"
                />
                <p>Focused</p>
              </div>
            )}
            <span className="tool-divider" />
            {includeAVControls && isStudentAVMeeting && (
              <Fragment>
                {isWebAudioEnabled ? (
                  <AudioInputVFControl />
                ) : (
                  <MicToggle toolTip="top" />
                )}
                <CameraToggle toolTip="top" />
              </Fragment>
            )}
            {includeAVControls &&
              (isStudentAVMeeting || isInstructorAVMeeting) && (
                <ClosedCaptionsButton
                  toolTip="top"
                  showClosedCaptions={showClosedCaptions}
                  toggleClosedCaptions={toggleClosedCaptions}
                  component="ICON_BUTTON"
                />
              )}
            {/* More Actions Menu with Participants toggle, Full Screen toggle, Leave meeting button */}
            <MoreActionsButton
              showActionsInput={showMoreActionsMenu}
              toolTip="top"
              alignment="horizontal"
              id="student-tools__more-actions"
              onClick={(e) => setShowMoreActionsMenu(!showMoreActionsMenu)}
            >
              <MoreActionsWrapper
                itemClick={(e) => {
                  e.preventDefault();
                  setShowMoreActionsMenu(false);
                }}
                upOrDown="up"
                leftOrRight="left"
              >
                <MoreActionsItem id="student-tools__more-actions__mobile-participants-toggle">
                  <MobileParticipantsToggle
                    showInstructors={showInstructors}
                    toggleInstructors={toggleInstructors}
                  />
                </MoreActionsItem>
                <MoreActionsItem id="student-tools__more-actions__full-screen">
                  <FullScreenToggle component="BUTTON" />
                </MoreActionsItem>
                {(isInstructorAVMeeting || isStudentAVMeeting) && (
                  <>
                    <MoreActionsItem id="replay-tools__more-actions__captions">
                      <Button
                        btnType={PRIMARY}
                        text="View settings"
                        size={SMALL}
                        disabled={false}
                        id="mobile-devices-meeting-button"
                        onClick={toggleDeviceSettings}
                        icon="settings"
                      />
                    </MoreActionsItem>
                    <MoreActionsItem id="replay-tools__more-actions__captions-size">
                      <ClosedCaptionsSize
                        onClick={() => {
                          setCcSizePopup(!showCcSizePopup);
                        }}
                        onSelectSize={(e) => {
                          setCaptionsSize(e);
                          setTimeout(() => {
                            setCcSizePopup(false);
                            setShowMoreActionsMenu(false);
                          }, 2000);
                        }}
                        captionsSize={captionsSize}
                        showCcSizePopup={showCcSizePopup}
                      />
                    </MoreActionsItem>
                  </>
                )}
                {!isLeader && useMoreActionsButton && (
                  <MoreActionsItem id="student-tools__more-actions__end-meeting-control">
                    {/* Only available for students on mobile */}
                    <Button
                      btnType={PRIMARY}
                      text="Leave meeting"
                      size={SMALL}
                      disabled={false}
                      id="mobile-leave-meeting-button"
                      onClick={() => setShowEndModal(!showEndModal)}
                      icon="log-out"
                    />
                  </MoreActionsItem>
                )}
              </MoreActionsWrapper>
            </MoreActionsButton>
            {!showParticipants && (
              <HelperPopUp
                userMessage={`${
                  useMobileTools ? "Tap" : "Click"
                } here to show student videos`}
                bottom={"40px"}
                right={"8px"}
                downArrow={true}
                isDismissible={false}
                isTimed={true}
                minWidth="148px"
                arrowRight="4px"
              />
            )}
            {!useMoreActionsButton && (
              <>
                <IconButton
                  iconName="log-out"
                  onClick={() => setShowEndModal(!showEndModal)}
                  desc="Leave"
                  toolTip="right"
                  btnId="log-out-button"
                />
              </>
            )}
            <EndMeetingControl
              showEndModal={showEndModal}
              handleDismiss={() => setShowEndModal(!showEndModal)}
            />
          </Suspense>
        </ToolBar>
      )}
    </div>
  );
};
