import React, { useContext } from "react";
import { useAppContext } from "../../../contexts/appContext";
import { Icon } from "../../icon";
import { DARK, ThemeContext } from "../../../contexts/themeContext";
import { Container } from "../../container";
import { useScreenContext } from "../../../contexts/screenContext";

/** Focus/Explore Mode indicator component for mobile student view
 */

export const MobileMode: React.FC = () => {
  const { state } = useAppContext();
  const { theme } = useContext(ThemeContext);
  const { isSmall } = useScreenContext();

  return (
    <div className="mode-mobile-wrapper">
      {isSmall ? (
        state.exploreMode ? (
          <Container className="mode-mobile" isDismissable={false}>
            <Icon
              name={theme === DARK ? "exploring-dark" : "exploring"}
              desc="Exploring this board independently"
              toolTip="none"
              stroke=""
              height="24px"
            />
            <p>Exploring</p>
          </Container>
        ) : (
          <Container className="mode-mobile" isDismissable={false}>
            <Icon
              name={theme === DARK ? "focused-dark" : "focused"}
              desc="Following along with presenter"
              toolTip="none"
              stroke=""
              hoverState={false}
              height="24px"
            />
            <p>Focused</p>
          </Container>
        )
      ) : (
        <div className="icon-tool__wrapper">
          <div className="icon-tool__inner" style={{ right: "4px" }}>
            <Icon
              name={state.exploreMode ? "explore" : "focus"}
              desc={state.exploreMode ? "Explore" : "Focus"}
              toolTip="none"
              className="mode-mobile"
              height="24px"
            />
          </div>
        </div>
      )}
    </div>
  );
};
