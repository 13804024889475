import { NetworkStatus, QueryResult } from "@apollo/client";
import React, { Fragment, useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { ApolloErrorsList } from "../apolloErrorsList";
import { SearchBar } from "../inputs/searchBar";
import { ModalBody } from "../modal/modalBody";
import { ModalFooter } from "../modal/modalFooter";
import { ModalHeader } from "../modal/modalHeader";
import Spinner from "../spinner";
import { Filter, FilterBar } from "../inputs/filterBar";

type ContentModalWrapperType = {
  title: string;
  handleSearch?: () => Promise<void>;
  searchValue?: string;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  handleFilters?: (e, i) => void;
  queryResult?: QueryResult;
  children: JSX.Element;
  footerChildren: JSX.Element;
  altSearchChildren?: JSX.Element;
  showFilter?: boolean;
  filterArray?: Filter[];
};

/**
 * @param {ContentModalWrapperType} props
 * @prop {string} title - Modal heading
 * @prop {() => Promise<void>} handleSearch - Optional function that is called when search form is submitted
 * @prop {string} searchValue - Current value of the search form
 * @prop {React.Dispatch<React.SetStateAction<string>>} setSearchValue - Setter for updating the value of the search term
 * @prop {QueryResult} queryResult - Result from the GQL query that populates the modal content
 * @prop {JSX.Element} children - Content for the ModalBody component
 * @prop {JSX.Element} footerChildren - Content for the ModalFooter component
 */
export const ContentModalWrapper: React.FC<ContentModalWrapperType> = ({
  title,
  handleSearch,
  searchValue,
  setSearchValue,
  handleFilters,
  queryResult,
  children,
  footerChildren,
  altSearchChildren,
  showFilter,
  filterArray,
}) => {
  const { loading, error, networkStatus } = queryResult || {};
  const [simpleBarHeight, setSimpleBarHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>();

  // Get the heigth of the content div so we can set the simple bar to the same height
  useEffect(() => {
    setSimpleBarHeight(contentRef.current.clientHeight);
  }, []);

  return (
    <div className="add-content__right">
      <ModalHeader className="add-content__header">
        <h1>{title}</h1>
        {handleSearch && setSearchValue && (
          <Fragment>
            <div className="search__searchbar">
              {/* Search bar */}
              <SearchBar
                handleSearch={handleSearch}
                value={searchValue}
                setValue={setSearchValue}
              />
            </div>
            <div className="filter-section">
              {showFilter && (
                <FilterBar
                  handleFilters={(e, i) => {
                    handleFilters(e, i);
                  }}
                  filters={filterArray}
                  hasLabel={true}
                />
              )}
            </div>
          </Fragment>
        )}

        {altSearchChildren && altSearchChildren}
      </ModalHeader>
      <ModalBody className="add-content__body">
        <div className="tab-content" ref={contentRef}>
          {loading || networkStatus === NetworkStatus.refetch ? (
            // Display spinner when loading
            <Spinner />
          ) : error?.graphQLErrors ? (
            // Display GQL errors when there is an issue with the query
            <div className="art-object-gallery__no-results">
              <div className="no-results-message">
                <span>The following errors occurred:</span>
                <ApolloErrorsList error={error} />
              </div>
            </div>
          ) : (
            <SimpleBar
              forceVisible="y"
              autoHide={false}
              style={{ height: `${simpleBarHeight}px` }}
            >
              {children}
            </SimpleBar>
          )}
        </div>
      </ModalBody>
      {footerChildren && (
        <ModalFooter>
          <div className="add-content__footer">{footerChildren}</div>
        </ModalFooter>
      )}
    </div>
  );
};
