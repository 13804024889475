import { ENSEMBLE } from "../constants";
import config from "./config";

/** Generates the identifying caption information for an ensemble wall
 * @param {string} ensembleIndex - Index that identifies the ensemble
 * @returns {string} - Caption information for the ensemble index
 */
export const getRoomAndTitleText = (ensembleIndex: string): string => {
  const wallTitle = ENSEMBLE[ensembleIndex].wallTitle;
  const wallTitleStr = wallTitle ? `, ${wallTitle}` : "";

  return `${ENSEMBLE[ensembleIndex].roomTitle}${wallTitleStr}`;
};

/** Generates the URL for an ensemble wall image
 * @param {string} ensembleIndex - Index that identifies the ensemble
 * @returns {string} - URL of the ensemble image
 */
export const getEnsembleImgUrl = (ensembleIndex: string): string => {
  return `${config.collectionBaseImgUrl}/ensembles/${ensembleIndex}.jpg`;
};

/** Generates the URL for an ensemble wall IIIF manifest
 * @param {string} ensembleIndex - Index that identifies the ensemble
 * @returns {string} - URL of the ensemble IIIF manifest
 */
export const getEnsembleImgIIF = (ensembleIndex: string): string => {
  return `https://collection-tif-tiler.s3.amazonaws.com/ensembleTiles/${ensembleIndex}/info.json`;
};
