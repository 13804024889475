import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Icon } from "../icon";

type TextInputProps = {
  autofocus?: boolean;
  autoComplete?: string;
  disabled: boolean;
  errorMessage: string;
  hasError: boolean;
  icon?: string;
  iconStroke?: string;
  iconFill?: string;
  id: string;
  initialValue?: string;
  label: string;
  minLength?: number;
  minLengthMessage?: string;
  maxLength?: number;
  maxLengthMessage?: string;
  name: string;
  pattern?: string;
  patternMessage?: string;
  parentForm?: string;
  placeHolder?: string;
  required?: boolean;
  onChange?: (e) => void;
  onBeforeInput?: (e) => void;
  onLengthError?: (e) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onBlur?: (e) => void;
  type?: string;
};

// the various form validations going on inside the input text will emit onChange and can be found at
// e.target.validity.valid if that is true all of the validators have passed and the input is good to go

/**
 * Component for an VxP text input
 * @TextInputProps autoComplete, autofocus, disabled, errorMessage, hasError, icon, iconFill, iconStroke, id, initialValue, label, name, minLength, minLengthMessage, maxLength, maxLengthMessage, parentForm, pattern, patternMessage, placeHolder, required, onChange, onBeforeInput, onKeyDown
 */

export const TextInput: React.FC<TextInputProps> = ({
  autofocus,
  autoComplete = "on",
  disabled,
  errorMessage,
  hasError,
  icon,
  iconFill,
  iconStroke,
  id,
  initialValue,
  label,
  name,
  minLength,
  minLengthMessage,
  maxLength,
  maxLengthMessage,
  parentForm,
  pattern,
  patternMessage,
  placeHolder,
  required,
  onChange,
  onBeforeInput,
  onKeyDown,
  onBlur,
  onLengthError,
  type = "text",
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [minError, setMinError] = useState<boolean>(false);
  const [maxError, setMaxError] = useState<boolean>(false);
  const [patternError, setPatternError] = useState<boolean>(false);
  const [requiredError, setRequiredError] = useState<boolean>(false);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    } else {
      setValue("");
    }
  }, [initialValue]);

  return (
    <div className="text-input-container">
      <label
        htmlFor={id}
        className={classnames("text-input-label", {
          disabled: disabled,
          error:
            hasError || minError || maxError || patternError || requiredError,
          "label-icon": icon,
          transform: isFocus || placeHolder || value,
        })}
      >
        <span>{label}</span>
      </label>
      {icon && (
        <span className="text-input-icon">
          <Icon
            name={icon}
            desc={icon}
            toolTip="none"
            stroke={iconStroke}
            fill={iconFill}
            height="22px"
          />
        </span>
      )}
      <input
        autoComplete={autoComplete}
        aria-errormessage={id + "error"}
        aria-invalid={hasError}
        className={classnames("text-input-input", {
          error:
            hasError || minError || maxError || patternError || requiredError,
          "input-icon": icon,
          disabled: disabled,
        })}
        autoFocus={autofocus}
        type={type}
        id={id}
        minLength={minLength}
        maxLength={maxLength}
        name={name}
        disabled={disabled}
        form={parentForm}
        pattern={pattern}
        placeholder={placeHolder}
        required={required}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          if (e.target?.validity?.patternMismatch === true) {
            setPatternError(true);
            return;
          }
          if (e.target.value.length >= minLength) {
            setMinError(false);
          }
          if (e.target.value.length >= maxLength) {
            setMaxError(true);
            onLengthError(true);
          } else {
            setValue(e.target.value);
            onChange && onChange(e);
            setMaxError(false);
            setPatternError(false);
            setRequiredError(false);
          }
        }}
        onBeforeInput={(e: React.FormEvent<HTMLInputElement>): void => {
          let ke = e.nativeEvent as KeyboardEvent;
          onBeforeInput && onBeforeInput(ke.key);
        }}
        onFocus={(e) => setIsFocus(true)}
        onBlur={(e) => {
          value === "" ? setIsFocus(false) : setIsFocus(true);
          if (value.length < minLength) {
            setMinError(true);
          }
          if (value === "" && required) {
            setRequiredError(true);
          }

          onBlur && onBlur(e);
        }}
        onKeyDown={onKeyDown}
      />
      {/* The error stuff below is kinda alot but with the various error states I figured this was the 
      easiest way to do it and to have the errors stack correctly...
      */}
      {(hasError || requiredError) && (
        <p role="alert" id={id + "error"} className="it-error-message">
          {errorMessage}
        </p>
      )}
      {minError && (
        <p
          role="alert"
          id={id + "min-error"}
          className={classnames("it-error-message", {
            "multi-error": hasError || requiredError,
          })}
        >
          {minLengthMessage}
        </p>
      )}
      {maxError && (
        <p
          role="alert"
          id={id + "max-error"}
          className={classnames("it-error-message", {
            "multi-error": hasError || requiredError,
          })}
        >
          {maxLengthMessage}
        </p>
      )}
      {patternError && (
        <p
          role="alert"
          id={id + "pattern-error"}
          className={classnames("it-error-message", {
            "multi-error": hasError || requiredError || minError || maxError,
          })}
        >
          {patternMessage}
        </p>
      )}
    </div>
  );
};
