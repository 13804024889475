import React, { Fragment, useEffect, useRef } from "react";
import {
  VideoTile as ChimeVideoTile,
  useApplyVideoObjectFit,
  useAttendeeStatus,
  useAudioVideo,
} from "amazon-chime-sdk-component-library-react";
import classnames from "classnames";
import { IconButton } from "../buttons/iconButton";
import { Icon } from "../icon";
import {
  DataEmotesActionType,
  EmoteTypes,
  useDataEmotes,
} from "../../providers/dataEmotes";
import { useDataEvents } from "../../providers/dataEvents";
import { EventTypes } from "../../providers/types";
import { useAppContext } from "../../contexts/appContext";
import { Participant } from "../../contexts/types";
import variables from "../../styles/variables.scss";
import { VideoTile, VideoType } from "./videoTile";

type StudentVideoProps = {
  tileId?: number;
  name: string;
  iconHex: string;
  participant: Participant;
};

export const StudentVideo: React.FC<StudentVideoProps> = ({
  name,
  tileId,
  iconHex,
  participant,
}) => {
  const { isInstructorOrModerator, state } = useAppContext();
  const { sendEmote, raisedHands } = useDataEmotes();
  const audioVideo = useAudioVideo();
  const videoEl = useRef<HTMLVideoElement>(null);
  useApplyVideoObjectFit(videoEl);
  const participantStats = useAttendeeStatus(participant.chimeAttendeeId);
  participant["muted"] = participantStats.muted;
  participant["videoEnabled"] = participantStats.videoEnabled;
  const { sendEvent } = useDataEvents();

  useEffect(() => {
    if (!audioVideo || !videoEl.current) {
      return;
    }

    audioVideo.bindVideoElement(tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId]);

  const handleRaisedHand = () => {
    if (isInstructorOrModerator) {
      sendEmote({
        type: EmoteTypes.HAND_LOWER,
        leader: false,
        action: DataEmotesActionType.REMOVE,
        targetAttendeeId: participant.chimeAttendeeId,
      });
    }
  };

  const handleMute = () => {
    if (isInstructorOrModerator && !participant?.muted) {
      sendEvent(
        {
          type: EventTypes.MUTE_INDIVIDUAL,
          leader: true,
          attendeeId: participant.chimeAttendeeId,
        },
        state
      );
    }
  };

  return (
    <div className="videos__student">
      <VideoTile
        isVideoEnabled={!!tileId}
        isInstructorOrModerator={isInstructorOrModerator}
        videoType={VideoType.STUDENT}
        speaking={participant.speaking}
        iconHex={iconHex}
        name={name}
        handRaised={raisedHands.has(participant.chimeAttendeeId)}
        muted={participant.muted}
        handleMute={handleMute}
        handleLowerHand={handleRaisedHand}
        dismissable={false}
        softBanned={participant.softBanned}
      >
        <Fragment>
          <ChimeVideoTile
            ref={videoEl}
            nameplate={name}
            className={classnames("video__tile", `ch-remote-video--${tileId}`, {
              "video__tile-featured": participant.speaking,
              "video__tile-hand-raised": raisedHands.has(
                participant.chimeAttendeeId
              ),
            })}
          />
          <div className="video__tile-header --video-on">
            <header className="ch-nameplate --icon">
              {isInstructorOrModerator ? (
                <IconButton
                  iconName={participant.muted ? "mic-off" : "mic"}
                  onClick={() => {
                    if (isInstructorOrModerator && !participant?.muted) {
                      sendEvent(
                        {
                          type: EventTypes.MUTE_INDIVIDUAL,
                          leader: true,
                          attendeeId: participant.chimeAttendeeId,
                        },
                        state
                      );
                    }
                  }}
                  desc={
                    participant?.muted
                      ? `${participant.name} is muted`
                      : `Mute ${participant.name}`
                  }
                  toolTip={participant.muted ? "none" : "top"}
                  stroke={
                    participant.muted ? variables.white : variables.actionGreen
                  }
                  btnId="mute-mic-button"
                  size="0.875rem"
                  hoverState={!participant.muted}
                />
              ) : (
                <Icon
                  name={participant.muted ? "mic-off" : "mic"}
                  desc={`${name} is ${participant.muted ? "muted" : "unmuted"}`}
                  toolTip="none"
                  height="0.875rem"
                />
              )}
            </header>
          </div>
        </Fragment>
      </VideoTile>
    </div>
  );
};
