import React, { useContext } from "react";
import { DARK, ThemeContext } from "../../../contexts/themeContext";
import { Button, PRIMARY, SMALL } from "../../buttons/button";
import { IconButton, IconButtonProps } from "../../buttons/iconButton";
import variables from "../../../styles/variables.scss";
import { useAppContext } from "../../../contexts/appContext";

type ClosedCaptionsButtonProps = {
  toolTip?: IconButtonProps["toolTip"];
  showClosedCaptions: boolean;
  toggleClosedCaptions: () => void;
  component: "BUTTON" | "ICON_BUTTON";
  fill?: string;
  disabled?: boolean;
};

export const ClosedCaptionsButton: React.FC<ClosedCaptionsButtonProps> = ({
  toolTip,
  showClosedCaptions,
  toggleClosedCaptions,
  component,
  fill,
  disabled,
}) => {
  const { theme } = useContext(ThemeContext);
  // The SVG that we are using for CC updates the color based on fill instead of path
  const fillColor = fill ? fill : theme === DARK ? variables.white : undefined;
  const fillColorIncludeLabel = fill
    ? fill
    : theme === DARK
    ? undefined
    : variables.white;

  return component === "BUTTON" ? (
    <Button
      onClick={toggleClosedCaptions}
      btnType={PRIMARY}
      text={
        showClosedCaptions ? "Hide closed captions" : "Show closed captions"
      }
      customWidth="100%"
      size={SMALL}
      fill={fillColorIncludeLabel}
      icon={
        showClosedCaptions ? "closed-captioning-filled" : "closed-captioning"
      }
      id="closed-captions-button"
      disabled={disabled}
    />
  ) : (
    <IconButton
      onClick={toggleClosedCaptions}
      iconName={
        showClosedCaptions ? "closed-captioning-filled" : "closed-captioning"
      }
      desc={
        showClosedCaptions ? "Hide closed captions" : "Show closed captions"
      }
      btnId="closed-captions-button"
      toolTip={toolTip}
      selected={showClosedCaptions}
      fill={fillColor}
      disabled={disabled}
    />
  );
};
