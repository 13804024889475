import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    const isInFullScreen = () => document?.fullscreenElement !== null;
    const checkFullScreen = () => {
      // if (isInFullScreen()) {
      // The browser entered full-screen mode
      setTimeout(() => {
        handleResize();
      }, 0);
      // }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("fullscreenchange", checkFullScreen);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("fullscreenchange", checkFullScreen);
    };
  });

  return windowDimensions;
};
