import React, { useEffect, useMemo, useState } from "react";
import {
  useFeaturedTileState,
  useRemoteVideoTileState,
  RosterType,
  RosterAttendeeType,
  useDeviceLabelTriggerStatus,
  DeviceLabelTriggerStatus,
  useActiveSpeakersState,
} from "amazon-chime-sdk-component-library-react";
import classnames from "classnames";
import { useAppContext } from "../../contexts/appContext";
import { MeetingMode, MeetingRole, Participant } from "../../contexts/types";
import { StorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { Attendee } from "../../types";
import { useDataEmotes } from "../../providers/dataEmotes";
import { InstructorVideos } from "./instructorVideos";
import { StudentVideos } from "./studentVideos";
import { UserVideo } from "./userVideo";
import { useScreenContext } from "../../contexts/screenContext";
import { UserVideoToggle } from "../toolbars/controls/userVideoToggle";
import { VideoType } from "./videoTile";
import { CanvasDataEvent } from "../../providers/types";
import { useRoster } from "../../providers/rosterProvider";

export type VideoProps = {
  tileId?: number;
  name: string;
  featured: boolean;
  iconHex: string;
  meetingRole?: number;
  isInstructor?: boolean;
  handIsRaised?: boolean;
  attendeeId?: string;
};

type VideosProps = {
  testRoster?: {
    [attendeeId: string]: {
      name: string;
    };
  };
  testTiles?: number[];
  showInstructors: boolean;
  toggleInstructors: () => void;
  events?: CanvasDataEvent[];
};

export const Videos: React.FC<VideosProps> = ({
  testRoster,
  testTiles,
  showInstructors,
  toggleInstructors,
  events,
}) => {
  const { getLocalStorage } = useLocalStorage();
  const {
    firstName,
    lastName,
    meetingMode,
    meetingRole,
    attendeeList,
    setAttendeeList,
    isInstructorOrModerator,
    showParticipants,
    setShowParticipants,
    showUserVideo,
    toggleShowUserVideo,
    isInstructorAVMeeting,
    isStudentAVMeeting,
    meetingHasChat,
    simpleView,
  } = useAppContext();
  const { useMobileTools } = useScreenContext();
  let { roster } = useRoster();
  const { raisedHands } = useDataEmotes();
  const { tileId: featuredTileId } = useFeaturedTileState();
  const activeSpeaker = useDebounce(useActiveSpeakersState(), 1500);
  let { tiles, attendeeIdToTileId } = useRemoteVideoTileState();
  const [instructorList, setInstructorList] = useState<Participant[]>([]);
  const locAttendee: Attendee = getLocalStorage(StorageKeys.attendee);
  const includeAVControls = meetingMode === MeetingMode.Attendee;
  const permissionsStatus = useDeviceLabelTriggerStatus();
  const shouldShowStudentVideos =
    (!simpleView && isInstructorOrModerator) ||
    (meetingRole === MeetingRole.Audience && isStudentAVMeeting);

  const userVideoCanBeShown =
    (!useMobileTools &&
      // If a user joins without audio or visual (the meeting mode check) or
      // if a user denies the browser A/V trigger this will hide their video
      includeAVControls &&
      permissionsStatus !== DeviceLabelTriggerStatus.DENIED &&
      meetingRole === MeetingRole.Audience &&
      isStudentAVMeeting) ||
    (isInstructorOrModerator && isInstructorAVMeeting);

  const updatedRoster = useMemo(() => {
    return {
      ...(roster as {
        [attendeeId: string]: RosterAttendeeType & {
          tileId?: number;
          meetingRole: number;
        };
      }),
    };
  }, [roster]);

  let AttendeeId = "0"; // Defaulting to "0" for testing purposes
  AttendeeId = locAttendee?.AttendeeId;
  // Set up roster and tiles for testing purposes
  if (testRoster) roster = testRoster as RosterType;
  if (testTiles) tiles = testTiles;

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes
      // once the value settles after the delay return the debouncedValue
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  // Combine roster and tiles data to create attendee list
  useEffect(() => {
    let updatedAttendeeList: Participant[] = [];
    let updatedAttendeeVideoOn: Participant[] = [];
    let updatedInstructorList: Participant[] = [];
    // we rip through the updatedRoster and sort the instructors and students into their own arrays
    Object.values(updatedRoster.valueOf()).forEach((attendeeId) => {
      if (attendeeId === undefined) return;
      let participant = attendeeId as unknown as Participant;
      const tileId = attendeeIdToTileId[participant.AttendeeId];
      participant.handIsRaised = raisedHands.has(participant.AttendeeId);
      participant.speaking = false;
      // If there is a tile id for the attendee, add it to the attendee info
      // we assign a new tileId everytime so we have to strip the old one if it is there
      if (tileId) {
        participant.tileId = tileId;
      } else {
        participant.tileId = undefined;
      }
      if (
        participant.meetingRole === MeetingRole.Presenter &&
        AttendeeId !== participant.AttendeeId
      ) {
        participant.speaking =
          activeSpeaker.indexOf(participant?.AttendeeId) > -1;
        updatedInstructorList.push(participant);
      } else {
        // we don't check for "your video" here as it is strip out in the view map function
        if (participant.tileId) {
          updatedAttendeeVideoOn.push(participant);
        } else {
          updatedAttendeeList.push(participant);
        }
      }
    });
    // add the video on users to the front of the updated attendee list
    updatedAttendeeList = updatedAttendeeVideoOn.concat(updatedAttendeeList);
    // what's that you want more hacky bits? okay so this part handles the raise hand
    // reorder to add the raise hand participants to the front in the order they
    // raised their hands
    let handRaisedAttendees: Participant[] = [];
    raisedHands?.forEach((handId) => {
      updatedAttendeeList?.forEach((attendee, index) => {
        if (attendee?.AttendeeId === handId) {
          attendee.handIsRaised = true;
          handRaisedAttendees.push(attendee);
          updatedAttendeeList.splice(index, 1);
        }
      });
    });
    if (handRaisedAttendees.length > 0) {
      updatedAttendeeList = handRaisedAttendees.concat(updatedAttendeeList);
    }
    // this is the vaguely hacky bit that looks for the active speakers. We are
    // at the mercy of Chimes "Active Speaker Policy". https://aws.github.io/amazon-chime-sdk-component-library-react/?path=/story/sdk-hooks-useactivespeakersstate--page
    // so it might seem like the not most active speaker hangs out for a bit after they are done talking
    // the logic that they have in place is supposed to simulate a real meeting where
    // you only turn your attention to a new speaker if they have been speaking for some time
    let speakingAttendees: Participant[] = [];
    activeSpeaker?.forEach((speaker) => {
      updatedAttendeeList?.forEach((attendee, index) => {
        if (attendee.AttendeeId === speaker) {
          attendee.speaking = true;
          speakingAttendees.push(attendee);
          updatedAttendeeList.splice(index, 1);
        }
      });
    });
    if (speakingAttendees.length > 0) {
      updatedAttendeeList = speakingAttendees.concat(updatedAttendeeList);
    }

    setAttendeeList(updatedAttendeeList);
    setInstructorList(updatedInstructorList);
    return function () {
      updatedAttendeeList = [];
      updatedInstructorList = [];
    };
  }, [
    updatedRoster,
    attendeeIdToTileId,
    featuredTileId,
    AttendeeId,
    raisedHands,
    raisedHands.size,
    activeSpeaker,
  ]);

  // only show participant videos by default on page load if meeting role is presenter
  useEffect(() => {
    setShowParticipants(isInstructorOrModerator ? true : false);
  }, []);

  return (
    <div className="videos">
      <div className="videos__wrapper">
        {/* these empty flex 1 divs are for automatic layout handling for instuctor / student on/off on desktop */}
        <div style={{ flex: "1" }}>
          {(!useMobileTools || MeetingRole.Presenter) &&
            (showInstructors ? (
              <InstructorVideos
                instructorList={instructorList}
                toggleInstructors={toggleInstructors}
                events={events}
              />
            ) : (
              <div className="icon-tool__wrapper">
                <div
                  className="icon-tool__inner"
                  style={{ top: "-4px", left: "4px" }}
                >
                  <UserVideoToggle
                    toolTip="bottom"
                    showVideo={showInstructors}
                    toggleShowVideo={toggleInstructors}
                    videoType={VideoType.INSTRUCTOR}
                  />
                </div>
              </div>
            ))}
        </div>
        {!useMobileTools && showParticipants && shouldShowStudentVideos && (
          <div
            className={classnames("student__wrapper", {
              "__all-participants": showInstructors,
              "_no-participants": !showParticipants,
            })}
          >
            <StudentVideos attendeeList={attendeeList} />
          </div>
        )}
        {/* these empty flex 1 divs are for automatic layout handling for instuctor / student on/off on desktop */}
        {!useMobileTools && <div style={{ flex: "1" }}></div>}
      </div>
      {userVideoCanBeShown &&
        (showUserVideo ? (
          <UserVideo
            name={firstName + " " + lastName}
            iconHex={locAttendee?.iconColor?.split("_")[1]}
            handIsRaised={raisedHands.has(locAttendee?.AttendeeId)}
          />
        ) : (
          <div className="icon-tool__wrapper">
            <div
              className="icon-tool__inner user-video-toggle__inner"
              style={{ display: useMobileTools ? "none" : "null" }}
            >
              <UserVideoToggle
                toolTip="top"
                showVideo={showUserVideo}
                toggleShowVideo={toggleShowUserVideo}
                videoType={VideoType.USER}
              />
            </div>
          </div>
        ))}
    </div>
  );
};
