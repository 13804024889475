import React, { useState } from "react";
import { Button, PRIMARY, SECONDARY, SMALL } from "../buttons/button";
import { ToolBar } from "../toolbars/toolBar";
import { classConstants } from "../../cssConstants";

type PlaybackSpeedProps = {
  playbackSpeed: number;
  setPlaybackSpeed: (speed: number) => void;
};

export const PlaybackSpeed: React.FC<PlaybackSpeedProps> = ({
  playbackSpeed,
  setPlaybackSpeed,
}) => {
  const [showSpeedPopup, setShowSpeedPopup] = useState(false);
  const [speed, setSpeed] = useState(playbackSpeed);

  function handleSetSpeedAndClose(speed: number, e) {
    setSpeed(speed);
    const pscs = document.getElementsByClassName(classConstants.MAPC);
    const macs = document.getElementsByClassName(classConstants.MAC);
    if (pscs) {
      const psc = pscs[0];
      psc?.classList?.add("hidden");
    }
    if (macs) {
      const mac = macs[0];
      mac?.classList?.add("hidden");
    }
    e?.preventDefault();
    e?.stopPropagation();
    setTimeout(() => {
      setPlaybackSpeed(speed);
      setShowSpeedPopup(false);
    }, 2000);
  }
  return (
    <>
      <Button
        btnType={PRIMARY}
        text={"Playback speed: " + speed.toString() + "x"}
        size={SMALL}
        disabled={false}
        id="playback-speed-button"
        onClick={(e) => {
          setShowSpeedPopup(!showSpeedPopup);
          e.preventDefault();
          e.stopPropagation();
        }}
        icon="playback-speed"
      />
      {showSpeedPopup && (
        <ToolBar
          className={classConstants.MAPC + " speed-popup-container"}
          orientation="vertical"
          id="speed-popover"
        >
          <div>
            <Button
              onClick={(e) => handleSetSpeedAndClose(0.5, e)}
              size="small"
              btnType={SECONDARY}
              inputClass={speed === 0.5 ? "__selected-speed" : ""}
              id="speed-zero-point-five"
            >
              .5x
            </Button>
            <Button
              onClick={(e) => handleSetSpeedAndClose(1, e)}
              size="small"
              btnType={SECONDARY}
              inputClass={speed === 1 ? "__selected-speed" : ""}
              id="speed-one"
            >
              1x
            </Button>
            <Button
              onClick={(e) => handleSetSpeedAndClose(1.5, e)}
              size="small"
              btnType={SECONDARY}
              inputClass={speed === 1.5 ? "__selected-speed" : ""}
              id="speed-one-point-five"
            >
              1.5x
            </Button>
            <Button
              onClick={(e) => handleSetSpeedAndClose(2, e)}
              size="small"
              btnType={SECONDARY}
              inputClass={speed === 2 ? "__selected-speed" : ""}
              id="speed-two"
            >
              2x
            </Button>
          </div>
        </ToolBar>
      )}
    </>
  );
};
