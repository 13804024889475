// Turning off type-checks because of TypeError in MeetingProvider due to a change made to React that has not been fixed in UI library yet
// see https://github.com/aws/amazon-chime-sdk-component-library-react/issues/786 for issue updates

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { LoggerProvider } from "amazon-chime-sdk-component-library-react";
import { LogLevel } from "amazon-chime-sdk-js";
import { AppRoutes } from "./routes/appRoutes";
import { AppContextProvider } from "./contexts/appContext";
import { AuthContextProvider } from "./contexts/authContext";
import { ThemeContextProvider } from "./contexts/themeContext";
import { DataEventsProvider } from "./providers/dataEvents";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ScreenContextProvider } from "./contexts/screenContext";
import { FeatureFlagsContextProvider } from "./contexts/featureContext";
import { ReplayDataEventsProvider } from "./providers/replayDataEvents";
import config from "./utils/config";
import { ErrorProvider } from "./contexts/errorContext";
import ErrorBoundary from "./providers/errorBoundary";
import { ReplayDataMessageProvider } from "./providers/replayDataMessages";
import { DataMessagesProvider } from "./providers/dataMessages";
import { ReplayCaptionsEventsProvider } from "./providers/replayDataCaptions";
import { DataCaptionsProvider } from "./providers/dataCaptions";
import { AlertProvider } from "./contexts/alertContext";

function App() {
  // Mock logger to prevent default logging from Chime
  // To re-enable Chime logging, remove the LoggerProvider
  const logger = {
    getLogLevel: (level) => LogLevel.OFF,
    debug: (debug) => null,
    info: (info) => null,
    warn: (warn) => null,
    error: (error) => null,
    setLogLevel: (level) => null,
  };

  return (
    // @ts-ignore
    <LoggerProvider logger={logger}>
      <ScreenContextProvider>
        <ThemeContextProvider>
          <ErrorProvider>
            <ErrorBoundary>
              <AlertProvider>
                <GoogleOAuthProvider clientId={config.googleClientId}>
                  <FeatureFlagsContextProvider>
                    <BrowserRouter>
                      <AppContextProvider>
                        <AuthContextProvider>
                          <DataMessagesProvider>
                            <DataEventsProvider>
                              <DataCaptionsProvider>
                                <ReplayDataMessageProvider>
                                  <ReplayDataEventsProvider>
                                    <ReplayCaptionsEventsProvider>
                                      <AppRoutes />
                                    </ReplayCaptionsEventsProvider>
                                  </ReplayDataEventsProvider>
                                </ReplayDataMessageProvider>
                              </DataCaptionsProvider>
                            </DataEventsProvider>
                          </DataMessagesProvider>
                        </AuthContextProvider>
                      </AppContextProvider>
                    </BrowserRouter>
                  </FeatureFlagsContextProvider>
                </GoogleOAuthProvider>
              </AlertProvider>
            </ErrorBoundary>
          </ErrorProvider>
        </ThemeContextProvider>
      </ScreenContextProvider>
    </LoggerProvider>
  );
}

export default App;
