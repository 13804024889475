import React from "react";
import { Button, PRIMARY, SMALL } from "../../../buttons/button";

interface MoreActionsItemCommonProps {
  id: string;
}

type MoreActionsItemConditionalProps =
  | {
      // When MoreActionsItem child is a Button
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
      text: string;
      icon: string;
      disabled: boolean;
      children?: never;
    }
  | {
      // When MoreActionsItem child is another component
      onClick?: never;
      text?: never;
      icon?: never;
      disabled?: never;
      children: JSX.Element;
    };

type MoreActionsItemProps = MoreActionsItemCommonProps &
  MoreActionsItemConditionalProps;

/** Component for a More actions menu item, should be used as a child component
 * of the MoreActionsWrapper component. Can optionally pass a JSX Element as a
 * child or use the required props to default to a Button component
 * @param {MoreActionsItemProps} props - This component has conditional props
 * based on whether the child is a Button or another component
 * @prop {string} id - unique ID for the button
 * @prop {(e: React.MouseEvent<HTMLButtonElement>) => void} onClick - Event
 * handler for mouse click, only required if not passing a JSX Element child
 * @prop {string} text - Text that will display in the button, only required if
 * not passing a JSX Element child
 * @prop {string} icon - name fo the icon that will display in the button, only
 * required if not passing a JSX Element child
 * @prop {boolean} disabled - Whether or not the button will be disabled, only
 * required if not passing a JSX Element child
 * @prop {JSX.Element} children - Child element
 */
export const MoreActionsItem: React.FC<MoreActionsItemProps> = ({
  id,
  onClick,
  text,
  icon,
  disabled,
  children,
}) => {
  return (
    <div className="more-actions-item" id={id}>
      {/* When MoreActionsItem child is another component */}
      {children ? (
        <>{children}</>
      ) : (
        // When MoreActionsItem child is a button
        <Button
          btnType={PRIMARY}
          text={text}
          customWidth="100%"
          size={SMALL}
          disabled={disabled}
          onClick={onClick}
          id={id}
          icon={icon}
        />
      )}
    </div>
  );
};
