import React, { useEffect, useRef } from "react";
import {
  useAudioVideo,
  useApplyVideoObjectFit,
  VideoTile as ChimeVideoTile,
} from "amazon-chime-sdk-component-library-react";
import classnames from "classnames";
import { useAppContext } from "../../contexts/appContext";
import { MeetingRole } from "../../contexts/types";
import { VideoTile, VideoType } from "./videoTile";
import { CanvasDataEvent } from "../../providers/types";

type InstructorVideoProps = {
  name: string;
  tileId?: number;
  iconHex: string;
  speaking?: boolean;
  featured?: boolean;
  toggleInstructors: () => void;
  events?: CanvasDataEvent[];
};

export const InstructorVideo: React.FC<InstructorVideoProps> = ({
  name,
  tileId,
  iconHex,
  speaking,
  featured,
  toggleInstructors,
  events,
}) => {
  const audioVideo = useAudioVideo();
  const videoEl = useRef<HTMLVideoElement>(null);
  useApplyVideoObjectFit(videoEl);
  const { meetingRole, isInstructorOrModerator, state } = useAppContext();

  useEffect(() => {
    if (!audioVideo || !videoEl.current) {
      return;
    }

    audioVideo.bindVideoElement(tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId]);

  return (
    <div className="videos__instructor">
      <VideoTile
        isVideoEnabled={!!tileId}
        isInstructorOrModerator={isInstructorOrModerator}
        videoType={VideoType.INSTRUCTOR}
        speaking={speaking}
        iconHex={iconHex}
        name={name}
        handRaised={false}
        dismissable={!state.spotlightInstructor}
        toggleShowVideo={toggleInstructors}
        events={events}
      >
        <ChimeVideoTile
          ref={videoEl}
          nameplate={name}
          className={classnames(
            "video__tile",
            `ch-remote-video--${tileId}`,
            "video__tile-instructor",
            {
              "video__tile-featured": speaking,
            }
          )}
        />
      </VideoTile>
    </div>
  );
};
