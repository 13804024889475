import React, { useState } from "react";
import classnames from "classnames";
import { IconButton } from "./buttons/iconButton";

type ContainerProps = {
  className?: string;
  childrenClassName?: string;
  isDismissable: boolean;
  children: any;
  onDismiss?: (e) => void;
  xToCloseId?: string;
  role?: string;
  id?: string;
};

/**
 * Component used to wrap content in container with rounded corners and drop shadow compliant with VxP style guidelines.
 * @ContainerProps className, childrenClassName, isDismissable, children, onDismiss
 */

export const Container: React.FC<ContainerProps> = ({
  className,
  childrenClassName,
  isDismissable,
  children,
  onDismiss,
  xToCloseId,
  role,
  id,
}) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div
      className={classnames(`container`, className, {
        active: isActive,
        dismissed: !isActive && isDismissable,
      })}
      role={role}
      id={id}
    >
      <div
        className={classnames(`children`, childrenClassName, {
          active: isActive,
          dismissed: !isActive && isDismissable,
        })}
      >
        {children}
      </div>
      <div
        className={classnames("close__wrapper", {
          active: isActive && isDismissable,
          dismissed: !isActive && isDismissable,
        })}
      >
        {isDismissable && (
          <IconButton
            size="1.5rem"
            onClick={(e) => {
              onDismiss(e);
              setIsActive(!isActive);
            }}
            iconName="dismiss"
            desc="Close"
            btnId="close-container-button"
            svgId={xToCloseId}
          />
        )}
      </div>
    </div>
  );
};
