import { EventPayload, EventTypes } from "../providers/types";

/**
 * @param {EventPayload} inputEvent - checks if the event is Panorama view setting event.
 *
 */

export function isPanoViewEventCheck(inputEvent: EventPayload): boolean {
  return inputEvent?.panoramaView ? true : false;
}

/**
 * @param {EventPayload} inputEvent - checks if the event is Video view setting event.
 *
 */

export function isVideoViewEventCheck(inputEvent: EventPayload): boolean {
  return inputEvent?.videoData ? true : false;
}

/**
 * @param {EventPayload} inputEvent - checks if the event is Three Dee view setting event.
 *
 */

export function isThreeDeeEventCheck(inputEvent: EventPayload): boolean {
  return inputEvent?.threeDeeData ? true : false;
}

/** Checks to see if an event included a focus/explore toggle
 * @param {EventPayload} inputEvent - Event to be checked
 * @returns {{ isExploreEvent: boolean, exploreModeValue: boolean }} - Whether
 * or not this event included a focus/explore toggle and the new exploreMode
 * value from the event
 */
export function exploreToggleEventCheck(inputEvent: EventPayload): {
  isExploreEvent: boolean;
  exploreModeValue: boolean;
} {
  let returnObject = { isExploreEvent: false, exploreModeValue: undefined };

  if (
    inputEvent?.type === EventTypes.TOGGLE_EXPLORE_MODE ||
    inputEvent?.type === EventTypes.TOGGLE_LASER_POINTER ||
    inputEvent?.type === EventTypes.SPOTLIGHT_INSTRUCTOR
  ) {
    returnObject.exploreModeValue = inputEvent?.exploreMode;
    returnObject.isExploreEvent = true;
  }

  return returnObject;
}
