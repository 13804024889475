import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { ART_OBJECT_GALLERY_INCREMENT, ENSEMBLE } from "../../constants";
import { Button, PRIMARY, SECONDARY, SMALL, STANDARD } from "../buttons/button";
import { ArtObjectOverlay } from "./contentComponents";
import {
  getEnsembleImgUrl,
  getRoomAndTitleText,
} from "../../utils/ensembleUtil";
import { ContentModalWrapper } from "./contentModalWrapper";

type EnsembleImageType = {
  title: string;
  handleSubmit: (ids: string[]) => void;
};

export const AddEnsembleImage: React.FC<EnsembleImageType> = ({
  title,
  handleSubmit,
}) => {
  const [ensembleIndexList, setEnsembleIndexList] = useState(
    Object.keys(ENSEMBLE)
  );
  const [selected, setSelected] = useState<string[]>([]);
  const [images, setImages] = useState(
    ensembleIndexList.slice(0, ART_OBJECT_GALLERY_INCREMENT)
  );
  const [searchValue, setSearchValue] = useState<string>("");

  const handleAddSelectedImage = (id: string, action: "add" | "remove") => {
    if (action === "add") {
      setSelected([...selected, id]);
    }
    if (action === "remove") {
      const updatedSelected = selected.filter((i) => i !== id);
      setSelected(updatedSelected);
    }
  };

  const filterEnsemble = () => {
    const filteredIndexes = Object.keys(ENSEMBLE).filter((index) => {
      const ensemble = ENSEMBLE[index];

      return (
        ensemble["roomTitle"]
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        (ensemble["wallTitle"]
          ? ensemble["wallTitle"]
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          : false)
      );
    });

    return filteredIndexes;
  };

  const handleViewMore = () => {
    let indexList = ensembleIndexList;

    if (searchValue.length) {
      indexList = filterEnsemble();
    }

    setImages(indexList.slice(0, images.length + ART_OBJECT_GALLERY_INCREMENT));
  };

  const handleSearch = async () => {
    if (searchValue === "") {
      const indexes = Object.keys(ENSEMBLE);
      setEnsembleIndexList(indexes);
      setImages(indexes.slice(0, ART_OBJECT_GALLERY_INCREMENT));
    } else {
      const filteredIndexes = filterEnsemble();
      setEnsembleIndexList(filteredIndexes);
      setImages(filteredIndexes.slice(0, ART_OBJECT_GALLERY_INCREMENT));
    }
  };

  const onSubmit = () => {
    setSearchValue("");
    handleSubmit(selected);
  };

  const footer = (
    <Button
      btnType={PRIMARY}
      disabled={selected?.length === 0}
      size={STANDARD}
      text="Add images to canvas"
      onClick={onSubmit}
      id="add-enesemble-button"
    />
  );

  return (
    <ContentModalWrapper
      title={title}
      footerChildren={footer}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      handleSearch={handleSearch}
    >
      <div className="add-ensemble__body">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 500: 2 }}>
          <Masonry gutter="20px" columnsCount={2}>
            {images.map((image, index) => (
              <div className="art-object" key={index}>
                <img
                  alt={getRoomAndTitleText(image)}
                  src={getEnsembleImgUrl(image)}
                  className="art-object-image"
                />
                <ArtObjectOverlay
                  invno={image}
                  index={index}
                  caption={ENSEMBLE[image].roomTitle}
                  title={ENSEMBLE[image].wallTitle}
                  handleAddContentClick={handleAddSelectedImage}
                />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>

        <div className="art-object-gallery__footer">
          {images.length < ensembleIndexList.length && (
            <div className="view-more-button">
              <Button
                btnType={SECONDARY}
                disabled={false}
                size={SMALL}
                text="View more"
                onClick={handleViewMore}
                id="view-more-ensemble-button"
              />
            </div>
          )}
        </div>
      </div>
    </ContentModalWrapper>
  );
};
