import { getUA, parseUserAgent } from "react-device-detect";
import { DeviceInfoInput } from "../types";

export type userDevice = {
  browserName: string;
  browserVersion: string;
  deviceVendor: string;
  deviceModel: string;
  deviceType: string;
  engine: string;
  os: string;
  osVersion: string;
  userAgent: string;
};

export const getAttendeeDeviceDate = (): DeviceInfoInput => {
  let userDevice = parseUserAgent(getUA);
  let returnDevice: userDevice | {} = {};
  returnDevice["browserName"] = userDevice?.browser?.name;
  returnDevice["browserVersion"] = userDevice?.browser?.version;
  returnDevice["deviceVendor"] = userDevice?.device?.vendor;
  returnDevice["deviceModel"] = userDevice?.device?.model;
  returnDevice["deviceType"] = userDevice?.device?.type;
  returnDevice["engine"] = userDevice?.engine?.name;
  returnDevice["os"] = userDevice?.os?.name;
  returnDevice["osVersion"] = userDevice?.os?.version;
  returnDevice["userAgent"] = userDevice?.ua;

  return returnDevice;
};
