import { useCallback, useEffect, useRef } from "react";
import OpenSeadragon from "openseadragon";
import { EventTypes, EventPayload, CanvasDataEvent } from "../providers/types";
import { exploreToggleEventCheck } from "../utils/eventUtils";
import { State } from "../contexts/types";
import { consoleNonProd } from "../utils/utilityBeltUtils";

type OSDEventHandler = (viewer: OpenSeadragon.Viewer, state: State) => void;

type UseOpenSeadragon = {
  addHandlers: OSDEventHandler;
  removeHandlers: OSDEventHandler;
  onFitCanvas: OSDEventHandler;
  onZoomIn: OSDEventHandler;
  onZoomOut: OSDEventHandler;
  onKeyboard: OSDEventHandler;
  sendCanvasClickEvent: (
    center: OpenSeadragon.Point,
    zoom: number,
    state: State
  ) => void;
  handleCanvasMovement: OSDEventHandler;
};

export const useOpenSeadragon = (
  leader: boolean,
  events: CanvasDataEvent[],
  sendEvent?: ((eventPayload: EventPayload, state: State) => void) | undefined
): UseOpenSeadragon => {
  // Send event handlers
  // sendhome, sendClick, and sendScroll have the exploremode removed from the dependency array
  // because occasionally the explore mode context was getting out of sync with the events exploreMode
  // causing some intermittent focus/explore state issues
  const sendHomeEvent = useCallback(
    (state: State) => {
      sendEvent &&
        sendEvent(
          {
            type: EventTypes.HOME,
            leader,
          },
          state
        );
    },
    [leader, sendEvent]
  );

  const sendCanvasClickEvent = useCallback(
    (center: OpenSeadragon.Point, zoom: number, state: State) => {
      sendEvent &&
        sendEvent(
          {
            type: EventTypes.CANVAS_CLICK,
            center,
            zoom,
            leader,
          },
          state
        );
    },
    [leader, sendEvent]
  );

  const sendCanvasScrollEvent = useCallback(
    (center: OpenSeadragon.Point, zoom: number, state: State) => {
      sendEvent &&
        sendEvent(
          {
            type: EventTypes.CANVAS_SCROLL,
            center,
            zoom,
            leader,
          },
          state
        );
    },
    [leader, sendEvent]
  );

  const clickTimer = useRef(null);
  const handleCanvasClick = useCallback(
    (event: OpenSeadragon.CanvasClickEvent, state: State) => {
      const viewport = event.eventSource.viewport;
      if (clickTimer !== null) {
        clearTimeout(clickTimer.current);
      }
      clickTimer.current = setTimeout(function () {
        if (leader) {
          // why these two consts down here instead of where viewport
          // is instantiated? It misses the first click if they are up there?
          // what is a computer?
          const viewportView = viewport.getCenter();
          const viewportZoom = viewport.getZoom();
          sendCanvasClickEvent(viewportView, viewportZoom, state);
        }
      }, 200);
    },
    [sendCanvasClickEvent, leader]
  );

  //const scrollTimer = useRef(0);
  const scrollTimeout = useRef(null);
  const handleCanvasScroll = useCallback(
    (event: OpenSeadragon.CanvasScrollEvent, state: State) => {
      // if we want to emit events during scroll we can uncomment the below
      // the concern with sending straight events is we can end up sending a hight TPS
      // series of events just to only care about the end result anyway... but again, if we want to
      // we can send this "intervalled" version that is commented below.
      // and adjust the time by adjusting n in the the scrollTimer + n < now

      // let now = new Date().getTime();
      // if (scrollTimer.current + 500 < now) {
      //   scrollTimer.current = now;
      //   const viewportCenter = event.eventSource.viewport.getCenter();
      //   const viewportZoom = event.eventSource.viewport.getZoom();
      //   sendCanvasScrollEvent(viewportCenter, viewportZoom);
      // }
      // end of event emitter below
      if (scrollTimeout !== null) {
        clearTimeout(scrollTimeout.current);
      }
      scrollTimeout.current = setTimeout(function () {
        if (leader) {
          const viewportCenter = event.eventSource.viewport.getCenter();
          const viewportZoom = event.eventSource.viewport.getZoom();
          sendCanvasScrollEvent(viewportCenter, viewportZoom, state);
        }
      }, 200);
    },
    [sendCanvasScrollEvent, leader]
  );

  // Helper to add all IIIF canvas interaction handlers to a viewer
  const addHandlers = useCallback(
    (viewer: OpenSeadragon.Viewer, state: State) => {
      viewer.addHandler(EventTypes.CANVAS_CLICK, (event) =>
        handleCanvasClick(event, state)
      );
      viewer.addHandler(EventTypes.CANVAS_SCROLL, (event) =>
        handleCanvasScroll(event, state)
      );
    },
    [handleCanvasClick, handleCanvasScroll]
  );

  // Helper to remove all IIIR canvas interaction handlers to a viewer
  const removeHandlers = useCallback(
    (viewer: OpenSeadragon.Viewer, state: State) => {
      viewer.removeHandler(EventTypes.CANVAS_CLICK, (event) =>
        handleCanvasClick(event, state)
      );
      viewer.removeHandler(EventTypes.CANVAS_SCROLL, (event) =>
        handleCanvasScroll(event, state)
      );
    },
    [handleCanvasClick, handleCanvasScroll]
  );

  // IIIF Canvas button interaction handlers
  const onFitCanvas = useCallback(
    (osd: OpenSeadragon.Viewer, state: State) => {
      osd?.viewport.fitBoundsWithConstraints(
        osd?.viewport.getHomeBounds() as OpenSeadragon.Rect
      );
      if (leader) sendHomeEvent(state);
    },
    [leader, sendHomeEvent]
  );

  const onZoomIn = useCallback(
    (osd: OpenSeadragon.Viewer, state: State) => {
      const viewport = osd?.viewport;

      if (viewport) {
        const viewportZoom = viewport.getZoom(true);
        const maxZoom = viewport.getMaxZoom();
        const zoomIn = viewportZoom * 2;
        const zoomTo = zoomIn <= maxZoom ? zoomIn : maxZoom;
        const viewportCenter = viewport.getCenter(true);
        viewport.zoomTo(zoomTo, viewportCenter);

        if (leader && events?.length)
          sendCanvasClickEvent(viewportCenter, zoomTo, state);
      }
    },
    [events, leader, sendCanvasClickEvent]
  );

  const keyTimer = useRef(null);
  const onKeyboard = useCallback(
    // the timeout here sets the debounce for the keys so if someone
    // keeps pressing left to go left, we will only send the last one to grab the current view
    // in half second intervals. Same logic is in the panorama viewer
    (osd: OpenSeadragon.Viewer, state: State) => {
      const viewport = osd?.viewport;
      if (viewport) {
        if (keyTimer !== null) {
          clearTimeout(keyTimer.current);
        }
        keyTimer.current = setTimeout(function () {
          if (leader) {
            const viewportView = viewport.getCenter();
            const viewportZoom = viewport.getZoom();
            sendCanvasClickEvent(viewportView, viewportZoom, state);
          }
        }, 500);
      }
    },
    [sendCanvasClickEvent, leader]
  );

  const onZoomOut = useCallback(
    (osd: OpenSeadragon.Viewer, state: State) => {
      const viewport = osd?.viewport;

      if (viewport) {
        const viewportZoom = viewport.getZoom(true);
        const minZoom = viewport.getMinZoom();
        const zoomOut = viewportZoom / 2;
        const zoomTo = zoomOut >= minZoom ? zoomOut : minZoom;
        const viewportCenter = viewport.getCenter(true);
        viewport.zoomTo(zoomTo, viewportCenter);

        if (leader && events.length)
          sendCanvasClickEvent(viewportCenter, zoomTo, state);
      }
    },
    [leader, events, sendCanvasClickEvent]
  );

  const handleCanvasMovement = (osd: OpenSeadragon.Viewer) => {
    const viewport = osd?.viewport;
    if (viewport && events?.length) {
      const canvasDataEv = events[events.length - 1];
      const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
      if (!canvasDataEv.isSelf && eventPayload) {
        if (eventPayload.leader || leader) {
          if (exploreToggleEventCheck(eventPayload).isExploreEvent) {
            // This try catch block is necessary to prevent an error from
            // rendering Audience users' page. Even though the error is thrown
            // by ODS, the mouse nav is properly enabled/disabled
            try {
              osd.setMouseNavEnabled(
                leader ? leader : eventPayload.exploreMode
              );
            } catch (e) {
              consoleNonProd(
                "Error setting mouse nav",
                eventPayload.exploreMode,
                e
              );
            }
          }

          if (
            !exploreToggleEventCheck(eventPayload).exploreModeValue &&
            !eventPayload.spotlightInstructor
          ) {
            switch (eventPayload.type) {
              case EventTypes.HOME:
                viewport.fitBoundsWithConstraints(
                  viewport.getHomeBounds() as OpenSeadragon.Rect
                );
                break;
              case EventTypes.PAN:
                viewport.panTo(eventPayload.center as OpenSeadragon.Point);
                break;
              case EventTypes.ZOOM:
              case EventTypes.CANVAS_CLICK:
              case EventTypes.CANVAS_SCROLL:
                viewport.zoomTo(
                  eventPayload.zoom as number,
                  eventPayload.center
                );
                viewport.panTo(eventPayload.center as OpenSeadragon.Point);
                break;
            }
          }
        }
      }
    }
  };

  return {
    addHandlers,
    removeHandlers,
    onFitCanvas,
    onZoomIn,
    onZoomOut,
    onKeyboard,
    sendCanvasClickEvent,
    handleCanvasMovement,
  };
};
