import OpenSeadragon from "openseadragon";
import { BoardImage } from "../types";
import { isAnImage } from "./validators";

/** Checks for an image and properly formats the tile source data for OSD
 * @param {BoardImage[]}
 * @returns {OpenSeadragon.Options["tileSources"]}
 */
export const getTileSources = (
  tiles: BoardImage[]
): OpenSeadragon.Options["tileSources"] => {
  // Ignore typecheck because OSD can render an array of image type sources
  // @ts-ignore
  const inputTileSources: OpenSeadragon.Options["tileSources"] = tiles.map(
    (tile) => {
      let parsedTile: any = {};

      if (tile.tileSource) {
        parsedTile = { ...tile };
      } else if (tile.imageUpload) {
        if (tile.imageUpload.tileSource) {
          parsedTile = {
            ...tile.imageUpload,
            height: null,
            width: null,
            x: null,
            y: null,
            crossOriginPolicy: "Anonymous",
            ajaxWithCredentials: false,
          };
        } else if (tile.imageUpload.uploadUrl) {
          parsedTile = {
            ...tile.imageUpload,
            height: null,
            width: null,
            x: null,
            y: null,
            crossOriginPolicy: "Anonymous",
            ajaxWithCredentials: false,
          };
          parsedTile["tileSource"] = tile.imageUpload.uploadUrl;
        }
      }

      if (isAnImage(parsedTile.tileSource)) {
        return {
          url: parsedTile.tileSource,
          type: "image",
          crossOriginPolicy: "Anonymous",
          ajaxWithCredentials: false,
        };
      } else {
        return parsedTile;
      }
    }
  );

  return inputTileSources;
};

/** Checks for an image and properly formats the tile source data for OSD
 * @param {BoardImage[]}
 * @returns {OpenSeadragon.Options["tileSources"]}
 */
export const getPreviewTileSources = (
  tiles: BoardImage[]
): OpenSeadragon.Options["tileSources"] => {
  // Ignore typecheck because OSD can render an array of image type sources
  // @ts-ignore
  const inputTileSources: OpenSeadragon.Options["tileSources"] = tiles.map(
    (tile) => {
      let parsedTile: any = {};

      if (tile.imageUpload) {
        if (tile.imageUpload.thumbnailUrl) {
          parsedTile = {
            ...tile.imageUpload,
            height: null,
            width: null,
            x: null,
            y: null,
          };
          parsedTile["tileSource"] = tile.imageUpload.thumbnailUrl;
        } else if (tile.imageUpload.tileSource) {
          parsedTile = {
            ...tile.imageUpload,
            height: null,
            width: null,
            x: null,
            y: null,
          };
        } else {
          parsedTile = {
            ...tile.imageUpload,
            height: null,
            width: null,
            x: null,
            y: null,
          };
          parsedTile["tileSource"] = tile.imageUpload.uploadUrl;
        }
      } else if (tile.tileSource) {
        parsedTile = { ...tile };
      }

      if (isAnImage(parsedTile.tileSource)) {
        return {
          url: parsedTile.tileSource,
          type: "image",
        };
      } else {
        return parsedTile;
      }
    }
  );

  return inputTileSources;
};

/** Checks if board images are an image or a manifest, if any of them are images
 * then render the OSD as divs instead of a canvas element
 * @param {any[]}
 * @returns {boolean}
 */
export const getIosRender = (tiles: any[]): boolean => {
  let useIosCanvas = true;

  tiles.forEach((tile) => {
    if (tile?.type && tile?.type === "image") {
      useIosCanvas = false;
    }
  });

  return useIosCanvas;
};
