import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { ArtObjectComponent } from "./artObjectComponent";
import { parseObject } from "../../../utils/artObjectUtils";
import { ArtObject } from "../../../types";
import { Button, SECONDARY, SMALL } from "../../buttons/button";

type ArtObjectGalleryElementProps = {
  object: ArtObject;
  numObjects: number;
  index: number;
  handleAddContentClick: (invno: string, action: "add" | "remove") => void;
};

/** Art object gallery element. */
const ArtObjectGalleryElement: React.FC<ArtObjectGalleryElementProps> = ({
  object,
  index,
  handleAddContentClick,
}) => {
  const parsedObject = parseObject(object);

  return (
    <ArtObjectComponent
      index={index}
      subCaption={object.title}
      caption={object.people}
      imgAlt={object.title}
      imageUrlSmall={parsedObject.imageUrlSmall}
      imageUrlLarge={parsedObject.imageUrlLarge}
      invno={object.invno}
      handleAddContentClick={handleAddContentClick}
    />
  );
};

type ArtObjectGalleryProps = {
  liveObjects: ArtObject[];
  handleAddContentClick: (invno: string, action: "add" | "remove") => void;
  hasMoreResults: boolean;
  handleViewMore;
};

/** Art object gallery. */
export const ArtObjectGallery: React.FC<ArtObjectGalleryProps> = ({
  liveObjects,
  handleAddContentClick,
  hasMoreResults,
  handleViewMore,
}) => {
  return (
    <div className="art-object-gallery__wrapper">
      {liveObjects && liveObjects.length ? (
        <>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 500: 2, 650: 3 }}
          >
            <Masonry gutter="20px" columnsCount={3}>
              {liveObjects?.map((object) => (
                <ArtObjectGalleryElement
                  index={liveObjects.indexOf(object)}
                  key={object.id}
                  object={object}
                  numObjects={liveObjects.length}
                  handleAddContentClick={handleAddContentClick}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
          <div className="art-object-gallery__footer">
            {liveObjects && hasMoreResults && (
              <div className="view-more-button">
                <Button
                  btnType={SECONDARY}
                  disabled={false}
                  size={SMALL}
                  text="View more"
                  onClick={handleViewMore}
                  id="view-more-button"
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="art-object-gallery__no-results">
          <div className="no-results-message">
            Hmm... there were no results that matched your search. Please try
            again!
          </div>
        </div>
      )}
    </div>
  );
};
