import React, { Fragment } from "react";
import { EngagementTools } from "../toolbars/engagementTools";
import { MeetingControls } from "../toolbars/meetingControls";
import { AttendeeState, CanvasMode, State } from "../../contexts/types";
import { CanvasDataEvent, EventPayload } from "../../providers/types";
import { ChatDataMessage } from "../../providers/dataMessages";
import { ClosedCaptions } from "./closedCaptions";
import { SlideManager } from "../toolbars/slideManager";
import { CaptionType } from "../../providers/dataCaptions";
import { Help } from "../toolbars/help";
import { MobileMode } from "../toolbars/mobile/mobileMode";
import { MobileTools } from "../toolbars/mobile/mobileTools";
import { StudentTools } from "../toolbars/studentTools";
import { useScreenContext } from "../../contexts/screenContext";
import { useAppContext } from "../../contexts/appContext";

type PresentationCanvasToolsProps = {
  captions: CaptionType[];
  currentPartial: CaptionType;
  editBoardIIIF: boolean;
  events: CanvasDataEvent[];
  focusOnBoard: () => void;
  handleChangeSlide: (sortOrder: number) => void;
  handleRefetchBoards: (newSelectedBoardNumber: number) => void;
  handleSelectSlide: (sortOrder: number) => void;
  handleSetSelectedBoard: (sortOrder: number) => void;
  iiifRemoved: boolean;
  messageCount: number;
  messages: ChatDataMessage[];
  scrollToBoard: (boardNumber: number) => void;
  sendEvent:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  sendMessage: (message: string, attendee?: AttendeeState) => void;
  setEditBoardIIIF: (editBoardIIIF: boolean) => void;
  setIIIFRemoved: (iiifRemoved: boolean) => void;
  setShowInstructors: (show: boolean) => void;
  showClosedCaptions: boolean;
  showDeviceSettings: boolean;
  showInstructors: boolean;
  toggleClosedCaptions: () => void;
  toggleDeviceSettings: () => void;
  toggleInstructions: () => void;
  toggleInstructors: () => void;
  useInstructorTools: boolean;
};

export const PresentationCanvasTools: React.FC<
  PresentationCanvasToolsProps
> = ({
  captions,
  currentPartial,
  editBoardIIIF,
  events,
  focusOnBoard,
  handleChangeSlide,
  handleRefetchBoards,
  handleSelectSlide,
  handleSetSelectedBoard,
  iiifRemoved,
  messageCount,
  messages,
  scrollToBoard,
  sendEvent,
  sendMessage,
  setEditBoardIIIF,
  setIIIFRemoved,
  setShowInstructors,
  showClosedCaptions,
  showDeviceSettings,
  showInstructors,
  toggleClosedCaptions,
  toggleDeviceSettings,
  toggleInstructions,
  toggleInstructors,
  useInstructorTools,
}) => {
  const { useMobileTools } = useScreenContext();
  const { simpleView } = useAppContext();

  return (
    <Fragment>
      {useInstructorTools ? (
        // Instructor tools for a live meeting
        <Fragment>
          <EngagementTools
            events={events}
            setShowInstructors={
              setShowInstructors ? setShowInstructors : () => null
            }
            showInstructors={showInstructors}
            toggleInstructors={toggleInstructors}
            messages={messages}
            sendMessage={sendMessage}
            messageCount={messageCount}
          />

          <MeetingControls
            handleRefetchBoards={handleRefetchBoards}
            showClosedCaptions={showClosedCaptions}
            toggleClosedCaptions={toggleClosedCaptions}
            toggleDeviceSettings={toggleDeviceSettings}
            mode={CanvasMode.PRESENTATION}
            editBoardIIIF={editBoardIIIF}
            setEditBoardIIIF={setEditBoardIIIF}
            setIIIFRemoved={setIIIFRemoved}
            handleChangeSlide={handleSetSelectedBoard}
            sendEvent={sendEvent}
          />

          {/* Slide Manager */}
          <div className="canvas__tools-wrapper __leader">
            {showClosedCaptions && (
              <ClosedCaptions
                captions={captions}
                currentPartial={currentPartial}
              />
            )}

            <SlideManager
              handleSelectSlide={handleSelectSlide}
              sendEvent={sendEvent}
              events={events}
              handleRefetchBoards={handleRefetchBoards}
              mode={CanvasMode.PRESENTATION}
              iiifRemoved={iiifRemoved}
              setIIIFRemoved={setIIIFRemoved}
              handleChangeSlide={handleChangeSlide}
              scrollToBoard={scrollToBoard}
              focusOnBoard={focusOnBoard}
            />
          </div>
        </Fragment>
      ) : (
        // Student tools for a live meeting
        <Fragment>
          {/* Floating help button for medium+ size screens */}
          {!simpleView && !useMobileTools && (
            <Help
              useMobileTools={useMobileTools}
              toggleInstructions={toggleInstructions}
              component="ICON_BUTTON"
            />
          )}

          {useMobileTools ? (
            // Student mobile tools
            <Fragment>
              <MobileMode />
              {showClosedCaptions && (
                <ClosedCaptions
                  captions={captions}
                  currentPartial={currentPartial}
                />
              )}
              <MobileTools
                showClosedCaptions={showClosedCaptions}
                toggleClosedCaptions={toggleClosedCaptions}
                toggleDeviceSettings={toggleDeviceSettings}
                showInstructors={showInstructors}
                toggleInstructors={toggleInstructors}
                sendMessage={sendMessage}
                messages={messages}
                toggleInstructions={toggleInstructions}
                showChapterList={false}
                setShowChapterList={() => null}
              />
            </Fragment>
          ) : (
            // Student medium+ screen tools
            <div className="canvas__tools-wrapper">
              {showClosedCaptions && (
                <ClosedCaptions
                  captions={captions}
                  currentPartial={currentPartial}
                />
              )}
              <StudentTools
                showClosedCaptions={showClosedCaptions}
                toggleClosedCaptions={toggleClosedCaptions}
                showDeviceSettings={showDeviceSettings}
                toggleDeviceSettings={toggleDeviceSettings}
                showInstructors={showInstructors}
                toggleInstructors={toggleInstructors}
                messages={messages}
                messageCount={messageCount}
              />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
