import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  EditorProvider,
  Editor,
  Toolbar,
  BtnBold,
  BtnItalic,
  BtnUnderline,
} from "react-simple-wysiwyg";
import sanitizeHtml from "sanitize-html";
import { Button, PRIMARY, STANDARD } from "./buttons/button";
import Spinner from "./spinner";
import { TextInput } from "./inputs/textInput";
import { CREATE_BOARD, EDIT_BOARD } from "../graphql/mutations";
import {
  Board,
  CreateBoardInput,
  BoardType,
  EditBoardInput,
  Mutation,
} from "../types";
import { useAppContext } from "../contexts/appContext";
import { ActionType } from "../contexts/types";
import { useError } from "../contexts/errorContext";
import { consoleNonProd } from "../utils/utilityBeltUtils";

type AddBoardProps = {
  handleSubmit: (sortOrder: number) => void;
  handleRefetchBoards?: () => void;
  meetingId: number;
  sortOrder: number;
  edit?: boolean;
  slide?: Board;
  slides?: Board[];
};

export const AddBoard: React.FC<AddBoardProps> = ({
  handleSubmit,
  handleRefetchBoards,
  meetingId,
  sortOrder,
  edit,
  slide,
  slides,
}) => {
  const [boardName, setBoardName] = useState<string>(slide?.title);
  const [boardSortOrder, setBoardSortOrder] = useState<number>(
    slide?.sortOrder
  );
  const [caption, setCaption] = useState<string>(slide?.caption);
  const { dispatch } = useAppContext();
  const { showError } = useError();

  const [createBoardMutation, { loading, error }] = useMutation(CREATE_BOARD, {
    async onCompleted(data: Pick<Mutation, "createBoard">) {
      const currentVersion = data.createBoard.currentVersion;
      handleRefetchBoards();
      handleSubmit(currentVersion);
    },
    onError(error) {
      consoleNonProd("Error adding board:", error);
      showError({
        error:
          'Something went wrong creating your board. Click "View details" to learn more.',
        details: error,
      });
    },
  });

  const [editBoardMutation] = useMutation(EDIT_BOARD, {
    async onCompleted(data: Pick<Mutation, "editBoard">) {
      const currentVersion = data.editBoard.currentVersion;
      handleRefetchBoards();
      dispatch({
        type: ActionType.UPDATE_CURRENT_BOARD,
        payload: { currentBoard: data.editBoard.board },
      });
      handleSubmit(currentVersion);
    },
    onError(error) {
      consoleNonProd("Error editing board:", error);
      showError({
        error:
          'Something went wrong editing your board. Click "View details" to learn more.',
        details: error,
      });
    },
  });

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleClick = () => {
    const sanitizedCaption = sanitizeHtml(caption, {
      allowedTags: ["b", "i", "em", "strong", "p", "br", "div", "span"],
    });

    if (!edit) {
      const input: CreateBoardInput = {
        meetingId,
        title: boardName,
        caption: sanitizedCaption,
        sortOrder: edit ? boardSortOrder : sortOrder,
        type: BoardType.IIIF,
      };

      createBoardMutation({ variables: { input } });
    } else {
      const input: EditBoardInput = {
        id: slide.id,
        sortOrder: boardSortOrder,
        title: boardName,
        caption: sanitizedCaption,
      };

      editBoardMutation({ variables: { input } });
    }
  };

  const title = edit ? "Edit board" : "Add a board";

  return (
    <div
      role="dialog"
      aria-labelledby="addBoardTitle"
      aria-describedby="addBoardDesc"
      className="add-board-container"
    >
      <h1 id="addBoardTitle" className="add-board__heading">
        {title}
      </h1>
      {loading ? (
        <Spinner />
      ) : (
        <form name="add-board__form" id="addBoardDesc">
          <TextInput
            disabled={false}
            errorMessage="please enter a board name"
            hasError={false}
            id="board-name"
            label="Board name"
            name="boardName"
            parentForm="add-board-form"
            initialValue={boardName}
            onChange={function (e: any): void {
              setBoardName(e?.target?.value);
            }}
          />

          <div className="add-board__form__caption focus-visible">
            <EditorProvider>
              <Editor
                value={caption}
                onChange={handleCaptionChange}
                id="board-caption (optional)"
                placeholder="Enter optional board caption here..."
              >
                <Toolbar>
                  <BtnBold />
                  <BtnItalic />
                  <BtnUnderline />
                </Toolbar>
              </Editor>
            </EditorProvider>
          </div>
          {edit && (
            <div className="add-board__form__sort-order">
              Board position: &nbsp;
              <select
                value={boardSortOrder}
                onChange={(e) => {
                  setBoardSortOrder(Number.parseInt(e.target.value));
                }}
                className="add-board__form__sort-select"
              >
                {slides?.map((board, index) => (
                  <option
                    className="add-board__form__sort-options"
                    key={index}
                    value={board.sortOrder}
                  >
                    {board.sortOrder}
                  </option>
                ))}
              </select>
            </div>
          )}

          <Button
            btnType={PRIMARY}
            id="add-board-primary-button"
            text={
              boardName && !edit
                ? "Create board"
                : edit
                ? "Save board"
                : "Please enter a board name"
            }
            size={STANDARD}
            disabled={false}
            onClick={handleClick}
            inputClass="add-board__form__submit"
          />
        </form>
      )}
    </div>
  );
};
