import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import OpenSeadragon from "openseadragon";
import {
  EventPayload,
  CanvasDataEvent,
  EventTypes,
} from "../../providers/types";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useAppContext } from "../../contexts/appContext";
import {
  CanvasMode,
  MeetingRole,
  NavEvents,
  State,
} from "../../contexts/types";
import { useOpenSeadragon } from "../../hooks/useOpenSeadragon";
import { BoardImage } from "../../types";
import { isIOS, isIOS13 } from "react-device-detect";
import { VALID_KEYS_WITH_ZOOM } from "../../constants";
import { exploreToggleEventCheck } from "../../utils/eventUtils";
import classnames from "classnames";
import { getTileSources, getIosRender } from "../../utils/openSeadragonUtils";
import EditIIIFViewer from "./editIIIFViewer";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

type IIIFViewerProps = {
  tileSources: BoardImage[];
  leader: boolean;
  events?: CanvasDataEvent[];
  handleRefetchBoards?: () => void;
  mode: CanvasMode;
  editBoardIIIF: boolean;
  setIIIFRemoved: (removed: boolean) => void;
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
};

/** IIIFViewer Component, uses a ref to drop out of react.
 * @param {IIIFViewerProps} props
 * @prop {BoardImage[]} tileSources - Array of image sources to be used
 * @prop {boolean} leader - Whether or not the user should emit their canvas actions
 * @prop {CanvasDataEvent[]} events - List of app events from the DataEventsProvider, defaults to an empty array
 */
const IIIFViewer: React.FC<IIIFViewerProps> = ({
  tileSources,
  leader,
  events = [],
  handleRefetchBoards,
  mode,
  editBoardIIIF,
  setIIIFRemoved,
  sendEvent,
}: IIIFViewerProps) => {
  const ref: React.Ref<HTMLDivElement> = useRef(null);
  const { height } = useWindowDimensions();
  const { generateEventHandlers, meetingRole, state } = useAppContext();
  const {
    addHandlers,
    onFitCanvas,
    onZoomIn,
    onZoomOut,
    onKeyboard,
    sendCanvasClickEvent,
    handleCanvasMovement,
  } = useOpenSeadragon(leader, events, sendEvent);
  const isMouseNavEnabled = useMemo(
    () => (leader || state.exploreMode) && !state.spotlightInstructor,
    [leader, state]
  );
  const [osd, setOsd] = useState<OpenSeadragon.Viewer>(undefined || null);

  // Pass ref to useEffect, canvas for IIIF viewer will be dropped underneath the ref.
  useEffect(() => {
    const gottenTileSources = getTileSources(tileSources);
    const useIosCanvas = getIosRender(gottenTileSources as any[]);
    const isIos = isIOS || isIOS13;
    const initOsd = OpenSeadragon({
      element: ref.current !== null ? ref.current : undefined,
      tileSources: gottenTileSources,
      // useCanvas: true,
      // collectionMode "FALSE" allows for custom placement based on x, y, width or height values
      collectionMode: true,
      collectionLayout: "horizontal",
      collectionRows:
        tileSources?.length > 7 ? Math.ceil(tileSources?.length / 8) : 1,
      visibilityRatio: 0.05,
      constrainDuringPan: true,
      showHomeControl: false,
      showZoomControl: false,
      showRotationControl: false,
      showFullPageControl: false,
      mouseNavEnabled: isMouseNavEnabled ? 1 : 0,
      minZoomImageRatio: 0.05,
      defaultZoomLevel: tileSources?.length > 2 ? 0 : 0.0006,
      // maxZoomPixelRatio: 1,
      iOSDevice: isIos,
      // use canvas unless it's ios and there is a straight image (non manifest.json)
      useCanvas: isIos && !useIosCanvas ? false : true,
    });

    initOsd.addHandler("canvas-key", function (event) {
      const keyEvent: KeyboardEvent = event.originalEvent as KeyboardEvent;
      if (VALID_KEYS_WITH_ZOOM.indexOf(keyEvent.key) > -1) {
        event.preventDefaultAction = false;
        onKeyboard(initOsd, state);
      } else {
        event.preventDefaultAction = true;
      }
    });

    initOsd.addHandler("canvas-drag", function (event) {
      if (isMouseNavEnabled) {
        ref.current.classList.add("iiif-viewer__iiif-drag");
      }
    });

    initOsd.addHandler("canvas-drag-end", function (event) {
      ref.current.classList.remove("iiif-viewer__iiif-drag");
    });

    leader && addHandlers(initOsd, state);
    setOsd(initOsd);
    return () => {
      initOsd.destroy();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
    // including leader and exploreMode in the dependency array caused the OSD
    // to reinitialize every time one of those vars updated, so they had to be removed.
  }, [tileSources, ref]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // bug fix for moderators coming back into focus and still having canvas controls
  useEffect(() => {
    // without the timeout parts of the OSD are undefined and the app blows up
    if (!osd) {
      return;
    }
    // without the try catches it blows up (see useOpenSeaDragon handleCanvasMovement)
    if (osd) {
      try {
        osd.setMouseNavEnabled(isMouseNavEnabled);
      } catch (e) {
        consoleNonProd("Error updating mouse navigation", e);
      }
    }
  }, [
    osd,
    state.exploreMode,
    meetingRole,
    state.spotlightInstructor,
    isMouseNavEnabled,
  ]);

  // Set event handlers for canvas button interactions via slide manager
  useEffect(() => {
    generateEventHandlers({
      [NavEvents.FIT]: (state) => onFitCanvas(osd, state),
      [NavEvents.ZOOM_IN]: (state) => onZoomIn(osd, state),
      [NavEvents.ZOOM_OUT]: (state) => onZoomOut(osd, state),
    });
  }, [generateEventHandlers, onFitCanvas, onZoomIn, onZoomOut, osd, state]);

  useEffect(() => {
    const viewport = osd?.viewport;
    if (viewport) {
      if (events?.length && !state.exploreMode) {
        handleCanvasMovement(osd, state);
      }
    }
  }, [osd, events, leader, state.exploreMode, handleCanvasMovement, state]);

  // Emit event when exploreMode updates
  useEffect(() => {
    const viewport = osd?.viewport;
    // exploreMode remove from the 115 if check because we only care about the events exploreMode and that
    // is checked below on 122 (as of 10/17/2022)
    if (events?.length && viewport) {
      const canvasDataEv = events[events.length - 1];
      const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);

      // When explore mode is turned off, leader should emit their location
      if (
        leader &&
        canvasDataEv.isSelf &&
        exploreToggleEventCheck(eventPayload).isExploreEvent &&
        !exploreToggleEventCheck(eventPayload).exploreModeValue
      ) {
        setTimeout(() => {
          const center = osd.viewport.getCenter(true);
          const viewportZoom = viewport.getZoom(true);
          sendCanvasClickEvent(center, viewportZoom, state);
        }, 300);
      }
      // on replay we don't have an instructor to ask for their view so
      // we have to cycle back through the events to find one that has
      // IIIF view attached to it and then we set our replay view to that
      if (
        mode === CanvasMode.REPLAY &&
        exploreToggleEventCheck(eventPayload).isExploreEvent &&
        !exploreToggleEventCheck(eventPayload).exploreModeValue
      ) {
        for (let i = events.length - 1; i >= 0; i--) {
          const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
          if (eventPayload.center && eventPayload.zoom) {
            viewport.zoomTo(eventPayload.zoom, eventPayload.center);
            break;
          }
        }
      }
    }
  }, [
    state.exploreMode,
    events,
    osd,
    leader,
    sendCanvasClickEvent,
    mode,
    state,
    state.spotlightInstructor,
  ]);

  // Update the handlers on state updates
  useEffect(() => {
    if (osd && leader) {
      addHandlers(osd, state);
    }
  }, [addHandlers, leader, osd, state]);

  function flexFinder(): number {
    const length = tileSources.length;
    let returnFlex = 0;
    if (length === 1) {
      returnFlex = 50;
    } else if (length === 2) {
      returnFlex = 40;
    } else if (length === 3) {
      returnFlex = 30;
    } else if (length === 4) {
      returnFlex = 20;
    } else if (length === 5) {
      returnFlex = 16.6;
    } else if (length >= 6) {
      returnFlex = 14.5;
    }
    return returnFlex;
  }

  const handleDeleteBoardComplete = (currentVersion: number) => {
    if (currentVersion) {
      // send version update event
      const event: EventPayload = {
        type: EventTypes.PRESENTATION_VERSION_CHANGE,
        presentationVersion: currentVersion,
        leader: true,
      };

      sendEvent(event, state);
    }

    handleRefetchBoards();
  };

  return (
    <Fragment>
      <div
        id="iiif-editor"
        className="iiif-editor"
        style={{
          visibility: editBoardIIIF ? "visible" : "hidden",
          display: editBoardIIIF ? "flex" : "none",
          justifyContent: tileSources?.length > 2 ? "space-around" : "center",
        }}
      >
        {tileSources?.map((tile, index) => {
          return (
            <EditIIIFViewer
              key={index}
              tileSources={tileSources}
              inputIndex={index}
              flexBasis={flexFinder()}
              handleDeleteBoardComplete={handleDeleteBoardComplete}
              setIIIFRemoved={setIIIFRemoved}
            />
          );
        })}
      </div>
      <div
        id="iiif-Viewer"
        className={classnames(`app__iiif-viewer iiif-viewer`, {
          "iiif-viewer__iiif-grabbable": isMouseNavEnabled,
          "iiif-viewer__blur": state.spotlightInstructor,
        })}
        ref={ref}
        style={{
          height: document?.documentElement?.style.getPropertyValue(
            "--mobile-available-screen"
          )
            ? document?.documentElement?.style.getPropertyValue(
                "--mobile-available-screen"
              )
            : "100vh",
          overflow: "hidden",
          visibility: editBoardIIIF ? "hidden" : "visible",
        }}
      />
    </Fragment>
  );
};

export default IIIFViewer;
