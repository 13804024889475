import { useQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import { CourseTable } from "../../components/adminConsole/courseTable";
import { PageWrapper } from "../../components/pageWrapper";
import { GET_COURSES_BY_MEETING_DATES } from "../../graphql/queries";
import { getEndOfDayIso, getStartOfDayIso } from "../../utils/timeUtiles";
import { Filter, FilterBar } from "../../components/inputs/filterBar";
import { useError } from "../../contexts/errorContext";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

export const Admin: React.FC = () => {
  const courseFilters = {
    CurrentCourses: {
      active: true,
      filterName: "Current courses",
      value: "current-courses",
      id: "current-courses",
    },
    FutureCourses: {
      active: false,
      filterName: "Future courses",
      value: "future-courses",
      id: "future-courses",
    },
    PastCourses: {
      active: false,
      filterName: "Past courses",
      value: "past-courses",
      id: "past-courses",
    },
    AllCourses: {
      active: false,
      filterName: "All courses",
      value: "all-courses",
      id: "all-courses",
    },
  };

  const sodIso = useMemo(() => getStartOfDayIso(), []);
  const eodIso = useMemo(() => getEndOfDayIso(), []);

  const { showError } = useError();
  const fetchCoursesError = `An error occurred while loading your courses. Try refreshing this page.`;
  const [currentCoursesFilter, setCurrentCoursesFilter] = useState<Filter>({
    active: true,
    filterName: "Current courses",
    value: "current-courses",
    id: "current-courses",
  });
  const [filtersValue, setFiltersValue] = useState<Filter[]>([
    currentCoursesFilter,
  ]);

  const [activeFilter, setActiveFilter] = useState<string>(
    courseFilters.CurrentCourses.filterName
  );

  const handleFilterChange = (e, i) => {
    if (i === 0) {
      setCurrentCoursesFilter(e);
    }
    setTimeout(() => {
      setFiltersValue([currentCoursesFilter]);
    }, 0);
  };

  // grab all the courses when a user heads back to home to get the latest created course
  useEffect(() => {
    refetch();
  }, []);

  // Commenting out for now until server side query is fixed, replacing with GET_ALL_COURSES above
  const { data, loading, refetch } = useQuery(GET_COURSES_BY_MEETING_DATES, {
    variables: {
      todayInput: {
        startDate: sodIso,
        endDate: eodIso,
      },
      currentInput: {
        startDate: sodIso,
        endDate: eodIso,
      },
      futureInput: {
        startDate: eodIso,
      },
      pastInput: {
        endDate: sodIso,
      },
    },
    onError(error) {
      consoleNonProd("Error fetching courses", error);
      let errorText: string;

      if (error.graphQLErrors[0].extensions.errors) {
        // Display errors from the server side
        errorText = (
          error.graphQLErrors[0].extensions.errors as { message: string }[]
        )
          .map((e) => e.message)
          .join(", ");
      } else if (error.graphQLErrors[0].message) {
        // Display errors from the client side
        errorText = error.graphQLErrors[0].message;
      } else {
        // Default to printing out all errors
        errorText = Object.values(error.graphQLErrors[0])
          .map((e) => e)
          .join(", ");
      }

      showError({
        error: fetchCoursesError,
        header: "Oops! Something went wrong.",
        details: `Error: ${errorText}`,
      });
    },
  });

  // PAST - end date is in past
  // CURRENT - start date is in past, end date is in future
  // FUTURE - start date is in future

  return (
    <PageWrapper className="admin">
      <div className="admin-content">
        <h1>Dashboard</h1>
        <div className="dashboard-container">
          {!loading && (
            <div className="dashboard-inner">
              {/* Search bar for future implementation */}
              {/* <div className="search__searchbar">
                <SearchBar
                  handleSearch={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                  value={""}
                  setValue={function (
                    value: React.SetStateAction<string>
                  ): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </div> */}
              <div className="filter-section">
                {/* Filter bar */}
                <FilterBar
                  handleFilters={(e, i) => handleFilterChange(e, i)}
                  hasLabel={true}
                  setActiveFilter={setActiveFilter}
                  activeFilter={activeFilter}
                  filters={Object.values(courseFilters)}
                />
              </div>
              {activeFilter === courseFilters.CurrentCourses.filterName && (
                <CourseTable
                  courseMapValue={data?.currentCourses}
                  loading={loading}
                />
              )}
              {activeFilter === courseFilters.FutureCourses.filterName && (
                <CourseTable
                  courseMapValue={data?.futureCourses}
                  loading={loading}
                />
              )}
              {activeFilter === courseFilters.PastCourses.filterName && (
                <CourseTable
                  courseMapValue={data?.pastCourses}
                  loading={loading}
                />
              )}
              {activeFilter === courseFilters.AllCourses.filterName && (
                <CourseTable
                  courseMapValue={data?.getAllCourses}
                  loading={loading}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
