import React, { createContext, useContext, useReducer, useState } from "react";
import {
  MeetingProvider,
  VoiceFocusProvider,
} from "amazon-chime-sdk-component-library-react";
import { UpdatePresentationType } from "../utils/editClassUtils";
import { StorageKeys, useLocalStorage } from "../hooks/useLocalStorage";
import {
  Action,
  ActionType,
  AttendeeState,
  CanvasNavEvent,
  JoinInfoType,
  MeetingMode,
  MeetingRole,
  NavEvents,
  Participant,
  State,
} from "./types";
import {
  MeetingProviderWithDeviceReplacement,
  MeetingWithRosterProvider,
} from "../providers/meetingProviders";
import { consoleNonProd } from "../utils/utilityBeltUtils";
import { RosterProvider } from "../providers/rosterProvider";

type AppContextType = {
  handleCanvasNav: (event: NavEvents) => void;
  generateEventHandlers: (eventHandlers: CanvasNavEvent) => void;
  presentationUpdated: boolean;
  setPresentationUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  presentationDiff?: UpdatePresentationType;
  setPresentationDiff: React.Dispatch<
    React.SetStateAction<UpdatePresentationType>
  >;
  meetingId: string;
  setMeetingId: React.Dispatch<React.SetStateAction<string>>;
  userColor: string;
  handleSetUserColor: (inputColor: string) => void;
  localUserName: string;
  setLocalUserName: React.Dispatch<React.SetStateAction<string>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  isWebAudioEnabled: boolean;
  toggleWebAudio: () => void;
  isEchoReductionEnabled: boolean;
  toggleEchoReduction: () => void;
  meetingMode?: MeetingMode;
  handleSetMeetingMode: (meetingMode: MeetingMode) => void;
  joinInfo?: JoinInfoType;
  handleSetJoinInfo: (joinInfo: JoinInfoType) => void;
  region: string;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  dbId: number;
  handleSetDbId: (dbId: number) => void;
  meetingRole: MeetingRole;
  handleSetMeetingRole: (meetingRole: MeetingRole) => void;
  isInstructorOrModerator: boolean;
  simpleView: boolean;
  setSimpleView: React.Dispatch<React.SetStateAction<boolean>>;
  isInstructorAVMeeting: boolean;
  setIsInstructorAVMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  isStudentAVMeeting: boolean;
  setIsStudentAVMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  meetingHasChat: boolean;
  setMeetingHasChat: React.Dispatch<React.SetStateAction<boolean>>;
  isDemo: boolean;
  setIsDemo: React.Dispatch<React.SetStateAction<boolean>>;
  attendeeList: Participant[];
  setAttendeeList: React.Dispatch<React.SetStateAction<Participant[]>>;
  showChat: boolean;
  setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
  toggleChat: () => void;
  closedChatCount: number;
  setClosedChatCount: React.Dispatch<React.SetStateAction<number>>;
  showParticipants: boolean;
  setShowParticipants: React.Dispatch<React.SetStateAction<boolean>>;
  toggleParticipants: () => void;
  showUserVideo: boolean;
  setShowUserVideo: React.Dispatch<React.SetStateAction<boolean>>;
  toggleShowUserVideo: () => void;
  expandToolBar: boolean;
  setExpandToolBar: React.Dispatch<React.SetStateAction<boolean>>;
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetShowPopup: () => void;
  popupMessage: string;
  setPopupMessage: React.Dispatch<React.SetStateAction<string>>;
  state: State;
  dispatch: React.Dispatch<Action>;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

/** Initial attendee state */
const initialAttendeeState: AttendeeState = {
  softBanned: false,
};

/** Initial presentation state */
const initialState: State = {
  currentBoard: undefined,
  exploreMode: false,
  laserInstructor: "",
  presentationVersion: undefined,
  showLaser: false,
  slides: undefined,
  spotlightInstructor: false,
  manualSpotlight: undefined,
  replayExplore: false,
  muteLock: false,
  attendee: initialAttendeeState,
  videoOffLock: false,
};

/** Reducer that handles updating presentation state */
const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  consoleNonProd(
    ActionType[type],
    "\npayload:",
    payload,
    "\nprevState:",
    state
  );
  let updatedState: State = { ...state };
  updatedState.muteLock = state.muteLock;
  updatedState.videoOffLock = state.videoOffLock;

  switch (type) {
    case ActionType.EVENT_TOGGLE_EXPLORE_MODE:
      updatedState = { ...state, exploreMode: payload.exploreMode };
      if (Object.keys(payload).includes("showLaser"))
        updatedState.showLaser = payload.showLaser;
      if (Object.keys(payload).includes("laserInstructor"))
        updatedState.laserInstructor = payload.laserInstructor;
      if (Object.keys(payload).includes("replayExplore"))
        updatedState.replayExplore = payload.replayExplore;
      if (Object.keys(payload).includes("currentSlideNumber"))
        updatedState.currentBoard = payload.currentBoard;
      if (payload.exploreMode) {
        updatedState.laserInstructor = "";
        updatedState.showLaser = false;
      }
      consoleNonProd("nextState TOGGLE_EXPLORE", updatedState);
      return updatedState;

    case ActionType.EVENT_TOGGLE_LASER_POINTER:
      updatedState = {
        ...state,
        showLaser: payload.showLaser,
        laserInstructor: payload.laserInstructor,
      };

      // Set explore mode to false when laser pointer is turned on
      if (payload.showLaser) updatedState.exploreMode = false;
      consoleNonProd("nextState TOGGLE_LASER", updatedState);
      return updatedState;

    case ActionType.HANDLE_CANVAS_INIT_LOAD:
      updatedState = {
        ...state,
        exploreMode: payload.exploreMode,
        spotlightInstructor: payload.spotlightInstructor,
      };
      consoleNonProd("nextState INIT_LOAD", updatedState);
      return updatedState;

    case ActionType.HANDLE_GET_PRESENTATION:
      updatedState = {
        ...state,
        currentBoard: payload.currentBoard,
        slides: payload.slides,
      };
      consoleNonProd("nextState HANDLE_PRESENTATION", updatedState);
      return updatedState;

    case ActionType.HANDLE_INCOMING_EVENT:
      updatedState = { ...state, ...payload };
      if (state.manualSpotlight) {
        updatedState.spotlightInstructor = true;
      }
      consoleNonProd("nextState HANDLE_INCOMING", updatedState);
      return updatedState;

    case ActionType.SET_INIT_ATTENDEE_STATE:
      updatedState.attendee = initialAttendeeState;
      consoleNonProd("nextState INIT ATTTNEDEE", updatedState);
      return updatedState;

    case ActionType.SET_INIT_STATE:
      updatedState = initialState;
      consoleNonProd("nextState INIT", updatedState);
      return updatedState;

    case ActionType.UPDATE_ATTENDEE_SOFT_BAN:
      updatedState.attendee = {
        ...state.attendee,
        softBanned: payload.softBan,
      };
      return updatedState;

    case ActionType.UPDATE_CURRENT_BOARD:
      updatedState = { ...state, currentBoard: payload.currentBoard };
      consoleNonProd("nextState UPDATE_BOARD", updatedState);
      return updatedState;

    case ActionType.UPDATE_EXPLORE_MODE:
      updatedState = { ...state, exploreMode: payload.exploreMode };
      consoleNonProd("nextState UPDATE_EXPLORE", updatedState);
      return updatedState;

    case ActionType.UPDATE_LASER_INSTRUCTOR:
      updatedState = { ...state, laserInstructor: payload.laserInstructor };
      consoleNonProd("nextState UPDATE_LASER", updatedState);
      return updatedState;

    case ActionType.UPDATE_PRESENTATION_VERSION:
      updatedState = {
        ...state,
        presentationVersion: payload.presentationVersion,
      };
      consoleNonProd("nextState UPDATE_PRESENTATION", updatedState);
      return updatedState;

    case ActionType.UPDATE_MUTE_LOCK:
      updatedState = {
        ...state,
        muteLock: payload.muteLock,
      };
      consoleNonProd("nextState MUTE_LOCK");
      return updatedState;

    case ActionType.UPDATE_VIDEO_OFF_LOCK:
      updatedState = {
        ...state,
        videoOffLock: payload.videoOffLock,
      };
      consoleNonProd("nextState VIDEO_OFF_LOCK");
      return updatedState;

    case ActionType.UPDATE_SHOW_LASER:
      updatedState = { ...state, showLaser: payload.showLaser };
      consoleNonProd("nextState UPDATE_SHOW_LASER", updatedState);
      return updatedState;

    case ActionType.UPDATE_SLIDES:
      updatedState = { ...state, slides: payload.slides };
      consoleNonProd("nextState UPDATE_SLIDES", updatedState);
      return updatedState;

    case ActionType.UPDATE_SPOTLIGHT_INSTRUCTOR:
      updatedState = {
        ...state,
        // check if manual spotlight is active or if the new state event
        // has the manual spotlight if it is there use that for spotlight
        spotlightInstructor: Object.keys(payload).includes("manualSpotlight")
          ? payload.manualSpotlight
          : payload.spotlightInstructor,
      };
      if (Object.keys(payload).includes("manualSpotlight"))
        updatedState.manualSpotlight = payload.manualSpotlight;

      // Set exploreMode to false when spotlightInstructor is active
      if (payload.spotlightInstructor) updatedState.exploreMode = false;
      consoleNonProd("nextState UPDATE_SPOTLIGHT", updatedState);
      return updatedState;

    case ActionType.UPDATE_REPLAY_EXPLORE:
      updatedState = { ...state, replayExplore: payload.replayExplore };
      consoleNonProd("nextState UPDATE_REPLAY_EXPLORE", updatedState);
      return updatedState;

    default:
      throw new Error("Incorrect action in AppContext reducer");
  }
};

export const AppContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { setLocalStorage } = useLocalStorage();

  // State that can be removed
  const [region, setRegion] = useState("us-east-1");

  // Presentation state that is set directly from db
  const [presentationUpdated, setPresentationUpdated] = useState(false);
  const [presentationDiff, setPresentationDiff] =
    useState<UpdatePresentationType>();
  const [simpleView, setSimpleView] = useState(false);
  const [isInstructorAVMeeting, setIsInstructorAVMeeting] = useState(false);
  const [isStudentAVMeeting, setIsStudentAVMeeting] = useState(false);
  const [meetingHasChat, setMeetingHasChat] = useState(false);
  const [meetingId, setMeetingId] = useState("");
  const [dbId, setDbId] = useState<number>();
  const [isDemo, setIsDemo] = useState(false);

  // Attendee state that is set directly from the db
  // TODO: move all of this state into reducer
  const [userColor, setUserColor] = useState("");
  const [localUserName, setLocalUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [meetingMode, setMeetingMode] = useState<MeetingMode>(
    MeetingMode.Attendee
  );
  const [meetingRole, setMeetingRole] = useState<MeetingRole>(
    MeetingRole.Audience
  );
  const [isInstructorOrModerator, setIsInstructorOrModerator] =
    useState<boolean>(false);

  // Chime related state
  const [isWebAudioEnabled, setIsWebAudioEnabled] = useState(false);
  const [isEchoReductionEnabled, setIsEchoReductionEnabled] = useState(false);
  const [joinInfo, setJoinInfo] = useState<JoinInfoType>();
  const [attendeeList, setAttendeeList] = useState<Participant[]>([]);

  // Interrelated local user state -- think about refactoring into a reducer or ref
  const [showChat, setShowChat] = useState(false);
  const [showParticipants, setShowParticipants] = useState(true);
  const [showUserVideo, setShowUserVideo] = useState(true);
  const [expandToolBar, setExpandToolBar] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [closedChatCount, setClosedChatCount] = useState(0); // total number of chat messages read when chat is closed

  // Presentation state that is dependent on events
  const [state, dispatch] = useReducer(reducer, initialState);

  let onFitCanvas = (state: State) => consoleNonProd("Initial onFitCanvas");
  let onZoomIn = (state: State) => consoleNonProd("Initial onZoomIn");
  let onZoomOut = (state: State) => consoleNonProd("Initial onZoomOut");

  const generateEventHandlers = (eventHandlers: CanvasNavEvent) => {
    onFitCanvas = eventHandlers[NavEvents.FIT];
    onZoomIn = eventHandlers[NavEvents.ZOOM_IN];
    onZoomOut = eventHandlers[NavEvents.ZOOM_OUT];
  };

  const handleCanvasNav = (event: NavEvents) => {
    consoleNonProd(event);
    switch (event) {
      case NavEvents.FIT:
        onFitCanvas(state);
        break;
      case NavEvents.ZOOM_IN:
        onZoomIn(state);
        break;
      case NavEvents.ZOOM_OUT:
        onZoomOut(state);
        break;
    }
  };

  const toggleWebAudio = () => {
    setLocalStorage(StorageKeys.webAudioEnabled, !isWebAudioEnabled);
    setIsWebAudioEnabled((current) => !current);
  };

  const toggleEchoReduction = () => {
    setIsEchoReductionEnabled((current) => !current);
  };

  const handleSetJoinInfo = (info: JoinInfoType) => {
    setJoinInfo(info);
    setLocalStorage(StorageKeys.meeting, info.Meeting);
    setLocalStorage(StorageKeys.attendee, info.Attendee);
  };

  const handleSetMeetingMode = (mode: MeetingMode) => {
    setMeetingMode(mode);
    setLocalStorage(StorageKeys.meetingMode, mode);
  };

  const handleSetUserColor = (inputColor: string) => {
    setUserColor(inputColor?.replace("_", "#"));
  };

  const handleSetDbId = (meetingId: number) => {
    setDbId(meetingId);
    setLocalStorage(StorageKeys.meetingDbId, meetingId);
  };

  const handleSetMeetingRole = (role: MeetingRole) => {
    setMeetingRole(role);
    setLocalStorage(StorageKeys.meetingRole, role);
    setIsInstructorOrModerator(
      role === MeetingRole.Moderator || role === MeetingRole.Presenter
    );
  };

  const toggleChat = (): void => {
    setShowChat(!showChat);
  };

  const toggleParticipants = (): void => {
    setShowParticipants(!showParticipants);
  };

  const toggleShowUserVideo = () => {
    setShowUserVideo(!showUserVideo);
  };

  const handleSetShowPopup = (): void => {
    setShowPopup(!showPopup);
    setPopupMessage(popupMessage);
  };

  const providerValue: AppContextType = {
    handleCanvasNav,
    generateEventHandlers,
    presentationUpdated,
    setPresentationUpdated,
    presentationDiff,
    setPresentationDiff,
    meetingId,
    setMeetingId,
    userColor,
    handleSetUserColor,
    localUserName,
    setLocalUserName,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    isWebAudioEnabled,
    toggleWebAudio,
    isEchoReductionEnabled,
    toggleEchoReduction,
    meetingMode,
    handleSetMeetingMode,
    joinInfo,
    handleSetJoinInfo,
    region,
    setRegion,
    dbId,
    handleSetDbId,
    meetingRole,
    handleSetMeetingRole,
    isInstructorOrModerator,
    simpleView,
    setSimpleView,
    isInstructorAVMeeting,
    setIsInstructorAVMeeting,
    isStudentAVMeeting,
    setIsStudentAVMeeting,
    meetingHasChat,
    setMeetingHasChat,
    isDemo,
    setIsDemo,
    attendeeList,
    setAttendeeList,
    showChat,
    setShowChat,
    closedChatCount,
    setClosedChatCount,
    toggleChat,
    showParticipants,
    setShowParticipants,
    toggleParticipants,
    showUserVideo,
    setShowUserVideo,
    toggleShowUserVideo,
    expandToolBar,
    setExpandToolBar,
    showPopup,
    setShowPopup,
    handleSetShowPopup,
    popupMessage,
    setPopupMessage,
    state,
    dispatch,
  };

  return (
    <AppContext.Provider value={providerValue}>
      {isWebAudioEnabled ? (
        // @ts-ignore
        <VoiceFocusProvider {...vfConfigValue}>
          {/* @ts-ignore */}
          <MeetingProviderWithDeviceReplacement>
            <MeetingWithRosterProvider>
              <RosterProvider>{children}</RosterProvider>
            </MeetingWithRosterProvider>
          </MeetingProviderWithDeviceReplacement>
        </VoiceFocusProvider>
      ) : (
        // @ts-ignore
        <MeetingProvider>
          <MeetingWithRosterProvider>
            <RosterProvider>{children}</RosterProvider>
          </MeetingWithRosterProvider>
        </MeetingProvider>
      )}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("Use useAppContext hook inside AppContextProvider.");
  }

  return context;
};
