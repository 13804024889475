import React, { Fragment, Suspense, useEffect, useState } from "react";
import {
  AudioInputVFControl,
  useLocalAudioOutput,
} from "amazon-chime-sdk-component-library-react";
import { useAppContext } from "../../contexts/appContext";
import {
  CanvasMode,
  MeetingMode,
  MeetingRole,
  State,
} from "../../contexts/types";
import { ToolBar } from "./toolBar";
import { IconButton } from "../buttons/iconButton";
import { CameraToggle } from "./controls/cameraToggle";
import { MicToggle } from "./controls/micToggle";
import { EndMeetingControl } from "./controls/endMeetingControl";
import { ClosedCaptionsButton } from "./controls/closedCaptionsButton";
import Spinner from "../spinner";
import { EditPresentationTools } from "./editPresentationTools";
import { EventPayload } from "../../providers/types";

type MeetingControlsProps = {
  handleRefetchBoards?: (currentBoard: number) => void;
  showClosedCaptions: boolean;
  toggleClosedCaptions: () => void;
  toggleDeviceSettings: () => void;
  mode: CanvasMode;
  editBoardIIIF: boolean;
  setEditBoardIIIF: (edit: boolean) => void;
  setIIIFRemoved: (removed: boolean) => void;
  handleChangeSlide;
  sendEvent: (eventPayload: EventPayload, appState: State) => void;
};

/** Toolbar component for the left docked meeting options used by the Instructor while presenting or editing a class
 * @param {MeetingControlsProps} props
 * @prop {() => void} handleRefetchBoards - Function that refetches the boards for the current meeting
 *  @prop {boolean} showClosedCaptions
 *  @prop {() => void} toggleClosedCaptions
 *  @prop {() => void} toggleDeviceSettings
 * @prop {CanvasMode} mode -  mode can be Presentation, Edit, Replay
 *  @prop {boolean} editBoardIIIF
 *  @prop {(edit -  boolean) => void} setEditBoardIIIF
 *  @prop {(removed -  boolean) => void} setIIIFRemoved
 * @prop {() => void} handleChangeSlide - Function to handle slide changes
 */
export const MeetingControls: React.FC<MeetingControlsProps> = ({
  handleRefetchBoards,
  showClosedCaptions,
  toggleClosedCaptions,
  toggleDeviceSettings,
  mode,
  editBoardIIIF,
  setEditBoardIIIF,
  setIIIFRemoved,
  handleChangeSlide,
  sendEvent,
}) => {
  const {
    isWebAudioEnabled,
    meetingMode,
    meetingRole,
    isInstructorAVMeeting,
    isStudentAVMeeting,
  } = useAppContext();
  const showCCToggle = isInstructorAVMeeting || isStudentAVMeeting;

  const includeAVControls = meetingMode === MeetingMode.Attendee;
  const [showEndModal, setShowEndModal] = useState(false);
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();

  // if you are a moderator and in the meeting you should have audio
  useEffect(() => {
    if (!isAudioOn) {
      toggleAudio();
    }
  }, [isAudioOn, toggleAudio]);

  return (
    <Fragment>
      <ToolBar orientation="vertical" className="meeting-controls">
        <Suspense fallback={<Spinner />}>
          {includeAVControls &&
            mode === CanvasMode.PRESENTATION &&
            isInstructorAVMeeting && (
              <Fragment>
                <IconButton
                  onClick={toggleDeviceSettings}
                  iconName="settings"
                  desc="View settings"
                  toolTip="right"
                  btnId="toggle-device-settings"
                />
                {isWebAudioEnabled ? (
                  // Amazon Chime Echo Reduction is a premium feature, please refer to the Pricing page for details.*/}
                  // TODO: Check price differences and if we want to use this, set it to true for all VxP meetings
                  <AudioInputVFControl />
                ) : (
                  <MicToggle toolTip="right" />
                )}
                <CameraToggle toolTip="right" />
                {/* <AudioOutputControl /> */}
                {showCCToggle && (
                  <ClosedCaptionsButton
                    component="ICON_BUTTON"
                    toolTip="right"
                    showClosedCaptions={showClosedCaptions}
                    toggleClosedCaptions={toggleClosedCaptions}
                  />
                )}
                <span className="divider" />
              </Fragment>
            )}

          {meetingRole === MeetingRole.Presenter &&
            mode === CanvasMode.PRESENTATION && (
              <EditPresentationTools
                handleRefetchBoards={handleRefetchBoards}
                mode={mode}
                editBoardIIIF={editBoardIIIF}
                setEditBoardIIIF={setEditBoardIIIF}
                setIIIFRemoved={setIIIFRemoved}
                handleChangeSlide={handleChangeSlide}
                sendEvent={sendEvent}
              />
            )}

          {/* {meetingRole === MeetingRole.Presenter && <ContentShareControl />} */}
          {mode === CanvasMode.PRESENTATION && (
            <>
              <span className="divider" />
              <IconButton
                iconName="log-out"
                onClick={() => setShowEndModal(!showEndModal)}
                desc="Leave"
                toolTip="right"
                btnId="log-out-button"
              />
              <EndMeetingControl
                showEndModal={showEndModal}
                handleDismiss={() => setShowEndModal(!showEndModal)}
              />
            </>
          )}
        </Suspense>
      </ToolBar>
    </Fragment>
  );
};
