const config = {
  // Environment
  env: process.env.REACT_APP_ENV,
  nodeEnv: process.env.NODE_ENV,
  // API
  api: process.env.REACT_APP_API_URL,
  // AWS Config
  awsRegion: process.env.REACT_APP_AWS_REGION,
  awsIdentityPool: process.env.REACT_APP_IDENTITY_POOL_ID,
  // S3 Buckets
  collectionS3Bucket: process.env.REACT_APP_AWS_BUCKET,
  collectionBaseImgUrl: process.env.REACT_APP_IMAGE_BASE_URL,
  uploadS3Bucket: process.env.REACT_APP_UPLOAD_S3_BUCKET,
  concatS3Bucket: process.env.REACT_APP_MEDIA_CONCATENATION_S3_BUCKET,
  // Cloudfront url for the replay recordings and vtt files
  replayCf: process.env.REACT_APP_REPLAY_CF_URL,
  // Google OAuth
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  // Logger URL
  loggerUrl: process.env.REACT_APP_LOGGER_URL,
};

export default config;
