import React, { Fragment, useEffect, useReducer, useState } from "react";
import { ApolloError } from "@apollo/client";
import classnames from "classnames";
import { ProgressBar } from "./inputs/progressBar";
import { consoleNonProd, pluralize } from "../utils/utilityBeltUtils";
import { Modal } from "./modal/modal";
import { ModalHeader } from "./modal/modalHeader";
import { ModalBody } from "./modal/modalBody";
import SimpleBar from "simplebar-react";
import { IconButton } from "./buttons/iconButton";
import { ModalFooter } from "./modal/modalFooter";
import { Button, PRIMARY, SECONDARY } from "./buttons/button";
import { validateFileName, validateText } from "../utils/validators";
import { s3Upload } from "../utils/s3Utils";
import { ApolloErrorsList } from "./apolloErrorsList";
import { CreateImageUploadInput, MetaDataPermissions } from "../types";
import { TextInput } from "./inputs/textInput";

type UploadProgressProps = {
  uploadPercent: number;
  file: File;
};

const UploadProgress: React.FC<UploadProgressProps> = ({
  uploadPercent,
  file,
}) => {
  return (
    <div className="upload-progress">
      <div className="upload-progress__title">{file.name}</div>
      <div className="upload-progress__details">
        <div>{Math.round(file.size / 1000000).toFixed(1)} MB</div>
        <div>{uploadPercent}%</div>
        {/* Add message saying that the upload will continue in the background and they can close this window */}
      </div>
      <ProgressBar max={100} value={uploadPercent} disabled={true} />
    </div>
  );
};

type ImgMetaFormProps = {
  fileName: string;
  onChange: (
    fileName: string,
    meta: Omit<CreateImageUploadInput, "uploadUrl">,
    valid: boolean
  ) => void;
};

const ImgMetaForm: React.FC<ImgMetaFormProps> = ({ fileName, onChange }) => {
  const [title, setTitle] = useState<string>();
  const [titleValid, setTitleValid] = useState(true);
  const [artist, setArtist] = useState<string>();
  const [artistValid, setArtistValid] = useState(true);
  const [year, setYear] = useState<string>();
  const [yearValid, setYearValid] = useState(true);
  const [permission, setPermission] = useState(MetaDataPermissions.PUBLIC);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const valid = validateText(event.target.value);
    setTitleValid(valid);
    setTitle(event.target.value !== "" ? event.target.value : null);

    const meta: Omit<CreateImageUploadInput, "uploadUrl"> = {
      title: event.target.value,
      people: artist,
      displayDate: year,
      permissions: permission,
    };
    const formValid = valid && artistValid && yearValid;
    onChange(fileName, meta, formValid);
  };

  const handleArtistChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const valid = event.target.value === "" || validateText(event.target.value);
    setArtistValid(valid);
    setArtist(event.target.value !== "" ? event.target.value : null);

    const meta: Omit<CreateImageUploadInput, "uploadUrl"> = {
      title,
      people: event.target.value,
      displayDate: year,
      permissions: permission,
    };
    const formValid = titleValid && valid && yearValid;
    onChange(fileName, meta, formValid);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const valid = event.target.value === "" || validateText(event.target.value);
    setYearValid(valid);
    setYear(event.target.value !== "" ? event.target.value : null);

    const meta: Omit<CreateImageUploadInput, "uploadUrl"> = {
      title,
      people: artist,
      displayDate: event.target.value,
      permissions: permission,
    };
    const formValid = titleValid && artistValid && valid;
    onChange(fileName, meta, formValid);
  };

  const handlePermissionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault();
    setPermission(event.target.value as MetaDataPermissions);

    const meta: Omit<CreateImageUploadInput, "uploadUrl"> = {
      title,
      people: artist,
      displayDate: year,
      permissions: event.target.value as MetaDataPermissions,
    };
    const formValid = titleValid && artistValid && yearValid;
    onChange(fileName, meta, formValid);
  };

  return (
    <div>
      <TextInput
        label="Title"
        required={true}
        name="title"
        id="title"
        onChange={handleTitleChange}
        disabled={false}
        hasError={!titleValid}
        errorMessage="Image title is required."
      />
      <TextInput
        label="Artist"
        required={false}
        name="artist"
        id="artist"
        onChange={handleArtistChange}
        disabled={false}
        hasError={!artistValid}
        errorMessage="Please enter a valid artist name."
      />
      <div className="upload-modal__label--content-selected__row img-meta-form__row">
        <TextInput
          label="Year"
          required={false}
          name="year"
          id="year"
          onChange={handleYearChange}
          disabled={false}
          hasError={!yearValid}
          errorMessage="Please enter a valid year."
        />
        <label htmlFor="permission">Permission level</label>
        <select onChange={handlePermissionChange} id="permission">
          {Object.values(MetaDataPermissions).map((v, i) => (
            <option value={v} key={i}>
              {v.toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

type UploadModalProps = {
  display: boolean;
  onDismiss: () => void;
  onComplete: (
    s3Key?: string,
    meta?: Omit<CreateImageUploadInput, "uploadUrl">
  ) => void;
  maxFiles?: number;
  validateFileType: (fileName: string) => { valid: boolean; message?: string };
  generateS3Key: (fileName?: string) => string;
  error?: ApolloError;
  showOnCompleteError: boolean;
  bucket: string;
  includeImgMetaForm?: boolean;
};

/** Modal that contains the upload form
 * @param {UploadModalProps} props
 * @prop {boolean} display - Whether or not the modal is displayed
 * @prop {() => void} onDismiss - Function that is called when the modal is dismissed
 * @prop {() => void} onComplete - Function that is called after upload to S3 has been completed
 * @prop {number} maxFiles
 * @prop {(fileName: string) => { valid: boolean; message?: string }} validateFileType
 * @prop {(fileName?: string) => string} generateS3Key
 * @prop {ApolloError} error
 * @prop {boolean} showOnCompleteError
 * @prop {string} bucket
 */
export const UploadModal: React.FC<UploadModalProps> = ({
  display,
  onDismiss,
  onComplete,
  maxFiles = 1,
  validateFileType,
  generateS3Key,
  error,
  showOnCompleteError = false,
  bucket,
  includeImgMetaForm = false,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(showOnCompleteError);
  const [tooManyFilesError, setTooManyFilesError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidFiles, setInvalidFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [disableSubmit, setDisableSubmit] = useState(true);

  type State = {
    [fileName: string]: number;
  };

  enum ActionType {
    UPDATE_PROGRESS,
    UPDATE_FILES,
  }

  type UpdateProgressAction = {
    type: ActionType.UPDATE_PROGRESS;
    payload: {
      fileName: string;
      progress: number;
    };
  };

  type UpdateFilesAction = {
    type: ActionType.UPDATE_FILES;
    payload: {
      fileName: string;
    };
  };

  type Action = UpdateFilesAction | UpdateProgressAction;

  const reducer = (state: State, action: Action): State => {
    const { type, payload } = action;
    const updatedState = { ...state };

    switch (type) {
      case ActionType.UPDATE_PROGRESS:
        updatedState[payload.fileName] = payload.progress;
        return updatedState;
      case ActionType.UPDATE_FILES:
        updatedState[payload.fileName] = 0;
        return updatedState;
    }
  };

  const [state, dispatch] = useReducer(reducer, {});

  type ImgMetaState = {
    [fileName: string]: Omit<CreateImageUploadInput, "uploadUrl">;
  };

  enum ImgMetaActionType {
    UPDATE,
  }

  type UpdateImgMetaAction = {
    type: ImgMetaActionType.UPDATE;
    payload: {
      fileName: string;
      meta: Omit<CreateImageUploadInput, "uploadUrl">;
    };
  };

  const imgMetaReducer = (
    state: ImgMetaState,
    action: UpdateImgMetaAction
  ): ImgMetaState => {
    const { type, payload } = action;
    const updatedState = { ...state };

    switch (type) {
      case ImgMetaActionType.UPDATE:
        updatedState[payload.fileName] = payload.meta;
        return updatedState;
    }
  };

  const [imgMetaState, imgMetaDispatch] = useReducer(imgMetaReducer, {});

  const submitButtonText = `Upload ${files?.length} ${pluralize(
    "file",
    files.length
  )}`;

  const tooManyFilesText = `Only ${maxFiles} ${pluralize(
    "file",
    maxFiles
  )} or less allowed`;

  const handleDrag = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleSetFiles = (fileList: FileList) => {
    const chosenFiles: File[] = Array.prototype.slice.call(fileList);
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];
    const errorMessages: string[] = [];

    chosenFiles.forEach((f) => {
      // validate file type
      const { valid, message } = validateFileType(f.name);

      if (valid) {
        // validate file name
        const fileName = f.name.trim();
        // Remove file extension for validation
        const baseFileName = fileName
          .substring(0, fileName.lastIndexOf("."))
          .trim();

        const fileNameValid = validateFileName(baseFileName);

        if (fileNameValid) {
          validFiles.push(f);
        } else {
          invalidFiles.push(f);
          errorMessages.push(
            "File names can only contain alpha numeric characters"
          );
        }
      } else {
        invalidFiles.push(f);
        errorMessages.push(message);
      }
    });

    const newFiles = [...files, ...validFiles];

    setFiles(newFiles);
    newFiles.forEach((f) => {
      dispatch({
        payload: { fileName: f.name },
        type: ActionType.UPDATE_FILES,
      });
    });

    setInvalidFiles(invalidFiles);
    setErrors(errorMessages);
  };

  const handleUpdateImgMeta = (
    fileName: string,
    meta: Omit<CreateImageUploadInput, "uploadUrl">,
    valid: boolean
  ) => {
    const action: UpdateImgMetaAction = {
      type: ImgMetaActionType.UPDATE,
      payload: {
        fileName,
        meta,
      },
    };

    imgMetaDispatch(action);
    setDisableSubmit(!valid);
  };

  const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSetFiles(e.dataTransfer.files);
    }
  };

  const handleChange = (e) => {
    handleSetFiles(e.target.files);
  };

  const handleUploadComplete = (
    s3Key: string,
    meta?: Omit<CreateImageUploadInput, "uploadUrl">
  ) => {
    onComplete && onComplete(s3Key, meta);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    for (const file of files) {
      try {
        const updateProgress = (percent: number) =>
          dispatch({
            payload: { fileName: file.name, progress: percent },
            type: ActionType.UPDATE_PROGRESS,
          });

        const { success, message, fileName } = await s3Upload(
          bucket,
          generateS3Key(file.name),
          file,
          updateProgress,
          (s3Key: string) =>
            handleUploadComplete(s3Key, imgMetaState[file.name])
        );

        if (!success) {
          setShowError(true);
          setErrorMessage(message);
          setIsLoading(false);
          return;
        }
      } catch (error) {
        // handle errors
        consoleNonProd("Error creating image uploads", error);
        setShowError(true);
        setIsLoading(false);
        setErrorMessage(JSON.stringify(error));
      }
    }
  };

  const handleDismiss = (_e) => {
    // Clear state on dismiss
    setShowError(false);
    setErrorMessage("");
    setErrors([]);
    setInvalidFiles([]);
    setIsLoading(false);
    setFiles([]);
    setDragActive(false);
    setErrorMessage("");
    onDismiss();
  };

  useEffect(() => {
    if (files.length > maxFiles) {
      setTooManyFilesError(true);
    } else {
      setTooManyFilesError(false);
    }
  }, [files, maxFiles]);

  return (
    <Modal
      dismissible={true}
      display={display}
      onDismiss={handleDismiss}
      className="upload-modal"
    >
      <ModalHeader>
        <h1>Upload Image</h1>
      </ModalHeader>
      <ModalBody className="upload-modal__body">
        {isLoading && !showError ? (
          <div className="upload-modal__loading">
            <div className="upload-modal__loading--message">
              {Object.values(state).some((percent) => percent < 100)
                ? `Upload started successfully! You can now close this modal and the ${pluralize(
                    "file",
                    files.length
                  )}  will continue to upload.`
                : "Upload completed!"}
            </div>
            <SimpleBar
              forceVisible={false}
              autoHide={true}
              className="upload-modal__loading--simple-bar"
            >
              {files.map((file) => (
                <UploadProgress
                  key={file.name}
                  uploadPercent={state[file.name]}
                  file={file}
                />
              ))}
            </SimpleBar>
          </div>
        ) : showError ? (
          // Display GQL errors when there is an issue with the query
          <div className="art-object-gallery__no-results">
            <div className="no-results-message upload-modal__error">
              <p>
                The following errors prevented {invalidFiles.length}{" "}
                {pluralize("file", invalidFiles.length)} from being uploaded:
              </p>
              {error?.graphQLErrors && <ApolloErrorsList error={error} />}
              {errorMessage && <div>{errorMessage}</div>}
            </div>
          </div>
        ) : (
          <form
            id="upload-modal__form"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <label
              className={classnames("upload-modal__label", {
                "drag-active": dragActive,
              })}
              htmlFor="upload-modal__input"
            >
              <SimpleBar
                forceVisible={false}
                autoHide={true}
                className="upload-modal__label--simple-bar"
              >
                <div className="upload-modal__label--content">
                  <p className="upload-modal__label--content-instructions">
                    Drag and drop your {pluralize("file", maxFiles)} here or
                    click to open the file browser ({maxFiles} file limit)
                  </p>
                  {files?.length > 0 && (
                    <Fragment>
                      <div className="upload-modal__label--content-selected">
                        Selected files: {files.length}
                        <ul>
                          {files.map((file, i: number) => (
                            <li key={i}>
                              <div className="upload-modal__label--content-selected__row">
                                <IconButton
                                  onClick={(e) => {
                                    setFiles((oldFiles) =>
                                      oldFiles.filter(
                                        (newFile, index) => index !== i
                                      )
                                    );
                                  }}
                                  iconName={"trash-2"}
                                  desc={"remove image"}
                                />
                                {file.name}
                              </div>
                              {includeImgMetaForm && (
                                <ImgMetaForm
                                  fileName={file.name}
                                  onChange={handleUpdateImgMeta}
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Fragment>
                  )}
                  {tooManyFilesError && (
                    <div className="upload-modal__error">
                      <p>
                        Oh no, this is too many files please remove{" "}
                        {files.length - maxFiles}{" "}
                        {pluralize("file", files.length - maxFiles)}
                      </p>
                    </div>
                  )}
                  {invalidFiles?.length > 0 && (
                    <div className="upload-modal__error">
                      The following files were not selected:
                      <ul>
                        {invalidFiles.map((file, i) => (
                          <li key={i}>
                            <div className="upload-modal__error--file">
                              {file.name}
                            </div>
                            <div>{errors[i]}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </SimpleBar>
            </label>
            <input
              type="file"
              id="upload-modal__input"
              multiple={true}
              onChange={handleChange}
            />
          </form>
        )}
      </ModalBody>
      <ModalFooter className="upload-modal__footer">
        {isLoading ? (
          <Button
            btnType={PRIMARY}
            text="Close"
            onClick={handleDismiss}
            id="close-video-upload-button"
          />
        ) : (
          <Fragment>
            <Button
              btnType={SECONDARY}
              text="Cancel"
              onClick={handleDismiss}
              disabled={isLoading}
              id="cancel-upload-button"
            />
            <Button
              btnType={PRIMARY}
              text={tooManyFilesError ? tooManyFilesText : submitButtonText}
              onClick={handleSubmit}
              type="submit"
              disabled={
                !files?.length ||
                isLoading ||
                showError ||
                tooManyFilesError ||
                disableSubmit
              }
              id="submit-upload-button"
            />
          </Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
};
