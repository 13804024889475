import React from "react";

type SwitchProps = {
  onClick: (e) => any;
  isOn: boolean;
  className?: string;
  id?: string;
  disabled?: boolean;
};

/**
 * Component for an VxP Switch
 * @param {SwitchProps} props
 * @prop {(e: React.MouseEvent<HTMLButtonElement>) => void} onClick - Event handler for mouse click
 * @prop {boolean} isOn - boolean value to provide the classnames for the correct styling
 * @prop {string} className - optional input for additonal classes
 * @prop {string} id - optional input to add unique ids for switches
 */

export const Switch: React.FC<SwitchProps> = ({
  onClick,
  isOn,
  className,
  id,
  disabled,
}) => {
  let toggleClassName = "switch";

  if (isOn) {
    toggleClassName = `${toggleClassName} switch--active`;
  }

  if (className) {
    toggleClassName = `${toggleClassName} ${className}`;
  }

  if (disabled) {
    toggleClassName = `${toggleClassName} switch--disabled`;
  }

  return (
    <input
      type="checkbox"
      onChange={onClick}
      className={toggleClassName}
      id={id}
      alt="switch"
      disabled={disabled}
      checked={isOn}
    />
  );
};
