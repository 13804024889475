import React, { useEffect } from "react";
import { Button, PRIMARY, SECONDARY, SMALL } from "../../buttons/button";
import { ToolBar } from "../toolBar";
import { consoleNonProd } from "../../../utils/utilityBeltUtils";
import { classConstants } from "../../../cssConstants";

type ClosedCaptionsSizeProps = {
  onClick: () => void;
  onSelectSize: (newSize: number) => void;
  captionsSize: number;
  showCcSizePopup: boolean;
};

export const ClosedCaptionsSize: React.FC<ClosedCaptionsSizeProps> = ({
  onSelectSize,
  onClick,
  captionsSize,
  showCcSizePopup,
}) => {
  // const [showCcSizePopup, setCcSizePopup] = useState(false);

  const captionSizeArray = ["large", "larger", "x-large", "xx-large"];

  const setClosedCaptionsFontSize = (newSize: number, e) => {
    document?.documentElement?.style.setProperty(
      "--closed-caption-size",
      captionSizeArray[newSize]
    );

    const pscs = document.getElementsByClassName(classConstants.MAPC);
    const macs = document.getElementsByClassName(classConstants.MAC);
    if (pscs) {
      const psc = pscs[0];
      psc?.classList?.add("hidden");
    }
    if (macs) {
      const mac = macs[0];
      mac?.classList?.add("hidden");
    }
    e?.preventDefault();
    e?.stopPropagation();
    onSelectSize(newSize);
  };

  useEffect(() => {
    consoleNonProd(captionsSize);
  }, [captionsSize]);

  return (
    <>
      <Button
        btnType={PRIMARY}
        text={"Captions size"}
        size={SMALL}
        disabled={false}
        id="caption-size-button"
        onClick={(e) => {
          onClick();
          e.preventDefault();
          e.stopPropagation();
        }}
        icon="closed-captioning"
      />
      {showCcSizePopup && (
        <ToolBar
          className={classConstants.MAPC}
          orientation="vertical"
          id="captions-popover"
        >
          <div>
            <Button
              onClick={(e) => setClosedCaptionsFontSize(0, e)}
              size="small"
              btnType={SECONDARY}
              disabled={captionsSize === 0}
              inputClass={captionsSize === 0 ? "__selected-speed" : ""}
              id="captions-small"
            >
              Small
            </Button>
            <Button
              onClick={(e) => setClosedCaptionsFontSize(1, e)}
              size="small"
              btnType={SECONDARY}
              inputClass={captionsSize === 1 ? "__selected-speed" : ""}
              disabled={captionsSize === 1}
              id="captions-large"
            >
              Standard
            </Button>
            <Button
              onClick={(e) => setClosedCaptionsFontSize(2, e)}
              size="small"
              btnType={SECONDARY}
              inputClass={captionsSize === 2 ? "__selected-speed" : ""}
              disabled={captionsSize === 2}
              id="captions-larger"
            >
              Large
            </Button>
            <Button
              onClick={(e) => setClosedCaptionsFontSize(3, e)}
              size="small"
              btnType={SECONDARY}
              inputClass={captionsSize === 3 ? "__selected-speed" : ""}
              disabled={captionsSize === 3}
              id="captions-extra-large"
            >
              Extra large
            </Button>
          </div>
        </ToolBar>
      )}
    </>
  );
};
