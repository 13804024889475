import React, { useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import { Container } from "../../../container";
import classnames from "classnames";
import { classConstants } from "../../../../cssConstants";

type MoreActionsWrapperProps = {
  itemClick?: (e) => void;
  children?: JSX.Element | JSX.Element[];
  upOrDown?: "up" | "down";
  leftOrRight?: "left" | "right";
  inputClass?: string;
  size?: "standard" | "slidePreview";
};

/** Component to wrap items in the More actions menu, should be used as the
 * child component of the MoreActionsButton component
 * @param {MoreActionsWrapperProps} props
 * @prop {(e) => void} itemClick - on click function
 * @prop {JSX.Element | JSX.Element[]} children - child elements passed to this
 * wrapper should be one or more MoreActionsItem elements
 * @prop {"up" | "down"} upOrDown - determines whether more actions menu expands up or down
 * @prop {"left" | "right"} leftOrRight - determines whether more actions menu is to left or right of more actions button
 * @prop {string} inputClass - Optional class name for the Container component
 * @prop {"standard" | "slidePreview"} size - Size of the more actions button, should match the size passed to the MoreActionsButton
 */
export const MoreActionsWrapper: React.FC<MoreActionsWrapperProps> = ({
  itemClick,
  children,
  upOrDown,
  leftOrRight,
  inputClass,
  size,
}) => {
  const styles = useSpring({
    loop: false,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      tension: 500,
      clamp: true,
    },
  });

  // Close the dropdown if the user clicks outside of it
  // and clean up afterwords
  useEffect(() => {
    function closeOnOutsideClick(event) {
      if (!event.target.matches("." + classConstants.MAC)) {
        itemClick(event);
      }
    }
    window.addEventListener("click", closeOnOutsideClick);
    return function closeCleanUp() {
      window.removeEventListener("click", closeOnOutsideClick);
    };
  }, [itemClick]);

  return (
    <div className="more-actions-wrapper">
      <animated.div style={styles}>
        <Container
          className={classnames([classConstants.MAC, inputClass], {
            "__going-up": upOrDown === "up",
            "__going-down": upOrDown === "down",
            "__going-left": leftOrRight === "left",
            "__going-right": leftOrRight === "right",
            "__slide-preview": size === "slidePreview",
          })}
          childrenClassName={classConstants.MAC + "-children"}
          isDismissable={false}
        >
          <div
            id="more-actions-focus"
            tabIndex={-1}
            style={{
              position: "absolute",
              top: "0px",
              height: 0,
              width: 0,
            }}
            aria-label="More actions list focus entry point"
          />
          {children}
        </Container>
      </animated.div>
    </div>
  );
};
