import { useAudioVideo } from "amazon-chime-sdk-component-library-react";
import { DataMessage } from "amazon-chime-sdk-js";
import React, {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useCallback,
} from "react";
import { DATA_MESSAGE_LIFETIME_MS, DATA_EMOTE_TOPIC } from "../constants";
import { useAppContext } from "../contexts/appContext";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";
import { consoleNonProd } from "../utils/utilityBeltUtils";

interface DataEmotesStateContextType {
  sendEmote: (emotePayload: EmotePayload) => void;
  emotes: EmoteDataEvent[];
  raisedHands: Set<string>;
}

const DataEmotesStateContext = createContext<
  DataEmotesStateContextType | undefined
>(undefined);

export interface EmoteDataEvent {
  emote: string;
  senderAttendeeId: string;
  timestamp: number;
  senderName: string;
  isSelf: boolean;
  targetAttendeeId?: string;
}

export interface State {
  emotes: EmoteDataEvent[];
  raisedHands: Set<string>;
}

export enum DataEmotesActionType {
  ADD,
  REMOVE,
}

export interface AddAction {
  type: DataEmotesActionType.ADD;
  payload: EmoteDataEvent;
}

export interface RemoveAction {
  type: DataEmotesActionType.REMOVE;
  payload: EmoteDataEvent;
}

export const initialState: State = {
  emotes: [],
  raisedHands: new Set<string>(),
};

export type Action = AddAction | RemoveAction;

export enum EmoteTypes {
  HAND_RAISE = "hand-raise",
  HAND_LOWER = "hand-lower",
  CLAP = "clap",
}

export type EmotePayload = {
  type: EmoteTypes;
  leader: boolean;
  action: DataEmotesActionType;
  targetAttendeeId?: string;
  clearRaisedHands?: boolean;
};

export function reducer(state: State, action: Action): State {
  const { payload } = action;
  if (payload) {
    const type = JSON.parse(payload.emote)?.action;
    const targetId = JSON.parse(payload.emote)?.targetAttendeeId;
    const clearHands = JSON.parse(payload.emote)?.clearRaisedHands;

    switch (type) {
      case DataEmotesActionType.ADD:
        if (JSON.parse(payload.emote).type === "hand-raise") {
          state.raisedHands.add(payload.senderAttendeeId);
        }
        return {
          emotes: [...state.emotes, payload],
          raisedHands: state.raisedHands,
        };
      case DataEmotesActionType.REMOVE:
        if (JSON.parse(payload.emote).type === "hand-lower") {
          if (targetId) {
            state.raisedHands.delete(targetId);
          } else {
            state.raisedHands.delete(payload.senderAttendeeId);
          }
          if (clearHands) {
            state.raisedHands.clear();
          }
        }
        return {
          emotes: [...state.emotes, payload],
          raisedHands: state.raisedHands,
        };
      default:
        throw new Error("Incorrect action in DataEmotesProvider reducer");
    }
  }
}

export const DataEmotesProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { localUserName } = useAppContext();
  const meetingManager = useMeetingWithRoster();
  const audioVideo = useAudioVideo();
  const [state, dispatch] = useReducer(reducer, initialState);

  const handler = useCallback(
    (dataEvent: DataMessage) => {
      if (!dataEvent.throttled) {
        const isSelf =
          dataEvent.senderAttendeeId ===
          meetingManager.meetingSession?.configuration.credentials?.attendeeId;
        const message: EmotePayload = dataEvent.json();
        const actionType = JSON.parse(dataEvent?.text())?.action;
        if (isSelf) {
          dispatch({
            type: isSelf ? actionType : message.action,
            payload: {
              emote: new TextDecoder().decode(dataEvent.data),
              senderAttendeeId: dataEvent.senderAttendeeId,
              timestamp: dataEvent.timestampMs,
              senderName: dataEvent.senderExternalUserId,
              isSelf: true,
            },
          });
        } else {
          const data = dataEvent.json();
          dispatch({
            type: isSelf ? actionType : message.action,
            payload: {
              emote: data.message,
              senderAttendeeId: dataEvent.senderAttendeeId,
              timestamp: dataEvent.timestampMs,
              senderName: data.senderName,
              isSelf: false,
            },
          });
        }
      } else {
        consoleNonProd("DataMessage is throttled. Please resend");
      }
    },
    [meetingManager]
  );

  const sendEmote = useCallback(
    (emotePayload: EmotePayload) => {
      if (
        !meetingManager ||
        !meetingManager.meetingSession ||
        !meetingManager.meetingSession.configuration.credentials ||
        !meetingManager.meetingSession.configuration.credentials.attendeeId ||
        !audioVideo
      ) {
        return;
      }

      const message = JSON.stringify(emotePayload);
      const payload = {
        message,
        senderName: localUserName,
        targetAttendee: emotePayload.targetAttendeeId,
      };
      const senderAttendeeId =
        meetingManager.meetingSession.configuration.credentials.attendeeId;
      audioVideo.realtimeSendDataMessage(
        DATA_EMOTE_TOPIC,
        payload,
        DATA_MESSAGE_LIFETIME_MS
      );
      handler(
        new DataMessage(
          Date.now(),
          DATA_EMOTE_TOPIC,
          new TextEncoder().encode(message),
          senderAttendeeId,
          localUserName
        )
      );
    },
    [meetingManager, audioVideo, handler, localUserName]
  );

  const value = {
    sendEmote,
    emotes: state.emotes,
    raisedHands: state.raisedHands,
  };

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_EMOTE_TOPIC, handler);
    return () => {
      audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_EMOTE_TOPIC);
    };
  }, [audioVideo, handler]);

  return (
    <DataEmotesStateContext.Provider value={value}>
      {children}
    </DataEmotesStateContext.Provider>
  );
};

export const useDataEmotes = (): {
  sendEmote: (emotePayload: EmotePayload) => void;
  emotes: EmoteDataEvent[];
  raisedHands: Set<string>;
} => {
  const meetingManager = useMeetingWithRoster();
  const context = useContext(DataEmotesStateContext);

  if (!meetingManager || !context) {
    throw new Error(
      "Use useDataEmotes hook inside DataEmotesProvider. Wrap DataEmotesProvider under MeetingProvider."
    );
  }

  return context;
};
