import React from "react";
import classnames from "classnames";
import { Icon } from "../icon";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export type IconButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  iconName: string;
  desc: string;
  className?: string;
  disabled?: boolean;
  disabledStroke?: string;
  toolTip?: "top" | "bottom" | "right" | "left" | "none";
  size?: string;
  stroke?: string;
  colorIcon?: boolean;
  fill?: string;
  btnId?: string;
  selected?: boolean;
  hoverState?: boolean;
  type?: "button" | "submit" | "reset";
  counter?: boolean;
  counterValue?: string | number;
  svgId?: string;
  overflowContainerId?: string;
};

/**
 * Component that treats the Icon component as a button. Choose from toolTip "top", "bottom", "right", "left", or "none" to customize tool tip display. Defaults to no tooltip. Defaults to size 24px, and a grey #282828 stroke.
 * @IconButtonProps onClick, iconName, desc, className, disabled, disabledStroke, toolTip, size, stroke, colorIcon, fill, btnId, selected, hoverState, type
 */

export const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  iconName,
  desc,
  className,
  disabled = false,
  disabledStroke,
  toolTip = "none",
  size = "24px",
  stroke,
  colorIcon,
  fill,
  btnId,
  selected = false,
  hoverState,
  type = "button",
  counter = false,
  counterValue,
  svgId,
  overflowContainerId,
}) => {
  const { getLocalStorage } = useLocalStorage();

  return (
    <div className="icon-button-wrapper">
      <button
        type={type}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        disabled={disabled}
        className={classnames("icon-button", className)}
        id={btnId}
      >
        <Icon
          name={iconName}
          desc={desc}
          toolTip={toolTip}
          height={size}
          stroke={stroke}
          colorIcon={colorIcon}
          fill={fill}
          disabledStroke={disabledStroke}
          disabled={disabled}
          hoverState={hoverState}
          className={selected ? "--selected" : ""}
          svgId={svgId}
          overflowContainerId={overflowContainerId}
        />
      </button>
      {counter && <p className="counter">{counterValue}</p>}
    </div>
  );
};
