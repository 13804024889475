import {
  GetObjectCommand,
  GetObjectOutput,
  GetObjectRequest,
  PutObjectCommand,
  PutObjectCommandInput,
} from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { v4 as uuid } from "uuid";
import { s3Client } from "./clients/awsClient";
import { validateFileName } from "./validators";
import config from "./config";

type S3UtilResponse = {
  success: boolean;
  album?: string;
  message?: string;
};

/** Function that creates a new S3 album inside the image upload bucket
 * @param {string} fileName - Name of the file that will be used to derive the album name
 * @returns {Promise<S3UtilResponse>} -- `success` - whether or not album creation
 * was successful, `message` - error when the creation was unsuccessful, `album` - title of the album
 */
export const createAlbum = async (
  fileName: string
): Promise<S3UtilResponse> => {
  let album = fileName.substring(0, fileName.lastIndexOf("."));
  album = album.trim();

  if (!album) {
    return {
      success: false,
      message: "File names must contain at least one non-space character.",
      album,
    };
  }

  if (album.indexOf("/") !== -1) {
    return {
      success: false,
      message: "File names cannot contain slashes.",
      album,
    };
  }

  if (!validateFileName(album)) {
    return {
      success: false,
      message: "File names can only contain alpha numeric characters",
      album,
    };
  }

  // append a uuid to the end of the folder name to prevent issues with duplicate file/album names
  var albumKey = encodeURIComponent(album) + "__" + uuid();

  // Create S3 album for the image
  try {
    const key = albumKey + "/";
    const params = { Bucket: config.uploadS3Bucket, Key: key };
    await s3Client.send(new PutObjectCommand(params));

    return { success: true, album: albumKey };
  } catch (err) {
    // Return error to component so it can be rendered
    return { success: false, message: err, album: albumKey };
  }
};

/** Function that uploads a file to an S3 Album
 * @param {string} album - Name of the album that we will be adding the file to
 * @param {File} file - File to be uploaded
 * @returns {Promise<S3UtilResponse>} -- `success` - whether or not file upload
 * was successful, `message` - error when the upload was unsuccessful,
 * `album` - title of the album, `key` - S3 file name
 */
export const addFile = async (
  album: string,
  file: File
): Promise<S3UtilResponse & { fileName: string }> => {
  const fileName = file.name.trim();
  // Remove file extension for validation
  const baseFileName = fileName.substring(0, fileName.lastIndexOf(".")).trim();

  if (!fileName || !baseFileName) {
    return {
      success: false,
      message: "File names must contain at least one non-space character.",
      album,
      fileName,
    };
  }

  if (fileName.indexOf("/") !== -1) {
    return {
      success: false,
      message: "File names cannot contain slashes.",
      album,
      fileName,
    };
  }

  if (!validateFileName(baseFileName)) {
    return {
      success: false,
      message: "File names can only contain alpha numeric characters",
      album,
      fileName,
    };
  }

  const key = album + "/" + encodeURIComponent(fileName);
  const parts = fileName.split(".");
  const fileType = parts[parts.length - 1];

  const uploadParams = {
    Bucket: config.uploadS3Bucket,
    Key: key,
    Body: file,
    ContentType: `image/${fileType}`,
  };

  try {
    await s3Client.send(new PutObjectCommand(uploadParams));
    return { success: true, album, fileName: key };
  } catch (e) {
    // Return error to component so it can be rendered
    return { success: false, message: e, album, fileName: key };
  }
};

/** Function that returns an S3 Object
 * @param {string} bucket - Bucket that contains the object
 * @param {string} key - Object key in S3
 */
export const getObject = async (
  bucket: string,
  key: string
): Promise<S3UtilResponse & { body?: GetObjectOutput["Body"] }> => {
  const getParams: GetObjectRequest = {
    Bucket: bucket,
    Key: key,
  };

  try {
    const resp = await s3Client.send(new GetObjectCommand(getParams));

    if (resp.$metadata.httpStatusCode === 200) {
      return { success: true, body: resp.Body };
    } else {
      return {
        success: false,
        message: `httpStatusCode: ${resp.$metadata.httpStatusCode}`,
      };
    }
  } catch (e) {
    return { success: false, message: e };
  }
};

/**
 * @param {string} album - Album/folder that the video will be uploaded under,
 * should be the media pipeline concat recording folder
 * @param {File} file - Video mp4 file to be uploaded
 * @param {(percent: number) => void} progressCallback - Callback function to share upload status
 * @param {() => void} completedCallback - Callback function to ba called when upload is completed
 * @returns {Promise<S3UtilResponse>} -- `success` - whether or not file upload
 * was successful, `message` - error when the upload was unsuccessful,
 * `album` - title of the album, `key` - S3 file name
 */
export const putReplaceVideoObject = async (
  album: string,
  file: File,
  progressCallback: (percent: number) => void,
  completedCallback: () => void
): Promise<S3UtilResponse & { fileName: string }> => {
  const key = album + "/replace-video/" + album + "__replace.mp4";

  try {
    const putOptions: PutObjectCommandInput = {
      Bucket: config.concatS3Bucket,
      Key: key,
      Body: file,
      ContentType: "video/mp4",
    };

    const parallelUploads3 = new Upload({
      client: s3Client,
      leavePartsOnError: false, // optional manually handle dropped parts
      params: putOptions,
    });

    parallelUploads3.on("httpUploadProgress", (progress) => {
      progressCallback(Math.round((progress.loaded / progress.total) * 100));

      if (progress.loaded === progress.total) {
        completedCallback();
      }
    });

    parallelUploads3.done();

    return { success: true, album, fileName: key };
  } catch (e) {
    return { success: false, message: e, album, fileName: key };
  }
};

/**
 * @param {string} album - Album/folder that the video will be uploaded under,
 * should be the media pipeline concat recording folder
 * @param {File} file - Video mp4 file to be uploaded
 * @param {(percent: number) => void} progressCallback - Callback function to share upload status
 * @param {() => void} completedCallback - Callback function to ba called when upload is completed
 * @returns {Promise<S3UtilResponse>} -- `success` - whether or not file upload
 * was successful, `message` - error when the upload was unsuccessful,
 * `album` - title of the album, `key` - S3 file name
 */
export const uploadImage = async (
  album: string,
  file: File,
  progressCallback: (percent: number) => void,
  completedCallback: (key: string) => void
): Promise<S3UtilResponse & { fileName: string }> => {
  const fileName = file.name.trim();
  // Remove file extension for validation
  const baseFileName = fileName.substring(0, fileName.lastIndexOf(".")).trim();

  let albumName = fileName.substring(0, fileName.lastIndexOf("."));
  albumName = albumName.trim();

  // append a uuid to the end of the folder name to prevent issues with duplicate file/album names
  var albumKey = encodeURIComponent(album) + "__" + uuid();

  const key = albumKey + "/" + encodeURIComponent(fileName);

  const parts = fileName.split(".");
  const fileType = parts[parts.length - 1];

  try {
    const putOptions: PutObjectCommandInput = {
      Bucket: config.uploadS3Bucket,
      Key: key,
      Body: file,
      ContentType: `image/${fileType}`,
    };

    const parallelUploads3 = new Upload({
      client: s3Client,
      leavePartsOnError: false, // optional manually handle dropped parts
      params: putOptions,
    });

    parallelUploads3.on("httpUploadProgress", (progress) => {
      progressCallback(Math.round((progress.loaded / progress.total) * 100));

      if (progress.loaded === progress.total) {
        completedCallback(key);
      }
    });

    parallelUploads3.done();

    return { success: true, album, fileName: key };
  } catch (e) {
    return { success: false, message: e, album, fileName: key };
  }
};

/**
 * @param {string} bucket - S3 bucket for storing the file
 * @param {string} key - Key for storing the file in S3
 * @param {File} file - File to be uploaded
 * @param {(percent: number) => void} progressCallback - Callback function to share upload status
 * @param {() => void} completedCallback - Callback function to ba called when upload is completed
 * @returns {Promise<S3UtilResponse>} -- `success` - whether or not file upload was successful,
 * `message` - error when the upload was unsuccessful,
 * `key` - Location of file in S3
 */
export const s3Upload = async (
  bucket: string,
  key: string,
  file: File,
  progressCallback: (percent: number) => void,
  completedCallback: (key: string) => void
): Promise<S3UtilResponse & { fileName: string }> => {
  const fileName = file.name.trim();
  const parts = fileName.split(".");
  const fileType = parts[parts.length - 1];
  const fileContent = fileType.toLowerCase() === "mp4" ? "video" : "image";

  try {
    const putOptions: PutObjectCommandInput = {
      Bucket: bucket,
      Key: key,
      Body: file,
      ContentType: `${fileContent}/${fileType}`,
    };

    const parallelUploads3 = new Upload({
      client: s3Client,
      leavePartsOnError: false, // optional manually handle dropped parts
      params: putOptions,
    });

    parallelUploads3.on("httpUploadProgress", (progress) => {
      progressCallback(Math.round((progress.loaded / progress.total) * 100));

      if (progress.loaded === progress.total) {
        completedCallback(key);
      }
    });

    parallelUploads3.done();

    return { success: true, fileName: key };
  } catch (e) {
    return { success: false, message: e, fileName: key };
  }
};
