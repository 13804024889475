import React from "react";
import {
  LocalVideo,
  useAudioVideo,
  useLocalVideo,
} from "amazon-chime-sdk-component-library-react";
import classnames from "classnames";
import { StorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import {
  DataEmotesActionType,
  EmoteTypes,
  useDataEmotes,
} from "../../providers/dataEmotes";
import { Attendee } from "../../types";
import { useAppContext } from "../../contexts/appContext";
import { MeetingRole } from "../../contexts/types";
import { useScreenContext } from "../../contexts/screenContext";
import { VideoTile, VideoType } from "./videoTile";

type UserVideoProps = {
  name?: string;
  iconHex?: string;
  handIsRaised?: boolean;
};

export const UserVideo: React.FC<UserVideoProps> = ({
  name,
  iconHex,
  handIsRaised,
}) => {
  const { toggleShowUserVideo, meetingRole, firstName, lastName, state } =
    useAppContext();
  const { getLocalStorage } = useLocalStorage();
  const { isVideoEnabled } = useLocalVideo();
  const { useMobileTools } = useScreenContext();

  const { sendEmote } = useDataEmotes();
  const { raisedHands } = useDataEmotes();
  const useFhd = getLocalStorage(StorageKeys.fhd) === true;
  const locAttendee: Attendee = getLocalStorage(StorageKeys.attendee);
  const userName = name ? name : firstName + " " + lastName;
  const userIconHex = `#${
    iconHex ? iconHex : locAttendee?.iconColor?.split("_")[1]
  }`;
  const userHandIsRaised = handIsRaised
    ? handIsRaised
    : raisedHands.has(locAttendee?.AttendeeId);

  const audioVideo = useAudioVideo();
  let userVideoQuality = { width: 320, height: 160, framerate: 15 };
  let maxBandwidth = 400;

  // Up the user video quality and bandwidth for presenters
  if (meetingRole === MeetingRole.Presenter) {
    maxBandwidth = 1400;

    if (useFhd) {
      userVideoQuality = { width: 1920, height: 1080, framerate: 30 };
    } else {
      userVideoQuality = { width: 1280, height: 720, framerate: 30 };
    }
  }

  audioVideo?.chooseVideoInputQuality(
    userVideoQuality.width,
    userVideoQuality.height,
    userVideoQuality.framerate
  );
  audioVideo?.setVideoMaxBandwidthKbps(maxBandwidth);

  const handleRaisedHand = () => {
    sendEmote({
      type: EmoteTypes.HAND_LOWER,
      leader: false,
      action: DataEmotesActionType.REMOVE,
    });
  };

  return (
    <div
      className={classnames("videos__user", {
        __spotlight:
          state.spotlightInstructor && meetingRole === MeetingRole.Presenter,
      })}
    >
      <VideoTile
        isVideoEnabled={isVideoEnabled}
        isInstructorOrModerator={false}
        videoType={VideoType.USER}
        speaking={false}
        iconHex={userIconHex}
        name={userName}
        handRaised={userHandIsRaised}
        handleLowerHand={handleRaisedHand}
        dismissable={!state.spotlightInstructor && !useMobileTools}
        toggleShowVideo={toggleShowUserVideo}
      >
        <LocalVideo nameplate="You" className="video__tile" />
      </VideoTile>
    </div>
  );
};
