import React, { useRef, useEffect, Fragment, useState } from "react";
import OpenSeadragon from "openseadragon";
import classnames from "classnames";
import { ChapterPreviewType } from "../../types";
import { Icon } from "../icon";
import { formatReplayTime } from "../../utils/timeUtiles";

type SlidePreviewType = {
  time?: string;
  chapter: VTTCue;
  className?: string;
  includeStartTime?: boolean;
  hoverState?: boolean;
};

type ContentType = {
  url: string;
  title: string;
  type: ChapterPreviewType;
};

type IIIFReplayPreviewProps = {
  content: ContentType;
  time?: string;
};

type PreviewTitleProps = {
  title: string;
  time?: string;
};

const PreviewTitle: React.FC<PreviewTitleProps> = ({ title, time }) => {
  return (
    <div className="slide__title" tabIndex={-1}>
      <h2 className="slide__title--text">{title}</h2>
      {time && <h3 className="slide__title--time">{time}</h3>}
    </div>
  );
};

// Because IIIF we still have to use osd to preview otherwise we can use html
const IIIFReplayPreview: React.FC<IIIFReplayPreviewProps> = ({
  time,
  content,
}) => {
  const ref: React.Ref<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const initOsd = OpenSeadragon({
      element: ref.current !== null ? ref.current : undefined,
      // have to do a "positive" check for an image because if the OSD thinks it's a single image it does extra work
      // and on new boards there isn't anything to operate on and OSD explodes...
      tileSources: content.url,
      // collection mode true and vertical are for sensible display of multi images
      collectionMode: true,
      collectionLayout: "horizontal",
      collectionRows: 1,
      showNavigator: false,
      mouseNavEnabled: 0,
      showNavigationControl: false,
      showSequenceControl: false,
      immediateRender: true,
      blendTime: 0,
      animationTime: 0,
      tabIndex: -1,
    });

    return () => {
      initOsd.destroy();
    };
  }, [content, ref]);

  return (
    <Fragment>
      <div
        className={classnames("slide__preview", "slide__preview__iiif")}
        ref={ref}
        tabIndex={-1}
      />
      <PreviewTitle title={content.title} time={time} />
    </Fragment>
  );
};

export const ReplayPreview: React.FC<SlidePreviewType> = ({
  time,
  chapter,
  className,
  includeStartTime = false,
  hoverState = false,
}) => {
  const [content, setContent] = useState<ContentType>();
  const [startTime, setStartTime] = useState("");

  useEffect(() => {
    if (chapter) {
      setStartTime(formatReplayTime(chapter?.startTime));
      if (chapter.text) {
        setContent(JSON.parse(chapter?.text));
      }
    }
  }, [chapter?.text, chapter?.startTime]);

  return (
    <div className={classnames("replay-preview-container", className)}>
      {(() => {
        switch (content?.type) {
          case ChapterPreviewType.IIIF_MANIFEST:
            return (
              <IIIFReplayPreview
                time={includeStartTime ? startTime : time}
                content={content}
              />
            );
          case ChapterPreviewType.IMAGE_URL:
            return (
              <Fragment>
                <img
                  className="slide__preview"
                  tabIndex={-1}
                  src={content?.url}
                  alt=""
                />
                <PreviewTitle
                  title={content?.title}
                  time={includeStartTime ? startTime : time}
                />
              </Fragment>
            );
          case ChapterPreviewType.THREEDEE:
            return (
              <Fragment>
                <Icon
                  name="box"
                  height="75px"
                  desc="3D Icon"
                  stroke="#d64220"
                  toolTip="none"
                  hoverState={hoverState}
                  className="slide__preview"
                />
                <PreviewTitle
                  title={content?.title}
                  time={includeStartTime ? startTime : time}
                />
              </Fragment>
            );
          case ChapterPreviewType.VIDEO:
            return (
              <Fragment>
                <Icon
                  name="play"
                  height="75px"
                  desc="video play icon"
                  stroke="#d64220"
                  toolTip="none"
                  hoverState={hoverState}
                  className="slide__preview"
                />
                <PreviewTitle
                  title={content?.title}
                  time={includeStartTime ? startTime : time}
                />
              </Fragment>
            );
        }
      })()}
    </div>
  );
};
