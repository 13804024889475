import React from "react";
import DateTimePicker from "react-datetime-picker";
import { Icon } from "../icon";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";

type DateTimePickerInputProps = {
  dateTime?: Date;
  setDateTime: (dateTime: Date) => void;
  disabled: boolean;
  autofocus?: boolean;
  minDate?: Date;
};

export const DateTimePickerInput: React.FC<DateTimePickerInputProps> = ({
  dateTime,
  setDateTime,
  disabled,
  autofocus = true,
  minDate,
}) => {
  return (
    <div>
      <DateTimePicker
        onChange={setDateTime}
        value={dateTime}
        disableClock={true}
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        yearPlaceholder="YYYY"
        hourPlaceholder="hh"
        minutePlaceholder="mm"
        minDate={minDate}
        autoFocus={autofocus}
        disabled={disabled}
        clearIcon={
          <Icon
            name="dismiss"
            desc="Clear class time"
            toolTip="top"
            disabled={disabled}
          />
        }
        calendarIcon={
          <Icon
            name="calendar"
            desc="Open date picker"
            toolTip="top"
            disabled={disabled}
          />
        }
      />
    </div>
  );
};
