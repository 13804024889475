import React, { Fragment, useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContext";
import { useDataEvents } from "../../providers/dataEvents";
import { useScreenContext } from "../../contexts/screenContext";
import classNames from "classnames";
import { Attendee } from "../../types";
import {
  CanvasDataEvent,
  EventPayload,
  EventTypes,
} from "../../providers/types";

type LaserProps = { localAttendee: Attendee; events: CanvasDataEvent[] };

export const LaserPointer: React.FC<LaserProps> = ({
  localAttendee,
  events,
}) => {
  const { sendEvent } = useDataEvents();
  const { isInstructorOrModerator, state } = useAppContext();
  const { screenWidth, screenHeight } = useScreenContext();
  const mainCanvas = document?.getElementById("main-canvas");

  useEffect(() => {
    // if you aren't the laser instructor then follow them
    if (localAttendee?.AttendeeId !== state.laserInstructor && events?.length) {
      const eventObj = events[events.length - 1];
      if (!eventObj.isSelf && eventObj.event) {
        const event: EventPayload = JSON.parse(eventObj.event);
        if (event.leader) {
          switch (event.type) {
            case EventTypes.LASER_POINTER_LOCATION:
              const figureOutTheY = (event: EventPayload) => {
                let pointerY = 0;
                if (event?.laserView?.y) {
                  // y postion on the instructor screen
                  const mouseY = event.laserView.y;
                  // then the screen percentage from the instructor screen
                  const yPercentage = mouseY / event.laserView.instHeight;
                  // then the aspect ratio difference between the two screens
                  const aspectRatioDiff =
                    screenWidth /
                    screenHeight /
                    (event.laserView.instWidth / event.laserView.instHeight);
                  // then we get the 50% point of the student screen, and some magic math happens
                  // uh we uh get the difference of the aspect ratio and it works?
                  pointerY =
                    screenHeight / 2 +
                    (yPercentage - 0.5) *
                      Math.abs(aspectRatioDiff) *
                      (screenHeight / 2) *
                      2;
                }
                return pointerY;
              };
              const newYPoint = figureOutTheY(event);
              let laser = document.getElementById("laserPointer");

              if (laser) {
                // set main pointer location
                laser.style.top = `${newYPoint}px`;
                laser.style.left = event.laserView.x + "%";
                // auto generate the trail and then remove it
                for (let index = 1; index < 6; index++) {
                  setTimeout(() => {
                    const trail = document.createElement("div");
                    trail.className = "student-trail";
                    const x = (laser.offsetLeft / window.innerWidth) * 100;
                    const y = laser.offsetTop;
                    trail.style.left = x + "%";
                    trail.style.top = y + "px";
                    mainCanvas?.appendChild(trail);
                    setTimeout(() => {
                      trail.style.left = event.laserView.x + "%";
                      trail.style.top = `${newYPoint}px`;
                    }, 10 * index);
                    setTimeout(() => {
                      mainCanvas?.removeChild(trail);
                    }, 500);
                  }, 200);
                }
              }
          }
        }
      }
    }
  }, [
    events,
    isInstructorOrModerator,
    screenHeight,
    screenWidth,
    state.laserInstructor,
    localAttendee?.AttendeeId,
  ]);

  useEffect(() => {
    // if you are an instructor and you are the laser instructor, then adjust your local pointer
    if (
      isInstructorOrModerator &&
      localAttendee?.AttendeeId === state.laserInstructor
    ) {
      let setLaserCoordinates = (event) => {
        let laser = document.getElementById("laserPointer");
        if (laser) {
          laser.style.top = event.y + "px";
          laser.style.left = event.x + "px";
          const trail = document.createElement("div");
          trail.className = "trail";
          trail.style.left = `${event.x}px`;
          trail.style.top = `${event.y}px`;
          mainCanvas?.appendChild(trail);
          setTimeout(() => {
            mainCanvas?.removeChild(trail);
          }, 200);
        }
      };
      document.addEventListener("mousemove", setLaserCoordinates);
      return function () {
        document.removeEventListener("mousemove", setLaserCoordinates);
      };
    }
  }, [
    isInstructorOrModerator,
    state.currentBoard,
    state.showLaser,
    sendEvent,
    screenHeight,
    screenWidth,
    localAttendee?.AttendeeId,
    state.laserInstructor,
  ]);

  // everything below this is for sending the event to students
  // at a decent interval rather than rapid fire events exploding
  // rapid fire style
  const intervalRef = useRef(null);
  const mouseXRef = useRef(null);
  const mouseYRef = useRef(null);
  const isMouseMovingRef = useRef(false);
  const timerRef = useRef(null);

  useEffect(() => {
    const sendCoordinates = () => {
      const mouseX = parseInt(mouseXRef.current);
      const mouseY = parseInt(mouseYRef.current);

      // event to be sent to students if you are the laser instructor
      if (
        isInstructorOrModerator &&
        mouseX &&
        localAttendee?.AttendeeId === state.laserInstructor
      ) {
        // because of the way the viewers work we send the x axis as %
        // and the y axis as pixels from center of screen
        let x = (mouseX / screenWidth) * 100;
        let y = mouseY;
        sendEvent(
          {
            type: EventTypes.LASER_POINTER_LOCATION,
            showLaser: state.showLaser,
            laserView: {
              x: x,
              y: y,
              instHeight: screenHeight,
              instWidth: screenWidth,
              activeInstructor: state.laserInstructor,
            },
            leader: isInstructorOrModerator,
          },
          state
        );
      }
    };
    const handleMouseMove = (event) => {
      mouseXRef.current = event.x;
      mouseYRef.current = event.y;

      // Start or restart the interval when the mouse moves
      if (!isMouseMovingRef.current) {
        intervalRef.current = setInterval(sendCoordinates, 500); // Adjust the interval as needed (in milliseconds)
      }

      // Mouse is considered moving
      isMouseMovingRef.current = true;

      // Clear the timer and start a new one
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        // Mouse has stopped moving
        clearInterval(intervalRef.current);
        isMouseMovingRef.current = false;
      }, 500); // Adjust the delay as needed (in milliseconds)
    };
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timerRef.current);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [
    screenHeight,
    screenWidth,
    state.currentBoard,
    localAttendee?.AttendeeId,
    state.laserInstructor,
    state.showLaser,
    isInstructorOrModerator,
    sendEvent,
  ]);

  return (
    <Fragment>
      <div
        id="laserPointer"
        className={classNames("laser-pointer", {
          "student-laser": localAttendee?.AttendeeId !== state.laserInstructor,
        })}
      />
    </Fragment>
  );
};
