import React, { ChangeEvent, useEffect, useState } from "react";

import { IconButton } from "../components/buttons/iconButton";
import {
  Button,
  PRIMARY,
  SECONDARY,
  SMALL,
  STANDARD,
  TERTIARY,
} from "../components/buttons/button";
import { TextInput } from "../components/inputs/textInput";
import { Icon } from "../components/icon";
import { ToolBar } from "../components/toolbars/toolBar";
import { Container } from "../components/container";
import { Toggle } from "../components/inputs/toggle";
import { Checkbox } from "../components/inputs/checkbox";
import Spinner from "../components/spinner";
import { PageWrapper } from "../components/pageWrapper";
import { PopUp } from "../components/popUps/popUp";
import { Modal } from "../components/modal/modal";
import { VideoTile, VideoType } from "../components/videos/videoTile";
import { HelperPopUp } from "../components/popUps/helperPopUp";
import { consoleNonProd, getHexFromIconColor } from "../utils/utilityBeltUtils";
import { Link } from "../components/link";
import { ProgressBar } from "../components/inputs/progressBar";
import { MoreActionsButton } from "../components/toolbars/controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "../components/toolbars/controls/moreActions/moreActionsWrapper";
import { MoreActionsItem } from "../components/toolbars/controls/moreActions/moreActionsItem";
import { IconColor } from "../types";
import { Chat } from "../components/chat";
import { ChatDataMessage } from "../providers/dataMessages";
import { MeetingRole } from "../contexts/types";
import { AttendeeInitials } from "../components/attendeeInitials";

// Component for the /style-guide route
export const StyleGuide: React.FC = () => {
  const [errorButton, setErrorButton] = useState<boolean>(true);
  const [checked, setChecked] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showHelperPopup, setShowHelperPopup] = useState(false);
  const popUp1 = "pop-up-1";
  const [showPopUp1, setShowPopUp1] = useState(false);
  const [showPopUp2, setShowPopUp2] = useState(false);
  const [showAgain1, setShowAgain1] = useState(true);
  const [showAgain2, setShowAgain2] = useState(true);
  const [progressBarValue, setProgressBarValue] = useState(50);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const [showMoreActions2, setShowMoreActions2] = useState(false);
  const [showMoreActions3, setShowMoreActions3] = useState(false);
  const [showMoreActions4, setShowMoreActions4] = useState(false);
  const [showMoreActions5, setShowMoreActions5] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [showChat2, setShowChat2] = useState(true);
  const [chatMessages, setChatMessages] = useState<ChatDataMessage[]>([
    {
      message: "Hello",
      senderName: "Albert Barnes",
      meetingRole: MeetingRole.Audience,
      color: getHexFromIconColor(IconColor._0091EA),
      isSelf: false,
      senderAttendeeId: "",
      messageId: "",
      deleted: false,
      timestamp: 123456,
      softBanned: false,
    },
    {
      message: "Hi!",
      senderName: "Test User",
      color: IconColor._00ACC1,
      timestamp: 123478,
      messageId: "",
      deleted: false,
      senderAttendeeId: "",
      isSelf: true,
      softBanned: false,
    },
    {
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      senderName: "Albert Barnes",
      meetingRole: MeetingRole.Audience,
      color: getHexFromIconColor(IconColor._0091EA),
      isSelf: false,
      senderAttendeeId: "",
      messageId: "",
      deleted: false,
      timestamp: 123456,
      softBanned: false,
    },
    {
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      senderName: "Test User",
      color: IconColor._00ACC1,
      timestamp: 123478,
      messageId: "",
      deleted: false,
      senderAttendeeId: "",
      isSelf: true,
      softBanned: false,
    },
    {
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      senderName: "Albert Barnes",
      meetingRole: MeetingRole.Audience,
      color: getHexFromIconColor(IconColor._0091EA),
      isSelf: false,
      senderAttendeeId: "",
      messageId: "",
      deleted: false,
      timestamp: 123456,
      softBanned: false,
    },
    {
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      senderName: "Test User",
      color: IconColor._00ACC1,
      timestamp: 123478,
      messageId: "",
      deleted: false,
      senderAttendeeId: "",
      isSelf: true,
      softBanned: false,
    },
  ]);

  const handleDontShowAgain1 = () => {
    setShowPopUp1(false);
    setShowAgain1(false);
    localStorage.setItem(popUp1, "true");
  };

  const handleDontShowAgain2 = () => {
    setShowPopUp2(false);
    setShowAgain2(false);
  };

  useEffect(() => {
    // On load set show pop ups
    const show1 = localStorage.getItem(popUp1);
    if (show1) {
      setShowPopUp1(false);
      setShowAgain1(false);
    }
  }, []);

  const colors = Object.values(IconColor).map(getHexFromIconColor);

  const sendMessage = (text: string) => {
    const timestamp = new Date().getTime();
    const message: ChatDataMessage = {
      message: text,
      senderName: "Test User",
      color: IconColor._00ACC1,
      timestamp,
      messageId: `${timestamp}_test`,
      deleted: false,
      senderAttendeeId: "",
      isSelf: true,
      softBanned: false,
    };

    setChatMessages([...chatMessages, message]);
  };

  return (
    <PageWrapper className="style-guide">
      <div className="page">
        <h1>Style Guide</h1>
        {/* Colors */}
        <div id="colors">
          <h2>Colors</h2>
          <Container
            isDismissable={false}
            className="brand-colors"
            xToCloseId="close-brand-colors"
          >
            <h3>Brand Colors</h3>
            <div className="colors">
              <div className="color" id="brand--light">
                <h4>Brand Light</h4>
              </div>
              <div className="color" id="brand--medium">
                <h4>Brand Medium</h4>
              </div>
              <div className="color" id="brand--dark">
                <h4>Brand Dark</h4>
              </div>
            </div>
          </Container>

          <Container
            isDismissable={false}
            className="app-colors"
            xToCloseId="close-app-colors"
          >
            <h3>App Colors</h3>
            <div className="colors">
              <div className="color" id="grey">
                <h4>Grey</h4>
              </div>
              <div className="color" id="contrast">
                <h4>Contrast</h4>
              </div>
              <div className="color" id="grey--opacity">
                <h4>Grey Opacity</h4>
              </div>
              <div className="color" id="error">
                <h4>Error</h4>
              </div>
              <div className="color" id="admin-blue">
                <h4>Admin Blue</h4>
              </div>
              <div className="color" id="admin-blue--light">
                <h4>Admin Blue Light</h4>
              </div>
              <div className="color" id="green">
                <h4>Green</h4>
              </div>
              <div className="color" id="yellow">
                <h4>Yellow</h4>
              </div>
              <div className="color" id="nav--hover">
                <h4>Nav Hover</h4>
              </div>
              <div className="color" id="button">
                <h4>Button</h4>
              </div>
              <div className="color" id="button--hover">
                <h4>Button Hover</h4>
              </div>
              <div className="color" id="button--border">
                <h4>Button Border</h4>
              </div>
              <div className="color" id="button--calendar">
                <h4>Button Calendar</h4>
              </div>
              <div className="color" id="border--dark">
                <h4>Border Dark</h4>
              </div>
              <div className="color" id="border--input">
                <h4>Border Input</h4>
              </div>
            </div>
          </Container>
          <Container
            isDismissable={false}
            className="app-colors"
            xToCloseId="close-app-colors"
          >
            <h3>Icon Colors</h3>
            <div className="colors">
              {colors.map((color) => (
                <div
                  className="color"
                  key={color}
                  style={{
                    backgroundColor: color,
                    color: "#fff",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  {color}
                </div>
              ))}
            </div>
          </Container>

          <Container
            isDismissable={false}
            className="copy-colors"
            xToCloseId="close-copy-colors"
          >
            <h3>Copy Colors</h3>
            <div className="colors">
              <div className="color" id="background--dark">
                <h4 id="copy">Default</h4>
              </div>
              <div className="color" id="background--shadow">
                <h4 id="copy--light">Light</h4>
              </div>
              <div className="color" id="background--shadow">
                <h4 id="copy--medium">Medium</h4>
              </div>
              <div className="color" id="background--dark">
                <h4 id="copy--dark">Dark</h4>
              </div>
              <div className="color" id="background--dark">
                <h4 id="copy--aws">AWS</h4>
              </div>
              <div className="color" id="background--dark">
                <h4 id="copy--label">Label</h4>
              </div>
            </div>
          </Container>

          <Container
            isDismissable={false}
            className=""
            xToCloseId="close-background-colors"
          >
            <h3>Background Colors</h3>
            <div className="colors">
              <div className="color" id="background--light">
                <h4>Light</h4>
              </div>
              <div className="color" id="background--grey">
                <h4>Grey</h4>
              </div>
              <div className="color" id="background--dark">
                <h4>Dark</h4>
              </div>
              <div className="color" id="background--shadow">
                <h4>Shadow</h4>
              </div>
              <div className="color" id="background--canvas">
                <h4>Canvas</h4>
              </div>
            </div>
          </Container>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Checkboxes */}
        <div id="checkbox">
          <h2>Checkbox</h2>
          <h3>Unchecked</h3>
          <Checkbox
            id={undefined}
            value={undefined}
            onChange={undefined}
            checked={false}
          />
          <h3>Checked</h3>
          <Checkbox
            id={undefined}
            value={undefined}
            onChange={undefined}
            checked={true}
          />
          <h3>With Label</h3>
          <Checkbox
            id={undefined}
            value={undefined}
            onChange={undefined}
            checked={true}
            label="Hello I'm an example label"
          />
        </div>
        <hr style={{ width: "100%" }} />

        {/* Buttons */}
        <div id="buttons">
          <h2>Buttons</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Button
              btnType={PRIMARY}
              disabled={false}
              size={STANDARD}
              text="Primary"
              onClick={() => null}
            />
            <Button
              btnType={PRIMARY}
              disabled={false}
              size={STANDARD}
              icon="video"
              onClick={() => null}
              text="Primary Icon"
            />
            <Button
              btnType={PRIMARY}
              disabled={true}
              size={STANDARD}
              text="Primary Disabled"
              onClick={() => null}
            />
            <Button
              btnType={PRIMARY}
              disabled={true}
              size={STANDARD}
              text="Primary Disabled"
              icon="trash"
              onClick={() => null}
            />
            <Button
              btnType={SECONDARY}
              disabled={false}
              size={STANDARD}
              text="Secondary"
              onClick={() => null}
            />
            <Button
              btnType={SECONDARY}
              disabled={false}
              size={STANDARD}
              icon="video"
              onClick={() => null}
              text="Secondary Icon"
            />
            <Button
              btnType={SECONDARY}
              disabled={true}
              size={STANDARD}
              text="Secondary Disabled"
              onClick={() => null}
            />
            <Button
              btnType={TERTIARY}
              disabled={false}
              size={STANDARD}
              text="Tertiary"
              onClick={() => null}
            />
            <Button
              btnType={TERTIARY}
              disabled={true}
              size={STANDARD}
              text="Tertiary Disabled"
              onClick={() => null}
            />
            <Button
              btnType={PRIMARY}
              disabled={false}
              size={SMALL}
              text="Primary Small"
              onClick={() => null}
            />
            <Button
              btnType={SECONDARY}
              disabled={false}
              size={SMALL}
              text="Secondary Small"
              onClick={() => null}
            />
            <Button
              onClick={() => null}
              icon="download"
              btnType={SECONDARY}
              size={SMALL}
              text="Secondary Icon"
            />
            <Button
              btnType={PRIMARY}
              disabled={false}
              size={SMALL}
              text="Primary Small Button with Very Long Text"
              onClick={() => null}
              icon="link"
              customWidth="100%"
            />
            <Button
              btnType={TERTIARY}
              disabled={false}
              size={SMALL}
              text="Tertiary Small"
              onClick={() => null}
            />
            <Button
              onClick={() => null}
              icon="download"
              btnType={TERTIARY}
              size={SMALL}
              text="Tertiary Icon Small"
            />
          </div>

          <h3>Icon Button</h3>
          <IconButton
            onClick={() => alert("Yeay! You clicked next!")}
            iconName="arrow-right"
            desc="Next slide"
            toolTip="top"
          />

          <h3>Icon Button Disabled</h3>
          <IconButton
            onClick={() => alert("Yeay! You clicked previous!")}
            iconName="arrow-left"
            desc="Previous slide"
            toolTip="top"
            disabled={true}
          />
        </div>
        <hr style={{ width: "100%" }} />

        {/* Text Inputs */}
        <div id="textInputs" style={{ width: "400px" }}>
          <h2>Text Inputs</h2>
          <TextInput
            disabled={false}
            errorMessage="This field is required"
            id="itone"
            label="Only numbers, min 3, max 10, & required"
            minLength={3}
            minLengthMessage="Minimum number of characters is 3"
            maxLength={10}
            maxLengthMessage="Maximum number of characters is 10"
            pattern="^[0-9]*$"
            patternMessage="Only numbers allowed"
            required={true}
            name="itone"
            parentForm=""
            hasError={false}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              consoleNonProd(e.target.value);
              consoleNonProd(e.target.validity.valid);
            }}
            onBeforeInput={(e: any): void => {
              consoleNonProd(e);
            }}
          />
          <TextInput
            disabled={false}
            errorMessage=""
            hasError={false}
            id="itTwo"
            label="First Name"
            name="itTwo"
            parentForm="joinForm"
            placeHolder="I'm a Placeholder Text Example"
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              consoleNonProd(e.target);
            }}
            onBeforeInput={(e: any): void => {
              consoleNonProd(e);
            }}
          />
          <TextInput
            disabled={false}
            errorMessage="I'm an example error message, that happens with no value"
            hasError={errorButton}
            id="itThree"
            label="I'm an error state label"
            name="itThree"
            parentForm="joinForm"
            placeHolder=""
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              consoleNonProd(e.target.value);
              if (e.target.value.length > 0) {
                setErrorButton(false);
              } else {
                setErrorButton(true);
              }
            }}
            onBeforeInput={(e: any): void => {
              consoleNonProd(e);
            }}
          />
          <TextInput
            disabled={false}
            errorMessage=""
            hasError={false}
            id="itFive"
            label="Icon Label"
            name="itFive"
            parentForm="joinForm"
            placeHolder=""
            icon="search"
            iconStroke="#282828"
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              consoleNonProd(e.target.value);
            }}
            onBeforeInput={(e: any): void => {
              consoleNonProd(e);
            }}
          />
          <TextInput
            disabled={true}
            errorMessage=""
            hasError={false}
            id="itFour"
            label="Disabled Label"
            name="itFour"
            parentForm="joinForm"
            placeHolder=""
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              consoleNonProd(e.target.value);
            }}
            onBeforeInput={(e: any): void => {
              consoleNonProd(e);
            }}
          />
          <TextInput
            disabled={true}
            errorMessage=""
            hasError={false}
            id="itSix"
            label="Disabled Icon Label"
            name="itSix"
            parentForm="joinForm"
            placeHolder=""
            icon="search"
            iconStroke="#6e6e6e"
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              consoleNonProd(e.target.value);
            }}
            onBeforeInput={(e: any): void => {
              consoleNonProd(e);
            }}
          />
        </div>
        <hr style={{ width: "100%" }} />

        {/* Headings */}
        <div id="headings">
          <h2>Headings</h2>
          <h1>h1 Heading</h1>
          <h2>h2 Heading</h2>
          <h3>h3 Heading</h3>
          <h4>h4 Heading</h4>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Text */}
        <div id="text">
          <h2>Text</h2>
          <h3>Paragraph</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <h3>Code Snippet</h3>
          <code>
            {"function helloWorld() {consoleNonProd('Hello, world!');}"}
          </code>
          <h2>Emphasis</h2>
          <p>
            <strong>This is bold text</strong>
          </p>
          <p>
            <em>This is italic text</em>
          </p>
          <p>
            <s>Strikethrough</s>
          </p>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Links */}
        <div id="links">
          <h2>Links</h2>
          <p>
            <a href="/style-guide">A Tag Link</a>
          </p>
          <p>
            <Link href="/style-guide" text="A Basic Link" />
          </p>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Lists */}
        <div id="lists">
          <h2>Lists</h2>
          <h3>Unordered</h3>
          <ul>
            <li>hey</li>
            <li>hi</li>
            <li>hello</li>
          </ul>
          <h3>Ordered</h3>
          <ol>
            <li>hey</li>
            <li>hi</li>
            <li>hello</li>
          </ol>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Icons */}
        <div id="icons">
          <h2>Icons</h2>
          <Container isDismissable={false}>
            <h3>Fill and stroke options</h3>
            <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
              <Icon
                name="film"
                stroke="black"
                fill="#d63220"
                height="50px"
                desc="Film icon with black stroke and red fill"
                toolTip="top"
              />

              <Icon
                name="film"
                stroke="#d64220"
                fill="black"
                height="50px"
                desc="Film icon with red stroke and black fill"
                toolTip="top"
              />
              <Icon
                name="film"
                stroke="#d64220"
                fill="none"
                height="50px"
                desc="Film icon with red stroke and no fill"
                toolTip="top"
              />
              <Icon
                name="film"
                stroke="black"
                fill="none"
                height="50px"
                desc="Film icon with black stroke and no fill"
                toolTip="top"
              />
              <Icon
                name="film"
                isPrimaryButton={true}
                height="50px"
                desc="Film icon with primary button styling"
                toolTip="top"
              />
              <Icon
                name="Film"
                colorIcon={true}
                fill="green"
                height="50px"
                desc="Film icon with color icon styling"
                toolTip="top"
              />
            </div>

            <h3>Tool tip orientations</h3>
            <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
              <Icon
                name="film"
                height="50px"
                desc="Film icon with tool tip on top"
                toolTip="top"
              />

              <Icon
                name="film"
                height="50px"
                desc="Film icon with tool tip on bottom"
                toolTip="bottom"
              />
              <Icon
                name="film"
                height="50px"
                desc="Film icon with tool tip to the left"
                toolTip="left"
              />
              <Icon
                name="film"
                height="50px"
                desc="Film icon with tool tip to the right"
                toolTip="right"
              />
            </div>

            <h3>Hover styling</h3>
            <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
              <Icon
                name="film"
                height="50px"
                desc="Film icon with no hover style"
                toolTip="top"
                hoverState={false}
              />
              <Icon
                name="film"
                height="50px"
                desc="Film icon with hover style"
                toolTip="top"
                hoverState={true}
              />
            </div>

            <h3>Enabled and disabled</h3>
            <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
              <Icon
                name="film"
                height="50px"
                desc="Enabled film icon"
                toolTip="top"
                disabled={false}
              />
              <Icon
                name="film"
                height="50px"
                desc="Disabled film icon"
                toolTip="top"
                disabled={true}
              />
            </div>
          </Container>

          <Container isDismissable={false}>
            <h3>Tooltip offset on window overflow</h3>
            <div
              style={{
                width: "85vw",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px left offset"
                    toolTip="top"
                  />
                </div>
                <div style={{ position: "absolute", left: "10px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px right offset"
                    toolTip="top"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px left offset"
                    toolTip="bottom"
                  />
                </div>
                <div style={{ position: "absolute", left: "10px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px right offset"
                    toolTip="bottom"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "0" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px left offset"
                    toolTip="top"
                  />
                </div>
                <div style={{ position: "absolute", left: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px right offset"
                    toolTip="top"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "0" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px left offset"
                    toolTip="bottom"
                  />
                </div>
                <div style={{ position: "absolute", left: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px right offset"
                    toolTip="bottom"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px left offset"
                    toolTip="top"
                  />
                </div>
                <div style={{ position: "absolute", left: "-50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px right offset"
                    toolTip="top"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px left offset"
                    toolTip="bottom"
                  />
                </div>
                <div style={{ position: "absolute", left: "-50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px right offset"
                    toolTip="bottom"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "-50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px left offset"
                    toolTip="top"
                  />
                </div>
                <div style={{ position: "absolute", left: "-75px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px right offset"
                    toolTip="top"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", position: "relative", height: "70px" }}
              >
                <div style={{ position: "absolute", right: "-50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px left offset"
                    toolTip="bottom"
                  />
                </div>
                <div style={{ position: "absolute", left: "-75px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px right offset"
                    toolTip="bottom"
                  />
                </div>
              </div>
            </div>
          </Container>

          <Container isDismissable={false} id="tooltip-container">
            <h3>Tooltip offset on container overflow</h3>
            <div
              style={{
                width: "500px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px left offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px right offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px left offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "50px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 25px right offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px left offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px right offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px left offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 50px right offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "0" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px left offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "0" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px right offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "0" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px left offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "0" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 75px right offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px left offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px right offset"
                    toolTip="top"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "70px",
                }}
              >
                <div style={{ position: "absolute", right: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px left offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
                <div style={{ position: "absolute", left: "-25px" }}>
                  <Icon
                    name="film"
                    fill="none"
                    height="50px"
                    desc="Film icon with 100px right offset"
                    toolTip="bottom"
                    overflowContainerId="tooltip-container"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Toolbars */}
        <div id="toolbars">
          <h2>Tool Bars</h2>
          <h3>Minimizable Horizontal</h3>
          <ToolBar orientation="horizontal" isMinimizable={true}>
            <IconButton
              onClick={() => null}
              iconName="message-circle"
              desc="Chat"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="help"
              desc="Help"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="list"
              desc="List"
              toolTip="top"
            />
          </ToolBar>

          <h3>Minimizable Vertical</h3>
          <ToolBar orientation="vertical" isMinimizable={true}>
            <IconButton
              onClick={() => null}
              iconName="message-circle"
              desc="Chat"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="help"
              desc="Help"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="list"
              desc="List"
              toolTip="top"
            />
          </ToolBar>

          <h3>Horizontal</h3>
          <ToolBar orientation="horizontal">
            <IconButton
              onClick={() => null}
              iconName="message-circle"
              desc="Chat"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="help"
              desc="Help"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="list"
              desc="List"
              toolTip="top"
            />
          </ToolBar>

          <h3>Vertical</h3>
          <ToolBar orientation="vertical">
            <IconButton
              onClick={() => null}
              iconName="message-circle"
              desc="Chat"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="help"
              desc="Help"
              toolTip="top"
            />
            <IconButton
              onClick={() => null}
              iconName="list"
              desc="List"
              toolTip="top"
            />
          </ToolBar>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Containers */}
        <div id="container">
          <h2>Containers</h2>
          <Container
            className=""
            isDismissable={true}
            onDismiss={() => consoleNonProd("onDismiss!")}
            xToCloseId="close-containers"
          >
            Text here
          </Container>
          <Container
            className=""
            isDismissable={false}
            xToCloseId="close-lorem"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </Container>
          <Container
            className=""
            isDismissable={true}
            onDismiss={() => consoleNonProd("onDismiss")}
            xToCloseId="close-ipsum"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Container>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Toggle */}
        <div id="toggle" style={{ width: "100%" }}>
          <h2>Toggle</h2>
          <Toggle
            option1="Explore"
            option2="Focus"
            name="toggle-demo"
            onChange={() => setChecked(!checked)}
            icon1="explore"
            icon2="focus"
            checked={checked}
          />
        </div>
        <hr style={{ width: "100%" }} />

        {/* Videos */}
        <div id="videos">
          <h2>Videos</h2>
          <h3>Video Tile</h3>
          <div
            style={{
              position: "relative",
              height: "175px",
            }}
          >
            <VideoTile
              isVideoEnabled={false}
              isInstructorOrModerator={true}
              dismissable={true}
              videoType={VideoType.INSTRUCTOR}
              speaking={false}
              iconHex="#6cace4"
              name="Albert Barnes"
              handRaised={false}
              toggleShowVideo={() => null}
            >
              <video style={{ backgroundColor: "#000" }} />
            </VideoTile>
          </div>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Spinner */}
        <div id="spinner">
          <h2>Spinner</h2>
          <Spinner />
        </div>
        <hr style={{ width: "100%" }} />

        {/* Pop up */}
        <div id="pop-up" style={{ width: "350px" }}>
          <h2>Pop Up</h2>
          <Button
            btnType={PRIMARY}
            text="Show Pop Up"
            size={STANDARD}
            disabled={false}
            onClick={() => setShowPopUp(!showPopUp)}
          />
          <Modal dismissible={false} display={showPopUp}>
            <div className="__modal-content">
              <PopUp
                userMessage="Here's an example of a pop up user message."
                descriptMessage="Here's an optional descript message."
                popUpHeader="Pop up header"
                buttonText1="Button Text 1"
                buttonText2="Optional Button Text 2"
                onClick1={() => setShowPopUp(!showPopUp)}
                onClick2={() => setShowPopUp(!showPopUp)}
              />
            </div>
          </Modal>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Modal */}
        <div id="modal">
          <h2>Modal</h2>
          <Button
            btnType={PRIMARY}
            text={showModal ? "Hide Modal" : "Show Modal"}
            size={STANDARD}
            disabled={false}
            onClick={() => setShowModal(!showModal)}
          />
          <Modal
            dismissible={true}
            display={showModal}
            onDismiss={() => setShowModal(!showModal)}
          >
            <div className="__modal-content">
              <div>Example modal with text.</div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
          </Modal>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Helper Popup */}
        <div id="helper-popup">
          <h2>Helper Popup</h2>
          <Button
            btnType={PRIMARY}
            text={showHelperPopup ? "Hide Helper Popup" : "Show Helper Popup"}
            size={STANDARD}
            disabled={false}
            onClick={() => setShowHelperPopup(!showHelperPopup)}
          />

          {showHelperPopup && (
            <HelperPopUp
              userMessage="This is an example Helper popup!"
              bottom="4px"
              right="-460px"
              leftArrow={true}
              isDismissible={false}
              onDismiss={() => setShowHelperPopup(false)}
              isTimed={true}
              minWidth="255px"
              arrowLeft="4px"
            />
          )}
          <Button
            btnType={PRIMARY}
            text={
              showPopUp1
                ? "Hide Helper Popup"
                : !showAgain1
                ? "Pop up will not show again"
                : "Show Helper Popup"
            }
            size={STANDARD}
            disabled={false}
            onClick={() => setShowPopUp1(!showPopUp1)}
          />
          {showPopUp1 && showAgain1 && (
            <HelperPopUp
              userMessage="This is an second example of a Helper popup with don't show again checkbox! On refresh, this should still be dismissed in local storage."
              isDismissible={true}
              onDismiss={(e) => setShowPopUp1(false)}
              isTimed={false}
              downArrow
              buttonText="Explore"
              buttonDisabled={false}
              onBtnClick={(e) => setShowPopUp1(false)}
              includeDontShowAgain={true}
              handleSetShowAgainCheckbox={handleDontShowAgain1}
              bottom="4px"
              right="-460px"
              minWidth="255px"
              arrowLeft="4px"
            />
          )}
          <Button
            btnType={PRIMARY}
            text={
              showPopUp2
                ? "Hide Helper Popup"
                : !showAgain2
                ? "Pop up will not show again"
                : "Show Helper Popup"
            }
            size={STANDARD}
            disabled={false}
            onClick={() => setShowPopUp2(!showPopUp2)}
          />
          {showPopUp2 && showAgain2 && (
            <HelperPopUp
              userMessage="This is an second example of a Helper popup with don't show again checkbox! On refresh, this should reset the state."
              isDismissible={true}
              onDismiss={(e) => setShowPopUp2(false)}
              isTimed={false}
              downArrow
              buttonText="Explore"
              buttonDisabled={false}
              onBtnClick={(e) => setShowPopUp2(false)}
              includeDontShowAgain={true}
              handleSetShowAgainCheckbox={handleDontShowAgain2}
              bottom="4px"
              right="-460px"
              minWidth="255px"
              arrowLeft="4px"
            />
          )}
        </div>
        <hr style={{ width: "100%" }} />

        {/* Progress Bar */}
        <div id="progress-bar">
          <h2>Progress Bar</h2>
          <ProgressBar
            id="style-guide"
            max={100}
            value={progressBarValue}
            onClick={setProgressBarValue}
          />
        </div>
        <hr style={{ width: "100%" }} />

        {/* More Actions */}
        <div id="more-actions">
          <h2>More Actions Menu</h2>
          <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
            <div>
              <h3>Menu appears down and to the right</h3>
              <ToolBar orientation="horizontal" isMinimizable={false}>
                <MoreActionsButton
                  showActionsInput={showMoreActions}
                  toolTip="none"
                  alignment="horizontal"
                  id="style-guide__more-actions"
                  onClick={() => setShowMoreActions(!showMoreActions)}
                >
                  <MoreActionsWrapper
                    itemClick={(e) => {
                      e.preventDefault();
                      setShowMoreActions(false);
                    }}
                    upOrDown="down"
                    leftOrRight="right"
                  >
                    <MoreActionsItem id="style-guide__more-actions__item-1">
                      <Button onClick={() => null} text="Button 1" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-2">
                      <Button onClick={() => null} text="Button 2" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-3">
                      <Button onClick={() => null} text="Button 3" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-4">
                      <Button onClick={() => null} text="Button 4" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-5">
                      <Button onClick={() => null} text="Button 5" />
                    </MoreActionsItem>
                  </MoreActionsWrapper>
                </MoreActionsButton>
              </ToolBar>
            </div>
            <div>
              <h3>Menu appears down and to the left</h3>
              <ToolBar orientation="horizontal" isMinimizable={false}>
                <MoreActionsButton
                  showActionsInput={showMoreActions2}
                  toolTip="none"
                  alignment="horizontal"
                  id="style-guide__more-actions"
                  onClick={() => setShowMoreActions2(!showMoreActions2)}
                >
                  <MoreActionsWrapper
                    itemClick={(e) => {
                      e.preventDefault();
                      setShowMoreActions2(false);
                    }}
                    upOrDown="down"
                    leftOrRight="left"
                  >
                    <MoreActionsItem id="style-guide__more-actions__item-1">
                      <Button onClick={() => null} text="Button 1" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-2">
                      <Button onClick={() => null} text="Button 2" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-3">
                      <Button onClick={() => null} text="Button 3" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-4">
                      <Button onClick={() => null} text="Button 4" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-5">
                      <Button onClick={() => null} text="Button 5" />
                    </MoreActionsItem>
                  </MoreActionsWrapper>
                </MoreActionsButton>
              </ToolBar>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
            <div>
              <h3>Menu appears up and to the right</h3>
              <ToolBar orientation="horizontal" isMinimizable={false}>
                <MoreActionsButton
                  showActionsInput={showMoreActions3}
                  toolTip="none"
                  alignment="horizontal"
                  id="style-guide__more-actions"
                  onClick={() => setShowMoreActions3(!showMoreActions3)}
                >
                  <MoreActionsWrapper
                    itemClick={(e) => {
                      e.preventDefault();
                      setShowMoreActions3(false);
                    }}
                    upOrDown="up"
                    leftOrRight="right"
                  >
                    <MoreActionsItem id="style-guide__more-actions__item-1">
                      <Button onClick={() => null} text="Button 1" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-2">
                      <Button onClick={() => null} text="Button 2" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-3">
                      <Button onClick={() => null} text="Button 3" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-4">
                      <Button onClick={() => null} text="Button 4" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-5">
                      <Button onClick={() => null} text="Button 5" />
                    </MoreActionsItem>
                  </MoreActionsWrapper>
                </MoreActionsButton>
              </ToolBar>
            </div>
            <div>
              <h3>Menu appears up and to the left</h3>
              <ToolBar orientation="horizontal" isMinimizable={false}>
                <MoreActionsButton
                  showActionsInput={showMoreActions4}
                  toolTip="none"
                  alignment="horizontal"
                  id="style-guide__more-actions"
                  onClick={() => setShowMoreActions4(!showMoreActions4)}
                >
                  <MoreActionsWrapper
                    itemClick={(e) => {
                      e.preventDefault();
                      setShowMoreActions4(false);
                    }}
                    upOrDown="up"
                    leftOrRight="left"
                  >
                    <MoreActionsItem id="style-guide__more-actions__item-1">
                      <Button onClick={() => null} text="Button 1" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-2">
                      <Button onClick={() => null} text="Button 2" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-3">
                      <Button onClick={() => null} text="Button 3" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-4">
                      <Button onClick={() => null} text="Button 4" />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-5">
                      <Button onClick={() => null} text="Button 5" />
                    </MoreActionsItem>
                  </MoreActionsWrapper>
                </MoreActionsButton>
              </ToolBar>
            </div>
          </div>

          <div>
            <div>
              <h3>More actions with small icon buttons</h3>
              <ToolBar orientation="horizontal" isMinimizable={false}>
                <MoreActionsButton
                  showActionsInput={showMoreActions5}
                  toolTip="top"
                  alignment="horizontal"
                  id="style-guide__more-actions"
                  onClick={() => setShowMoreActions5(!showMoreActions5)}
                >
                  <MoreActionsWrapper
                    itemClick={(e) => {
                      e.preventDefault();
                      setShowMoreActions5(false);
                    }}
                    upOrDown="up"
                    leftOrRight="right"
                  >
                    <MoreActionsItem id="style-guide__more-actions__item-1">
                      <Button
                        btnType={PRIMARY}
                        onClick={() => null}
                        text="Show instructors"
                        icon="user"
                        size={SMALL}
                      />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-2">
                      <Button
                        btnType={PRIMARY}
                        onClick={() => null}
                        text="Show students"
                        icon="view-participants"
                        size={SMALL}
                      />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-3">
                      <Button
                        btnType={PRIMARY}
                        onClick={() => null}
                        text="Enter full screen"
                        icon="full-screen-on"
                        size={SMALL}
                      />
                    </MoreActionsItem>
                    <MoreActionsItem id="style-guide__more-actions__item-4">
                      <Button
                        btnType={PRIMARY}
                        onClick={() => null}
                        text="Captions size"
                        icon="closed-captioning"
                        size={SMALL}
                      />
                    </MoreActionsItem>
                  </MoreActionsWrapper>
                </MoreActionsButton>
              </ToolBar>
            </div>
          </div>
        </div>
        <hr style={{ width: "100%" }} />

        {/* Chat */}
        <div id="chat">
          <h2>Chat</h2>
          <Container isDismissable={false}>
            <h3>Student chat</h3>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              {showChat && (
                <Chat
                  onDismiss={() => setShowChat(false)}
                  messages={chatMessages}
                  messageCount={chatMessages.length}
                  height="415px"
                  sendMessage={sendMessage}
                  replay={false}
                  testAdmin={false}
                />
              )}
              <Button
                onClick={() => setShowChat(!showChat)}
                text="Toggle student chat"
              />
            </div>
          </Container>

          <Container isDismissable={false}>
            <h3>Instructor chat</h3>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              {showChat2 && (
                <Chat
                  onDismiss={() => setShowChat(false)}
                  messages={chatMessages}
                  messageCount={chatMessages.length}
                  height="415px"
                  sendMessage={sendMessage}
                  replay={false}
                  testAdmin={true}
                />
              )}
              <Button
                onClick={() => setShowChat2(!showChat2)}
                text="Toggle instructor chat"
              />
            </div>
          </Container>
        </div>

        {/* Attendee Initials */}
        <div id="initials">
          <h2>Attendee Initials</h2>
          <Container isDismissable={false}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              <AttendeeInitials
                iconColor={IconColor._0091EA}
                name="Albert Barnes"
              />
              <AttendeeInitials
                iconColor={IconColor._00ACC1}
                name="Stew Dent"
              />
            </div>
            <h3>Initials with superscript</h3>
            <AttendeeInitials
              iconColor={IconColor._00ACC1}
              name="Banned Student"
              superscript={
                <Icon
                  name="slash"
                  toolTip="top"
                  desc={`Banned Student is soft banned: Muted microphone, disabled camera, and blocked messages`}
                  height="16px"
                  stroke="red"
                  fill="white"
                />
              }
            />
          </Container>
        </div>
      </div>
    </PageWrapper>
  );
};
