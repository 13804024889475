import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";

type ProtectedRouteProps = {
  redirect: string;
  children: JSX.Element;
};

/** Wrapper component for routes that require authentication
 * @param {ProtectedRouteProps} props
 * @prop {boolean} isAuthenticated - Whether or not there is an authenticated user
 * @prop {string} redirect - The route to redirect unauthenticated users
 * @prop {React.ReactNode} children - React component that will be rendered for authenticated users
 */
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirect,
  children,
}) => {
  const location = useLocation();
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    const redirectWithParams =
      location.pathname === "/admin"
        ? redirect
        : `${redirect}?r=${location.pathname}`;
    return <Navigate to={redirectWithParams} replace={true} />;
  }

  return children;
};
