import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useAudioVideo,
  useFeaturedTileState,
} from "amazon-chime-sdk-component-library-react";
import classnames from "classnames";
import { InstructorVideo } from "./instructorVideo";
import { useScreenContext } from "../../contexts/screenContext";
import { useAppContext } from "../../contexts/appContext";
import { Participant } from "../../contexts/types";
import { CanvasDataEvent } from "../../providers/types";

type InstructorVideosProps = {
  instructorList: Participant[];
  tileId?: number;
  toggleInstructors: () => void;
  events?: CanvasDataEvent[];
};

export const InstructorVideos: React.FC<InstructorVideosProps> = ({
  instructorList,
  tileId,
  toggleInstructors,
  events,
}) => {
  const { useMobileTools } = useScreenContext();
  const { meetingRole, state, isInstructorAVMeeting } = useAppContext();
  const [displayInstructor, setDisplayInstructor] = useState<Participant>();
  const audioVideo = useAudioVideo();
  const videoEl = useRef<HTMLVideoElement>(null);
  const { tileId: featuredTileId } = useFeaturedTileState();
  const [featured, setFeatured] = useState<boolean>(false);
  const [instructorIconColor, setInstructorIconColor] = useState<string>("");

  const setAndAdjustDisplayInstructor = useCallback(
    (inputInstructor: Participant) => {
      if (inputInstructor) {
        inputInstructor?.iconColor?.replace("_", "#");
        setDisplayInstructor(inputInstructor);
        setTimeout(() => {
          setInstructorIconColor(inputInstructor?.iconColor?.replace("_", "#"));
          setFeatured(featuredTileId === inputInstructor?.tileId);
        }, 0);
      }
    },
    [featuredTileId]
  );

  useEffect(() => {
    // This bit is what changes the instructor to the active speaking instructor
    if (instructorList) {
      const dispInst = instructorList?.find(
        (instructor) =>
          // the featuredTileId sometimes comes back as null or undefined
          // and if an instructor doesn't have their camera on their tileId = undefined
          // so we have to check if they both exist and if they match, and the instructor is speaking
          (instructor.tileId &&
            featuredTileId &&
            instructor.tileId === featuredTileId &&
            instructor?.speaking) ||
          instructor?.speaking
      );
      if (dispInst && dispInst !== displayInstructor) {
        setAndAdjustDisplayInstructor(dispInst);
      } else if (!displayInstructor) {
        // if there has never been a display instructor just set it to the first instructor.
        setAndAdjustDisplayInstructor(instructorList[0]);
      }
    }
  }, [
    instructorList,
    featuredTileId,
    displayInstructor,
    instructorIconColor,
    setAndAdjustDisplayInstructor,
  ]);

  useEffect(() => {
    if (!audioVideo || !videoEl.current) {
      return;
    }
    audioVideo.bindVideoElement(displayInstructor.tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(displayInstructor?.tileId);
      if (tile) {
        audioVideo.unbindVideoElement(displayInstructor?.tileId);
      }
    };
  }, [audioVideo, displayInstructor]);

  return (
    <>
      {isInstructorAVMeeting && (
        <div style={{ position: "relative" }}>
          {instructorList?.length > 0 && (
            <div
              className={classnames("instructor__wrapper", {
                "__mobile-instructor": useMobileTools,
                __spotlight: state.spotlightInstructor,
              })}
            >
              <InstructorVideo
                name={
                  displayInstructor?.firstName +
                  " " +
                  displayInstructor?.lastName
                }
                tileId={displayInstructor?.tileId}
                iconHex={instructorIconColor}
                speaking={displayInstructor?.speaking}
                featured={featured}
                toggleInstructors={toggleInstructors}
                events={events}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
