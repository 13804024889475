import classnames from "classnames";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";
import { DARK, ThemeContext } from "../contexts/themeContext";
import variables from "../styles/variables.scss";
import { useScreenContext } from "../contexts/screenContext";
import { isTablet } from "react-device-detect";

type IconProps = {
  name: string;
  stroke?: string;
  disabledStroke?: string;
  disabled?: boolean;
  fill?: string;
  height?: string;
  width?: string;
  desc: string;
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  className?: string;
  /** TODO: Fix the styling for the icon hover state so that the hover styling
   * is applied when this boolean is set to true */
  hoverState?: boolean;
  isPrimaryButton?: boolean;
  /** TODO: See if the colorIcon prop can be removed
   * setting this prop to true and then passing a fill color has the same effect
   * as leaving this undefined and passing a fill color */
  colorIcon?: boolean;
  svgId?: string;
  overflowContainerId?: string;
};

const StyledSVGIcon = styled(ReactSVG)`
  & div {
    ${({ width, height }) =>
      css`
        width: ${width};
        height: ${height};
      `}
  }
  svg {
    ${({ fill }) =>
      fill &&
      css`
        fill: ${fill};
      `}
    ${({ stroke }) =>
      stroke &&
      css`
        stroke: ${stroke};
      `}
    ${({ width, height }) =>
      width &&
      height &&
      css`
        width: ${width};
        height: ${height};
      `}
    ${({ transform }) =>
      transform &&
      css`
        transform: ${transform};
      `}
    path {
      ${({ stroke }) =>
        stroke &&
        css`
          stroke: ${stroke};
        `}
    }
  }
`;

/** Component for VxP icon. Stroke defaults to "black", size defaults to "20px", and hoverState defaults to "true"
 * @param {IconProps}
 * @prop {string} name - Name of the icon, should match the svg file `public/icons/<name>.svg`
 * @prop {string} stroke - Color for the icon stroke
 * @prop {string} disabledStroke - Color for stroke when icon is disabled
 * @prop {boolean} disabled - Whether or not the icon is disabled
 * @prop {string} fill - Background color for the icon
 * @prop {string} height - Height of the icon, defaults to 20px
 * @prop {string} width - Width of the icon, defaults to the same value as the height. Setting width to `100%` will
 * center the icon horizontally within any element where it is nested
 * @prop {string} desc - Text that will appear in the tool tip on hover
 * @prop {"top" | "bottom" | "right" | "left" | "none"} toolTip - Location for the tool tip
 * @prop {string} className - Class name to apply to the containing div
 * @prop {boolean} hoverState - Whether or not the icon will have hover styling, defaults to true
 * @prop {boolean} isPrimaryButton - Whether or not the icon will be used as part of a primary button, defaults to false
 * @prop {boolean} colorIcon - Whether or not the icon stroke is filled with a color other than the defaults
 * @prop {string} svgId - ID to add to the svg element
 * @prop {string} overflowContainerId - Optional ID for the specified element that the tooltip should not overflow. If no ref is passed, the tooltip overflow is determined based on the browser viewport
 */
export const Icon: React.FC<IconProps> = ({
  name,
  stroke,
  fill = "",
  height = "20px",
  width,
  disabledStroke,
  disabled,
  desc,
  toolTip,
  className,
  hoverState = true,
  isPrimaryButton = false,
  colorIcon,
  svgId = name,
  overflowContainerId,
}) => {
  const toolTipRef = useRef<HTMLSpanElement>(null);
  const { theme } = useContext(ThemeContext);
  const { useMobileTools } = useScreenContext();
  const [toolTipOffset, setToolTipOffset] = useState<string>();

  const strokeColor = colorIcon
    ? ""
    : theme === DARK && !isPrimaryButton && !stroke
    ? variables.white
    : stroke;
  const disabledStrokeColor = disabledStroke
    ? disabledStroke
    : theme === DARK
    ? "#6e6e6e"
    : "#5e5e5e";

  // Sets class for tool tip offset to prevent it from overflowing the viewport
  const createToolTipObserver = (overFlowContainer?: HTMLElement) => {
    const options: IntersectionObserverInit = {};

    if (overFlowContainer) {
      options["root"] = overFlowContainer;
    }

    return new IntersectionObserver((entries) => {
      const intersectionRatio = entries[0].intersectionRatio;

      // If the tooltip exceeds the bounds the container, add an offset class
      if (intersectionRatio < 1 && intersectionRatio > 0) {
        const boundingRect = entries[0].boundingClientRect;
        const rootBounds = entries[0].rootBounds;
        const leftBound = rootBounds.left;
        const rightBound = rootBounds.right;

        // See which direction it intersects
        if (boundingRect.right >= rightBound) {
          // intersects on right side of screen
          const overflow = boundingRect.right - rightBound;
          if (overflow > 0 && overflow < 25) {
            setToolTipOffset("l25");
          } else if (overflow >= 25 && overflow < 50) {
            setToolTipOffset("l50");
          } else if (overflow >= 50 && overflow < 75) {
            setToolTipOffset("l75");
          } else if (overflow >= 75) {
            setToolTipOffset("l100");
          }
        } else if (boundingRect.left <= leftBound) {
          // intersects on left side of screen
          const overflow = leftBound - boundingRect.left;
          if (overflow > 0 && overflow < 25) {
            setToolTipOffset("r25");
          } else if (overflow >= 25 && overflow < 50) {
            setToolTipOffset("r50");
          } else if (overflow >= 50 && overflow < 75) {
            setToolTipOffset("r75");
          } else if (overflow >= 75) {
            setToolTipOffset("r100");
          }
        }
      }
    }, options);
  };

  // Set the overflow container
  useEffect(() => {
    if (overflowContainerId) {
      const container = document.getElementById(overflowContainerId);

      if (toolTipRef?.current && toolTip && toolTip !== "none") {
        const toolTipObserver = createToolTipObserver(container);
        toolTipObserver.observe(toolTipRef.current);
      }
    } else if (toolTipRef?.current && toolTip && toolTip !== "none") {
      const toolTipObserver = createToolTipObserver();
      toolTipObserver.observe(toolTipRef.current);
    }
  }, [toolTipRef, toolTip, overflowContainerId]);

  return (
    <div className={classnames("icon", name, className)}>
      {!disabled && !useMobileTools && !isTablet && (
        <span
          className={classnames(`tool-tip`, toolTip, toolTipOffset)}
          ref={toolTipRef}
        >
          {desc}
        </span>
      )}
      <StyledSVGIcon
        className={classnames("styled-SVG-icon", {
          "--hover": hoverState && !useMobileTools && !isTablet,
          "--no-hover-pointer": !hoverState || useMobileTools || isTablet,
        })}
        id={`${svgId}-svg`}
        aria-label={desc}
        title={toolTip === "none" ? desc : ""}
        role="img"
        useRequestCache={true}
        src={`/icons/${name}.svg`}
        stroke={
          disabledStrokeColor && disabled
            ? disabledStrokeColor
            : name.includes("focused-dark")
            ? ""
            : strokeColor
        }
        fill={fill}
        height={height}
        width={width ? width : height}
        desc={desc}
        alt={desc}
      />
    </div>
  );
};
