import React, { useEffect, useRef } from "react";
// Use ! syntax to ensure videojs builds correctly with webpack in build: https://videojs.com/guides/webpack/
// eslint-disable-next-line import/no-webpack-loader-syntax
// @ts-ignore
import videojs from "!video.js";
import Player from "video.js/dist/types/player";

import variables from "../../styles/variables.scss";
import { CanvasDataEvent } from "../../providers/types";
import { ChatReplayMessage } from "../../providers/replayDataMessages";
import { useError } from "../../contexts/errorContext";
import { CaptionType } from "../../providers/dataCaptions";
import { isMobile, isTablet } from "react-device-detect";

type VideoPlayerType = {
  videoSrc: string;
  audioSrc: string;
  eventCues: string;
  chatCues?: string;
  chapterCues?: string;
  captionCues?: string;
  handleEventCueChange: (event: CanvasDataEvent) => void;
  handleChatCueChange: (event: ChatReplayMessage[]) => void;
  handleCaptionCueChange: (event: CaptionType[]) => void;
  setReplayChapters: React.Dispatch<React.SetStateAction<TextTrackCue[]>>;
  setActiveChapter: React.Dispatch<React.SetStateAction<number>>;
  playerRef: React.MutableRefObject<Player>;
};

export const VideoPlayer: React.FC<VideoPlayerType> = ({
  videoSrc,
  audioSrc,
  eventCues,
  chatCues,
  chapterCues,
  captionCues,
  handleEventCueChange,
  handleChatCueChange,
  handleCaptionCueChange,
  setReplayChapters,
  setActiveChapter,
  playerRef,
}) => {
  const videoRef = useRef<HTMLDivElement>();
  const { showError } = useError();

  /** Set up video player on component mount */
  useEffect(() => {
    const videoHeight = 720 / variables.aspectRatio;

    const videoJsOptions = {
      autoplay: false,
      controls: false,
      preload: "auto",
      width: 720,
      height: videoHeight,
      sources: [
        {
          src: videoSrc,
          type: "application/x-mpegURL",
        },
        {
          src: audioSrc,
          type: "video/mp4",
        },
      ],
    };

    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, videoJsOptions, () => {
        if (isMobile || isTablet) {
          playerRef?.current?.playsinline(true);
        }
        const videoElement = playerRef.current.children()[0];
        // Enable CORS for video element and child src attributes
        videoElement.setAttribute("crossorigin", "anonymous");

        videoElement.addEventListener("loadedmetadata", () => {
          // Create and add source element for video source
          const source = document.createElement("source");
          Object.assign(source, videoJsOptions.sources[0]);
          videoElement.appendChild(source);

          try {
            // Create and add track element for canvas events
            const track = document.createElement("track");
            Object.assign(track, {
              kind: "metadata",
              src: eventCues,
              default: true,
              // mode: "hidden",
              id: "events-track",
            });
            videoElement.appendChild(track);

            // Add event listener to the track event element cuechange event
            track.addEventListener("cuechange", (e) => {
              // @ts-ignore
              const track = e.target.track as TextTrack;
              const activeCues = track.activeCues;
              const index = Object.keys(activeCues).slice(-1)[0];
              const cue = activeCues[index];
              // @ts-ignore
              const event = JSON.parse(cue.text);
              // convert event data back into a string because that is what the addEvent fn expects
              event.event = JSON.stringify(event.event);
              // Add event from cue to event list
              handleEventCueChange(event);
            });

            // Create and add track element for chat events
            if (chatCues) {
              const chatTrack = document.createElement("track");
              Object.assign(chatTrack, {
                kind: "metadata",
                src: chatCues,
                default: true,
                // mode: "hidden",
                id: "chat-events-track",
              });
              videoElement.appendChild(chatTrack);

              // Add event listener to the track chat element cuechange event
              chatTrack.addEventListener("cuechange", (e) => {
                // @ts-ignore
                const chatTrack = e.target.track as TextTrack;
                const activeCues = chatTrack.activeCues;
                // convert event data back into a string because that is what the addEvent fn expects
                // convert the active cues (chat messages that exist from player current time and 0)
                let newActiveArray: any[] = Array.from(activeCues);
                // make an array of the parsed VTT events
                let vttEvents = newActiveArray.map(({ text }) =>
                  JSON.parse(text)
                );
                // grab just the capital "D" Data from the vtt event and make a chat replay message array
                let dataArray: ChatReplayMessage[] = vttEvents.map(
                  ({ Data }) => Data
                );
                if (dataArray) {
                  handleChatCueChange(dataArray);
                }
              });
            }

            // Create and add track element for chapter events
            if (chapterCues) {
              const chapterTrack = document.createElement("track");
              Object.assign(chapterTrack, {
                kind: "metadata",
                src: chapterCues,
                default: true,
                // mode: "hidden",
                id: "chapter-events-track",
              });
              videoElement.appendChild(chapterTrack);

              // Add event listener to the track chapter element load event
              chapterTrack.addEventListener("load", (e) => {
                // @ts-ignore
                const chapterTrack = e.target.track as TextTrack;
                const chapterCues = chapterTrack.cues;
                // convert the chapter cues to their own array
                let newActiveArray: TextTrackCue[] = Array.from(chapterCues);
                setReplayChapters(newActiveArray);
              });

              // Add event listener to the track active chapters
              chapterTrack.addEventListener("cuechange", (e) => {
                // @ts-ignore
                const track = e.target.track as any;
                // grab the chapter text from the cue
                if (
                  track?.activeCues?.length &&
                  track?.activeCues?.length > 0
                ) {
                  const trackText: string =
                    track?.activeCues[track?.activeCues?.length - 1]?.text;
                  const chapter = JSON.parse(trackText);
                  // grab the number from the string text (assumes text will be "Chapter #")
                  const [_text, activeIndex] = chapter?.title.split(" ");
                  activeIndex &&
                    setActiveChapter(
                      // Number.parseInt(activeIndex) === 1
                      // ?
                      Number.parseInt(activeIndex)
                      // : Number.parseInt(activeIndex) + 1
                    );
                } else {
                  setActiveChapter(0);
                }
              });
            }

            // Create and add track element for captions events
            if (captionCues) {
              const captionsTrack = document.createElement("track");
              Object.assign(captionsTrack, {
                kind: "metadata",
                src: captionCues,
                default: true,
                // mode: "hidden",
                id: "caption-events-track",
              });
              videoElement.appendChild(captionsTrack);

              // Add event listener to the caption track element cuechange event
              captionsTrack.addEventListener("cuechange", (e) => {
                // @ts-ignore
                const track = e.target.track as TextTrack;
                const activeCues = track.activeCues;
                let newActiveArray: any[] = Array.from(activeCues);
                const captionTextArray: CaptionType[] = newActiveArray?.map(
                  (obj) => ({ text: obj?.text })
                );
                handleCaptionCueChange(captionTextArray);
              });
            }
          } catch (error) {
            showError({
              details: error,
              error: "There was an issue starting your replay",
            });
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Clean up player on component unmount */
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} className="video__tile video__tile-instructor" />
    </div>
  );
};
