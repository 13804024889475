import React, { Fragment, useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useMutation, useQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";
import {
  CreateImageUploadInput,
  ImageUpload,
  Mutation,
  Query,
} from "../../types";
import { Button, PRIMARY, SECONDARY, SMALL } from "../buttons/button";
import { ArtObjectOverlay } from "./contentComponents";
import { SEARCH_IMAGE_UPLOADS } from "../../graphql/queries";
import { ART_OBJECT_GALLERY_INCREMENT } from "../../constants";
import { ContentModalWrapper } from "./contentModalWrapper";
import { Filter } from "../inputs/filterBar";
import { UploadModal } from "../uploadModal";
import { consoleNonProd } from "../../utils/utilityBeltUtils";
import { BULK_CREATE_IMAGE_UPLOADS } from "../../graphql/mutations";
import { validateImageForConversion } from "../../utils/validators";
import { useAlert } from "../../contexts/alertContext";
import config from "../../utils/config";

type AddImageUploadType = {
  title: string;
  handleSubmit: (imageUploadIds: number[]) => void;
};

/** Uploaded Image content for the Add Content Modal
 * @param {AddImageUploadType} props
 * @prop {string} title - Title for the content page
 * @prop {(imageUploadIds: number[]) => void} handleSubmit - Function to be called on form submit
 */
export const AddImageUpload: React.FC<AddImageUploadType> = ({
  title,
  handleSubmit,
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [myImagesFilter, setMyImagesFilter] = useState<Filter>({
    active: false,
    filterName: "My images",
    value: "USER",
    id: "my-images",
  });
  const [filtersValue, setFiltersValue] = useState<Filter[]>([myImagesFilter]);
  const [images, setImages] = useState<ImageUpload[]>([]);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const { showAlert } = useAlert();

  const toggleUploadForm = () => setShowUploadForm(!showUploadForm);

  const onDismissUploadForm = () => {
    setShowError(false);
    toggleUploadForm();
  };

  const handleAddSelectedImage = (id: number, action: "add" | "remove") => {
    if (action === "add") {
      setSelected([...selected, id]);
    }
    if (action === "remove") {
      const updatedSelected = selected.filter((i) => i !== id);
      setSelected(updatedSelected);
    }
  };

  const handleFiltersArrayForSearch = useMemo(() => {
    let returnArray = [];
    filtersValue.forEach((filter) => {
      if (filter.active) {
        returnArray.push(filter.value);
      }
    });
    return returnArray;
  }, [filtersValue]);

  const handleSetImages = (newImages: ImageUpload[], clearObjects = false) => {
    let imgUploads: ImageUpload[];

    if (clearObjects) {
      imgUploads = newImages;
    } else {
      imgUploads = [...images, ...newImages];
    }

    setImages(imgUploads);
    setHasMoreResults(ART_OBJECT_GALLERY_INCREMENT === newImages.length);
  };

  const handleViewMore = async (e) => {
    const offset = images.length;
    const obj = await queryResult.fetchMore({
      variables: {
        paginate: { offset: offset, limit: ART_OBJECT_GALLERY_INCREMENT },
        term: searchValue,
        filter: handleFiltersArrayForSearch,
      },
    });
    handleSetImages(obj.data.searchImageUploads);
  };

  const handleSearch = async () => {
    const obj = await queryResult.fetchMore({
      variables: {
        paginate: { offset: 0, limit: ART_OBJECT_GALLERY_INCREMENT },
        term: searchValue,
        filter: handleFiltersArrayForSearch,
      },
    });
    handleSetImages(obj.data.searchImageUploads, true);
  };

  const getImageTitle = (image: ImageUpload): string => {
    if (image.title) {
      return image.title;
    } else if (image.caption) {
      return image.caption;
    } else {
      const fileName = image.uploadUrl.split("/");
      return fileName[fileName.length - 1];
    }
  };

  const getImageSource = (image: ImageUpload): string => {
    return image.thumbnailUrl ? image.thumbnailUrl : image.uploadUrl;
  };

  const queryResult = useQuery(SEARCH_IMAGE_UPLOADS, {
    variables: {
      paginate: {
        offset: 0,
        limit: ART_OBJECT_GALLERY_INCREMENT,
      },
      term: searchValue,
      permissions: handleFiltersArrayForSearch,
      notifyOnNetworkStatusChange: true,
    },
    onCompleted: (data: Pick<Query, "searchImageUploads">) => {
      handleSetImages(data.searchImageUploads, true);
    },
  });

  const handleFilterChange = (e, i) => {
    if (i === 0) {
      setMyImagesFilter(e);
    }
    setTimeout(() => {
      setFiltersValue([myImagesFilter]);
    }, 0);
  };

  const [bulkCreateImageUploadsMutation, { error }] = useMutation(
    BULK_CREATE_IMAGE_UPLOADS,
    {
      async onCompleted(data: Pick<Mutation, "bulkCreateImageUploads">) {
        const { bulkCreateImageUploads } = data;
        const url = bulkCreateImageUploads[0].uploadUrl;
        const parts = url.split("/");
        const fileName = parts[parts.length - 1];

        showAlert({
          header: "Upload successful!",
          message: `${fileName} has completed uploading.`,
          isTimed: true,
          dismissTimeout: 5,
        });

        // trigger reload of all images in the AddImageUpload component
        await queryResult.refetch();
      },
      onError(error) {
        consoleNonProd("Error in bulk upload images mutation:", error);
        setShowError(true);
      },
    }
  );

  const validateFileType = (
    fileName: string
  ): { valid: boolean; message?: string } => {
    const valid = validateImageForConversion(fileName);

    return {
      valid,
      message: !valid
        ? 'Only files with types ".jpg", ".jpeg", or ".png" are accepted.'
        : undefined,
    };
  };

  const handleUploadComplete = (
    s3Key: string,
    meta: CreateImageUploadInput
  ) => {
    const input: CreateImageUploadInput = {
      ...meta,
      uploadUrl: s3Key,
    };
    bulkCreateImageUploadsMutation({ variables: { input: [input] } });
  };

  const generateS3Key = (file: string): string => {
    const fileName = file.trim();

    // Remove the file extension to generate the album/folder name
    let albumName = fileName.substring(0, fileName.lastIndexOf("."));
    albumName = albumName.trim();

    // append a uuid to the end of the folder name to prevent issues with duplicate file/album names
    var albumKey = encodeURIComponent(albumName) + "__" + uuid();

    const key = albumKey + "/" + encodeURIComponent(fileName);
    return key;
  };

  const footer = (
    <Fragment>
      <Button
        btnType={SECONDARY}
        text="Upload"
        onClick={toggleUploadForm}
        customWidth="314px"
        id="upload-image-button"
      />
      <Button
        btnType={PRIMARY}
        text="Add images to canvas"
        onClick={() => handleSubmit(selected)}
        disabled={selected?.length === 0}
        customWidth="314px"
        id="add-images-to-canvas-button"
      />
    </Fragment>
  );

  return (
    <Fragment>
      <ContentModalWrapper
        title={title}
        handleSearch={handleSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleFilters={(e, i) => handleFilterChange(e, i)}
        queryResult={queryResult}
        footerChildren={footer}
        showFilter={true}
        filterArray={filtersValue}
      >
        {/* Display query results */}
        <div className="image-upload__body">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 500: 2, 650: 3 }}
          >
            <Masonry gutter="20px" columnsCount={3}>
              {images.map((image, index) => (
                <div className="art-object" key={index}>
                  <img
                    alt={image.caption}
                    src={getImageSource(image)}
                    className="art-object-image"
                  />
                  <ArtObjectOverlay
                    invno={image.id}
                    index={index}
                    caption={image.people ?? null}
                    title={getImageTitle(image)}
                    handleAddContentClick={handleAddSelectedImage}
                  />
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
          <div className="art-object-gallery__footer">
            {hasMoreResults && (
              <div className="view-more-button">
                <Button
                  btnType={SECONDARY}
                  disabled={false}
                  size={SMALL}
                  text="View more"
                  onClick={handleViewMore}
                  id="add-image-view-more-button"
                />
              </div>
            )}
          </div>
        </div>
      </ContentModalWrapper>

      <UploadModal
        display={showUploadForm}
        onDismiss={onDismissUploadForm}
        maxFiles={15}
        validateFileType={validateFileType}
        onComplete={handleUploadComplete}
        generateS3Key={generateS3Key}
        error={error}
        showOnCompleteError={showError}
        bucket={config.uploadS3Bucket}
        includeImgMetaForm={true}
      />
    </Fragment>
  );
};
