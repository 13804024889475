import classnames from "classnames";
import React from "react";

type CheckboxProps = {
  id;
  value;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked;
  label?;
};

/**
 * Component for an VxP checkbox.
 * @CheckboxProps id, value, onChange, checked, label
 */

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  value,
  onChange,
  checked,
  label,
}) => {
  return (
    <div className="checkbox">
      <div className="checkbox-container">
        <div
          className={classnames({
            "checkbox-wrapper": !label,
            "checkbox-wrapper__labeled": label,
          })}
        >
          <input
            aria-label={value}
            id={id}
            type="checkbox"
            checked={checked}
            onChange={(e) => onChange(e)}
            value={value}
          />
          <span className="checkmark"></span>
        </div>
      </div>
      {label && (
        <label className="checkbox-label" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};
