import { useQuery } from "@apollo/client";
import classnames from "classnames";
import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { ART_OBJECT_GALLERY_INCREMENT } from "../../constants";
import { GET_PANORAMAS } from "../../graphql/queries";
import { Panorama, PanoramaCollection, Query } from "../../types";
import { Button, PRIMARY, STANDARD } from "../buttons/button";
import { Checkbox } from "../inputs/checkbox";
import { ArtObjectCaption, ArtObjectImage } from "./contentComponents";
import { ContentModalWrapper } from "./contentModalWrapper";

type PanoramaImageType = {
  title: string;
  handleSubmit: (id: number) => void;
  collection: PanoramaCollection;
};

/** Panorama image content for the Add Content Modal
 * @param {PanoramaImageType} props
 * @prop {string} title - Title for the content page
 * @prop {(id: number) => void} handleSubmit - Function to be called on form submit
 * @prop {PanoramaCollection} collection - panorama collection info from PanoramaCollection ENUM i.e. BARNES or UPLOADS
 */
export const PanoramaImage: React.FC<PanoramaImageType> = ({
  title,
  handleSubmit,
  collection,
}) => {
  const [selected, setSelected] = useState<number>();
  const [panoramas, setPanoramas] = useState<Panorama[]>([]);

  const queryResult = useQuery(GET_PANORAMAS, {
    variables: {
      paginate: {
        offset: 0,
        limit: ART_OBJECT_GALLERY_INCREMENT,
      },
    },
    onCompleted: (data: Pick<Query, "getAllPanoramas">) => {
      if (data?.getAllPanoramas) {
        const filteredPanoramas = data?.getAllPanoramas?.filter(
          (pano) => pano?.collection === collection
        );
        setPanoramas(filteredPanoramas);
      }
    },
  });

  const footer = (
    <Button
      btnType={PRIMARY}
      disabled={!selected}
      size={STANDARD}
      text="Add panorama to canvas"
      onClick={() => handleSubmit(selected)}
      id="add-panorama-button"
    />
  );

  return (
    <ContentModalWrapper
      title={title}
      footerChildren={footer}
      queryResult={queryResult}
    >
      <div className="add-panorama__body">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 500: 2 }}>
          <Masonry gutter="20px" columnsCount={2}>
            {panoramas.map((panorama, index) => (
              <div className="art-object" key={index}>
                <ArtObjectImage alt={panorama.name} src={panorama.sourceUrl} />
                <div
                  className={classnames("art-object-overlay", {
                    selected: selected,
                  })}
                  onClick={() => setSelected(panorama.id)}
                >
                  <Checkbox
                    id={index}
                    value={panorama.name}
                    checked={panorama.id === selected}
                    onChange={() => setSelected(panorama.id)}
                  />
                  <ArtObjectCaption caption={panorama.name} />
                </div>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </ContentModalWrapper>
  );
};
