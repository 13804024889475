/**
 * Validates the presence of text for an input field
 * @param {string} text - text to be validated
 * @param {number} minLength - Optional number that sets the minimum string length for validation, defaults to 1
 * @returns {boolean} - whether or not the text is valid
 */
export const validateText = (text: string, minLength = 1): boolean => {
  return text.length >= minLength;
};

/** Checks a string to ensure valid formatting for slugs
 * @param {string} slug - slug to be validated
 * @returns {boolean} - whether or not the slug is valid
 */
export const validateSlug = (slug: string): boolean => {
  const regex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  return !!slug.match(regex);
};

/** Checks a youtube to ensure valid formatting for video boards urls, must be the embed link
 * https://www.youtube.com/watch?v=-C9mLT58CVo vs https://www.youtube.com/embed/-C9mLT58CVo
 * @param {string} url - slug to be validated
 * @returns {string} - whether or not the slug is valid
 */
export const validateYouTubeEmbedUrl = (url: string): string => {
  if (url.indexOf("youtube") > -1 && url.indexOf("watch") > -1) {
    return url.replace("watch?v=", "embed/");
  } else return url;
};

/** Checks a string to ensure valid formatting for file names
 * @param {string} fileName - file name to be validated
 * @returns {boolean} - whether or not the file name is valid
 */
export const validateFileName = (fileName: string): boolean => {
  const regex = /^[-\w]+$/;
  return !!fileName.match(regex);
};

/** Validates whether or not a given input is a valid image file
 * @param {string} inputString - a string for the url you want to check for an image file extension
 * @returns {boolean} - Whether or not the input string is a valid image file
 */
export const isAnImage = (inputString?: string): boolean => {
  const imageTypes: string[] = [".jpg", ".png", ".gif", ".tif", ".jpeg"];
  let returnValue = false;
  imageTypes.forEach((element) => {
    if (inputString?.toLowerCase().indexOf(element) > 0) {
      returnValue = true;
    }
  });
  return returnValue;
};

/** Validates whether or not a given input is a valid image file for IIIF Conversion
 * @param {string} inputString - a string for the url you want to check for an image file extension
 * @returns {boolean} - Whether or not the input string is a valid image file
 */
export const validateImageForConversion = (inputString?: string): boolean => {
  // temporarily (or maybe forever) removing ".tif" from the array because a conversion issue
  const imageTypes: string[] = [".jpg", ".jpeg", ".png"];
  let returnValue = false;
  imageTypes.forEach((element) => {
    if (inputString?.toLowerCase().indexOf(element) > 0) {
      returnValue = true;
    }
  });
  return returnValue;
};

/** Validates accepted video file types
 * @param {string} fileName - File name to be validated
 * @returns {boolean} - Whether or not the filename is valid
 */
export const validateVideoFileType = (fileName?: string): boolean => {
  const acceptedFileTypes = [".mp4"];
  let valid = false;

  // Check file type
  acceptedFileTypes.forEach((fileType) => {
    if (fileName?.toLowerCase().indexOf(fileType) > 0) {
      valid = true;
    }
  });

  return valid;
};
