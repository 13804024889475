import React from "react";
import variables from "../../../styles/variables.scss";
import { useAppContext } from "../../../contexts/appContext";
import { HelperPopUp } from "../../popUps/helperPopUp";
import { useMutation } from "@apollo/client";
import { ACTIVATE_MEETING } from "../../../graphql/mutations";
import { Icon } from "../../icon";

type RecordingIndicatorProps = {
  recordingActive: boolean;
  useMobileTools?: boolean;
  lobbyActive: boolean;
  handleMeetingActivated?: (e) => void;
};

/** Recording Indicator component instructors to know if the class is actively recording / start the recording
 * @param {RecordingIndicatorProps} props
 * @prop {recordingActive} boolean - is the meeting recording, coming from the Meeting response
 * @prop {useMobileTools} boolean - use Mobile indicator
 * @prop {lobbyActive} boolean - is the Meetings lobby active or not
 * @prop {() => void} handleMeetingActivated - Function that fires when the recording has been started from the indicator
 */

export const RecordingIndicator: React.FC<RecordingIndicatorProps> = ({
  recordingActive,
  useMobileTools = false,
  lobbyActive,
  handleMeetingActivated,
}) => {
  const { isDemo, state } = useAppContext();
  const [activateMeetingMutation, mutationResult] = useMutation(
    ACTIVATE_MEETING,
    {
      onCompleted(data) {
        handleMeetingActivated(data.activateMeeting.meeting);
      },
      onError(error) {},
    }
  );
  const lobbyStatus = lobbyActive
    ? " Students are in the lobby."
    : " Students are in the class.";
  const recordingStatus = recordingActive
    ? " Recording has started."
    : " Recording has not started.";
  const helperHeader =
    // "Please remember to start the recording and admit the students." +
    lobbyStatus + recordingStatus;
  const buttonText =
    lobbyActive && !recordingActive
      ? "Admit students and start recording"
      : !lobbyActive && !recordingActive
      ? "Start recording"
      : "Admit students";
  const handleSubmit = () => {
    activateMeetingMutation({
      variables: { id: state.currentBoard?.meetingId },
    });
  };

  return (
    <div className="recording-indicator__wrapper">
      {/* If shown for students, we'll want to useMobileTools to change position */}
      <div
        className="recording-indicator__inner"
        style={{ top: "8px", right: "8px" }}
      >
        {(!recordingActive || lobbyActive) && !isDemo && (
          <HelperPopUp
            bottom="-150px"
            right="-34px"
            popUpHeader={helperHeader}
            isTimed={false}
            buttonText={buttonText}
            onBtnClick={() => handleSubmit()}
            buttonDisabled={mutationResult.loading}
          />
        )}
        <Icon
          name="recording"
          desc={
            recordingActive
              ? "This meeting is being recorded"
              : !recordingActive && !isDemo
              ? "Recording has not been started for this meeting"
              : "Recording is not enabled for demo meetings"
          }
          toolTip="left"
          fill={recordingActive ? variables.actionRed : variables.selectedGrey}
          stroke={
            recordingActive ? variables.actionRed : variables.selectedGrey
          }
        />
      </div>
    </div>
  );
};
