import React, { Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BarnesLogo } from "../assets/logos";
import { adminRoutes, routes } from "../constants";
import { useAuthContext } from "../contexts/authContext";
import { useAppContext } from "../contexts/appContext";
import { MeetingRole } from "../contexts/types";
import classnames from "classnames";
import { Icon } from "./icon";
import { Switch } from "./inputs/switch";
import { DARK, DEFAULT, ThemeContext } from "../contexts/themeContext";

export const Nav: React.FC = () => {
  // const isCentennial = today.getFullYear() === 2022;
  const { meetingRole, firstName, lastName } = useAppContext();
  const { isAuthenticated, handleLogout } = useAuthContext();
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(ThemeContext);

  const onLogout = async (e) => {
    e.preventDefault();
    handleLogout();
    navigate("/");
  };

  return (
    <div className="nav-header">
      <Fragment>
        {/* Barnes logo */}
        <BarnesLogo className="nav-header__logo" />
        <Fragment>
          {meetingRole === MeetingRole.Presenter || isAuthenticated ? (
            <Fragment>
              <br />
              <Link
                className={classnames(`nav__link`, {
                  "--active":
                    location.pathname.includes(`${routes.admin}`) &&
                    !location.pathname.includes(
                      `${adminRoutes.createCourse}`
                    ) &&
                    !location.pathname.includes(`${adminRoutes.course}`) &&
                    !location.pathname.includes(
                      `${adminRoutes.meetingPresentationEditor}`
                    ),
                })}
                to={
                  isAuthenticated
                    ? `/${routes.admin}`
                    : `/${routes.admin}/${adminRoutes.logIn}`
                }
              >
                Home
              </Link>
              <Link
                className={classnames(`nav__link`, {
                  "--active": location.pathname.includes(
                    `${adminRoutes.createCourse}`
                  ),
                })}
                to={`/${routes.admin}/${adminRoutes.createCourse}`}
              >
                Create a course
              </Link>
              <Link
                className={classnames(`nav__link`, {
                  "--active": location.pathname.includes(`${routes.join}`),
                })}
                to={"/" + routes.join}
              >
                Join a class
              </Link>
              <Link
                className={classnames(`nav__link`, {
                  "--active": location.pathname.includes(`${adminRoutes.demo}`),
                })}
                to={`/${routes.admin}/${adminRoutes.demo}`}
              >
                Start a demo
              </Link>
              {isAuthenticated && (
                <Link
                  className={classnames(`nav__link`, {
                    "--active": location.pathname.includes(
                      `${adminRoutes.demo}`
                    ),
                  })}
                  to={`/${routes.watch}/${adminRoutes.replayDemo}`}
                >
                  Replay demo
                </Link>
              )}

              <div className="nav-header__log-out">
                <div className="nav__theme-toggle">
                  <label htmlFor="switch-logout">
                    <Icon name="sun" desc="Light theme" toolTip="none" />
                  </label>
                  <Switch
                    className="nav__switch"
                    onClick={() => setTheme(theme === DEFAULT ? DARK : DEFAULT)}
                    isOn={theme === DEFAULT}
                    id="switch-theme__logout"
                  />
                  <Icon name="moon" desc="Dark theme" toolTip="none" />
                </div>
                <a className="nav__link" onClick={onLogout}>
                  <span>Log out</span>
                  <Icon name="log-out" desc="Log out" toolTip="none" />
                </a>
                {firstName && lastName && (
                  <span className="logged-in-as">
                    Logged in as {firstName} {lastName}
                  </span>
                )}
              </div>
            </Fragment>
          ) : (
            <div className="nav-header__log-in">
              <div className="nav__theme-toggle">
                <Icon name="sun" desc="Light theme" toolTip="none" />
                <Switch
                  className="nav__switch"
                  onClick={() => setTheme(theme === DEFAULT ? DARK : DEFAULT)}
                  isOn={theme === DEFAULT}
                  id="switch-theme__log-in"
                />
                <Icon name="moon" desc="Dark theme" toolTip="none" />
              </div>
              <Link
                className="nav__link"
                to={`/${routes.admin}/${adminRoutes.logIn}`}
              >
                <span>Admin login</span>
                <Icon name="log-in" desc="Log in" toolTip="none" />
              </Link>
            </div>
          )}
        </Fragment>
      </Fragment>
    </div>
  );
};
