import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  DeviceLabelTriggerStatus,
  useDeviceLabelTriggerStatus,
} from "amazon-chime-sdk-component-library-react";
import { CreateAttendeeInput, CreateDemoMeetingInput } from "../types";
import { useAppContext } from "../contexts/appContext";
import { MeetingRole } from "../contexts/types";
import { JoinFormMeetingId } from "./joinForm/joinFormMeetingId";
import { JoinFormName } from "./joinForm/joinFormName";
import { Button, PRIMARY, STANDARD } from "./buttons/button";
import { populateMeetingIdFromURL } from "../utils/utilityBeltUtils";
import Spinner from "./spinner";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { loadSvgsIntoCache } from "../utils/cacheMachine";
import { PopUp } from "./popUps/popUp";
import { Modal } from "./modal/modal";
import { Devices } from "./devices";
import { useScreenContext } from "../contexts/screenContext";
import classnames from "classnames";
import { browserName, isMobile, isTablet } from "react-device-detect";
import { MutationResult } from "@apollo/client";
import { getAttendeeDeviceDate } from "../utils/deviceUtils";

type OnSubmitArgs = {
  variables: {
    input: CreateAttendeeInput | CreateDemoMeetingInput;
  };
};

type MeetingFormProps = {
  formType: "join" | "demo";
  onSubmit: (args: OnSubmitArgs) => void;
  mutationResult: MutationResult;
};

// Component for the /join route
export const MeetingForm: React.FC<MeetingFormProps> = ({
  formType,
  onSubmit,
  mutationResult,
}) => {
  const { error } = mutationResult;
  const path = useLocation().pathname;
  const status = useDeviceLabelTriggerStatus();
  const { clearLocalStorage } = useLocalStorage();
  const { isValidBrowser } = useScreenContext();
  const {
    meetingId,
    setMeetingId,
    setLocalUserName,
    firstName,
    setFirstName,
    lastName,
    handleSetMeetingRole,
  } = useAppContext();

  const [meetingIdErr, setMeetingIdErr] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [devicePermissionsGranted, setDevicePermissionsGranted] =
    useState(false);
  const [showDeviceSettings, setShowDeviceSettings] = useState(false);

  const toggleDeviceSettings = () => setShowDeviceSettings(!showDeviceSettings);

  useEffect(() => {
    // fires once on mount to check for a meetingID in the URL path if it's there
    // it sets meetingID to that and the form is updated
    // also clears localStorage so old any old meetings / users is cleared
    clearLocalStorage();
    setMeetingId(populateMeetingIdFromURL(path));
    // load svgs into the svg cache for instructions page for a smooth experience
    loadSvgsIntoCache([
      "/icons/mouseMove.svg",
      "/icons/mobileMove.svg",
      "/icons/mouseZoom.svg",
      "/icons/trackpadZoom.svg",
    ]);
  }, []);

  useEffect(() => {
    // Explicitly set device permissions granted to prevent bug where the modal flickered
    // because the status switched between IN_PROGRESS and GRANTED a few times
    if (status === DeviceLabelTriggerStatus.GRANTED)
      setDevicePermissionsGranted(true);
  }, [status]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  const handleClickJoinMeeting = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const id = meetingId.trim().toLocaleLowerCase();

    // if demo form, allow /presenter backdoor
    if (formType === "demo") {
      const [name, role] = firstName.split("/");
      setFirstName(name);
      setLocalUserName(name + " " + lastName);
      role === "presenter"
        ? handleSetMeetingRole(MeetingRole.Presenter)
        : handleSetMeetingRole(MeetingRole.Audience);
    }

    // for all other forms...
    else {
      // Set role in local storage, it defaults to Audience and is changed to Presenter if user logs in
      setLocalUserName(firstName + " " + lastName);
      handleSetMeetingRole(MeetingRole.Audience);
    }

    if (!id || !firstName || !lastName) {
      setIsLoading(false);

      if (!id) {
        setMeetingIdErr(true);
      }
      if (!firstName) {
        setFirstNameErr(true);
      }
      if (!lastName) {
        setLastNameErr(true);
      }
      return;
    }

    const joinInput: CreateAttendeeInput = {
      firstName: firstName,
      lastName: lastName,
      title: id,
      deviceInfo: getAttendeeDeviceDate(),
    };

    const demoMeetingInput: CreateDemoMeetingInput = {
      attendee: joinInput,
      title: id,
    };

    const input = formType === "demo" ? demoMeetingInput : joinInput;

    onSubmit({ variables: { input } });
  };

  const devicePermissionsError = (
    <Fragment>
      This meeting will be recorded for replay purposes. By attending this
      meeting you consent to being recorded. <br />
      Before you join this meeting, a mic and camera permissions check must be
      completed. When the pop-up appears, please choose <strong>Allow</strong>.
      <br /> If you choose to <strong>Block</strong> the permissions you will
      not have access to your microphone or camera for the class.
    </Fragment>
  );
  const devicePermissionsMoreDetails = (
    <Fragment>
      Enabling camera and microphone permissions allows you to participate in
      class discussions. For your privacy, your microphone and camera will be
      off upon joining the class.
    </Fragment>
  );

  return (
    <Fragment>
      {(!isValidBrowser || (isMobile && !isTablet)) && (
        <div
          className={classnames("join__browser-error demo__browser-error", {
            "join__browser-error--mobile": isMobile && !isTablet,
          })}
        >
          <p id="unsupported-browser-message" style={{ width: "80%" }}>
            {!isValidBrowser &&
              `We're sorry but we've detected your browser is incompatible with the Barnes Visual Experience Platform (VxP). `}
            {!isValidBrowser &&
              `Please download an updated version of ${browserName} or try joining again using a different browser. `}
            {isMobile &&
              !isTablet &&
              `The Barnes strives to make this site compatible with all devices. For the best experience,
              it is recommended you use a tablet, laptop, or desktop computer to participate in this class.`}
          </p>
        </div>
      )}
      <div className="joinform-container">
        <div className="joinform">
          <header>
            <h1>
              {formType === "demo" ? "Start a demo meeting" : "Join a meeting"}
            </h1>
            <div style={{ marginBottom: "0.5rem", marginTop: "-0.5rem" }}>
              <span>(all fields required)</span>
            </div>
          </header>
          <main>
            <div style={{ fontSize: "0.875rem", marginTop: "0" }}>
              <span>
                {formType === "demo" ? (
                  <>
                    You are about to create a new meeting with a demo
                    presentation. <br />
                    Anyone with access to the meeting ID can join. <br />
                    You can join the demo meeting as a presenter by adding
                    &lsquo;/presenter&lsquo; to the end of your first name{" "}
                    <br /> for example: Jane/presenter
                  </>
                ) : (
                  <>
                    Anyone with access to the meeting ID can join. <br />
                    This meeting will be recorded for replay purposes.
                    <br /> By attending this meeting you consent to being
                    recorded.
                  </>
                )}
              </span>
            </div>
            <form name="joinForm">
              <JoinFormMeetingId
                idError={meetingIdErr}
                updateIDError={setMeetingIdErr}
              />
              <JoinFormName
                firstNameError={firstNameErr}
                lastNameError={lastNameErr}
                updateFirstNameError={setFirstNameErr}
                updateLastNameError={setLastNameErr}
              />
              <div className="joinForm-button">
                <Button
                  btnType={PRIMARY}
                  disabled={
                    isLoading ||
                    !isValidBrowser ||
                    !firstName ||
                    !lastName ||
                    !meetingId
                  }
                  size={STANDARD}
                  onClick={handleClickJoinMeeting}
                  id="join-meeting-form-button"
                >
                  <Fragment>
                    {isLoading ? <Spinner /> : "Join meeting"}
                  </Fragment>
                </Button>
              </div>

              <Modal
                dismissible={false}
                display={
                  status === DeviceLabelTriggerStatus.IN_PROGRESS &&
                  !devicePermissionsGranted
                }
              >
                <div className="__modal-content">
                  <PopUp
                    descriptMessage={devicePermissionsMoreDetails}
                    userMessage={devicePermissionsError}
                    popUpHeader="Almost there!"
                  />
                </div>
              </Modal>
            </form>
            <Modal
              dismissible={true}
              display={showDeviceSettings}
              onDismiss={toggleDeviceSettings}
            >
              <Devices toggleDeviceSettings={toggleDeviceSettings} />
            </Modal>
          </main>
        </div>
      </div>
    </Fragment>
  );
};
