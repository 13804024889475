import classnames from "classnames";
import React from "react";
import SimpleBar from "simplebar-react";

type ModalBodyProps = {
  children: any;
  className?: string;
};

/**
 * Component for VxP modal body. To be wrapped in Modal component
 * @ModalBodyProps children
 */

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  className,
}) => {
  return (
    <section className={classnames("modal_body_section", className)}>
      {children}
    </section>
  );
};
