import React, { useState } from "react";
import classnames from "classnames";
import { MinimizeExpandToggle } from "./controls/minimizeExpandToggle";

type ToolBarProps = {
  className?: string;
  orientation: "horizontal" | "vertical";
  isMinimizable?: boolean;
  children?: any;
  id?: string;
};

/**
 * Component for an VxP toolbar. Choose from orientation "horizontal" and "vertical".
 * @ToolBarProps className, orientation, children, isMinimizable
 */

export const ToolBar: React.FC<ToolBarProps> = ({
  className,
  orientation,
  children,
  isMinimizable = false,
  id,
}) => {
  const [isActive, setIsActive] = useState(true);
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      className={classnames(`toolbar ${className}`, orientation, {
        active: isActive,
        inactive: !isActive,
      })}
    >
      <div
        className={classnames("children", orientation, {
          active: isActive,
          minimized: !isActive && isMinimizable,
          divider: isMinimizable,
        })}
        id={id}
      >
        {children}
      </div>

      {isMinimizable && (
        <>
          <span className="divider" />
          <MinimizeExpandToggle
            isActive={isActive}
            isHorizontal={orientation === "horizontal"}
            onClick={toggleActive}
            className="toolbar__min-max-toggle"
          />
        </>
      )}
    </div>
  );
};
