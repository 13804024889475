import React, { Fragment } from "react";
import { IconButton } from "../buttons/iconButton";
import { useScreenContext } from "../../contexts/screenContext";

type VideoControlsProps = {
  onPlayPause;
  handleRewind;
  handleFastForward;
  isPlaying;
  activeChapter: number;
  chapters: VTTCue[];
  handleSeek;
  currentTime;
  remainingTime;
  togglePlayPause;
};

export const VideoControls: React.FC<VideoControlsProps> = ({
  onPlayPause,
  handleRewind,
  handleFastForward,
  isPlaying,
  activeChapter,
  chapters,
  handleSeek,
  currentTime,
  remainingTime,
  togglePlayPause,
}) => {
  const { useMobileTools } = useScreenContext();
  const handleChapterSkipForward = (e) => {
    if (activeChapter && activeChapter !== chapters.length) {
      handleSeek(chapters[activeChapter]?.startTime);
    }
  };

  const handleChapterSkipBackwards = (e) => {
    let difference: number;
    try {
      difference = currentTime - chapters[activeChapter - 1].startTime;
    } catch (error) {
      difference = 0;
    }
    if (activeChapter !== 1) {
      if (difference > 5) {
        handleSeek(chapters[activeChapter - 1].startTime);
      } else {
        handleSeek(chapters[activeChapter - 2].startTime);
      }
    }
  };

  return (
    <Fragment
    // Uncomment to renenable overlay video controls on mobile
    // className={classnames(`video-controls`, {
    //   "mobile-tools": useMobileTools,
    // })}
    >
      <IconButton
        onClick={handleChapterSkipBackwards}
        iconName="rewind"
        desc="Previous Chapter"
        toolTip="top"
        btnId="video-rewind-chapter-toggle"
        disabled={activeChapter === 1 || !chapters}
      />
      <IconButton
        onClick={handleRewind}
        iconName={"rotate-ccw"}
        desc={`Rewind 15 seconds`}
        toolTip="top"
        btnId="video-rewind-15-toggle"
      />
      <IconButton
        size={useMobileTools ? "2rem" : ""}
        onClick={togglePlayPause}
        iconName={
          remainingTime < 2
            ? "replayFromStart"
            : isPlaying
            ? "pause"
            : "playVideo"
        }
        desc={`${
          remainingTime < 2 ? "Replay" : isPlaying ? "Pause" : "Play"
        } video`}
        toolTip="top"
        btnId="video-controls__play-toggle"
        fill=""
      />

      <IconButton
        onClick={handleFastForward}
        iconName={"rotate-cw"}
        desc={`Fast forward 15 seconds`}
        toolTip="top"
        btnId="video-fast-forward-15-toggle"
      />
      <IconButton
        onClick={handleChapterSkipForward}
        iconName="fast-forward"
        desc="Next Chapter"
        toolTip="top"
        btnId="video-ff-chapter-toggle"
        disabled={activeChapter === chapters?.length || !chapters}
      />
    </Fragment>
  );
};
