import { SVGInjector } from "@tanem/svg-injector";
import { consoleNonProd } from "./utilityBeltUtils";

/**
 * @param {string []} svgs - an array of string paths, that point to the svg(s)
 *  you want to load into the svg cache
 * "/icons/trackpadZoom.svg" as an example for something in the public icons folder
 *
 */

export const loadSvgsIntoCache = (svgs: string[]) => {
  svgs.forEach((svg) => {
    const img = new Image();
    img.src = svg;
    SVGInjector(img);
  });
};

/**
 * @param {string []} urls - an array of string URL paths, that point to the data
 *  you want to load into browser cache
 * "[https://www.something.com/data/image.jpg]" as an example
 *
 */

export const fetchAll = async (urls: string[]) => {
  try {
    const fetchPromises = urls.map((url) => fetch(url));
    const responses = await Promise.all(fetchPromises);
  } catch (error) {
    consoleNonProd("Error fetching data:", error);
  }
};
