import React, { ErrorInfo, PropsWithChildren } from "react";
import { ErrorContextType, withErrorHOC } from "../contexts/errorContext";
import { consoleNonProd, logger } from "../utils/utilityBeltUtils";
import { LOG } from "../constants";

class ErrorBoundary extends React.Component<
  PropsWithChildren<ErrorContextType>
> {
  constructor(props) {
    super(props);
    this.state = { hasBoundaryError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasBoundaryError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // log the error in CloudWatch, show error modal, redirect to home
    const message = {
      source: "ErrorBoundary.componentDidCatch",
      error,
      stackTrace: info.componentStack,
    };
    consoleNonProd(message);
    logger({ type: LOG, message });
    this.props.showError({
      details: error.message,
      onClick1: () => {
        window.location.assign("/");
      },
    });
  }

  render() {
    // If an error is caught by the boundary, return nothing
    // @ts-ignore
    if (this.state.hasBoundaryError) return;

    // Otherwise, return children
    return this.props.children;
  }
}

export default withErrorHOC(ErrorBoundary);
