import React, { useState } from "react";
import { Modal } from "../modal/modal";
import { ModalHeader } from "../modal/modalHeader";
import { ModalBody } from "../modal/modalBody";
import { Button, PRIMARY, SECONDARY, STANDARD } from "../buttons/button";
import {
  MediaPipeline,
  Meeting,
  Mutation,
  SetReplayRecordingPipelineInput,
} from "../../types";
import { ModalFooter } from "../modal/modalFooter";
import { useMutation } from "@apollo/client";
import { SET_REPLAY_RECORDING_PIPELINE } from "../../graphql/mutations";
import { useError } from "../../contexts/errorContext";
import { useAlert } from "../../contexts/alertContext";
import { PipelineDetails } from "./pipelineDetails";

type RecordingDetailsModalProps = {
  display: boolean;
  onDismiss: () => void;
  meeting: Meeting;
  handleSetMeetingInfo: (meeting: Meeting) => void;
  replayRecording?: MediaPipeline;
};

export const RecordingDetailsModal: React.FC<RecordingDetailsModalProps> = ({
  display,
  onDismiss,
  meeting,
  handleSetMeetingInfo,
  replayRecording,
}) => {
  const { showError } = useError();
  const [selectedReplayRecording, setSelectedReplayRecording] = useState<
    MediaPipeline | undefined
  >(meeting.mediaPipelines?.find((mp) => mp.replayRecording));
  const { showAlert } = useAlert();

  const [setReplayRecordingPipelineMutation] = useMutation(
    SET_REPLAY_RECORDING_PIPELINE,
    {
      onCompleted(data: Pick<Mutation, "setReplayRecordingPipeline">) {
        const meeting = data.setReplayRecordingPipeline;
        // Update meeting details in Course page component
        handleSetMeetingInfo(meeting);
      },
      onError(error) {
        showError({
          error:
            'Something went wrong while updating the replay video. Click "View details" to learn more.',
          details: error,
        });
      },
    }
  );

  const handleSelectReplayRecording = (mp: MediaPipeline) => {
    // Update the replay recording with the one passed
    if (selectedReplayRecording !== mp) {
      setSelectedReplayRecording(mp);
    }
    // Or deselect the current replay recording
    else {
      setSelectedReplayRecording(undefined);
    }
  };

  const handleSubmit = () => {
    const input: SetReplayRecordingPipelineInput = {
      id: meeting.id,
      mediaPipelineId: selectedReplayRecording?.id,
    };

    setReplayRecordingPipelineMutation({ variables: { input } });
    onDismiss();
  };

  const onCompleteReplaceRecording = () => {
    handleSetMeetingInfo(meeting);

    showAlert({
      header: "Upload successful!",
      message:
        "The file upload has completed and replay video processing has started.",
      isTimed: true,
      dismissTimeout: 5,
    });
  };

  return (
    <Modal dismissible={true} display={display} onDismiss={onDismiss}>
      <div className="recording-details__modal">
        <ModalHeader>
          <h3>Class recording details</h3>
        </ModalHeader>
        <ModalBody>
          <table className="recording-details__table">
            <thead>
              <tr>
                <th className="recording-details__table__cell">
                  Recording date
                </th>
                <th className="recording-details__table__cell">Chat Log</th>
                <th className="recording-details__table__cell">
                  Attendee List
                </th>
                <th className="recording-details__table__cell">
                  Use recording for replay
                </th>
                <th className="recording-details__table__cell">
                  Replay processing status
                </th>
              </tr>
            </thead>
            <tbody>
              {meeting.mediaPipelines?.map((mp, i) => (
                <PipelineDetails
                  mediaPipeline={mp}
                  key={i}
                  handleSelectReplayRecording={handleSelectReplayRecording}
                  selectedReplayRecording={selectedReplayRecording}
                  onComplete={onCompleteReplaceRecording}
                />
              ))}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <div className="pop-up__buttons">
            <Button
              btnType={SECONDARY}
              disabled={false}
              size={STANDARD}
              text="Close"
              onClick={onDismiss}
              id="popup-footer-first-button"
            />
            <Button
              btnType={PRIMARY}
              disabled={replayRecording === selectedReplayRecording}
              size={STANDARD}
              text="Update replay recording"
              onClick={handleSubmit}
              id="popup-footer-second-button"
            />
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
};
