import React, { createContext, useContext, useReducer } from "react";
import { Modal } from "../components/modal/modal";
import { ModalBody } from "amazon-chime-sdk-component-library-react";
import { PopUp } from "../components/popUps/popUp";
import {
  ErrorActionType as ActionType,
  ErrorAction as Action,
  ErrorState as State,
  ShowErrorAction,
} from "./types";
import { ApolloErrorsList } from "../components/apolloErrorsList";
import { ApolloError } from "@apollo/client";

export type ErrorContextType = {
  state: State;
  dispatch: React.Dispatch<Action>;
  showError: (payload: ShowErrorAction["payload"]) => void;
};

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

const defaultError =
  "Something went wrong, please click continue to close this message.";
const defaultHeader = "Oops!";
const defaultButton = "Continue";

const initialState: State = {
  hasError: false,
  header: defaultHeader,
  error: defaultError,
  button1: defaultButton,
};

const parseErrorObj = (
  error: ApolloError | any
): string | JSX.Element | undefined => {
  if (!error || error === "") {
    return undefined;
  } else if (error.hasOwnProperty("graphQLErrors")) {
    return <ApolloErrorsList error={error} />;
  } else if (typeof error === "string") {
    return error;
  } else {
    return JSON.parse(error);
  }
};

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.RESET:
      return initialState;

    case ActionType.SHOW_ERROR:
      const updatedState = { ...state, hasError: true };

      if (payload.header) updatedState["header"] = payload.header;
      if (payload.error) {
        updatedState["error"] = parseErrorObj(payload.error);
      }
      if (payload.details) {
        updatedState["details"] = parseErrorObj(payload.details);
      }
      if (payload.button1) updatedState["button1"] = payload.button1;
      if (payload.button2) updatedState["button2"] = payload.button2;
      if (payload.onClick1) updatedState["onClick1"] = payload.onClick1;
      if (payload.onClick2) updatedState["onClick2"] = payload.onClick2;

      return updatedState;
  }
};

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClick1 = () => {
    state.onClick1 && state.onClick1();
    dispatch({
      type: ActionType.RESET,
    });
  };

  const handleClick2 = () => {
    state.onClick2 && state.onClick2();
    dispatch({
      type: ActionType.RESET,
    });
  };

  const showError = (payload: ShowErrorAction["payload"]) => {
    dispatch({
      type: ActionType.SHOW_ERROR,
      payload,
    });
  };

  return (
    <ErrorContext.Provider value={{ state, dispatch, showError }}>
      {state.hasError && (
        <Modal display={state.hasError} dismissible={false}>
          <ModalBody>
            <PopUp
              userMessage={state.error}
              descriptMessage={state.details}
              popUpHeader={state.header}
              buttonText1={state.button1}
              buttonText2={state.button2}
              onClick1={handleClick1}
              onClick2={handleClick2}
            />
          </ModalBody>
        </Modal>
      )}
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = (): ErrorContextType => {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error("Use useError hook inside ErrorProvider.");
  }

  return context;
};

/** Higher order component to enable use of the useError hook in class components */
export const withErrorHOC = (Component: any) => {
  const WrappedComponent = (props) => {
    const { state, dispatch, showError } = useError();

    return (
      <Component
        errorState={state}
        errorDispatch={dispatch}
        showError={showError}
        {...props}
      />
    );
  };

  WrappedComponent.displayName = "wrappedComponent";
  return WrappedComponent;
};
