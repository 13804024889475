import React, { useEffect, useState } from "react";
import FocusLockUI from "react-focus-lock/UI";
import { sidecar } from "use-sidecar";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { Container } from "../container";
import { ThemeWrapper } from "../themeWrapper";

type ModalProps = {
  children: any;
  display: boolean;
  dismissible: boolean;
  onDismiss?: (e) => void;
  className?: string;
  noBackground?: boolean;
};
// prefetch sidecar. data would be loaded, but js would not be executed
const FocusLockSidecar = sidecar(
  () => import(/* webpackPrefetch: true */ "react-focus-lock/sidecar")
);

/**
 * Component for an VxP modal, will lock focus on the modal and darken the background.
 * @ModalProps children, display, dismissable, onDismiss
 */

export const Modal: React.FC<ModalProps> = ({
  children,
  display,
  dismissible,
  onDismiss,
  className,
  noBackground,
}) => {
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(display);

  const keyHandler = (e) => {
    if (e.key === "Escape") {
      window.removeEventListener("keyup", keyHandler);
      setShouldDisplay(false);
      onDismiss(e);
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", keyHandler);

    //component will unmount
    return () => {
      window.removeEventListener("keyup", keyHandler);
    };
  });

  useEffect(() => {
    setShouldDisplay(display);
    // while the modal is open the screen should lock on the open modal and unlock when it is closed
    setTimeout(() => {
      if (display) {
        document.body.style.overflow = "hidden";
      } else {
        // if the modal is on during a canvas related render keep the bodys overflow hidden
        // if it's in an admin page or something else let the body overflow like normal
        const canvas = document.getElementById("main-canvas");
        if (!canvas) document.body.style.overflow = "initial";
      }
    }, 100);
  }, [display]);

  return ReactDOM.createPortal(
    shouldDisplay && (
      <ThemeWrapper
        className={classnames(
          {
            "modal-background--no-background": noBackground,
          },
          "modal-background"
        )}
        elementType="div"
      >
        <FocusLockUI sideCar={FocusLockSidecar}>
          <Container
            onDismiss={(e) => {
              setShouldDisplay(false);
              onDismiss && onDismiss(e);
            }}
            className={classnames("modal-container", className)}
            isDismissable={dismissible}
            role="dialog"
          >
            {children}
          </Container>
        </FocusLockUI>
      </ThemeWrapper>
    ),
    document.body
  );
};
