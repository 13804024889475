import config from "./config";

const AWS_BUCKET = config.collectionS3Bucket;
const IMAGE_BASE_URL =
  config.collectionBaseImgUrl || `//s3.amazonaws.com/${AWS_BUCKET}`;
const imageUrlBase = IMAGE_BASE_URL;

const generateObjectImageUrls = (object) => {
  // Clone existing object
  const newObject = Object.assign({}, object);
  const imageIdReg = `${object.id}_${object.imageSecret}`;
  const imageIdOrig = `${object.id}_${object.imageOriginalSecret}`;

  // Construct image urls for object with updated url roots
  newObject.imageUrlSmall = `${imageUrlBase}/${imageIdReg}_n.jpg`;
  newObject.imageUrlOriginal = `${imageUrlBase}/${imageIdOrig}_o.jpg`;
  newObject.imageUrlLarge = `${imageUrlBase}/${imageIdReg}_b.jpg`;

  return newObject;
};

const sanitizeEnsembleIndex = (object) => {
  let index = object.ensembleIndex;

  // Update the ensemble index
  object.ensembleIndex = index ? index.split(",")[0] : null;

  return object;
};

export const parseObject = (object) => {
  object = generateObjectImageUrls(object);
  object = sanitizeEnsembleIndex(object);

  return object;
};

/**
 * Convert index into string URL to be saved to the database
 * @param {string} index numerical index for accessing image from S3
 * @returns {string} tile source string URL to be saved to the database
 */
export const getTileSourceURL: (index: string) => string = (index) => {
  return `https://collection-tif-tiler.s3.amazonaws.com/tiles/${index}/info.json`;
};
