import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { TextInput } from "../inputs/textInput";
import { useAppContext } from "../../contexts/appContext";

interface joinMeetingIDProps {
  idError: boolean;
  updateIDError: (arg: boolean) => void;
}

export const JoinFormMeetingId: React.FC<joinMeetingIDProps> = ({
  idError,
  updateIDError,
}) => {
  const { meetingId, setMeetingId } = useAppContext();
  const [meetingErr, setMeetingErr] = useState<boolean>(false);

  useEffect(() => {
    setMeetingErr(idError);
  }, [idError]);

  return (
    <Fragment>
      <TextInput
        disabled={false}
        errorMessage="Please enter a valid meeting ID"
        id="meetingIdInput"
        initialValue={meetingId}
        label="Meeting Id"
        name="meetingId"
        parentForm="joinForm"
        hasError={meetingErr}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          updateIDError(meetingErr);
          setMeetingId(e.target.value);
          if (meetingErr) {
            setMeetingErr(false);
          }
        }}
        onBeforeInput={(e: any): void => {}}
      />
    </Fragment>
  );
};
