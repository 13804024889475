import React from "react";
import classnames from "classnames";
import { Icon } from "../icon";
import { useScreenContext } from "../../contexts/screenContext";
import { useAppContext } from "../../contexts/appContext";
import { MeetingRole } from "../../contexts/types";

type ToggleProps = {
  option1: string;
  option2: string;
  name: string;
  onChange: (e) => void;
  checked: boolean;
  icon1?: string;
  icon2?: string;
  disabled?: boolean;
};

/**
 * Component for an VxP toggle
 * @ToggleProps option1, option2, icon1, icon2, name, onChange, checked, disabled
 */

export const Toggle: React.FC<ToggleProps> = ({
  option1,
  option2,
  icon1,
  icon2,
  name,
  onChange,
  checked,
  disabled = false,
}) => {
  const { meetingRole } = useAppContext();
  const { useMobileTools } = useScreenContext();
  function handleKeyPress(e) {
    if (e.keyCode !== 13 && e.keyCode !== 32) {
      return;
    }
    e.preventDefault();
    onChange(e);
  }

  return (
    <div
      className={classnames(`toggle-container`, {
        "mobile-tools": useMobileTools && meetingRole === MeetingRole.Audience,
      })}
    >
      <span className="toggle">
        <input
          type="checkbox"
          checked={checked}
          name={name}
          id={name}
          onChange={onChange}
          disabled={disabled}
        />
        <label
          htmlFor={name}
          data-option1={option1}
          tabIndex={0}
          onKeyDown={(e) => handleKeyPress(e)}
          data-option2={option2}
          className={classnames("toggle-inner", {
            disabled: disabled,
            checked: checked,
          })}
        >
          <span className="toggle__icon-one">
            <Icon
              name={icon1}
              desc={icon1}
              stroke={!checked ? "#d64220" : disabled ? "#6e6e6e" : ""}
              fill=""
              toolTip="none"
              hoverState={disabled ? false : true}
            />
          </span>
          <span className="toggle__icon-two">
            <Icon
              name={icon2}
              desc={icon2}
              stroke={checked ? "#d64220" : disabled ? "#6e6e6e" : ""}
              fill=""
              toolTip="none"
            />
          </span>
        </label>
      </span>
    </div>
  );
};
