import classnames from "classnames";
import React, { useState } from "react";
import { Checkbox } from "../../inputs/checkbox";

import { ArtObjectCaption } from "./index";

type ArtObjectOverlayProps = {
  invno: string | number;
  index: number;
  title?: string;
  caption: string;
  handleAddContentClick: (
    invno: string | number,
    action: "add" | "remove"
  ) => void;
};

export const ArtObjectOverlay: React.FC<ArtObjectOverlayProps> = ({
  invno,
  index,
  title,
  caption,
  handleAddContentClick,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  // Update the array of selected objects on change
  const artObjectSelection = () => {
    const updateSelected = !isSelected;
    setIsSelected(updateSelected);
    handleAddContentClick(invno, updateSelected ? "add" : "remove");
    let artInFocus = document?.getElementById(index.toString()) as HTMLElement;
    artInFocus.focus();
  };

  return (
    <div
      className={classnames(`art-object-overlay`, { selected: isSelected })}
      onClick={artObjectSelection}
    >
      <Checkbox
        id={`${index}`}
        value={title}
        checked={isSelected}
        onChange={artObjectSelection}
      />
      <ArtObjectCaption title={title} caption={caption} />
    </div>
  );
};

export default ArtObjectOverlay;
