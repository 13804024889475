import React, { Fragment, useEffect, useState } from "react";
import classnames from "classnames";
import { IconButton } from "../buttons/iconButton";
import variables from "../../styles/variables.scss";
import { Icon } from "../icon";
import { useAppContext } from "../../contexts/appContext";
import { ActionType } from "../../contexts/types";
import {
  CanvasDataEvent,
  EventPayload,
  EventTypes,
} from "../../providers/types";
import { AttendeeInitials } from "../attendeeInitials";

export enum VideoType {
  INSTRUCTOR,
  STUDENT,
  USER,
  REPLAY,
}

type VideoTileProps = {
  isVideoEnabled: boolean;
  handleLowerHand?: () => void;
  dismissable: boolean;
  toggleShowVideo?: () => void;
  handleManualSpotlight?: (bool: boolean) => void;
  isInstructorOrModerator: boolean;
  videoType: VideoType;
  speaking: boolean;
  iconHex: string;
  name: string;
  handRaised: boolean;
  muted?: boolean;
  handleMute?: () => void;
  children: JSX.Element;
  events?: CanvasDataEvent[];
  softBanned?: boolean;
};

/** Component that is used to wrap the video player and camera off placeholder
 * @param {VideoTileProps} props
 * @prop {boolean} isVideoEnabled - Whether or not the video/camera is on
 * @prop {() => void} handleLowerHand - Fn that handles lower the hand for a
 * user associated with the video during a live meeting
 * @prop {boolean} dismissable - Whether or not the video can be dismissed/minimized
 * @prop {() => void} toggleShowVideo - Fn for the onClick handler for the dismiss button
 * @prop {JSX.Element} children - The player for the camera stream for live
 * classes or the video player for replay. This prop should be passed as a
 * nested child component
 * @prop {boolean} isInstructorOrModerator
 * @prop {VideoType} videoType
 * @prop {boolean} speaking - Whether or not the user associated with the video
 * is an active speaker
 * @prop {string} iconHex - Hex code for the user's color
 * @prop {string} name - User's full name
 * @prop {boolean} handRaised - Whether or not the user associated with the
 * video has their hand raised
 * @prop {boolean} muted - Whether nor not the user associated with the video is
 * muted
 * @prop {() => void} handleMute - Fn that handles muting the mic for a user
 * associated with the video during a live meeting
 * @prop {CanvasDataEvent} events - meeting events array
 */
export const VideoTile: React.FC<VideoTileProps> = ({
  isVideoEnabled,
  children,
  isInstructorOrModerator,
  videoType,
  speaking,
  iconHex,
  name,
  handRaised,
  muted,
  handleMute,
  handleLowerHand,
  dismissable,
  toggleShowVideo,
  events,
  softBanned,
}) => {
  const { state, dispatch } = useAppContext();
  const [disableSpotLightButton, setDisableSpotlightButton] = useState(false);
  const handleManualSpotlight = (shouldSpotlight: boolean) => {
    dispatch({
      type: ActionType.UPDATE_SPOTLIGHT_INSTRUCTOR,
      payload: {
        spotlightInstructor: shouldSpotlight,
        manualSpotlight: shouldSpotlight,
      },
    });
  };

  useEffect(() => {
    if (events && events?.length) {
      const canvasDataEv = events[events.length - 1];
      const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
      if (
        !canvasDataEv.isSelf &&
        eventPayload.type === EventTypes.SPOTLIGHT_INSTRUCTOR
      ) {
        eventPayload?.spotlightInstructor
          ? setDisableSpotlightButton(true)
          : setDisableSpotlightButton(false);
      }
    }
  }, [events]);

  return (
    <div className="video__wrapper">
      {isVideoEnabled ? (
        // Player for camera stream or video
        <Fragment>{children}</Fragment>
      ) : (
        // Fall back video placeholder with initials
        <div
          className={classnames("video__tile", {
            "video__tile-featured": speaking,
            "video__tile-instructor": videoType === VideoType.INSTRUCTOR,
            "video__tile-hand-raised":
              videoType !== VideoType.INSTRUCTOR && handRaised,
          })}
        >
          <div className="video__tile-initials">
            {iconHex && (
              <AttendeeInitials
                iconColor={iconHex}
                name={name}
                superscript={
                  softBanned &&
                  isInstructorOrModerator && (
                    <Icon
                      name="slash"
                      toolTip="none"
                      desc={`${name} is soft banned: Muted microphone, disabled camera, and blocked messages`}
                      height="16px"
                      fill={variables.white}
                      stroke={variables.actionRed}
                    />
                  )
                }
              />
            )}
          </div>
          <div className="video__tile-header">
            <header className="ch-nameplate --name">
              <p>{videoType === VideoType.USER ? "You" : name}</p>
            </header>
            {videoType === VideoType.STUDENT && (
              <header className="ch-nameplate --icon">
                {isInstructorOrModerator ? (
                  <IconButton
                    iconName={muted ? "mic-off" : "mic"}
                    onClick={handleMute}
                    desc={muted ? `${name} is muted` : `Mute ${name}`}
                    toolTip={muted ? "none" : "top"}
                    stroke={muted ? variables.white : variables.actionGreen}
                    btnId="mute-mic-button"
                    size="0.875rem"
                    hoverState={!muted}
                  />
                ) : (
                  <Icon
                    name={muted ? "mic-off" : "mic"}
                    desc={`${name} is ${muted ? "muted" : "unmuted"}`}
                    toolTip="none"
                    height="0.875rem"
                  />
                )}
              </header>
            )}
          </div>
        </div>
      )}

      {/* Active hand raise icon for student and user videos */}
      {(videoType === VideoType.STUDENT || videoType === VideoType.USER) &&
        handRaised && (
          <div className="video__wrapper--hand-raised">
            <IconButton
              size="38px"
              iconName="hand-raise-color"
              desc={
                isInstructorOrModerator
                  ? "Dismiss hand"
                  : `${name}'s hand is raised`
              }
              toolTip={isInstructorOrModerator ? "right" : "none"}
              fill="red"
              colorIcon={true}
              onClick={handleLowerHand}
              btnId="student-hand-raised-button"
            />
          </div>
        )}

      {/* Dismiss/Minimize button */}
      {(videoType === VideoType.INSTRUCTOR || videoType === VideoType.USER) && (
        <IconButton
          iconName="dismiss"
          desc="Hide video"
          toolTip={videoType === VideoType.USER ? "top" : "bottom"}
          onClick={() => {
            toggleShowVideo();
            handleManualSpotlight && handleManualSpotlight(false);
          }}
          className="videos__user-dismiss"
          size="20px"
          btnId="toggle-user-video-button"
          disabled={!dismissable}
        />
      )}

      {/* Dismiss/Minimize manual spotlight button for instructor video */}
      {!isInstructorOrModerator &&
        (videoType === VideoType.INSTRUCTOR ||
          videoType === VideoType.REPLAY) && (
          <IconButton
            iconName={
              state.manualSpotlight || state.spotlightInstructor
                ? "minimize-2"
                : "maximize-2"
            }
            desc={
              state.manualSpotlight || state.spotlightInstructor
                ? "Minimize video"
                : "Maximize video"
            }
            toolTip={"bottom"}
            onClick={() => {
              handleManualSpotlight &&
                handleManualSpotlight(
                  state.spotlightInstructor
                    ? !state.spotlightInstructor
                    : !state.manualSpotlight
                );
            }}
            className="videos__user-dismiss videos__user-dismiss--manual-spotlight"
            size="16px"
            btnId="manual-spotlight-button"
            disabled={disableSpotLightButton}
          />
        )}
    </div>
  );
};
