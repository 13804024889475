import React, { useReducer, createContext, useContext } from "react";

export interface ChatReplayMessage {
  message: string;
  senderName: string;
  color: string;
  messageId?: string;
}
export interface ReplayDataMessageStateContextType {
  updateReplayChatEvents: (event: ChatReplayMessage[]) => void;
  chatEvents: ChatReplayMessage[];
  messageCount: number;
}

enum DataMessagesActionType {
  UPDATE,
}

const ReplayDataMessageStateContext = createContext<
  ReplayDataMessageStateContextType | undefined
>(undefined);

export interface State {
  chatEvents: ChatReplayMessage[];
  messageCount: number;
}

interface AddAction {
  type: DataMessagesActionType.UPDATE;
  payload: ChatReplayMessage[];
}

type Action = AddAction;

const initialState: State = {
  chatEvents: [],
  messageCount: 0,
};

export function reducer(state: State, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case DataMessagesActionType.UPDATE:
      const messageCount = state.messageCount + 1;
      return { chatEvents: payload, messageCount };
    default:
      throw new Error("Incorrect action in ReplayDataEventsProvider reducer");
  }
}

export const ReplayDataMessageProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateReplayChatEvents = (event: ChatReplayMessage[]) => {
    dispatch({
      type: DataMessagesActionType.UPDATE,
      payload: event,
    });
  };

  const value = {
    updateReplayChatEvents,
    chatEvents: state.chatEvents,
    messageCount: state.messageCount,
  };

  return (
    <ReplayDataMessageStateContext.Provider value={value}>
      {children}
    </ReplayDataMessageStateContext.Provider>
  );
};

export const useReplayDataMessages = (): {
  updateReplayChatEvents: (event: ChatReplayMessage[]) => void;
  chatEvents: ChatReplayMessage[];
  messageCount: number;
} => {
  const context = useContext(ReplayDataMessageStateContext);

  if (!context) {
    throw new Error(
      "Use useReplayDataMessages hook inside useReplayDataMessagesProvider. Wrap useReplayDataMessagesProvider under MeetingProvider."
    );
  }

  return { ...context };
};
