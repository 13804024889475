import { useMutation } from "@apollo/client";
import classnames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageWrapper } from "../../components/pageWrapper";
import { adminRoutes, routes } from "../../constants";
import { useAuthContext } from "../../contexts/authContext";
import { CREATE_COURSE } from "../../graphql/mutations";
import { Mutation } from "../../types";
import { CourseForm } from "../../components/courseForm/courseForm";

export const CreateCourse: React.FC = () => {
  const navigate = useNavigate();
  const pageTitle = "Create new course";
  const { isAuthenticated } = useAuthContext();

  const [createCourseMutation, mutationResult] = useMutation(CREATE_COURSE, {
    onCompleted(data: Pick<Mutation, "createCourse">) {
      const course = data.createCourse;
      // save course and meeting data in the router history
      // redirect to /admin/courses/course-slug
      setTimeout(() => {
        navigate(`/${routes.admin}/${adminRoutes.course}/${course.slug}`, {
          state: {
            course,
          },
        });
      }, 300);
    },
  });

  return (
    <PageWrapper className="admin">
      <div className="admin-content">
        <h1>{pageTitle}</h1>
        <nav className="admin-subpage-nav">
          <a
            className="a-basic-link"
            onClick={(e) =>
              navigate(
                isAuthenticated
                  ? `/${routes.admin}`
                  : `/${routes.admin}/${adminRoutes.logIn}`
              )
            }
          >
            Dashboard
          </a>{" "}
          →{" "}
          <a
            className={classnames(`a-basic-link`, {
              "--active": location.pathname.includes("/create-course"),
            })}
          >
            {pageTitle}
          </a>
        </nav>
        <CourseForm
          onSubmit={createCourseMutation}
          mutationResult={mutationResult}
          createMode={true}
          meetings={[]}
        />
      </div>
    </PageWrapper>
  );
};
