import React from "react";
import { useAppContext } from "../../../contexts/appContext";
import { useDataEvents } from "../../../providers/dataEvents";
import { EventTypes } from "../../../providers/types";
import { IconButton } from "../../buttons/iconButton";

type VideoOffAllButtonProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  onClick?: (e) => void;
};

export const VideoOffAllButton: React.FC<VideoOffAllButtonProps> = ({
  toolTip,
  onClick,
}) => {
  const { sendEvent } = useDataEvents();
  const { isInstructorOrModerator, state } = useAppContext();

  const videoOffAllAction = (e) => {
    sendEvent(
      {
        type: EventTypes.VIDEO_OFF_ALL,
        leader: isInstructorOrModerator,
      },
      state
    );
    onClick(e);
  };

  return (
    <IconButton
      onClick={videoOffAllAction}
      iconName="video-off-all"
      desc="Turn all student videos off"
      toolTip={toolTip}
      size="28px"
    />
  );
};
