import React, { useEffect, useState } from "react";
import { IconButton } from "../../buttons/iconButton";
import { Button, PRIMARY, SMALL } from "../../buttons/button";

type FullScreenToggleProps = {
  component: "BUTTON" | "ICON_BUTTON";
  toolTip?: "top" | "bottom" | "right" | "left" | "none";
};

export const FullScreenToggle: React.FC<FullScreenToggleProps> = ({
  component,
  toolTip = "left",
}) => {
  const [isFull, setIsFull] = useState(document.fullscreenElement !== null);
  const setFullScreen = () => {
    let elem = document?.documentElement;

    if (!isFull) {
      if (elem?.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      if (document?.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    const isInFullScreen = () => document.fullscreenElement !== null;
    const checkFullScreen = () => {
      if (isInFullScreen()) {
        // The browser entered full-screen mode
        setIsFull(true);
      } else {
        // The browser exited full-screen mode
        setIsFull(false);
      }
    };

    // Listen for changes in full-screen mode to show the correct svgs
    // this could be done just with the clicks but escape key also exits full screen
    document.addEventListener("fullscreenchange", checkFullScreen);
    return function () {
      document.removeEventListener("fullscreenchange", checkFullScreen);
    };
  }, []);

  return component === "ICON_BUTTON" ? (
    <IconButton
      desc={isFull ? "Exit full screen" : "Enter full screen"}
      size={SMALL}
      disabled={false}
      onClick={() => {
        setFullScreen();
      }}
      iconName={isFull ? "full-screen-off" : "full-screen-on"}
      toolTip={toolTip}
      btnId="full-screen-toggle-button"
    />
  ) : (
    <Button
      btnType={PRIMARY}
      text={isFull ? "Exit full screen" : "Enter full screen"}
      size={SMALL}
      disabled={false}
      id="full-screen-toggle-button"
      onClick={() => {
        setFullScreen();
      }}
      icon={isFull ? "full-screen-off" : "full-screen-on"}
    />
  );
};
