import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  MicSelection,
  useLocalAudioInputActivityPreview,
  SpeakerSelection,
  useAudioOutputs,
  CameraSelection,
  useLocalAudioOutput,
  useToggleLocalMute,
  useAudioVideo,
  VideoTile,
  useLogger,
  useVideoInputs,
  useMeetingManager,
  useLocalVideo,
} from "amazon-chime-sdk-component-library-react";
import {
  DefaultAudioMixController,
  TimeoutScheduler,
} from "amazon-chime-sdk-js";
import { Button, PRIMARY, SECONDARY, SMALL, STANDARD } from "./buttons/button";
import { ModalHeader } from "./modal/modalHeader";
import { ModalBody } from "./modal/modalBody";
import { ModalFooter } from "./modal/modalFooter";
import { Switch } from "../components/inputs/switch";
import { DARK, DEFAULT, ThemeContext } from "../contexts/themeContext";
import { Icon } from "../components/icon";
import { isIOS, isMobileSafari } from "react-device-detect";
import { consoleNonProd } from "../utils/utilityBeltUtils";
import { useAppContext } from "../contexts/appContext";
import { MeetingRole } from "../contexts/types";

class TestSound {
  constructor(
    sinkId: string | null,
    frequency = 440,
    durationSec = 1,
    rampSec = 0.1,
    maxGainValue = 0.1
  ) {
    const audioContext: AudioContext = new (window.AudioContext ||
      // @ts-ignore
      window.webkitAudioContext)();
    const gainNode = audioContext.createGain();
    gainNode.gain.value = 0;
    const oscillatorNode = audioContext.createOscillator();
    oscillatorNode.frequency.value = frequency;
    oscillatorNode.connect(gainNode);
    const destinationStream = audioContext.createMediaStreamDestination();
    gainNode.connect(destinationStream);
    const { currentTime } = audioContext;
    const startTime = currentTime + 0.1;
    gainNode.gain.linearRampToValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(maxGainValue, startTime + rampSec);
    gainNode.gain.linearRampToValueAtTime(
      maxGainValue,
      startTime + rampSec + durationSec
    );
    gainNode.gain.linearRampToValueAtTime(
      0,
      startTime + rampSec * 2 + durationSec
    );
    oscillatorNode.start();
    const audioMixController = new DefaultAudioMixController();

    const handlingBindingAsynchronous = async () => {
      if ("setSinkId" in HTMLAudioElement.prototype) {
        try {
          // @ts-ignore
          await audioMixController.bindAudioDevice({ deviceId: sinkId });
        } catch (e) {
          consoleNonProd("Failed to bind audio device", e);
        }
      }

      try {
        // @ts-ignore
        await audioMixController.bindAudioElement(new Audio());
      } catch (e) {
        consoleNonProd("Failed to bind audio element", e);
      }
    };

    handlingBindingAsynchronous();
    audioMixController.bindAudioStream(destinationStream.stream);
    new TimeoutScheduler((rampSec * 2 + durationSec + 1) * 1000).start(() => {
      audioContext.close();
    });
  }
}

type DevicesProps = {
  toggleDeviceSettings: () => void;
};

type DevicesContentProps = {
  useAudio?: boolean;
};

/** Video Preview component
 * Adapted from https://github.com/aws/amazon-chime-sdk-component-library-react/blob/main/src/components/sdk/PreviewVideo/index.tsx
 */
const PreviewVideo: React.FC = () => {
  const logger = useLogger();
  const audioVideo = useAudioVideo();
  const { selectedDevice } = useVideoInputs();
  const videoEl = useRef<HTMLVideoElement>(null);
  const meetingManager = useMeetingManager();

  useEffect(() => {
    const videoElement = videoEl.current;
    return () => {
      if (videoElement) {
        audioVideo?.stopVideoPreviewForVideoInput(videoElement);
      }
    };
  }, [audioVideo]);

  useEffect(() => {
    async function startPreview(): Promise<void> {
      if (!audioVideo || !selectedDevice || !videoEl.current) {
        return;
      }

      try {
        await meetingManager.startVideoInputDevice(selectedDevice);
        audioVideo.startVideoPreviewForVideoInput(videoEl.current);
      } catch (error) {
        logger.error("Failed to start video preview");
      }
    }

    startPreview();
  }, [audioVideo, logger, meetingManager, selectedDevice]);

  return <VideoTile className="video-preview" ref={videoEl} />;
};

/** Content for the devices modal
 * @param {DevicesContentProps}
 * @prop {boolean} useAudio - Whether or not the component should use audio
 */
export const DevicesContent: React.FC<DevicesContentProps> = ({
  useAudio = true,
}) => {
  const activityBarRef = useRef<HTMLDivElement>();
  useLocalAudioInputActivityPreview(activityBarRef);

  const { selectedDevice } = useAudioOutputs();
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);
  const { muted, toggleMute } = useToggleLocalMute();
  const { isStudentAVMeeting, meetingRole } = useAppContext();
  const audioVideo = useAudioVideo();

  // For chatOnly students we want to hide most of the device component
  // to avoid the lobby button issue we ran into I'm checking the opposite of student
  // rather than the positive student
  const hideInChatOnly =
    !isStudentAVMeeting &&
    meetingRole !== MeetingRole.Presenter &&
    meetingRole !== MeetingRole.Moderator;

  const handleChange = (deviceId: string): void => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  useEffect(() => {
    // a fix for ios Safari lobby having audio
    if (!useAudio && isMobileSafari) {
      if (!muted) {
        toggleMute();
      }
      if (isAudioOn) {
        toggleAudio();
      }
    }
  }, [useAudio, muted, toggleMute, toggleAudio, isAudioOn]);

  useEffect(() => {
    if (!isAudioOn && useAudio) {
      toggleAudio();
    }
  }, [isAudioOn, toggleAudio, useAudio]);

  useEffect(() => {
    // this kicks off a new list devices call which according to AWS docs
    // "Once any list function is called, changes in device availability are broadcast to any registered DeviceChangeObserver."
    // which should update dropdowns
    async function getAVDevices() {
      await audioVideo?.listAudioInputDevices(true);
      await audioVideo?.listVideoInputDevices(true);
      if (isIOS) {
        await audioVideo?.startAudioInput(null);
      }
    }
    if (useAudio && !hideInChatOnly) {
      getAVDevices();
    }
  }, [useAudio, hideInChatOnly, audioVideo]);

  return (
    <div className="device-settings">
      <div className="device-settings__body">
        <div className="audio-container">
          <h2 className="device-settings__subheader">Audio</h2>
          {!hideInChatOnly && (
            <>
              <section className="device-settings__section">
                <label className="device-settings__label">
                  Microphone activity
                </label>
                <div className="mic-activity__container">
                  <div
                    ref={activityBarRef}
                    className="mic-activity__indicator"
                  />
                </div>
              </section>
              <section className="device-settings__section">
                <MicSelection />
              </section>
            </>
          )}
          <section className="device-settings__section">
            <SpeakerSelection onChange={handleChange} />
            <div style={{ width: "fit-content" }}>
              <Button
                btnType={SECONDARY}
                disabled={false}
                size={SMALL}
                text="Test speakers"
                onClick={handleTestSpeaker}
                id="test-speakers-button"
                inputClass="audio-check__toggle"
              />
            </div>
          </section>
        </div>
        {!hideInChatOnly && (
          <div className="video-container">
            <h2 className="device-settings__subheader">Video</h2>
            <section className="device-settings__section">
              <CameraSelection />
            </section>
            <section className="device-settings__section">
              <label className="device-settings__label">Video preview</label>
              <PreviewVideo />
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

export const Devices: React.FC<DevicesProps> = ({ toggleDeviceSettings }) => {
  const activityBarRef = useRef<HTMLDivElement>();
  useLocalAudioInputActivityPreview(activityBarRef);
  const { theme, setTheme } = useContext(ThemeContext);

  const closeModal = (): void => {
    toggleDeviceSettings();
  };

  return (
    <div className="device-settings">
      <ModalHeader>
        <div className="device-settings__top">
          <Fragment>
            <h1 className="device-settings__header">Settings</h1>

            <div className="nav__theme-toggle">
              <Icon name="sun" desc="Light theme" toolTip="none" />
              <Switch
                className="nav__switch"
                onClick={() => setTheme(theme === DARK ? DEFAULT : DARK)}
                isOn={theme === DEFAULT}
                id="switch-theme"
              />
              <Icon name="moon" desc="Dark theme" toolTip="none" />
            </div>
          </Fragment>
        </div>
      </ModalHeader>
      <ModalBody>
        <DevicesContent />
      </ModalBody>
      <ModalFooter>
        <section className="device-settings__section">
          <Button
            btnType={PRIMARY}
            disabled={false}
            size={STANDARD}
            text="Continue"
            onClick={closeModal}
            customWidth="300px"
            id="close-device-modal-button"
          />
        </section>
      </ModalFooter>
    </div>
  );
};
