import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Canvas from "../../components/canvas/canvas";
import { PageWrapper } from "../../components/pageWrapper";
import Spinner from "../../components/spinner";
import { useAppContext } from "../../contexts/appContext";
import { ActionType, CanvasMode } from "../../contexts/types";
import { GET_MEETING_BY_TITLE } from "../../graphql/queries";
import { Meeting, Query, QueryGetMeetingByTitleArgs } from "../../types";
import {
  consoleNonProd,
  populateMeetingIdFromURL,
} from "../../utils/utilityBeltUtils";
import { diffBoards } from "../../utils/editClassUtils";
import { useError } from "../../contexts/errorContext";

export const ViewClass: React.FC = () => {
  // get meetingId from the slug
  const path = useLocation().pathname;
  const meetingId = populateMeetingIdFromURL(path);
  const {
    setPresentationUpdated,
    setPresentationDiff,
    handleSetDbId,
    state,
    dispatch,
  } = useAppContext();
  const { showError } = useError();
  const [initialQuery, setInitialQuery] = useState(true);
  const [userUpdated, setUserUpdated] = useState(false);

  const { loading, error, refetch } = useQuery(GET_MEETING_BY_TITLE, {
    variables: {
      title: meetingId,
    } as QueryGetMeetingByTitleArgs,
    onCompleted(data: Pick<Query, "getMeetingByTitle">) {
      const meeting: Meeting = data.getMeetingByTitle;

      if (!userUpdated && !initialQuery) {
        // If the presentation was not updated by the current user, set presentation updated to true
        setPresentationDiff(diffBoards(state.slides, meeting.boards));
        setPresentationUpdated(true);
      } else {
        // Otherwise, switch the state back to false
        setUserUpdated(false);
      }

      dispatch({
        type: ActionType.HANDLE_GET_PRESENTATION,
        payload: {
          slides: meeting.boards,
          currentBoard: state.currentBoard
            ? state.currentBoard
            : meeting.boards[0],
        },
      });
      handleSetDbId(meeting.id);
      initialQuery && setInitialQuery(false);
    },
    onError(error) {
      consoleNonProd("Error fetching boards for meeting", meetingId, error);
      showError({
        error: "Something went wrong when fetching the board.",
      });
    },
  });

  /** Reset canvas state on unmount */
  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.SET_INIT_STATE,
      });
    };
  }, []);

  const handleRefetchBoards = () => {
    // Set that the current user has made this update
    setUserUpdated(true);
    refetch({ title: meetingId });
  };

  return (
    <PageWrapper className="admin" navBar={true}>
      {loading ? (
        <Spinner />
      ) : (
        <Canvas
          mode={CanvasMode.VIEW_ONLY}
          handleRefetchBoards={handleRefetchBoards}
          setShowChapterList={() => {
            consoleNonProd("view show chapter list");
          }}
        />
      )}
    </PageWrapper>
  );
};
