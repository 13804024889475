import React, { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import {
  CanvasDataEvent,
  EventPayload,
  EventTypes,
} from "../../providers/types";
import ReactPannellum, {
  getConfig,
  getViewer,
  lookAt,
  addScene,
  loadScene,
  removeScene,
  getPitch,
  getYaw,
  getHfov,
  mouseEventToCoords,
} from "react-pannellum";
import { VALID_KEYS } from "../../constants";
import {
  exploreToggleEventCheck,
  isPanoViewEventCheck,
} from "../../utils/eventUtils";
import { useAppContext } from "../../contexts/appContext";
import { CanvasMode, State } from "../../contexts/types";

type PanoramaViewerType = {
  sourceUrl?: string;
  top: string;
  bottom: string;
  left: string;
  right: string;
  front: string;
  back: string;
  leader?: boolean;
  mode: CanvasMode;
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  events?: CanvasDataEvent[];
};

export const PanoramaViewer: React.FC<PanoramaViewerType> = ({
  front,
  back,
  left,
  right,
  top,
  bottom,
  leader,
  mode,
  sendEvent,
  events,
}) => {
  const panImage: ReactPannellum = useRef(getViewer());
  const { height } = useWindowDimensions();
  const { state } = useAppContext();
  const [panoCubeMap, setPanoCubeMap] = useState<string[]>([
    front,
    right,
    back,
    left,
    top,
    bottom,
  ]);
  let drag = useRef(false);
  let timer = useRef(null);
  const containerId = "pano-viewer";
  const config = {
    showZoomCtrl: false,
    keyboardZoom: false,
    mouseZoom: false,
    draggable: leader || state.exploreMode,
    doubleClickZoom: false,
    showFullscreenCtrl: false,
    autoLoad: true,
    cubeMap: panoCubeMap,
    sceneId: "panoScene",
    type: "cubemap",
    uiText: {
      loadButtonLabel: "Click to<br>Load<br>Panorama",
      loadingLabel: "Loading...",
      bylineLabel: "by %s",
      noPanoramaError: "Oh no, no panorama image was specified.",
      fileAccessError: "Oh no, something went wrong accessing this panorama",
      malformedURLError:
        "Oh no, there is something wrong with the panorama URL.",
      iOS8WebGLError:
        "Due to iOS 8's broken WebGL implementation, only progressive encoded JPEGs work for your device (this panorama uses standard encoding).",
      genericWebGLError:
        "Oh no, your browser does not have the necessary WebGL support to display this panorama.",
      textureSizeError:
        "This panorama is too big for your device! It's %spx wide, but your device only supports images up to %spx wide. Try another device. (If you're the author, try scaling down the image.)",
      unknownError: "Unknown error. Check developer console.",
    },
  };

  useEffect(() => {
    const parentEl = document.getElementById(containerId);

    if (getConfig() && !leader) {
      getConfig().draggable = state.exploreMode;
      // commented out but if we ever want to set zoom-a-bility
      // getConfig().keyboardZoom = exploreMode;
      // getConfig().mouseZoom = exploreMode;
    }

    const handleMouseUp = (leader: boolean) => {
      if (leader || state.exploreMode) {
        parentEl?.addEventListener("mousedown", mouseDownAction);
        parentEl?.addEventListener("mousemove", mouseMoveAction);
        //parentEl?.addEventListener("wheel", wheelStop);
        parentEl?.addEventListener("mouseup", mouseUpAction);
        parentEl?.addEventListener("keyup", keyAction);
      }
    };

    const sendPanoView = (
      pitch: number,
      yaw: number,
      zoom: number,
      speed: number,
      leader: boolean,
      exploreMode: boolean
    ) => {
      if (sendEvent && leader && !exploreMode) {
        sendEvent(
          {
            type: EventTypes.PANORAMA_CLICK,
            leader: leader,
            panoramaView: {
              p: pitch,
              y: yaw,
              z: zoom,
              s: speed,
            },
          },
          state
        );
      }
    };

    function mouseDownAction() {
      drag.current = false;
    }

    function mouseMoveAction() {
      drag.current = true;
    }

    function keyAction(keyEvent: KeyboardEvent) {
      // the timeout here sets the debounce for the keys so if someone
      // keeps pressing left to go left, we will only send the last one to grab the current view
      // in half second intervals
      if (VALID_KEYS.indexOf(keyEvent.key) > -1) {
        if (timer !== null) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(function () {
          if (leader || state.exploreMode) {
            let p = getPitch();
            let y = getYaw();
            let z = getHfov();
            sendPanoView(p, y, z, 1000, leader, state.exploreMode);
          }
        }, 500);
      }
    }
    // currently don't need this but it is a two finger zoom / mouse wheel zoom event sender if we implement that
    function wheelStop() {
      if (timer !== null) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(function () {
        if (leader || state.exploreMode) {
          let p = getPitch();
          let y = getYaw();
          let z = getHfov();
          sendPanoView(p, y, z, 1000, leader, state.exploreMode);
        }
      }, 300);
    }

    function mouseUpAction(evt) {
      if (leader || state.exploreMode) {
        if (drag.current) {
          let p = getPitch();
          let y = getYaw();
          let z = getHfov();
          lookAt(p, y, z, 1000);
          sendPanoView(p, y, z, 1000, leader, state.exploreMode);
        } else {
          let p = mouseEventToCoords(evt)[0];
          let y = mouseEventToCoords(evt)[1];
          let z = getHfov();
          lookAt(p, y, z, 1000);
          sendPanoView(p, y, z, 1000, leader, state.exploreMode);
        }
      }
    }
    handleMouseUp(leader);
    return () => {
      parentEl?.removeEventListener("mouseup", mouseUpAction);
      parentEl?.removeEventListener("mousedown", mouseDownAction);
      parentEl?.removeEventListener("mousemove", mouseMoveAction);
      parentEl?.removeEventListener("wheel", wheelStop);
      parentEl?.removeEventListener("keyup", keyAction);
    };
  }, [panImage, leader, state.exploreMode]);

  useEffect(() => {
    if (events?.length) {
      const canvasDataEv = events[events.length - 1];
      const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
      if (
        sendEvent &&
        leader &&
        exploreToggleEventCheck(eventPayload).isExploreEvent &&
        !exploreToggleEventCheck(eventPayload).exploreModeValue &&
        canvasDataEv.isSelf
      ) {
        let p = getPitch();
        let y = getYaw();
        let z = getHfov();
        sendEvent(
          {
            type: EventTypes.PANORAMA_CLICK,
            leader: leader,
            panoramaView: {
              p: p,
              y: y,
              z: z,
              s: 1000,
            },
          },
          state
        );
        return;
      }

      // on replay we don't have an instructor to ask for their view so
      // we have to cycle back through the events to find one that has
      // pano view attached to it and then we set our replay view to that
      if (
        mode === CanvasMode.REPLAY &&
        exploreToggleEventCheck(eventPayload).isExploreEvent &&
        !exploreToggleEventCheck(eventPayload).exploreModeValue
      ) {
        for (let i = events.length - 1; i >= 0; i--) {
          const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
          if (eventPayload.type === EventTypes.PANORAMA_CLICK) {
            const newView = {
              p: eventPayload.panoramaView.p,
              y: eventPayload.panoramaView.y,
              z: eventPayload.panoramaView.z,
              s: eventPayload.panoramaView.s,
            };
            lookAt(newView.p, newView.y, newView.z, newView.s);
            break;
          }
        }
        return;
      }

      if (isPanoViewEventCheck(eventPayload)) {
        if (!canvasDataEv.isSelf && eventPayload && !state.exploreMode) {
          // if (!leader) { if we want to not send events to other instuctors turn this back on
          switch (eventPayload.type) {
            case EventTypes.PANORAMA_CLICK:
              const newView = {
                p: eventPayload.panoramaView.p,
                y: eventPayload.panoramaView.y,
                z: eventPayload.panoramaView.z,
                s: eventPayload.panoramaView.s,
              };
              lookAt(newView.p, newView.y, newView.z, newView.s);

              break;
            //  }
          }
        }
      }
    }
  }, [events, leader, state.exploreMode]);

  useEffect(() => {
    // we're just telling Pannellum if a new cubemap front
    // (no need to check for all of the sides because if front changes they all change)
    // has shown up, if it has replace the current scene with the new cubemap.
    // Why the timeout? it just has to be there or else the new
    // map doesn't pickup. Who knows...
    const oldFront = panoCubeMap[0];
    if (oldFront !== front) {
      const newCubeMap = [front, right, back, left, top, bottom];
      const newConfig = {
        ...config,
        cubeMap: newCubeMap,
      };
      addScene("panoScene", newConfig);
      setTimeout(() => {
        loadScene("panoScene");
        setPanoCubeMap(newCubeMap);
      }, 50);
      return () => {
        removeScene("panoSceneId");
      };
    }
  }, [front]);

  // This useEffect hook will handle the component mount and unmount
  useEffect(() => {
    // Function to clean up the Pannellum instance and release WebGL context
    const cleanUp = () => {
      if (panImage.current) {
        getViewer()?.destroy();
      }
    };

    // Call the cleanup function when the component is unmounted
    return () => {
      cleanUp();
    };
  }, []);

  return (
    <div style={{ height }} className="panorama">
      {front && (
        <ReactPannellum
          ref={panImage}
          type="cubemap"
          cubeMap={panoCubeMap}
          id={containerId}
          sceneId="panoScene"
          config={config}
          style={{
            width: "auto",
            height: height,
            background: "#010101",
          }}
        />
      )}
    </div>
  );
};
