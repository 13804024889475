type UseFocusCheck = {
  playerHasFocus: () => boolean;
};

const playerHasFocus = (): boolean => {
  const videoPlayerToolBar: HTMLElement = document?.getElementById(
    "video-controls-tools"
  );
  const focusedElement = document?.activeElement;

  // Check if the focused element is within the control panel or its children
  if (videoPlayerToolBar?.contains(focusedElement)) {
    return true;
  } else return false;
};

// hook to use in components
export const useFocusCheck = (): UseFocusCheck => {
  return {
    playerHasFocus,
  };
};
