import React, { Fragment, useState } from "react";
import { Button, PRIMARY, SECONDARY, STANDARD } from "./buttons/button";
import { Icon } from "./icon";
import classnames from "classnames";
import { ModalFooter } from "./modal/modalFooter";
import { ModalHeader } from "./modal/modalHeader";
import { ModalBody } from "./modal/modalBody";
import { useScreenContext } from "../contexts/screenContext";
import SimpleBar from "simplebar-react";
import { useAppContext } from "../contexts/appContext";
import { Toggle } from "./inputs/toggle";
import { consoleNonProd } from "../utils/utilityBeltUtils";
import { REPLAY_IDLE_TIMEOUT } from "../constants";
import { tutorialLink } from "../constants";

type InstructionsProps = {
  handleContinue?: (e) => void;
  lobby?: boolean;
  isReplay?: boolean;
};

type InstructionsContentProps = {
  showKeyBoardControls: boolean;
};

type ReplayInstructionsContentProps = {};

export const InstructionsContent: React.FC<InstructionsContentProps> = ({
  showKeyBoardControls,
}) => {
  const { useMobileTools, orientation } = useScreenContext();
  const trackMobileHeader = "If using a trackpad or touch screen…";
  const trackMobileMove = useMobileTools
    ? "Slide one finger to move around"
    : '"Click" and slide one finger to move around';
  const trackMobileMoveIcon = "mobileMove";
  const trackMobileIconSize = useMobileTools ? "100px" : "75px";
  const trackMobileZoom = useMobileTools
    ? "Pinch to zoom in or out"
    : "Pinch to zoom or scroll to zoom";

  return (
    <div className="instructions-content__wrapper">
      {!showKeyBoardControls ? (
        <div className="instructions-content">
          {!useMobileTools && (
            <>
              <div className="instructions-content__instructions-column">
                <h3>If using a mouse...</h3>
                <div
                  className={classnames(
                    "instructions-content__commands-wrapper",
                    {
                      "--landscape":
                        useMobileTools && orientation === "landscape",
                    }
                  )}
                >
                  <div className="instructions-content__instructions-commands">
                    <Icon
                      name="mouseMove"
                      desc="Mouse move instructions"
                      height="75px"
                      toolTip="none"
                      stroke=""
                      className="__instructions-icons"
                      hoverState={false}
                    />
                    <span className="instructions-content__instruction-text">
                      Click the left mouse button and drag to move around
                    </span>
                  </div>
                  <div className="instructions-content__instructions-commands">
                    <Icon
                      name="mouseZoom"
                      desc="Mouse zoom instructions"
                      toolTip="none"
                      height="75px"
                      stroke=""
                      className="instructions-content__instructions-icons"
                      hoverState={false}
                    />
                    <span className="instructions-content__instruction-text">
                      Scroll the mouse wheel to zoom
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ width: 0, borderRight: "1px solid lightgray" }} />
            </>
          )}
          <div
            className={classnames("instructions-content__instructions-column", {
              mobile: useMobileTools,
            })}
          >
            {!useMobileTools && <h3>{trackMobileHeader}</h3>}
            <div
              className={classnames("instructions-content__commands-wrapper", {
                "--landscape": useMobileTools && orientation === "landscape",
              })}
            >
              <div className="instructions-content__instructions-commands">
                <Icon
                  name={trackMobileMoveIcon}
                  desc="Trackpad move instructions"
                  toolTip="none"
                  height={trackMobileIconSize}
                  stroke=""
                  className="__instructions-icons"
                  hoverState={false}
                />
                <span className="instructions-content__instruction-text">
                  {trackMobileMove}
                </span>
              </div>
              <div className="instructions-content__instructions-commands">
                <Icon
                  name="trackpadZoom"
                  desc="Trackpad zoom instructions"
                  toolTip="none"
                  height={trackMobileIconSize}
                  stroke=""
                  className="__instructions-icons"
                  hoverState={false}
                />
                <span className="instructions-content__instruction-text">
                  {trackMobileZoom}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="instructions-content__keyboard">
          <h3>If using your keyboard...</h3>
          <span className="instructions-content__keyboard--instructions">
            <span className="instructions-content__keyboard--keypress">
              &quot;w&quot; or up arrow
            </span>
            &nbsp;- Move your view of the image up
          </span>
          <span className="instructions-content__keyboard--instructions">
            <span className="instructions-content__keyboard--keypress">
              &quot;s&quot; or down arrow
            </span>
            &nbsp;- Move your view of the image down
          </span>
          <span className="instructions-content__keyboard--instructions">
            <span className="instructions-content__keyboard--keypress">
              &quot;a&quot; or left arrow
            </span>
            &nbsp;- Move your view of the image left
          </span>
          <span className="instructions-content__keyboard--instructions">
            <span className="instructions-content__keyboard--keypress">
              &quot;d&quot; or right arrow
            </span>
            &nbsp;- Move your view of the image right
          </span>
          <span className="instructions-content__keyboard--instructions">
            <span className="instructions-content__keyboard--keypress">
              &quot;+&quot;
            </span>
            &nbsp;- Zoom in
          </span>
          <span className="instructions-content__keyboard--instructions">
            <span className="instructions-content__keyboard--keypress">
              &quot;-&quot;
            </span>
            &nbsp;- Zoom out
          </span>
          <span className="instructions-content__keyboard--instructions">
            <span className="instructions-content__keyboard--keypress">
              &quot;0&quot;
            </span>
            &nbsp;- Fit image(s) to screen
          </span>
        </div>
      )}
    </div>
  );
};

export const ReplayInstructions: React.FC<
  ReplayInstructionsContentProps
> = ({}) => {
  const { state } = useAppContext();
  const { useMobileTools } = useScreenContext();

  const exploreInstructions = (
    <p>
      The class is currently in
      <strong> Explore</strong> mode. <strong>On demand</strong> classes allows
      you to explore whenever you want, but while the class is in{" "}
      <strong>Explore</strong> mode you can explore like normal. This does not
      require “unfollowing” the instructor.
    </p>
  );

  const focusInstructions = (
    <p>
      The class is currently in <strong>Focus</strong> mode.{" "}
      <strong>On demand</strong> classes allow you to explore whenever you want,
      even if the class is in <strong>Focus</strong>. This requires
      <strong>“unfollowing”</strong> the instructor.
      <br />
      <br />
      To <strong>unfollow</strong> the instructor...
    </p>
  );

  return (
    <Fragment>
      <SimpleBar
        forceVisible="y"
        autoHide={true}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div className="instructions-subheading">
          {state.exploreMode ? exploreInstructions : focusInstructions}
        </div>
        <div className="instructions-body">
          {!state.exploreMode && (
            <>
              <p>
                Use your trackpad, mouse, or keyboard controls to interact with
                the canvas...
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                {useMobileTools ? (
                  <>
                    <Icon
                      name={"mobileMove"}
                      desc="Trackpad move instructions"
                      toolTip="none"
                      height="75px"
                      stroke=""
                      className="__instructions-icons"
                      hoverState={false}
                    />
                    <Icon
                      name="trackpadZoom"
                      desc="Trackpad zoom instructions"
                      toolTip="none"
                      height="75px"
                      stroke=""
                      className="__instructions-icons"
                      hoverState={false}
                    />
                  </>
                ) : (
                  <>
                    <Icon
                      name="mouseMove"
                      desc="Mouse move instructions"
                      height="75px"
                      toolTip="none"
                      stroke=""
                      className="__instructions-icons"
                      hoverState={false}
                    />
                    <Icon
                      name="mouseZoom"
                      desc="Mouse zoom instructions"
                      toolTip="none"
                      height="75px"
                      stroke=""
                      className="instructions-content__instructions-icons"
                      hoverState={false}
                    />
                  </>
                )}
              </div>

              <p>
                ...or click the toggle to enter <strong>Explore</strong> mode
              </p>
              <Toggle
                option1="Explore"
                option2="Focus"
                name="focus-explore-toggle-inst"
                onChange={() => consoleNonProd("toggle!")}
                icon1="focus"
                icon2="explore"
                disabled={state.exploreMode && !state.replayExplore}
                checked={state.exploreMode}
              />
            </>
          )}

          {state.exploreMode ? (
            <p>
              The class is currently in <strong>Explore</strong> mode. You are
              “following” the class&apos;s mode, so if the instructor switches
              the class to <strong>Focus</strong>, you will too.
            </p>
          ) : (
            <p>
              To <strong>“refollow”</strong> the class&apos;s mode and enter
              <strong> Focus</strong> mode, <br /> click the toggle, or wait{" "}
              {REPLAY_IDLE_TIMEOUT} seconds and you&apos;ll automatically{" "}
              <strong>“refollow”</strong> the class.
            </p>
          )}
        </div>
      </SimpleBar>
    </Fragment>
  );
};

export const Instructions: React.FC<InstructionsProps> = ({
  handleContinue,
  lobby,
  isReplay,
}) => {
  const { firstName, state } = useAppContext();
  const [keyBoardControls, setKeyBoardControls] = useState<boolean>(false);
  const { useMobileTools } = useScreenContext();
  const welcomeOrKeyboardLine1 = "Welcome to online classes at the Barnes. ";
  const welcomeOrKeyboardLine2 = "Let's begin with the basics.";
  const keyBoardButtonText = !keyBoardControls
    ? "Keyboard controls"
    : "Mouse controls";
  const HeaderText = isReplay
    ? "Welcome to on demand classes at the Barnes!"
    : "Hi " + firstName + "!";

  const [initialContent, setInitialContent] = useState(true);

  const keyboardCycle = () => {
    setKeyBoardControls(!keyBoardControls);
  };

  const basicFocusExploreExplainer = state.exploreMode ? (
    <p>
      You are currently in <strong>Explore</strong> mode. This means you have
      the ability to independently navigate the board and view the content
      displayed using the controls outlined below. The presenter will change the
      mode to <strong>Focus</strong> when they want to guide you through the
      content on the board.
    </p>
  ) : (
    <p>
      You are currently in <strong>Focus</strong> mode. This means the presenter
      is guiding you through the content on the board. The presenter will change
      the mode to <strong>Explore</strong> when they want you to have the
      ability to independently navigate the board and view the content displayed
      using the controls outlined below.
    </p>
  );

  return (
    <Fragment>
      <div className="instructions-container">
        {!lobby && (
          <ModalHeader>
            <h1
              className={classnames("instructions-heading", {
                "replay-instructions-heading": isReplay,
              })}
            >
              {HeaderText}
            </h1>
          </ModalHeader>
        )}
        <ModalBody>
          <SimpleBar
            forceVisible="y"
            autoHide={true}
            style={{
              maxHeight: "100%",
              width: "100%",
              height: "100%",
            }}
          >
            {!lobby && (
              <div
                className={classnames("instructions-subheading", {
                  "instructions-subheading__no-display":
                    !initialContent && basicFocusExploreExplainer,
                })}
              >
                <h3>
                  {!isReplay && welcomeOrKeyboardLine1}
                  {useMobileTools && <br />}
                  {!isReplay && welcomeOrKeyboardLine2}
                </h3>
                {initialContent && basicFocusExploreExplainer}
              </div>
            )}
            {initialContent ? (
              <InstructionsContent showKeyBoardControls={keyBoardControls} />
            ) : (
              <ReplayInstructions />
            )}
          </SimpleBar>
        </ModalBody>
        <ModalFooter>
          {!useMobileTools && (
            <div className="instructions-footer__buttons">
              <Button
                btnType={SECONDARY}
                text={
                  isReplay && !initialContent
                    ? "Back"
                    : isReplay && initialContent
                    ? keyBoardButtonText
                    : keyBoardButtonText
                }
                size={STANDARD}
                disabled={false}
                inputClass="instructions-secondary"
                onClick={() =>
                  isReplay && !initialContent
                    ? setInitialContent(true)
                    : keyboardCycle()
                }
                id="switch-instructors-button"
                customWidth="200px"
              />
            </div>
          )}
          {handleContinue && (
            <div
              className={classnames("instructions-footer__buttons", {
                "--single-button": useMobileTools,
              })}
            >
              <Button
                btnType={PRIMARY}
                text={
                  isReplay && initialContent ? "Continue" : "Continue to class"
                }
                size={STANDARD}
                disabled={false}
                onClick={(e) => {
                  isReplay && initialContent
                    ? setInitialContent(!initialContent)
                    : handleContinue(e);
                }}
                id="instructions-continue-button"
                customWidth="200px"
              />
            </div>
          )}
          <div className="instructions-footer__skip-to-class">
            {!useMobileTools && (
              <a onClick={handleContinue}>Skip and continue to class</a>
            )}
            {isReplay && !useMobileTools && (
              <>
                <br /> Need more guidance?{" "}
                <a onClick={() => window.open(tutorialLink, "_blank")}>
                  Try the interactive tutorial.
                </a>
              </>
            )}
          </div>
        </ModalFooter>
      </div>
    </Fragment>
  );
};
