import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Canvas from "../components/canvas/canvas";
import { PageWrapper } from "../components/pageWrapper";
import Spinner from "../components/spinner";
import { useAppContext } from "../contexts/appContext";
import { ActionType, CanvasMode } from "../contexts/types";
import { GET_MEETING_BY_TITLE } from "../graphql/queries";
import { Meeting, Query, QueryGetMeetingByTitleArgs } from "../types";
import {
  consoleNonProd,
  populateMeetingIdFromURL,
} from "../utils/utilityBeltUtils";
import { useError } from "../contexts/errorContext";

export const ViewPresentation: React.FC = () => {
  // get meetingId from the slug
  const path = useLocation().pathname;
  const meetingId = populateMeetingIdFromURL(path);
  const { handleSetDbId, state, dispatch } = useAppContext();
  const { showError } = useError();
  const [initialQuery, setInitialQuery] = useState(true);

  const { loading, error, refetch } = useQuery(GET_MEETING_BY_TITLE, {
    variables: {
      title: meetingId,
    } as QueryGetMeetingByTitleArgs,
    onCompleted(data: Pick<Query, "getMeetingByTitle">) {
      const meeting: Meeting = data.getMeetingByTitle;

      dispatch({
        type: ActionType.HANDLE_GET_PRESENTATION,
        payload: {
          slides: meeting.boards,
          currentBoard: state.currentBoard
            ? state.currentBoard
            : meeting.boards[0],
        },
      });
      handleSetDbId(meeting.id);
      initialQuery && setInitialQuery(false);
    },
    onError(error) {
      consoleNonProd("Error fetching boards for meeting", meetingId, error);
      showError({
        error: "Something went wrong when fetching the board.",
      });
    },
  });

  /** Reset canvas state on unmount */
  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.SET_INIT_STATE,
      });
    };
  }, []);

  const handleRefetchBoards = (newSelectedBoardNumber?: number) => {
    // Set that the current user has made this update
    refetch({ title: meetingId }).then((data) => {
      if (newSelectedBoardNumber && data?.data?.getMeetingByTitle) {
        dispatch({
          type: ActionType.UPDATE_CURRENT_BOARD,
          payload: {
            currentBoard:
              data.data.getMeetingByTitle.boards[newSelectedBoardNumber - 1],
          },
        });
      }
    });
  };

  return (
    <PageWrapper className="view-presentation" navBar={false}>
      {loading ? (
        <Spinner />
      ) : (
        <Canvas
          mode={CanvasMode.VIEW_ONLY}
          handleRefetchBoards={handleRefetchBoards}
          showEditTools={false}
        />
      )}
    </PageWrapper>
  );
};
