import React from "react";
import classNames from "classnames";
import { formatReplayTime } from "../../utils/timeUtiles";

type TimeDisplayProps = {
  currentTime: number;
  maxTime: number;
};

export const TimeDisplay: React.FC<TimeDisplayProps> = ({
  currentTime,
  maxTime,
}) => {
  const currentTimeStr = formatReplayTime(currentTime);
  const currentTimeStrLen = currentTimeStr.length;
  const maxTimeStr = formatReplayTime(maxTime);
  const maxTimeStrLen = maxTimeStr.length;

  return (
    <div className="replay-tools__time-display">
      <span>
        <span
          className={classNames("replay-tools__time-display__current", {
            minutes: currentTimeStrLen > 3 && currentTimeStrLen < 6,
            hours: currentTimeStrLen > 6,
          })}
        >
          {currentTimeStr}
        </span>
        {Boolean(maxTime) && (
          <span className="replay-tools__time-display__divider"> / </span>
        )}
      </span>
      {Boolean(maxTime) && (
        <span
          className={classNames("replay-tools__time-display__max", {
            minutes: maxTimeStrLen > 3 && maxTimeStrLen < 6,
            hours: maxTimeStrLen > 6,
          })}
        >
          {maxTimeStr}
        </span>
      )}
    </div>
  );
};
