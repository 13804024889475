import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CourseForm } from "../../components/courseForm/courseForm";
import { PageWrapper } from "../../components/pageWrapper";
import Spinner from "../../components/spinner";
import { EDIT_COURSE } from "../../graphql/mutations";
import { GET_COURSE_BY_SLUG } from "../../graphql/queries";
import { Course as CourseType, Meeting, Mutation, Query } from "../../types";
import { populateMeetingIdFromURL } from "../../utils/utilityBeltUtils";
import { useAuthContext } from "../../contexts/authContext";
import { adminRoutes, routes } from "../../constants";
import classnames from "classnames";
import { Container } from "../../components/container";

export const Course: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const courseSlug = populateMeetingIdFromURL(location.pathname);
  const courseData: CourseType = location.state && location.state["course"];
  const { isAuthenticated } = useAuthContext();

  const [name, setName] = useState<string>();
  const [slug, setSlug] = useState<string>();
  const [id, setId] = useState<number>();
  const [meetings, setMeetings] = useState<Meeting[]>();

  const [getCourseBySlug, { loading }] = useLazyQuery(GET_COURSE_BY_SLUG, {
    // the individual course calls where being cached and we were missing the course info if you
    // visited the same course from the home screen twice
    fetchPolicy: "no-cache",
    onCompleted(data: Pick<Query, "getCourseBySlug">) {
      const course = data.getCourseBySlug;
      handleSetCourseInfo(course);
      // TODO: handle what happens when course data is not returned from the server
    },
  });

  const [editCourseMutation, mutationResult] = useMutation(EDIT_COURSE, {
    onCompleted(data: Pick<Mutation, "editCourse">) {
      const course = data.editCourse;
      setId(course.id);
      setName(course.name);
      setSlug(course.slug);
      setMeetings(course.meetings);

      // Update the location state, test to not use location
      setTimeout(() => {
        getCourseBySlug({
          variables: { slug: courseSlug },
        });
      }, 100);
    },
  });

  const handleSetCourseInfo = (course: CourseType) => {
    setId(course.id);
    setName(course.name);
    setSlug(course.slug);
    setMeetings(course.meetings);

    // Update the location state
    setTimeout(() => {
      navigate(`/${routes.admin}/${adminRoutes.course}/${course.slug}`, {
        replace: true,
        state: { course },
      });
    }, 100);
  };

  // Replaces the meeting info for a single meeting
  const handleSetMeetingInfo = (meeting: Meeting) => {
    const tmpMeetings = [];

    for (const m of meetings) {
      if (m.id === meeting.id) {
        tmpMeetings.push(meeting);
      } else {
        tmpMeetings.push(m);
      }
    }

    setMeetings(tmpMeetings);
  };

  useEffect(() => {
    if (courseData || (id && name && slug)) {
      setId(courseData.id);
      setName(courseData.name);
      setSlug(courseData.slug);
      setMeetings(courseData.meetings);
    } else {
      // run mutation for fetching course data
      getCourseBySlug({
        variables: { slug: courseSlug },
      });
    }
  }, []);

  return (
    <PageWrapper className="admin">
      <div className="admin-content">
        <h1>{name}</h1>
        <div className="admin-subpage-nav">
          <a
            className="a-basic-link"
            onClick={(e) =>
              navigate(
                isAuthenticated
                  ? `/${routes.admin}`
                  : `/${routes.admin}/${adminRoutes.logIn}`
              )
            }
          >
            Dashboard
          </a>{" "}
          →{" "}
          <a
            className={classnames(`a-basic-link`, {
              "--active": location.pathname.includes("/course"),
            })}
          >
            {name}
          </a>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <CourseForm
            name={name}
            slug={slug}
            id={id}
            meetings={meetings}
            createMode={false}
            onSubmit={(editedCourse) => editCourseMutation(editedCourse)}
            mutationResult={mutationResult}
            handleSetCourseInfo={handleSetCourseInfo}
            handleSetMeetingInfo={handleSetMeetingInfo}
          />
        )}
      </div>
    </PageWrapper>
  );
};
