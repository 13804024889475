import React, { Fragment, useEffect, useState } from "react";
import { Chat } from "./chat";
import { Modal } from "./modal/modal";
import { DevicesContent } from "./devices";
import { useScreenContext } from "../contexts/screenContext";
import { useLocalAudioOutput } from "amazon-chime-sdk-component-library-react";
import { convertUtcToMonthDateTime } from "../utils/dateUtils";
import { PageWrapper } from "./pageWrapper";
import { useAppContext } from "../contexts/appContext";
import { Instructions, InstructionsContent } from "./instructions";
import { TabList } from "./addContent/tabList";
import { Container } from "./container";
import { ChatDataMessage } from "../providers/dataMessages";
import { ChatReplayMessage } from "../providers/replayDataMessages";
import { Button, SECONDARY, STANDARD } from "./buttons/button";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";
import { Link } from "./link";
import { AttendeeState } from "../contexts/types";

type LobbyProps = {
  courseName?: string;
  meetingTime?: number;
  messages?: ChatReplayMessage[] | ChatDataMessage[];
  sendMessage?: (message: string, attendee?: AttendeeState) => void;
  messageCount?: number;
};

interface Tab {
  name: string;
  id: string;
}

interface ContentTabs {
  DeviceSettings?: Tab;
  Chat?: Tab;
  Instructions?: Tab;
}

export const Lobby: React.FC<LobbyProps> = ({
  courseName,
  meetingTime,
  messages,
  sendMessage,
  messageCount,
}) => {
  let contentTabs: ContentTabs = {
    DeviceSettings: {
      name: "Device Settings",
      id: "device-settings",
    },
    Chat: { name: "Chat", id: "chat" },
    Instructions: {
      name: "Instructions",
      id: "instructions",
    },
  };
  const { firstName, simpleView, isStudentAVMeeting, meetingHasChat } =
    useAppContext();
  const [activeTab, setActiveTab] = useState<string>(
    contentTabs.DeviceSettings.name
  );
  const { isMedium, isLarge, useMobileTools } = useScreenContext();
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const [chatHeight, setChatHeight] = useState("60%");
  const [showKeyBoardControls, setShowKeyboardControls] = useState(false);
  const keyBoardButtonText = !showKeyBoardControls
    ? "Keyboard controls"
    : "Mouse controls";

  const meetingManager = useMeetingWithRoster();
  const audioElements = document?.getElementsByTagName("audio");
  const chimeAudio = audioElements.length > 0 ? audioElements[0] : undefined;

  useEffect(() => {
    if (isLarge) {
      setChatHeight("calc(50vh - 104px)");
    } else if (isMedium) {
      setChatHeight("calc(50vh - 104px)");
    } else {
      setChatHeight("calc(40vh - 104px)");
    }
  }, [isMedium, isLarge]);

  // if you are a student and the lobby component is open you should never have audio
  useEffect(() => {
    if (isAudioOn) {
      toggleAudio();
    }
  }, [isAudioOn, toggleAudio]);

  useEffect(() => {
    meetingManager?.audioVideo?.realtimeMuteLocalAudio();
    if (chimeAudio) {
      chimeAudio.muted = true;
      chimeAudio.id = "chime-audio";
    }
  }, [chimeAudio, meetingManager?.audioVideo]);

  // when the lobby closes turn on audio
  useEffect(() => {
    return function () {
      chimeAudio.muted = false;
      if (!isAudioOn) {
        toggleAudio();
      }
    };
  }, []);

  function tabCreator(): any[] {
    if (!meetingHasChat) {
      delete contentTabs.Chat;
    }
    return Object.values(contentTabs);
  }

  return (
    <PageWrapper className="lobby">
      <Modal display={true} dismissible={false} className="lobby-container">
        <div className="__modal-content">
          <div className="lobby-content__header">
            <div className="lobby-text">
              {courseName && firstName && (
                <h2>
                  Hi {firstName}! Welcome to the lobby for {courseName}.
                </h2>
              )}
              {meetingTime && (
                <p>
                  This class is scheduled for{" "}
                  {convertUtcToMonthDateTime(meetingTime)} and will begin
                  shortly.{" "}
                  {!useMobileTools && !simpleView && (
                    <>
                      {" "}
                      While you wait, please confirm your audio{" "}
                      {isStudentAVMeeting && "and video"}
                      settings{" "}
                      {activeTab !== contentTabs.DeviceSettings.name &&
                        'by clicking "Device Settings" in the left menu '}
                      below. If you experience any technical issues, please
                      contact our Student Help Desk line at{" "}
                      <Link href="tel:+12678314464" text=" 267.831.4464 " />
                      or email{" "}
                      <Link
                        href="mailto: support@barnesclasses.org"
                        text=" support@barnesclasses.org "
                      />
                      .
                    </>
                  )}
                  <br />
                </p>
              )}
            </div>
          </div>
          {/* Only show tabs and content if not simple view */}
          {!simpleView ? (
            <Fragment>
              {useMobileTools ? (
                <div className="lobby-modal-body-container">
                  <div className="lobby-content__mobile">
                    <div className="lobby-content__left">
                      <TabList
                        tabs={tabCreator()}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    </div>
                    <div className="lobby-content__right">
                      {activeTab === contentTabs?.DeviceSettings?.name && (
                        // Show Device Settings for regular meeting...
                        <DevicesContent useAudio={false} />
                      )}
                      {activeTab === contentTabs?.Instructions?.name && (
                        <Instructions lobby={true} />
                      )}

                      {activeTab === contentTabs?.Chat?.name &&
                        meetingHasChat && (
                          // ... or show Chat regular meeting
                          <Chat
                            height={chatHeight}
                            dismissable={false}
                            className="lobby-chat-container"
                            messages={messages}
                            sendMessage={sendMessage}
                            messageCount={messageCount}
                          />
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <Container
                  className="lobby-modal-body-container"
                  childrenClassName="lobby-content"
                  isDismissable={false}
                >
                  <div className="lobby-content__left">
                    <TabList
                      tabs={tabCreator()}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  </div>
                  <div className="lobby-content__right">
                    {activeTab === contentTabs?.DeviceSettings?.name && (
                      // Show Device Settings for regular meeting...
                      <DevicesContent useAudio={false} />
                    )}
                    {activeTab === contentTabs?.Instructions?.name && (
                      <Fragment>
                        <InstructionsContent
                          showKeyBoardControls={showKeyBoardControls}
                        />
                        <Button
                          btnType={SECONDARY}
                          text={keyBoardButtonText}
                          size={STANDARD}
                          disabled={false}
                          inputClass="instructions-secondary"
                          onClick={() =>
                            setShowKeyboardControls(!showKeyBoardControls)
                          }
                          id="switch-instructors-button"
                          customWidth="300px"
                        />
                      </Fragment>
                    )}

                    {activeTab === contentTabs?.Chat?.name &&
                      meetingHasChat && (
                        // ... or show Chat regular meeting
                        <Chat
                          height={chatHeight}
                          dismissable={false}
                          className="lobby-chat-container"
                          messages={messages}
                          sendMessage={sendMessage}
                          messageCount={messageCount}
                        />
                      )}
                  </div>
                </Container>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div className="lobby-modal-body-container">
                <Instructions lobby={true} />
              </div>
            </Fragment>
          )}
          <div className="lobby-content__footer">
            <p className="lobby-text">
              <small>
                {!simpleView && isStudentAVMeeting && (
                  <span>
                    For your privacy, your microphone and camera will be off
                    upon joining the class. If prompted, please grant microphone
                    and camera permissions in your browser. Enabling these
                    settings will allow you to participate in class discussions.
                  </span>
                )}
              </small>
            </p>
          </div>
        </div>
      </Modal>
    </PageWrapper>
  );
};
