import React, { Fragment, Suspense, useEffect, useState } from "react";
import { useAppContext } from "../../contexts/appContext";
import { ActionType, CanvasMode, State } from "../../contexts/types";
import { ToolBar } from "./toolBar";
import { IconButton } from "../buttons/iconButton";
import { Modal } from "../modal/modal";
import { AddContent } from "../addContent/addContentModal";
import Spinner from "../spinner";
import { EventPayload, EventTypes } from "../../providers/types";
import { useMutation } from "@apollo/client";
import { CREATE_BOARD } from "../../graphql/mutations";
import { Icon } from "../icon";
import { Board, BoardType, CreateBoardInput, Mutation } from "../../types";
import { MoreActionsItem } from "./controls/moreActions/moreActionsItem";
import { Button, SMALL } from "../buttons/button";
import { MoreActionsWrapper } from "./controls/moreActions/moreActionsWrapper";
import { MoreActionsButton } from "./controls/moreActions/moreActionsButton";
import { useScreenContext } from "../../contexts/screenContext";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

type NonMobileEditToolsProps = {
  mode: CanvasMode;
  editBoardIIIF: boolean;
  toggleAddContent: () => void;
  handleEditBoard: (event: any) => void;
  handleAddBoard: (next?: boolean) => void;
};

const NonMobileEditTools: React.FC<NonMobileEditToolsProps> = ({
  mode,
  editBoardIIIF,
  toggleAddContent,
  handleEditBoard,
  handleAddBoard,
}) => {
  let { isInstructorOrModerator, state } = useAppContext();
  const [leader] = useState(
    (mode === CanvasMode.PRESENTATION && isInstructorOrModerator) ||
      mode === CanvasMode.EDIT ||
      mode === CanvasMode.VIEW_ONLY ||
      mode === CanvasMode.REPLAY
  );
  const [showAddActions, setShowAddActions] = useState<boolean>(false);

  return (
    <>
      <IconButton
        onClick={(e) => toggleAddContent()}
        iconName="art-piece"
        desc="Add content to board"
        toolTip="right"
        btnId="add-content-button"
        disabled={mode === CanvasMode.VIEW_ONLY}
      />
      {/* Edit/Grab board toggle */}
      <IconButton
        onClick={handleEditBoard}
        iconName={editBoardIIIF ? "hand-move" : "edit-pencil"}
        desc={
          editBoardIIIF
            ? "Editing board, click to exit edit mode"
            : "Edit board content"
        }
        toolTip="right"
        btnId="edit-board-button"
        disabled={
          mode === CanvasMode.PRESENTATION ||
          !state.slides ||
          state.currentBoard?.type !== BoardType.IIIF ||
          mode === CanvasMode.VIEW_ONLY
        }
      />
      <MoreActionsButton
        showActionsInput={showAddActions}
        toolTip="right"
        alignment="horizontal"
        onClick={(e) => {
          setTimeout(() => {
            setShowAddActions(!showAddActions);
          }, 0);
        }}
        customIcon="plus"
        id="add-a-board-button"
        disabled={mode === CanvasMode.VIEW_ONLY}
        className="add-board-button"
      >
        <MoreActionsWrapper
          upOrDown="down"
          leftOrRight="right"
          itemClick={(e) => {
            e.preventDefault();
            setShowAddActions(!showAddActions);
          }}
          inputClass="--add-a-board-wrapper"
        >
          <MoreActionsItem
            text="New board after selected board"
            id="add-board-next"
            onClick={(e) => {
              handleAddBoard(true);
            }}
            disabled={!state.slides?.length || mode === CanvasMode.VIEW_ONLY}
            icon="plus"
          />
          <MoreActionsItem
            text="New board after last board "
            id="add-board-end"
            disabled={mode === CanvasMode.VIEW_ONLY}
            onClick={(e) => {
              handleAddBoard();
            }}
            icon="plus"
          />
        </MoreActionsWrapper>
      </MoreActionsButton>
    </>
  );
};

type EditPresentationToolsProps = {
  handleRefetchBoards?: (newSelectedBoardNumber: number) => void;
  handleChangeSlide: (sortOrder: number) => void;
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  mode: CanvasMode;
  editBoardIIIF: boolean;
  setEditBoardIIIF: (edit: boolean) => void;
  setIIIFRemoved: (removed: boolean) => void;
};

/** Toolbar component for edit presentation tools, Add Content, Edit Board, Add Board
 * @param {EditPresentationToolsProps} props
 * @prop {() => void} handleRefetchBoards - Function that refetches the boards for the current meeting
 * @prop {() => void} handleChangeSlide - Function to handle slide changes
 * @prop {((eventPayload: EventPayload) => void) | undefined} sendEvent - Function to send events using the DataEventsProvider
 * @prop {CanvasMode} mode -  mode can be Presentation, Edit, Replay
 * @prop {boolean} editBoardIIIF
 * @prop {(edit: boolean) => void} setEditBoardIIIF
 * @prop {(removed -  boolean) => void} setIIIFRemoved
 */

export const EditPresentationTools: React.FC<EditPresentationToolsProps> = ({
  handleRefetchBoards,
  handleChangeSlide,
  sendEvent,
  mode,
  editBoardIIIF,
  setEditBoardIIIF,
}) => {
  let {
    dbId: meetingId,
    presentationUpdated,
    presentationDiff,
    isInstructorOrModerator,
    showPopup,
    setShowPopup,
    setPopupMessage,
    state,
    dispatch,
  } = useAppContext();
  const { useMobileTools } = useScreenContext();

  const [showAddContent, setShowAddContent] = useState(false);
  const [showAddErrorModal, setShowAddErrorModal] = useState<boolean>(false);
  const [showMoreActionsMenu, setShowMoreActionsMenu] = useState(false);

  const [leader] = useState(
    (mode === CanvasMode.PRESENTATION && isInstructorOrModerator) ||
      mode === CanvasMode.EDIT ||
      mode === CanvasMode.VIEW_ONLY ||
      mode === CanvasMode.REPLAY
  );
  const useEditTools =
    mode === CanvasMode.EDIT || mode === CanvasMode.VIEW_ONLY;

  const toggleAddContent = () => setShowAddContent(!showAddContent);

  const [createBoardMutation] = useMutation(CREATE_BOARD, {
    async onCompleted(data: Pick<Mutation, "createBoard">) {
      const board: Board = data.createBoard.board;
      const currentVersion = data.createBoard.currentVersion;
      handleRefetchBoards(board.sortOrder);
      dispatch({
        type: ActionType.UPDATE_CURRENT_BOARD,
        payload: { currentBoard: board },
      });
      setTimeout(() => {
        if (currentVersion) {
          // send version update event
          const event: EventPayload = {
            type: EventTypes.PRESENTATION_VERSION_CHANGE,
            presentationVersion: currentVersion,
            leader: true,
          };

          sendEvent && sendEvent(event, state);
        }
        handleChangeSlide(board.sortOrder);
      }, 250);
    },
    onError(error) {
      consoleNonProd("Error adding board:", error);
      setShowAddErrorModal(true);
      setShowPopup(false);
    },
  });

  const toggleShowAddErrorModal = () => {
    setShowAddErrorModal(!showAddErrorModal);
  };

  const handleEditBoard = (event) => {
    event.preventDefault();
    setEditBoardIIIF(!editBoardIIIF);
  };

  /** Handles showing the popup when the presentation is updated by another user */
  useEffect(() => {
    if (presentationUpdated && presentationDiff) {
      const message = `Board ${presentationDiff.board} was ${presentationDiff.action}`;
      setPopupMessage(message);
      setShowPopup(true);
    }
  }, [presentationUpdated, presentationDiff]);

  const handleAddBoard = (next?: boolean) => {
    setTimeout(() => {
      const input: CreateBoardInput = {
        meetingId,
        title: undefined,
        sortOrder: next
          ? state.currentBoard?.sortOrder + 1
          : state.slides?.length + 1,
        type: BoardType.IIIF,
      };
      setPopupMessage("New board added!");
      setShowPopup(true);
      createBoardMutation({ variables: { input } });
    }, 100);
  };

  const handleAddObjectComplete = (currentVersion: number) => {
    if (currentVersion && sendEvent) {
      // send version update event
      const event: EventPayload = {
        type: EventTypes.PRESENTATION_VERSION_CHANGE,
        presentationVersion: currentVersion,
        leader: true,
      };

      sendEvent(event, state);
    }

    handleRefetchBoards(state.currentBoard.sortOrder);
  };

  return (
    <Fragment>
      {/* Edit presentation tools in presentation mode more actions menu for larger screens */}
      {mode === CanvasMode.PRESENTATION && !useMobileTools && (
        <Suspense fallback={<Spinner />}>
          <NonMobileEditTools
            mode={mode}
            editBoardIIIF={editBoardIIIF}
            toggleAddContent={toggleAddContent}
            handleEditBoard={handleEditBoard}
            handleAddBoard={handleAddBoard}
          />
        </Suspense>
      )}
      {/* Edit presentation tools in edit mode */}
      {useEditTools && (
        <ToolBar orientation="vertical" className="meeting-controls">
          <Suspense fallback={<Spinner />}>
            <NonMobileEditTools
              mode={mode}
              editBoardIIIF={editBoardIIIF}
              toggleAddContent={toggleAddContent}
              handleEditBoard={handleEditBoard}
              handleAddBoard={handleAddBoard}
            />
          </Suspense>
        </ToolBar>
      )}
      {/* Edit presentation tools in presentation mode more actions menu for smaller screens */}
      {mode === CanvasMode.PRESENTATION && useMobileTools && (
        <MoreActionsButton
          showActionsInput={showMoreActionsMenu}
          toolTip="right"
          alignment="vertical"
          id="edit-presentation-tools__more-actions"
          onClick={(e) => setShowMoreActionsMenu(!showMoreActionsMenu)}
          disabled={false}
        >
          <MoreActionsWrapper
            itemClick={(e) => {
              e.preventDefault();
              setShowMoreActionsMenu(false);
            }}
            upOrDown="up"
            leftOrRight="right"
          >
            <MoreActionsItem id="add-content-button">
              <Button
                onClick={(e) => toggleAddContent()}
                icon="art-piece"
                text="Add content to board"
                id="add-content-button"
                customWidth="100%"
                size={SMALL}
                disabled={false}
              />
            </MoreActionsItem>
            {/* Edit/Grab board toggle */}
            <MoreActionsItem id="edit-board-button">
              <Button
                onClick={handleEditBoard}
                icon={editBoardIIIF ? "hand-move" : "edit-pencil"}
                text={
                  editBoardIIIF
                    ? "Editing board, click to exit edit mode"
                    : "Edit board content"
                }
                id="edit-board-button"
                customWidth="100%"
                size={SMALL}
                disabled={
                  !state.slides || state.currentBoard?.type !== BoardType.IIIF
                }
              />
            </MoreActionsItem>
            <MoreActionsItem id="add-a-board-button">
              <Button
                icon="plus"
                text="Add new board"
                disabled={!leader || showPopup}
                onClick={() => handleAddBoard()}
                id="add-a-board-button"
                customWidth="100%"
                size={SMALL}
              />
            </MoreActionsItem>
          </MoreActionsWrapper>
        </MoreActionsButton>
      )}

      <Modal
        dismissible={true}
        display={showAddErrorModal}
        onDismiss={toggleShowAddErrorModal}
      >
        <div className="__modal-content">
          <div id="addBoardDesc" className="add-board__error">
            <div>
              <Icon
                name="frown"
                stroke=""
                fill=""
                height="24px"
                desc="Frowning face"
                toolTip="none"
                className="add-board__error-icon"
              />
              <div>
                <span>
                  Oh no! Something went wrong creating your board, please try
                  again later...
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        dismissible={true}
        display={showAddContent}
        onDismiss={() => toggleAddContent()}
      >
        <div className="__modal-content">
          <AddContent
            onAddObjectCompleted={handleAddObjectComplete}
            toggleAddContent={toggleAddContent}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
