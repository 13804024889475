import classNames from "classnames";
import React from "react";

type ModalFooterProps = {
  children: any;
  className?: string;
};

/**
 * Component for VxP modal footer. To be wrapped in Modal component
 * @ModalFooterProps children
 */

export const ModalFooter: React.FC<ModalFooterProps> = ({
  children,
  className,
}) => {
  return (
    <section className={classNames("modal_footer_section", className)}>
      {children}
    </section>
  );
};
