import React from "react";
import { Nav } from "./nav";

type PageWrapperProps = {
  children: any;
  className?: string;
  navBar?: boolean;
};

/** Component used to wrap page content that contains the site logo and log in/log out functionality
 * @param {PageWrapperProps} props
 * @prop {any} children - Child component that will be rendered inside the wrapper
 * @prop {any} className - optional classname
 * @prop {any} navBar - boolean for navbar, defaults to true
 */
export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  className,
  navBar = true,
}) => {
  return (
    <div className={`pagewrapper ${className}`}>
      {navBar && <Nav />}
      {children}
      {/* Footer */}
    </div>
  );
};
