import classnames from "classnames";
import React from "react";
import { Button, SMALL, TERTIARY } from "../buttons/button";

export type Filter = {
  filterName: string;
  active: boolean;
  value: string | number;
  id: string;
};

export type FilterProps = {
  filter: Filter;
  emitValue?: (boolean) => void;
  setActiveFilter?: React.Dispatch<React.SetStateAction<string>>;
  isActive: boolean;
};

export const Filter: React.FC<FilterProps> = ({
  filter,
  emitValue,
  setActiveFilter,
  isActive,
}) => {
  return (
    <Button
      onClick={(e) => {
        let newValue = filter;
        newValue.active = !filter.active;
        emitValue && emitValue(newValue);
        setActiveFilter && setActiveFilter(filter.filterName);
      }}
      id={filter.id}
      btnType={TERTIARY}
      size={SMALL}
      inputClass={classnames("filter-button", {
        "filter-button__active": isActive,
      })}
    >
      {filter.filterName}
    </Button>
  );
};

type FilterBarProps = {
  handleFilters: (e, i) => void;
  filters: Filter[];
  hasLabel?: boolean;
  label?: string;
  setActiveFilter?: React.Dispatch<React.SetStateAction<string>>;
  activeFilter?: string;
};

/**
 * Component for an VxP search bar
 * @SearchBarProps handleSearch, value, setValue
 */

export const FilterBar: React.FC<FilterBarProps> = ({
  handleFilters,
  filters,
  hasLabel,
  label = "Filter by:",
  setActiveFilter,
  activeFilter,
}) => {
  return (
    <div className="filter-container">
      {hasLabel && (
        <div className="filter-text">
          <p>{label}</p>
        </div>
      )}
      {filters?.map((filter, index) => (
        <Filter
          key={index}
          filter={filter}
          emitValue={(e) => {
            handleFilters(e, index);
          }}
          setActiveFilter={setActiveFilter}
          isActive={filter.filterName === activeFilter}
        />
      ))}
    </div>
  );
};
