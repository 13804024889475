import React from "react";
import classNames from "classnames";
import { Button, SMALL, TERTIARY } from "../buttons/button";

type TabListItemType = {
  name: string;
  isActive: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  id: string;
};

type TabListType = {
  tabs: {
    name: string;
    id: string;
  }[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
};

export const TabListItem: React.FC<TabListItemType> = ({
  name,
  isActive,
  setActiveTab,
  id,
}) => {
  return (
    <Button
      btnType={TERTIARY}
      disabled={false}
      size={SMALL}
      text={name}
      onClick={() => {
        setActiveTab(name);
      }}
      inputClass={classNames({
        "tab-list__item--active": isActive,
      })}
      id={id}
    />
  );
};

/** Component that contains a list of tabs/buttons that will have one active tab
 * @param {TabListType} props
 * @prop {string[]} tabs - List of tab items to be included in the list
 * @prop {string} activeTab - The name of the active/current tab
 * @prop {React.Dispatch<React.SetStateAction<string>>} - Function to set the active/current tab
 */

export const TabList: React.FC<TabListType> = ({
  tabs,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="tab-list">
      {tabs.map((tab, index) => (
        <TabListItem
          key={index}
          name={tab.name}
          setActiveTab={setActiveTab}
          isActive={tab.name === activeTab}
          id={`tab-list__item-${tab.id}`}
        />
      ))}
    </div>
  );
};
