import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { S3Client } from "@aws-sdk/client-s3";
import config from "../config";

// Initialize the Amazon Cognito credentials provider
export const s3Client = new S3Client({
  region: config.awsRegion,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({
      region: config.awsRegion,
    }),
    identityPoolId: config.awsIdentityPool,
  }),
});
