import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { Board } from "../../types";
import {
  EventTypes,
  EventPayload,
  CanvasDataEvent,
} from "../../providers/types";
import { IconButton } from "../buttons/iconButton";
import { Toggle } from "../inputs/toggle";
import { useAppContext } from "../../contexts/appContext";
import {
  ActionType,
  CanvasMode,
  EventToggleExploreModeAction,
  MeetingRole,
  State,
} from "../../contexts/types";
import { SlidePreview, SlidePreviewRefetchReason } from "./slidePreview";
import SimpleBar from "simplebar-react";
import { CanvasNav } from "./controls/canvasNav";
import { HelperPopUp } from "../popUps/helperPopUp";
import { MinimizeExpandToggle } from "./controls/minimizeExpandToggle";
import { useScreenContext } from "../../contexts/screenContext";

type SlideManagerProps = {
  handleSelectSlide: (sortOrder: number) => void;
  handleChangeSlide;
  testSlides?: Board[];
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  events?: CanvasDataEvent[];
  handleRefetchBoards?: (newSelectedBoardNumber?: number) => void;
  mode: CanvasMode;
  iiifRemoved: boolean;
  setIIIFRemoved: (removed: boolean) => void;
  scrollToBoard;
  focusOnBoard;
};

/** Toolbar component for the bottom docked slide preview controls used by the Instructor while presenting or editing a class
 * @param {SlideManagerProps} props
 * @prop {(sortOrder: number) => void} handleSelectSlide - Function that sets the current slide number in the parent component
 * @prop {Board[]} testSlides - Faked slide data to use for the Style Guide or testing purposes
 * @prop {((eventPayload: EventPayload) => void) | undefined} sendEvent - Function to send events using the DataEventsProvider
 * @prop {CanvasDataEvent[]} events - List of app events from the DataEventsProvider, defaults to an empty array
 * @prop {boolean} presentationMode - Whether or not the meeting is being presented (true) or edited (false), defaults to true
 * @prop {() => void} handleRefetchBoards - Function that refetches the boards for the current meeting
 */
export const SlideManager: React.FC<SlideManagerProps> = ({
  handleSelectSlide,
  handleChangeSlide,
  testSlides,
  sendEvent,
  events = [],
  handleRefetchBoards,
  mode,
  iiifRemoved,
  setIIIFRemoved,
  scrollToBoard,
  focusOnBoard,
}) => {
  let {
    dbId: meetingId,
    meetingRole,
    isInstructorOrModerator,
    presentationUpdated,
    setPresentationUpdated,
    presentationDiff,
    showPopup,
    setShowPopup,
    popupMessage,
    setPopupMessage,
    state,
    dispatch,
  } = useAppContext();

  const { useMobileTools } = useScreenContext();

  // TODO: see if this still works
  // Set up slides for testing purposes and the Style Guide
  if (testSlides) state.slides = testSlides;
  const defaultPopupMessage = "Presentation has been updated by another user!";

  const [leader] = useState(
    (mode === CanvasMode.PRESENTATION && isInstructorOrModerator) ||
      mode === CanvasMode.EDIT ||
      mode === CanvasMode.VIEW_ONLY ||
      mode === CanvasMode.REPLAY
  );
  const [showSlides, setShowSlides] = useState(leader);
  const memoizedExploreMode = useMemo(
    () =>
      mode == CanvasMode.EDIT ||
      mode === CanvasMode.VIEW_ONLY ||
      state.exploreMode,
    [state.exploreMode, mode]
  );
  const memoizedIsLeader = useMemo(
    () =>
      mode === CanvasMode.EDIT ||
      mode === CanvasMode.VIEW_ONLY ||
      meetingRole === MeetingRole.Presenter,
    [meetingRole, mode]
  );

  /** Set focus to canvas on first load when new boards are added */
  useEffect(() => {
    setTimeout(() => {
      focusOnBoard();
      scrollToBoard(state.currentBoard?.sortOrder);
    }, 0);
  }, [state.slides?.length]);

  /** Handles slide changes and explore/focus toggle from other instructors/admin users */
  useEffect(() => {
    if (events?.length) {
      const eventObj = events[events.length - 1];

      if (!eventObj.isSelf && eventObj.event) {
        const event: EventPayload = JSON.parse(eventObj.event);

        if (event.leader) {
          handleSelectSlide(event.currentSlideNumber);

          switch (event.type) {
            case EventTypes.CHANGE_SLIDE:
              handleSelectSlide(event.currentSlideNumber);
              break;
            case EventTypes.TOGGLE_EXPLORE_MODE:
              dispatch({
                type: ActionType.UPDATE_EXPLORE_MODE,
                payload: {
                  exploreMode: event.exploreMode,
                },
              });
          }
        }
      }
    }
  }, [events, handleSelectSlide, dispatch]);

  /** Handles showing the popup when the presentation is updated by another user */
  useEffect(() => {
    if (presentationUpdated && presentationDiff) {
      const message = `Board ${presentationDiff.board} was ${presentationDiff.action}`;
      setPopupMessage(message);
      setShowPopup(true);
    }
  }, [presentationUpdated, presentationDiff]);

  /** Handles showing the popup when images are removed from a board */
  useEffect(() => {
    if (iiifRemoved) {
      const message = `Image removed`;
      setPopupMessage(message);
      setShowPopup(true);
    }
  }, [iiifRemoved]);

  const toggleSlides = () => {
    setShowSlides(!showSlides);
  };

  /** on setting of show slides scroll to current board */
  useEffect(() => {
    if (showSlides) {
      scrollToBoard(state.currentBoard?.sortOrder);
    }
  }, [showSlides]);

  /** Handles sending explore/focus change event and setting AppContext
   * @param {React.ChangeEvent<HTMLInputElement>} e - Click event
   */
  const handleToggleExploreMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    // This ternary is a safety check so explore will always have a default value of false
    const exploreMode: boolean = e.target.checked ? e.target.checked : false;
    const payload: EventToggleExploreModeAction["payload"] = { exploreMode };

    if (exploreMode) {
      payload["showLaser"] = false;
      payload["laserInstructor"] = undefined;
    }

    dispatch({
      type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
      payload,
    });

    sendEvent &&
      sendEvent(
        {
          type: EventTypes.TOGGLE_EXPLORE_MODE,
          leader: isInstructorOrModerator,
          exploreMode,
        },
        state
      );
  };

  const handleDismissPopup = (event) => {
    setShowPopup(false);
    setPresentationUpdated(false);
    setIIIFRemoved(false);
    setPopupMessage(defaultPopupMessage);
  };

  const handleDuplication = (newSortOrder: number) => {
    setPopupMessage("Board duplicated");
    setShowPopup(true);
    handleRefetchBoards(
      newSortOrder > state?.slides?.length
        ? state?.slides?.length + 1
        : newSortOrder
    );
  };

  return (
    <section>
      <div
        className={classnames("canvas-controls", { minimized: !showSlides })}
      >
        {useMobileTools ? (
          <div className="canvas-controls__toolbar">
            <div className="canvas-controls__toolbar-buttons">
              <div className="canvas-controls__toolbar-toggle">
                <MinimizeExpandToggle
                  isActive={showSlides}
                  isHorizontal={false}
                  onClick={toggleSlides}
                />
              </div>
              {mode !== CanvasMode.EDIT && mode !== CanvasMode.VIEW_ONLY && (
                <div className="canvas-controls__explore-focus">
                  <Toggle
                    option1="Explore"
                    option2="Focus"
                    name="focus-explore-toggle"
                    onChange={handleToggleExploreMode}
                    icon1="focus"
                    icon2="explore"
                    disabled={
                      !isInstructorOrModerator || state.spotlightInstructor
                    }
                    checked={state.exploreMode}
                  />
                </div>
              )}
            </div>

            <div className="canvas-controls__toolbar-slide-nav">
              <div className="canvas-controls__toolbar-slide-nav__group">
                <CanvasNav
                  isLeader={memoizedIsLeader}
                  exploreMode={memoizedExploreMode}
                />
              </div>
              <div className="canvas-controls__toolbar-slide-nav__group">
                <IconButton
                  iconName="arrow-left"
                  desc="Previous"
                  toolTip="top"
                  onClick={() =>
                    handleChangeSlide(state.currentBoard?.sortOrder - 1)
                  }
                  disabled={!leader || state.currentBoard?.sortOrder === 1}
                  btnId="previous-slide-button"
                />
                <IconButton
                  iconName="arrow-right"
                  desc="Next"
                  toolTip="top"
                  onClick={() =>
                    handleChangeSlide(state.currentBoard?.sortOrder + 1)
                  }
                  disabled={
                    !leader ||
                    state.currentBoard?.sortOrder === state.slides?.length
                  }
                  btnId="next-slide-button"
                />

                {showPopup && (
                  <HelperPopUp
                    userMessage={popupMessage}
                    bottom="30px"
                    right="-2px"
                    downArrow={true}
                    isDismissible={false}
                    isTimed={true}
                    onDismiss={handleDismissPopup}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="canvas-controls__toolbar">
            <div className="canvas-controls__toolbar-toggle">
              <MinimizeExpandToggle
                isActive={showSlides}
                isHorizontal={false}
                onClick={toggleSlides}
              />
            </div>
            {mode !== CanvasMode.EDIT && mode !== CanvasMode.VIEW_ONLY && (
              <div className="canvas-controls__explore-focus">
                <Toggle
                  option1="Explore"
                  option2="Focus"
                  name="focus-explore-toggle"
                  onChange={handleToggleExploreMode}
                  icon1="focus"
                  icon2="explore"
                  disabled={
                    !isInstructorOrModerator || state.spotlightInstructor
                  }
                  checked={state.exploreMode}
                />
              </div>
            )}
            {/* <label htmlFor="leader">Leader</label> */}
            <div className="canvas-controls__toolbar-slide-nav">
              <div className="canvas-controls__toolbar-slide-nav__group">
                <CanvasNav
                  isLeader={memoizedIsLeader}
                  exploreMode={memoizedExploreMode}
                />
              </div>
              <div className="canvas-controls__toolbar-slide-nav__group">
                <IconButton
                  iconName="arrow-left"
                  desc="Previous"
                  toolTip="top"
                  onClick={() =>
                    handleChangeSlide(state.currentBoard?.sortOrder - 1)
                  }
                  disabled={!leader || state.currentBoard?.sortOrder === 1}
                  btnId="previous-slide-button"
                />
                <IconButton
                  iconName="arrow-right"
                  desc="Next"
                  toolTip="top"
                  onClick={() =>
                    handleChangeSlide(state.currentBoard?.sortOrder + 1)
                  }
                  disabled={
                    !leader ||
                    state.currentBoard?.sortOrder === state.slides?.length
                  }
                  btnId="next-slide-button"
                />

                {showPopup && (
                  <HelperPopUp
                    userMessage={popupMessage}
                    bottom="30px"
                    right="-2px"
                    downArrow={true}
                    isDismissible={false}
                    isTimed={true}
                    onDismiss={handleDismissPopup}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <SimpleBar
          forceVisible="x"
          autoHide={false}
          className="canvas-controls__slide-manager__simplebar"
        >
          <div className="canvas-controls__slide-manager" tabIndex={-1}>
            {state.slides?.map((slide) => (
              <SlidePreview
                slide={slide}
                slides={state.slides}
                handleSelectSlide={handleChangeSlide}
                current={state.currentBoard?.sortOrder === slide?.sortOrder}
                key={slide?.sortOrder}
                leader={leader}
                sendEvent={sendEvent}
                handleRefetchBoards={(e, f) => {
                  if (e === SlidePreviewRefetchReason.DUPLICATION) {
                    handleDuplication(f);
                  } else {
                    handleRefetchBoards();
                  }
                }}
                mode={mode}
              />
            ))}
          </div>
        </SimpleBar>
      </div>
    </section>
  );
};
