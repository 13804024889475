import classnames from "classnames";
import React, { useContext } from "react";
import { DARK, ThemeContext } from "../contexts/themeContext";

type LinkProps = {
  target?: string;
  rel?: string;
  href?: string;
  style?: any;
  className?: string;
  text?: string;
};

/**
 * Component for VxP link. pass in the usual anchor tag props
 * @LinkProps target, rel, href, style, className, text,
 */

export const Link: React.FC<LinkProps> = ({
  target,
  rel,
  href,
  style,
  className = "a-basic-link",
  text,
}) => {
  const { theme } = useContext(ThemeContext);
  const darkThemeClass = className + "--dark";
  return (
    <a
      target={target}
      rel={rel}
      href={href}
      style={style}
      // className={theme === DARK ? className + "--dark" : className}
      className={classnames(className, {
        [darkThemeClass]: theme === DARK,
      })}
    >
      {text}
    </a>
  );
};
