/**
 * @param {string} path - utc Time in miliseconds
 * @param {string} timeZone - locale time zone string, i.e. "America/New York" can be found with Intl.DateTimeFormat().resolvedOptions().timeZone
 * @returns {string} - Formatted time
 */

export const convertUTCtoTime = (utCDate: number, timeZone?: string) => {
  let tz: string = "";
  if (!timeZone) {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    tz = timeZone;
  }

  const formattedTime = new Date(utCDate).toLocaleString("en-US", {
    timeZone: tz,
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedTime;
};

/**
 * @param {string} path - utc Time in miliseconds
 * @param {string} timeZone - locale time zone string, i.e. "America/New York" can be found with Intl.DateTimeFormat().resolvedOptions().timeZone
 * @returns {string} - Formatted date and time
 */

export const convertUTCtoDateTime = (utCDate: number, timeZone?: string) => {
  let tz: string = "";
  if (!timeZone) {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    tz = timeZone;
  }

  const formattedDate = new Date(utCDate).toLocaleDateString("en-US", {
    timeZone: tz,
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedDate;
};

/**
 * @param {number} utcDate - UTC Time in milliseconds
 * @param {string} timeZone - Locale time zone string, i.e., "America/New_York" can be found with Intl.DateTimeFormat().resolvedOptions().timeZone
 * @returns {string} - Formatted date and time in the "Month Dth at H:mmam/pm ET" format
 */
export const convertUtcToMonthDateTime = (
  utcDate: number,
  timeZone?: string
) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(utcDate);

  let tz = "";
  if (!timeZone) {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } else {
    tz = timeZone;
  }

  const etDate = new Date(
    date.toLocaleString("en-US", { timeZone: "America/New_York" })
  );

  const month = months[etDate.getMonth()];
  const day = etDate.getDate();
  const hours = etDate.getHours();
  const minutes = etDate.getMinutes();
  const ampm = hours < 12 ? "am" : "pm";

  const dayWithSuffix = getDayWithSuffix(day); // Helper function to add "st", "nd", "rd", or "th" to the day

  const formattedDate = `${month} ${dayWithSuffix} at ${
    hours === 0 ? "12" : hours <= 12 ? hours : hours - 12
  }:${minutes < 10 ? "0" + minutes : minutes}${ampm} ET`;

  return formattedDate;
};

// Helper function to add "st", "nd", "rd", or "th" to the day
const getDayWithSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return day + "th";
  }
  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
};
