import classnames from "classnames";
import React from "react";

type ModalHeaderProps = {
  className?: string;
  children: any;
};

/**
 * Component for VxP modal header. To be wrapped in Modal component
 * @ModalHeaderProps children
 */

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  className,
  children,
}) => {
  return (
    <section className={classnames("modal_header_section", className)}>
      {children}
    </section>
  );
};
