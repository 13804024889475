import React, {
  useState,
  useContext,
  ChangeEvent,
  Fragment,
  useMemo,
  useEffect,
} from "react";
import {
  RosterHeader,
  useActiveSpeakersState,
  useAttendeeAudioStatus,
} from "amazon-chime-sdk-component-library-react";

import { useAttendeeStatus } from "amazon-chime-sdk-component-library-react";
import { IconButton } from "./buttons/iconButton";
import classnames from "classnames";
import { useDataEvents } from "../providers/dataEvents";
import { EventTypes } from "../providers/types";
import { useAppContext } from "../contexts/appContext";
import { MeetingRole, Participant } from "../contexts/types";
import {
  DataEmotesActionType,
  EmoteTypes,
  useDataEmotes,
} from "../providers/dataEmotes";
import { DARK, ThemeContext } from "../contexts/themeContext";
import SimpleBar from "simplebar-react";
import variables from "../styles/variables.scss";
import { AttendeeInitials } from "./attendeeInitials";
import { Icon } from "./icon";
import { MoreActionsButton } from "./toolbars/controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "./toolbars/controls/moreActions/moreActionsWrapper";
import { MoreActionsItem } from "./toolbars/controls/moreActions/moreActionsItem";
import { Button, PRIMARY, SMALL } from "./buttons/button";
import { useRoster } from "../providers/rosterProvider";
import { useSoftBan } from "../hooks/useSoftBan";
import { Filter, FilterBar } from "./inputs/filterBar";
import { ThemeWrapper } from "./themeWrapper";

type RosterParticipantProps = {
  participant: Participant;
  activeFilter?: Filter;
};

enum RosterFilters {
  unmuted,
  cameraOn,
  handRaised,
  all,
}

type RosterFiltersObject = {
  All: Filter;
  Unmuted: Filter;
  CameraOn: Filter;
  HandRaised: Filter;
};

const rosterFilters: RosterFiltersObject = {
  All: {
    active: true,
    filterName: "All",
    value: RosterFilters.all,
    id: "all-roster",
  },
  Unmuted: {
    active: false,
    filterName: "Unmuted",
    value: RosterFilters.unmuted,
    id: "unmuted-roster",
  },
  CameraOn: {
    active: false,
    filterName: "Camera on",
    value: RosterFilters.cameraOn,
    id: "camera-on-roster",
  },
  HandRaised: {
    active: false,
    filterName: "Hand raised",
    value: RosterFilters.handRaised,
    id: "hand-raised-roster",
  },
};

export const RosterParticipant: React.FC<RosterParticipantProps> = ({
  participant,
  activeFilter,
}) => {
  const { isInstructorOrModerator, state } = useAppContext();
  const { sendEvent } = useDataEvents();
  const { sendEmote, raisedHands } = useDataEmotes();
  const { chimeAttendeeId } = participant || {};
  const participantStats = useAttendeeStatus(participant.chimeAttendeeId);
  const { signalStrength, muted } = useAttendeeAudioStatus(
    participant.chimeAttendeeId
  );

  const { theme } = useContext(ThemeContext);
  const { handleSoftBan, handleUndoSoftBan, loading } = useSoftBan();
  const wrapperId = useMemo(
    () => `roster-participant-wrapper__${participant.chimeAttendeeId}`,
    [participant]
  );

  const fillColor = theme === DARK ? variables.white : variables.darkGrey;
  const strokeColor = theme === DARK ? variables.white : variables.darkGrey;
  const signalStrengthString =
    signalStrength > 0.6 ? "great" : signalStrength < 0.2 ? "bad" : "okay";

  participant["signalStrength"] = signalStrength;
  participant["muted"] = muted;
  participant["videoEnabled"] = participantStats.videoEnabled;
  participant["connectionQuality"] =
    signalStrength > 0.6
      ? variables.actionGreen
      : signalStrength < 0.2
      ? variables.actionRed
      : variables.colorYellow;

  const includeMoreActions =
    participant.meetingRole !== MeetingRole.Moderator &&
    participant.meetingRole !== MeetingRole.Presenter;

  const [showMoreActions, setShowMoreActions] = useState(false);
  const toggleMoreActions = () => setShowMoreActions(!showMoreActions);

  const handleRaisedHand = () => {
    if (isInstructorOrModerator) {
      sendEmote({
        type: EmoteTypes.HAND_LOWER,
        leader: false,
        action: DataEmotesActionType.REMOVE,
        targetAttendeeId: participant.chimeAttendeeId,
      });
    }
  };

  const [display, setDisplay] = useState(true);
  const activeHand = raisedHands.has(participant.chimeAttendeeId);

  useEffect(() => {
    if (activeFilter?.value === RosterFilters.unmuted && muted) {
      setDisplay(false);
    } else if (
      activeFilter?.value === RosterFilters.cameraOn &&
      !participantStats.videoEnabled
    ) {
      setDisplay(false);
    } else if (
      activeFilter?.value === RosterFilters.handRaised &&
      !activeHand
    ) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [
    activeFilter,
    muted,
    participant,
    participantStats.videoEnabled,
    activeHand,
    raisedHands,
  ]);

  return (
    display && (
      <div
        key={chimeAttendeeId}
        className={classnames(`roster-participant-wrapper`, {
          "--participant-speaking": participant.speaking,
          "__soft-ban": participant.softBanned,
        })}
        id={wrapperId}
      >
        {participant.softBanned && (
          <span
            className={classnames("roster-participant__tool-tip", {
              "__hide-tool-tip": showMoreActions,
            })}
          >
            <strong>{participant.name} has been soft banned.</strong> They are
            unable to use their camera, microphone, or send messages.
          </span>
        )}
        <div className="roster-participant-wrapper__name-initials">
          <AttendeeInitials
            iconColor={participant?.iconColor}
            name={participant?.firstName + " " + participant?.lastName}
            className={classnames(`chat-live-area__chat-initials`, {
              "chat-live-area__chat-initials__participant-hand-raised":
                raisedHands.has(participant.chimeAttendeeId),
              "chat-live-area__chat-initials__participant-speaking":
                participant.speaking && !participant.muted,
            })}
            superscript={
              participant?.softBanned && (
                <Icon
                  name="slash"
                  toolTip="none"
                  desc={`${participant.firstName} is soft banned`}
                  height="16px"
                  fill={theme === DARK ? variables.darkGrey : variables.white}
                  stroke={variables.actionRed}
                />
              )
            }
          />{" "}
          <span className="__name">{participant.name}</span>
        </div>
        <div className="roster-participant-wrapper__buttons">
          <IconButton
            iconName={participant.muted ? "mic-off" : "mic"}
            onClick={() => {
              if (isInstructorOrModerator && !participant?.muted) {
                sendEvent(
                  {
                    type: EventTypes.MUTE_INDIVIDUAL,
                    leader: true,
                    attendeeId: participant.chimeAttendeeId,
                  },
                  state
                );
              }
            }}
            desc={
              participant?.muted
                ? `${participant.name} is muted`
                : `Mute ${participant.name}`
            }
            toolTip="top"
            stroke={participant.muted ? "" : variables.actionGreen}
            btnId={"mute-mic-button-" + chimeAttendeeId}
            size="24px"
            hoverState={!participant.muted}
            disabled={participant.softBanned}
            overflowContainerId={wrapperId}
          />
          <IconButton
            iconName={participant.videoEnabled ? "video" : "video-off"}
            onClick={() => {
              if (isInstructorOrModerator && participant?.videoEnabled) {
                sendEvent(
                  {
                    type: EventTypes.VIDEO_OFF_INDIVIDUAL,
                    leader: true,
                    attendeeId: participant.chimeAttendeeId,
                  },
                  state
                );
              }
            }}
            desc={
              participant.videoEnabled
                ? `Turn ${participant.name}'s video off`
                : `${participant.name} video off`
            }
            toolTip="top"
            stroke={participant.videoEnabled ? variables.actionGreen : ""}
            size="24px"
            hoverState={participant.videoEnabled}
            disabled={participant.softBanned}
            overflowContainerId={wrapperId}
          />
          {participant.meetingRole === 0 && (
            <IconButton
              onClick={() => {
                if (raisedHands.has(participant.chimeAttendeeId)) {
                  handleRaisedHand();
                }
              }}
              hoverState={raisedHands.has(participant.chimeAttendeeId)}
              iconName="hand"
              desc={
                raisedHands.has(participant.chimeAttendeeId)
                  ? `Lower ${participant.name}'s hand`
                  : ""
              }
              fill={
                raisedHands.has(participant.chimeAttendeeId)
                  ? fillColor
                  : "none"
              }
              stroke={strokeColor}
              toolTip={
                raisedHands.has(participant.chimeAttendeeId) ? "top" : "none"
              }
              btnId="raise-your-hand-button"
              overflowContainerId={wrapperId}
            />
          )}
          <IconButton
            iconName="wifi"
            onClick={() => {}}
            desc={`${participant.name}'s connection quality is ${signalStrengthString}`}
            toolTip="top"
            stroke={participant.connectionQuality}
            size="24px"
            hoverState={false}
            overflowContainerId={wrapperId}
          />
          {includeMoreActions && (
            <MoreActionsButton
              showActionsInput={showMoreActions}
              toolTip="top"
              alignment="horizontal"
              id={"roster-participant__more__" + chimeAttendeeId}
              onClick={toggleMoreActions}
              overflowContainerId={wrapperId}
            >
              <MoreActionsWrapper
                itemClick={() => setShowMoreActions(false)}
                upOrDown="up"
                leftOrRight="left"
              >
                <MoreActionsItem
                  id={"roster-participant__more__toggle__" + chimeAttendeeId}
                >
                  <Button
                    btnType={PRIMARY}
                    id={"soft-ban-button-" + chimeAttendeeId}
                    text={
                      !participant.softBanned
                        ? `Soft ban ${participant.name}`
                        : `Remove ${participant.name}'s soft ban`
                    }
                    customWidth="100%"
                    size={SMALL}
                    onClick={() => {
                      !participant.softBanned
                        ? handleSoftBan(participant.AttendeeId)
                        : handleUndoSoftBan(participant.AttendeeId);
                    }}
                    icon={!participant.softBanned ? "slash" : ""}
                    disabled={Boolean(loading)}
                  />
                </MoreActionsItem>
              </MoreActionsWrapper>
            </MoreActionsButton>
          )}
        </div>
      </div>
    )
  );
};

type ParticipantListProps = {
  setShowRoster: (showRoster: boolean) => void;
};

export const ParticipantList: React.FC<ParticipantListProps> = ({
  setShowRoster,
}) => {
  const { roster } = useRoster();
  const [filter, setFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState<Filter>({
    active: true,
    filterName: "All",
    value: RosterFilters.all,
    id: "all-roster",
  });

  const [students, setStudents] = useState<Participant[]>([]);
  const [instructors, setInstructors] = useState<Participant[]>([]);
  const [moderators, setModerators] = useState<Participant[]>([]);
  const [numberOfParticipants, setNumberOfParticipants] = useState<number>(0);
  const activeSpeaker: string[] = useDebounce(useActiveSpeakersState(), 1500);
  const participants = useMemo(() => {
    let tempParticipants = Object.values(roster) as Participant[];
    tempParticipants = tempParticipants?.filter(
      (attendee: Participant) =>
        attendee?.name?.toLowerCase().includes(filter?.trim().toLowerCase()) &&
        !attendee?.externalUserId.includes("MediaPipeline")
    );
    return tempParticipants;
  }, [roster, filter]);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes
      // once the value settles after the delay return the debouncedValue
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  useEffect(() => {
    let tempInstructors = [];
    let tempModerators = [];
    let tempStudents = [];
    const alphaParticipants: Participant[] = participants?.sort((a, b) => {
      if (a?.name?.toUpperCase() < b?.name?.toUpperCase()) {
        return -1;
      }
      if (a?.name?.toUpperCase() > b?.name?.toUpperCase()) {
        return 1;
      }
      return 0;
    });
    alphaParticipants.forEach((part) => {
      part.speaking = false;
      if (activeSpeaker?.indexOf(part.chimeAttendeeId) > -1) {
        part.speaking = true;
      }
      if (part.meetingRole === MeetingRole.Presenter) {
        tempInstructors.push(part);
      }
      if (part.meetingRole === MeetingRole.Moderator) {
        tempModerators.push(part);
      } else if (part.meetingRole === MeetingRole.Audience) {
        tempStudents.push(part);
      }
    });
    setNumberOfParticipants(participants?.length ? participants.length : 0);
    setInstructors(tempInstructors);
    setModerators(tempModerators);
    setStudents(tempStudents);
  }, [participants, activeSpeaker]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const closeRoster = () => setShowRoster(false);

  const handleFilterChange = (filter: Filter) => {
    if (activeFilter?.value === filter?.value) {
      // If the same filter is clicked, toggle it off (set to undefined)
      setActiveFilter(rosterFilters.All);
    } else {
      // Set the clicked filter as active
      setActiveFilter(filter);
    }
  };

  return (
    <Fragment>
      <RosterHeader
        searchValue={filter}
        onSearch={handleSearch}
        onClose={closeRoster}
        title="Present"
        badge={numberOfParticipants}
        className="participant-list-header"
      />
      <div className="participant-list-filter-wrapper">
        {/* Filter bar */}
        <FilterBar
          handleFilters={(e, i) => handleFilterChange(e)}
          hasLabel={false}
          activeFilter={activeFilter?.filterName}
          filters={Object.values(rosterFilters)}
        />
      </div>

      <section className="participant-list-container">
        <SimpleBar
          forceVisible="y"
          autoHide={true}
          style={{ maxHeight: "425px" }}
          className="chat-container__simplebar"
        >
          <div className="participant-list-container__roster">
            <h3 className="participant-list-container__headings">Students</h3>
            {students.map((participant) => {
              return (
                <RosterParticipant
                  participant={participant}
                  key={participant.AttendeeId}
                  activeFilter={activeFilter}
                />
              );
            })}
            <h3 className="participant-list-container__headings">
              Instructors
            </h3>
            {instructors.map((participant) => {
              return (
                <RosterParticipant
                  participant={participant}
                  key={participant.AttendeeId}
                />
              );
            })}
            <h3 className="participant-list-container__headings">Moderators</h3>
            {moderators.map((participant) => {
              return (
                <RosterParticipant
                  participant={participant}
                  key={participant.AttendeeId}
                />
              );
            })}
          </div>
        </SimpleBar>
      </section>
    </Fragment>
  );
};
