import { gql } from "@apollo/client";

export const MEETING_FIELDS = gql`
  fragment MeetingFields on Meeting {
    id
    title
    MeetingId
    meetingTime
    ExternalMeetingId
    AudioHostUrl
    AudioFallbackUrl
    ScreenDataUrl
    ScreenSharingUrl
    ScreenViewingUrl
    SignalingUrl
    TurnControlUrl
    EventIngestionUrl
    MediaRegion
    instructorAV
    studentAV
    chat
    muteLock
    videoOffLock
    courseId
    currentlyRecording
    lobbyActive
  }
`;

export const ATTENDEE_FIELDS = gql`
  fragment AttendeeFields on Attendee {
    id
    firstName
    lastName
    ExternalUserId
    AttendeeId
    JoinToken
    iconColor
    meetingRole
    softBanned
  }
`;

export const BOARD_FIELDS = gql`
  fragment BoardFields on Board {
    id
    title
    sortOrder
    type
    caption
  }
`;

export const BOARD_IMAGE_FIELDS = gql`
  fragment BoardImageFields on BoardImage {
    id
    tileSource
    x
    y
    width
    height
  }
`;

export const PANORAMA_FIELDS = gql`
  fragment PanoramaFields on Panorama {
    id
    name
    sourceUrl
    top
    bottom
    left
    right
    front
    back
    collection
  }
`;

export const BOARD_VIDEO_FIELDS = gql`
  fragment BoardVideoFields on BoardVideo {
    id
    sourceUrl
    start
    end
  }
`;

export const BOARD_THREEDEE_FIELDS = gql`
  fragment BoardThreedeeFields on BoardThreedee {
    id
    sourceUrl
    scale
  }
`;

export const COURSE_FIELDS = gql`
  fragment CourseFields on Course {
    id
    name
    slug
  }
`;

export const BASE_MEETING_FIELDS = gql`
  fragment BaseMeetingFields on Meeting {
    id
    meetingTime
    title
    lobbyActive
    muteLock
    videoOffLock
    instructorAV
    studentAV
    chat
  }
`;

export const IMAGE_UPLOAD_FIELDS = gql`
  fragment ImageUploadFields on ImageUpload {
    id
    caption
    uploadUrl
    thumbnailUrl
    tileSource
    displayDate
    people
    title
    permissions
  }
`;
