import React, { useState } from "react";
import {
  Button,
  ButtonType,
  PRIMARY,
  SECONDARY,
  SMALL,
  STANDARD,
} from "../buttons/button";
import { ModalBody } from "../modal/modalBody";
import { ModalFooter } from "../modal/modalFooter";
import { ModalHeader } from "../modal/modalHeader";

type PopUpProps = {
  descriptMessage?: string | JSX.Element;
  userMessage?: string | JSX.Element;
  popUpHeader: string;
  buttonText1?: string;
  buttonText2?: string;
  buttonType1?: ButtonType;
  buttonType2?: ButtonType;
  onClick1?: (e) => void;
  onClick2?: (e) => void;
};

/**
 * Component for VxP popup messages. Contains Modal, ModalHeader, ModalBody and ModalFooter components.
 * @PopUpProps descriptMessage, userMessage, popUpHeader, buttonText1, buttonText2, onClick1, onClick2
 */

export const PopUp: React.FC<PopUpProps> = ({
  descriptMessage,
  userMessage,
  popUpHeader,
  buttonText1,
  buttonText2,
  buttonType1,
  buttonType2,
  onClick1,
  onClick2,
}) => {
  const [showDescriptMessage, setShowDescriptMessage] = useState(false);
  return (
    <div className="pop-up">
      <ModalHeader>
        <h3>{popUpHeader}</h3>
      </ModalHeader>
      <ModalBody>
        <div className="pop-up__user-message">{userMessage}</div>
        {descriptMessage && (
          <div className="pop-up__details">
            <Button
              btnType={SECONDARY}
              disabled={false}
              size={SMALL}
              text={showDescriptMessage ? "Hide details" : "View details"}
              onClick={() => setShowDescriptMessage(!showDescriptMessage)}
              id="popup-details-button"
            />
            {showDescriptMessage && <div>{descriptMessage}</div>}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="pop-up__buttons">
          {buttonText1 && (
            <Button
              btnType={buttonType1 ? buttonType1 : PRIMARY}
              disabled={false}
              size={STANDARD}
              text={buttonText1}
              onClick={onClick1}
              id="popup-footer-first-button"
            />
          )}
          {buttonText2 && (
            <Button
              btnType={buttonType2 ? buttonType2 : SECONDARY}
              disabled={false}
              size={STANDARD}
              text={buttonText2}
              onClick={onClick2}
              id="popup-footer-second-button"
            />
          )}
        </div>
      </ModalFooter>
    </div>
  );
};
