import React, { Fragment, useState } from "react";
import { Board, DeletedBoard } from "../types";
import { ModalHeader } from "./modal/modalHeader";
import { Button, PRIMARY, SECONDARY, STANDARD } from "./buttons/button";
import classnames from "classnames";
import { ModalFooter } from "./modal/modalFooter";
import { ModalBody } from "./modal/modalBody";
import { useMutation } from "@apollo/client";
import { DELETE_BOARD } from "../graphql/mutations";
import { useAppContext } from "../contexts/appContext";
import { ActionType } from "../contexts/types";

type DeletModalProps = {
  handleClose: (e) => void;
  boardInfo?: Board;
  handleRefetchBoards?: () => void;
  onCompleted: (currentVersion: number) => void;
};

export const DeleteBoard: React.FC<DeletModalProps> = ({
  handleClose,
  boardInfo,
  handleRefetchBoards,
  onCompleted,
}) => {
  const { state, dispatch } = useAppContext();

  const [deleteBoardError, setDeleteBoardError] = useState(false);
  const [deleteOrClose, setDeleteOrClose] = useState<"delete" | "close">(
    "delete"
  );

  const [deleteBoardMutation] = useMutation(DELETE_BOARD, {
    onCompleted(data: { deleteBoard: DeletedBoard }) {
      const currentVersion = data.deleteBoard.currentVersion;
      onCompleted(currentVersion);
      // Select index for board to move to
      // The logic here is a little weird because we are setting the current
      // before the slides are refetched since that relies on useState and updates
      // inconsistently
      let newIndex: number;
      if (boardInfo.sortOrder === 1) newIndex = 0;
      else if (
        boardInfo.sortOrder === state.currentBoard?.sortOrder &&
        boardInfo.sortOrder === state.slides?.length
      )
        newIndex = boardInfo.sortOrder - 2;
      else if (boardInfo.sortOrder < state.currentBoard?.sortOrder)
        newIndex = state.currentBoard?.sortOrder - 2;
      else if (boardInfo.sortOrder === state.currentBoard?.sortOrder)
        newIndex = state.currentBoard?.sortOrder - 1;

      if (newIndex && state.slides?.length > 0) {
        dispatch({
          type: ActionType.UPDATE_CURRENT_BOARD,
          payload: {
            currentBoard: state.slides[newIndex],
          },
        });
      }

      // Refetch presentation information
      handleRefetchBoards();
    },
    onError(error) {
      setDeleteBoardError(true);
    },
  });

  const deleteBoardHeader =
    deleteOrClose === "delete"
      ? "Delete this board?"
      : deleteBoardError
      ? "Oh no!"
      : "Success!";
  const deleteBoardText =
    deleteOrClose === "delete"
      ? "Are you sure you want to delete this board?"
      : deleteBoardError
      ? "There was a problem deleting your board"
      : "Your board was deleted.";

  const deleteBoard = () => {
    const input = {
      id: boardInfo.id,
    };
    setDeleteOrClose("close");
    deleteBoardMutation({ variables: { input } });
  };

  const reFetchBoards = (e) => {
    if (!deleteBoardError) {
      handleRefetchBoards();
    }
    handleClose(e);
  };

  return (
    <Fragment>
      <ModalHeader>
        <h1 className="delete-board-heading">{deleteBoardHeader}</h1>
      </ModalHeader>
      <ModalBody>
        <div className="delete-board-content">
          <span>{deleteBoardText}</span>
          {deleteOrClose === "delete" && (
            <div>
              Are you sure? Once a board is deleted it cannot be undone.
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {deleteOrClose === "delete" && (
          <div className="delete-board-footer__buttons">
            <Button
              btnType={SECONDARY}
              text="Cancel"
              size={STANDARD}
              disabled={false}
              inputClass="delete-board-secondary"
              onClick={(e) => {
                handleClose(e);
              }}
            />
          </div>
        )}
        <div
          className={classnames("delete-board-footer__buttons --primary", {
            "--single-button": deleteOrClose === "close",
          })}
        >
          <Button
            btnType={PRIMARY}
            text={deleteOrClose === "delete" ? "Delete" : "Close"}
            size={STANDARD}
            disabled={false}
            onClick={(e) => {
              if (deleteOrClose === "delete") {
                deleteBoard();
              } else {
                reFetchBoards(e);
              }
            }}
            id={
              deleteOrClose === "delete"
                ? "delete-board-button"
                : "close-delete-board-button"
            }
          />
        </div>
      </ModalFooter>
    </Fragment>
  );
};
