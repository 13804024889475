import classnames from "classnames";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { DARK, ThemeType } from "../../contexts/themeContext";
import { formatReplayTime } from "../../utils/timeUtiles";
import { Board } from "../../types";
import { ReplayPreview } from "../replay/replayPreview";
import { useScreenContext } from "../../contexts/screenContext";
import variables from "../../styles/variables.scss";

type ProgressBarProps = {
  onClick?: (value: number) => void;
  max: number;
  value: number;
  id?: string;
  className?: string;
  disabled?: boolean;
  theme?: ThemeType;
  chapters?: VTTCue[];
  activeChapter?;
  slides?: Board[];
};

/**
 * Component for an VxP progressBar
 * @ProgressBarProps id, onClick, max, value, className, disabled
 */

export const ProgressBar: React.FC<ProgressBarProps> = ({
  id,
  onClick,
  max,
  value,
  className,
  disabled,
  chapters,
  theme = DARK, // Defaulting to dark theme for videos used on canvas with dark backgrounds
  activeChapter,
  slides,
}: ProgressBarProps) => {
  const progressRef = useRef(null);
  const [hoveredValue, setHoveredValue] = useState(0); // Track the hovered value
  const { isLarge } = useScreenContext();
  const touchPreviewId = "chapter-marker__touch-preview";

  // Add event handlers for progress bar value updates on click, hover, and touch
  useEffect(() => {
    const progressBar = progressRef?.current;

    function progressSelector(e) {
      e.preventDefault();

      if (disabled) {
        return;
      }
      const progressContainer = document.getElementById(
        "progress-container-" + id
      );
      const maxWidth = progressBar?.clientWidth;
      const clientRect = progressContainer?.getBoundingClientRect();
      const position = e.clientX - clientRect?.left;
      let percentage = Math.round((position / maxWidth) * 100);

      if (percentage > 100) {
        percentage = 100;
      }

      const returnValue = (percentage / 100) * max;
      onClick(returnValue);
    }

    function handleMouseMove(e) {
      if (!disabled) {
        const maxWidth = progressBar?.clientWidth;
        const clientRect = progressBar?.getBoundingClientRect();
        const position = e.clientX - clientRect?.left;
        let percentage = (position / maxWidth) * 100;

        if (percentage < 0) {
          percentage = 0;
        } else if (percentage > 100) {
          percentage = 100;
        }

        const returnValue = (percentage / 100) * max;
        setHoveredValue(returnValue);
      }
    }

    function handleMouseLeave() {
      setHoveredValue(0);
    }

    function handleTouchEvent(e: TouchEvent) {
      e.preventDefault();

      if (disabled) {
        return;
      }
      const progressContainer = document.getElementById(
        "progress-container-" + id
      );
      const maxWidth = progressBar?.clientWidth;
      const clientRect = progressContainer?.getBoundingClientRect();
      const position: number = e.targetTouches[0]?.clientX - clientRect?.left;
      let percentage = Math.round((position / maxWidth) * 100);

      if (percentage > 100) {
        percentage = 100;
      }

      const returnValue = (percentage / 100) * max;
      onClick(returnValue);

      const chapter = document.getElementById(touchPreviewId);
      if (chapter) chapter.style.visibility = "visible";
    }

    function handleTouchEnd(e: TouchEvent) {
      e.preventDefault();
      const chapter = document.getElementById(touchPreviewId);
      if (chapter) chapter.style.visibility = "hidden";
    }

    progressBar?.addEventListener("click", progressSelector);
    progressBar?.addEventListener("mousemove", handleMouseMove);
    progressBar?.addEventListener("mouseleave", handleMouseLeave);
    progressBar?.addEventListener("touchmove", handleTouchEvent);
    progressBar?.addEventListener("touchstart", handleTouchEvent);
    progressBar?.addEventListener("touchend", handleTouchEnd);

    return function () {
      progressBar?.removeEventListener("click", progressSelector);
      progressBar?.removeEventListener("mousemove", handleMouseMove);
      progressBar?.removeEventListener("mouseleave", handleMouseLeave);
      progressBar?.removeEventListener("touchmove", handleTouchEvent);
      progressBar?.removeEventListener("touchstart", handleTouchEvent);
      progressBar?.removeEventListener("touchend", handleTouchEnd);
    };
  }, [max, onClick, disabled, value]);

  useEffect(() => {
    progressRef.current?.style.setProperty("--w", (value / max) * 100 + "%");
  }, [value, max]);

  const calculateChapterLeft = (start, end) => {
    // Get window width
    const windowWidth = window.innerWidth;
    // Get progress bar container width
    const container = document.getElementById("progress-container-" + id);
    const containerWidth = container?.clientWidth;
    // Get preview width
    const previewWidth = parseInt(
      variables.chapterMarkerPreviewWidth.split("px")[0]
    );

    // If there is enough room around the progress bar to accomodate the preview, calculate based on percentage
    if (windowWidth - containerWidth > previewWidth * 2) {
      if (start && end) {
        return (start / end) * 100 + "%";
      }
      return "initial";
    }
    // Otherwise
    else {
      if (start && end) {
        const percent = start / end;
        const containerAndPreviewWidth =
          containerWidth * percent + previewWidth + container?.offsetLeft;
        let offset: number;

        if (containerAndPreviewWidth < windowWidth) {
          offset = 0;
        } else {
          offset = windowWidth - containerAndPreviewWidth + previewWidth / 1.5;
        }

        return `calc(${percent * 100}% - ${offset}px)`;
      }
      return "initial";
    }
  };

  // Add the event handlers for the chapter preview on hover
  useEffect(() => {
    const divs = document.querySelectorAll(".chapter-marker");

    function handleMouseMove(event, index) {
      const chapter = document.getElementById("chapter-marker" + index);
      const hoverDiv = document.getElementById(
        "chapter-sub-container__left" + index
      );

      const mouseX = event.clientX - chapter?.getBoundingClientRect().left;
      const chapterWidth = chapter?.clientWidth;
      const positionPercentage = (mouseX / chapterWidth) * 100;
      if (hoverDiv) {
        hoverDiv.style.width = `${positionPercentage}%`;
        hoverDiv.style.height = "16px";
      }
    }

    function handleMouseLeave(event, index) {
      const hoverSubChapter = document.getElementById(
        "chapter-sub-container__left" + index
      );
      const hoverDiv = document.getElementById(
        "chapter-sub-container__left" + index
      );
      if (hoverSubChapter && hoverDiv) {
        hoverDiv.style.width = "0";
        hoverSubChapter.style.width = "0";
        hoverSubChapter.style.height = "12px";
      }
    }

    divs.forEach((div, index) => {
      div?.addEventListener("mousemove", (e) => handleMouseMove(e, index));
      div?.addEventListener("mouseleave", (e) => handleMouseLeave(e, index));
    });
    return function () {
      divs.forEach((div) => {
        div?.removeEventListener("mousemove", (e) => handleMouseMove);
        div?.removeEventListener("mouseleave", (e) => handleMouseLeave);
      });
    };
  }, [chapters]);

  function calculateTimeIntoChapter(
    currentValue,
    maxValue,
    chapterDuration,
    chapterStart
  ) {
    const progressPercentage = (currentValue / maxValue) * 100;
    const timeIntoChapter = currentValue - chapterStart;
    return (timeIntoChapter / chapterDuration) * 100;
  }

  return (
    <div
      ref={progressRef}
      className={classnames("progress-container", {
        "--disabled": disabled,
        "progress-container--dark": theme === DARK,
        "progress-container--replay-container": chapters,
      })}
      id={"progress-container-" + id}
    >
      {chapters && (
        <div
          style={{
            left: calculateChapterLeft(
              chapters[activeChapter - 1]?.startTime,
              max
            ),
            visibility: "hidden",
          }}
          className="chapter-marker__preview"
          id={touchPreviewId}
        >
          <ReplayPreview
            chapter={chapters[activeChapter - 1]}
            time={formatReplayTime(value)}
          />
        </div>
      )}
      {isLarge &&
        chapters &&
        chapters?.map((chapter, index) => (
          <Fragment key={index}>
            <div
              className={classnames(
                "chapter-marker",
                "chapter-marker" + index,
                {
                  "active-chapter": index === activeChapter,
                  "hover-chapter": true,
                  "chapter-marker--past-chapter": index + 1 < activeChapter,
                }
              )}
              style={{
                width: calculateChapterLeft(
                  index === chapters.length - 1
                    ? max - chapter.startTime
                    : chapter.endTime - chapter?.startTime,
                  max
                ),
                left: calculateChapterLeft(chapter?.startTime, max),
              }}
              id={"chapter-marker" + index}
            >
              <div
                className={classnames("sub-chapter", {
                  "sub-chapter--past-chapter": index + 1 < activeChapter,
                })}
                id={"chapter-sub-container__left" + index}
              ></div>
              {index + 1 === activeChapter && (
                <div
                  style={{
                    width:
                      calculateTimeIntoChapter(
                        value,
                        max,
                        Math.abs(
                          chapter.startTime -
                            (index === chapters.length - 1
                              ? max
                              : chapter.endTime)
                        ),
                        chapter.startTime
                      ) + "%",
                  }}
                  className="current-chapter-played"
                ></div>
              )}
            </div>
            <div
              style={{
                left: calculateChapterLeft(chapter?.startTime, max),
              }}
              className="chapter-marker__preview"
            >
              <ReplayPreview
                chapter={chapter}
                time={formatReplayTime(hoveredValue)}
              />
            </div>
          </Fragment>
        ))}

      <progress
        className={classnames("progress-bar", {
          className,
        })}
        id={id}
        value={value}
        max={max}
      ></progress>
      <div
        className={classnames("progress-overlay", {
          "progress-overlay--chapters": chapters,
        })}
        style={{
          width: `${(hoveredValue / max) * 100}%`,
        }}
      ></div>
    </div>
  );
};
