import React from "react";
import { useAppContext } from "../../../contexts/appContext";
import {
  DataEmotesActionType,
  EmoteTypes,
  useDataEmotes,
} from "../../../providers/dataEmotes";
import { IconButton } from "../../buttons/iconButton";

type LowerAllHandsButtonProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  emitClick?: (e) => void;
};

export const LowerAllHandsButton: React.FC<LowerAllHandsButtonProps> = ({
  toolTip,
  emitClick,
}) => {
  const { sendEmote, raisedHands } = useDataEmotes();
  const { isInstructorOrModerator } = useAppContext();

  const lowerAllAction = (e) => {
    sendEmote({
      type: EmoteTypes.HAND_LOWER,
      leader: isInstructorOrModerator,
      action: DataEmotesActionType.REMOVE,
      clearRaisedHands: true,
    });
    emitClick(e);
  };

  return (
    <IconButton
      onClick={lowerAllAction}
      iconName="hands-lower-all"
      desc="Lower all hands"
      toolTip={toolTip}
      counter={raisedHands.size !== 0}
      counterValue={raisedHands.size < 10 ? raisedHands.size : "9+"}
    />
  );
};
