import React, { useState } from "react";
import classnames from "classnames";
import { Container } from "./container";
import { Switch } from "./inputs/switch";
import { consoleNonProd } from "../utils/utilityBeltUtils";
import { useMutation } from "@apollo/client";
import { MUTE_LOCK, VIDEO_OFF_LOCK } from "../graphql/mutations";
import { Mutation } from "../types";
import { useAppContext } from "../contexts/appContext";
import { ActionType } from "../contexts/types";
import { EventTypes } from "../providers/types";
import { useDataEvents } from "../providers/dataEvents";

export const MeetingOptions: React.FC<{}> = () => {
  const { state, isInstructorOrModerator, dispatch, isStudentAVMeeting } =
    useAppContext();
  const { sendEvent } = useDataEvents();
  const [muteLockError, setMuteLockError] = useState(false);
  const [videoOffLockError, setVideoOffLockError] = useState(false);

  const [toggleMuteLockMutation, { loading: loadingMuteLock }] = useMutation(
    MUTE_LOCK,
    {
      onCompleted(data: Pick<Mutation, "toggleMuteLock">) {
        setMuteLockError(false);
        const newMuteLock = data?.toggleMuteLock?.meeting?.muteLock;
        sendEvent &&
          sendEvent(
            {
              type: EventTypes.MUTE_LOCK,
              leader: isInstructorOrModerator,
              muteLock: newMuteLock,
            },
            state
          );
        dispatch &&
          dispatch({
            type: ActionType.UPDATE_MUTE_LOCK,
            payload: {
              muteLock: newMuteLock,
            },
          });
      },
      onError(error) {
        setMuteLockError(true);
        consoleNonProd("error in toggleMuteLockMutation", error);
      },
    }
  );

  const [toggleVideoOffLockMutation, { loading: loadingVideoOffLock }] =
    useMutation(VIDEO_OFF_LOCK, {
      onCompleted(data: Pick<Mutation, "toggleVideoOffLock">) {
        setVideoOffLockError(false);
        const newVideoOffLock = data?.toggleVideoOffLock?.meeting?.videoOffLock;
        sendEvent &&
          sendEvent(
            {
              type: EventTypes.VIDEO_OFF_LOCK,
              leader: isInstructorOrModerator,
              videoOffLock: newVideoOffLock,
            },
            state
          );
        dispatch &&
          dispatch({
            type: ActionType.UPDATE_VIDEO_OFF_LOCK,
            payload: {
              videoOffLock: newVideoOffLock,
            },
          });
      },
      onError(error) {
        setVideoOffLockError(true);
        consoleNonProd("error in toggleVideoOffLockMutation", error);
      },
    });

  return (
    <div className="meeting-options-container-theme">
      <Container
        className={classnames("meeting-options-container")}
        childrenClassName="meeting-options-section"
        isDismissable={false}
      >
        <h1 className="meeting-options-section__h1">Meeting options</h1>
        <div className="meeting-options-section__mute-lock-container">
          <div className="meeting-options-section__one-line">
            <label htmlFor="switch-mute-lock">
              <h4 className="meeting-options-section__heading">Mute Lock</h4>
            </label>
            <Switch
              className="nav__switch"
              onClick={() => {
                toggleMuteLockMutation({
                  variables: {
                    id: state.currentBoard?.meetingId,
                    muteLock: !state.muteLock,
                  },
                });
              }}
              isOn={state.muteLock}
              id="switch-mute-lock"
              disabled={loadingMuteLock || !isStudentAVMeeting}
            />
          </div>
          <div>
            <p className="meeting-options-section__subtext">
              Turning on ‘Mute lock’ will turn off and disable the microphone
              for all students.
            </p>
            {muteLockError && (
              <span className="meeting-options-section__subtext meeting-options-section__subtext--error">
                Something went wrong setting the mute lock. Please try again.
              </span>
            )}
          </div>
        </div>

        <div className="meeting-options-section__video-lock-container">
          <div className="meeting-options-section__one-line">
            <label htmlFor="switch-video-off-lock">
              <h4 className="meeting-options-section__heading">
                Video Off Lock
              </h4>
            </label>
            <Switch
              className="nav__switch"
              onClick={() => {
                toggleVideoOffLockMutation({
                  variables: {
                    id: state.currentBoard?.meetingId,
                    videoOffLock: !state.videoOffLock,
                  },
                });
              }}
              isOn={state.videoOffLock}
              id="switch-video-off-lock"
              disabled={loadingVideoOffLock || !isStudentAVMeeting}
            />
          </div>
          <div>
            <p className="meeting-options-section__subtext">
              Turning on ‘Video off lock’ will turn off and disable cameras for
              all students.
            </p>
            {videoOffLockError && (
              <span className="meeting-options-section__subtext meeting-options-section__subtext--error">
                Something went wrong setting the video off lock. Please try
                again.
              </span>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
