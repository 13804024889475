import React, { createContext, useContext, useEffect, useState } from "react";
import {
  isMobile,
  isTablet,
  isDesktop,
  browserName,
  browserVersion,
} from "react-device-detect";
import variables from "../styles/variables.scss";

export type ScreenContextType = {
  orientation: "portrait" | "landscape";
  screenWidth: number;
  screenHeight: number;
  mobileScreenHeight: number;
  isExtraSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isExtraLarge: boolean;
  isExtraExtraLarge: boolean;
  isValidBrowser: boolean;
  useMobileTools: boolean;
};

const ScreenContext = createContext<ScreenContextType>({
  orientation: null,
  screenWidth: null,
  screenHeight: null,
  mobileScreenHeight: null,
  isExtraSmall: null,
  isSmall: null,
  isMedium: null,
  isLarge: null,
  isExtraLarge: null,
  isExtraExtraLarge: null,
  isValidBrowser: null,
  useMobileTools: null,
});

export const ScreenContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [orientation, setOrientation] = useState<"portrait" | "landscape">(
    window.innerHeight > window.innerWidth ? "portrait" : "landscape"
  );

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
  const doc = document.documentElement;
  const [mobileScreenHeight, setMobileScreenHeight] = useState<number>(
    calculateMobileScreen()
  );

  // NOTE: use !isMedium || !desktop for mobile to catch responsive screen sizes and device detection
  const [isExtraSmall] = useState<boolean>(true);
  const [isSmall, setIsSmall] = useState<any>(
    window.matchMedia(`(min-width: ${variables.breakpoint_small_start}px)`)
      .matches
  );
  const [isMedium, setIsMedium] = useState<boolean>(
    window.matchMedia(`(min-width: ${variables.breakpoint_medium_start}px)`)
      .matches
  );
  const [isLarge, setIsLarge] = useState<boolean>(
    window.matchMedia(`(min-width: ${variables.breakpoint_large_start}px)`)
      .matches
  );
  const [isExtraLarge, setIsExtraLarge] = useState<boolean>(
    window.matchMedia(`(min-width: ${variables.breakpoint_xlarge_start}px)`)
      .matches
  );
  const [isExtraExtraLarge, setIsExtraExtraLarge] = useState<boolean>(
    window.matchMedia(`(min-width: ${variables.breakpoint_xxlarge_start}px)`)
      .matches
  );
  const [isValidBrowser, setIsValidBrowser] = useState<boolean>(true);
  const [useMobileTools, setUseMobileTools] = useState<boolean>(false);

  //// helper functions ////
  function calculateMobileScreen(): number {
    return (
      window.innerHeight -
      Number.parseInt(
        getComputedStyle(document.documentElement)?.getPropertyValue(
          "--safe-area-top"
        )
      ) -
      Number.parseInt(
        getComputedStyle(document.documentElement)?.getPropertyValue(
          "--safe-area-bottom"
        )
      )
    );
  }

  /** Handles mobile tools display   */
  useEffect(() => {
    if (!isMedium || !isDesktop || isMobile) {
      // if not large screen or not desktop, use mobile tools
      setUseMobileTools(true);
    }
    if (isTablet || isMedium) {
      // use desktop tools for tablet
      setUseMobileTools(false);
    }
    if (isMobile && !isTablet) {
      setUseMobileTools(true);
    }
  }, [
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isExtraExtraLarge,
    isDesktop,
    isTablet,
    orientation,
  ]);

  useEffect(() => {
    let browserValueNumer = Number.parseInt(browserVersion);
    switch (browserName) {
      case "Firefox":
        browserValueNumer > 74
          ? setIsValidBrowser(true)
          : setIsValidBrowser(false);
        break;
      case "Chrome":
        if (!isMobile) {
          browserValueNumer > 77
            ? setIsValidBrowser(true)
            : setIsValidBrowser(false);
        } else {
          browserValueNumer > 59
            ? setIsValidBrowser(true)
            : setIsValidBrowser(false);
        }
        break;
      case "Edge":
        browserValueNumer > 78
          ? setIsValidBrowser(true)
          : setIsValidBrowser(false);
        break;
      case "Safari":
        browserValueNumer >= 13
          ? setIsValidBrowser(true)
          : setIsValidBrowser(false);
        break;
      case "Mobile Safari":
        browserValueNumer >= 12
          ? setIsValidBrowser(true)
          : setIsValidBrowser(false);
        break;
      default:
        setIsValidBrowser(true);
        break;
    }
  }, [browserName, browserVersion]);

  // media queries currently set up for open ended queries we can add max values if we want
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio#monitoring_screen_resolution_or_zoom_level_changes
  useEffect(() => {
    const smallWatcher = window.matchMedia(
      `(min-width: ${variables.breakpoint_small_start}px)`
    );
    const mediumWatcher = window.matchMedia(
      `(min-width: ${variables.breakpoint_medium_start}px)`
    );
    const largeWatcher = window.matchMedia(
      `(min-width: ${variables.breakpoint_large_start}px)`
    );
    const extraLargeWatcher = window.matchMedia(
      `(min-width: ${variables.breakpoint_xlarge_start}px)`
    );
    const extraExtraLargeWatcher = window.matchMedia(
      `(min-width: ${variables.breakpoint_xxlarge_start}px)`
    );

    function updateSmall(e) {
      setIsSmall(e.matches);
    }
    function updateMedium(e) {
      setIsMedium(e.matches);
    }
    function updateLarge(e) {
      setIsLarge(e.matches);
    }
    function updateExtraLarge(e) {
      setIsExtraLarge(e.matches);
    }
    function updateExtraExtraLarge(e) {
      setIsExtraExtraLarge(e.matches);
    }

    if (smallWatcher?.addEventListener) {
      smallWatcher?.addEventListener("change", updateSmall);
      mediumWatcher?.addEventListener("change", updateMedium);
      largeWatcher?.addEventListener("change", updateLarge);
      extraLargeWatcher?.addEventListener("change", updateExtraLarge);
      extraExtraLargeWatcher?.addEventListener("change", updateExtraExtraLarge);
    } else {
      smallWatcher?.addListener(updateSmall);
      mediumWatcher?.addListener(updateMedium);
      largeWatcher?.addListener(updateLarge);
      extraLargeWatcher?.addListener(updateExtraLarge);
      extraExtraLargeWatcher?.addListener(updateExtraExtraLarge);
    }

    return function cleanup() {
      if (smallWatcher?.removeEventListener) {
        smallWatcher?.removeEventListener("change", updateSmall);
        mediumWatcher?.removeEventListener("change", updateMedium);
        largeWatcher?.removeEventListener("change", updateLarge);
        extraLargeWatcher?.removeEventListener("change", updateExtraLarge);
        extraExtraLargeWatcher?.removeEventListener(
          "change",
          updateExtraExtraLarge
        );
      } else {
        smallWatcher?.removeListener(updateSmall);
        mediumWatcher?.removeListener(updateMedium);
        largeWatcher?.removeListener(updateLarge);
        extraLargeWatcher?.removeListener(updateExtraLarge);
        extraExtraLargeWatcher?.removeListener(updateExtraExtraLarge);
      }
    };
  }, []);

  useEffect(() => {
    // width and height and orientation, fires on resize and orientation
    function setHeightWidthOrientation() {
      const currentPortrait = window.innerHeight > window.innerWidth;
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
      setMobileScreenHeight(calculateMobileScreen());
      // set the css var for css based updates
      document?.documentElement?.style.setProperty(
        "--mobile-available-screen",
        `${calculateMobileScreen()}px`
      );
      setOrientation(currentPortrait ? "portrait" : "landscape");
    }
    document?.documentElement?.style.setProperty(
      "--mobile-available-screen",
      `${calculateMobileScreen()}px`
    );
    window.addEventListener("resize", setHeightWidthOrientation);
    window.addEventListener("orientationchange", setHeightWidthOrientation);
    if (window?.screen?.orientation) {
      window.screen.orientation.addEventListener(
        "change",
        setHeightWidthOrientation
      );
    }

    return () => {
      window.removeEventListener("resize", setHeightWidthOrientation);
      window.removeEventListener(
        "orientationchange",
        setHeightWidthOrientation
      );
      if (window?.screen?.orientation) {
        window.screen.orientation.removeEventListener(
          "change",
          setHeightWidthOrientation
        );
      }
    };
  }, []);

  const providerValue: ScreenContextType = {
    // mobile,
    // tablet,
    // desktop,
    orientation,
    screenWidth,
    screenHeight,
    mobileScreenHeight,
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isExtraExtraLarge,
    isValidBrowser,
    useMobileTools,
  };

  return (
    <ScreenContext.Provider value={providerValue}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreenContext = (): ScreenContextType => {
  const context = useContext(ScreenContext);

  if (!context) {
    throw new Error("Use useScreenContext hook inside ScreenContextProvider.");
  }

  return context;
};
