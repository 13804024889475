import React, {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useAudioVideo } from "amazon-chime-sdk-component-library-react";
import { DataMessage } from "amazon-chime-sdk-js";

import { useAppContext } from "../contexts/appContext";
import { DATA_MESSAGE_LIFETIME_MS, DATA_MESSAGE_TOPIC } from "../constants";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";
import { consoleNonProd } from "../utils/utilityBeltUtils";
import { AttendeeState, MeetingRole } from "../contexts/types";
import { useFilter } from "../hooks/useFilter";

export interface ChatDataMessage {
  message: string;
  senderAttendeeId: string;
  timestamp: number;
  senderName: string;
  isSelf: boolean;
  color?: string;
  messageId: string; // Message ID is created by joining the timestamp and the sender attendee id
  deleted: boolean;
  deleteData?: {
    senderAttendeeId: string;
    timestamp: number;
    senderName: string;
  };
  meetingRole?: MeetingRole;
  softBanned: boolean;
}

interface DataMessageInput {
  message?: string;
  deleted: boolean;
  messageId: string;
  meetingRole?: MeetingRole;
  softBanned: boolean;
}

interface DataMessagesStateContextType {
  sendMessage: (message: string, attendee: AttendeeState) => void;
  deleteMessage: (message: ChatDataMessage, deletePermission: boolean) => void;
  resetMessages: () => void;
  messages: ChatDataMessage[];
  messageCount: number;
}

const DataMessagesStateContext = createContext<
  DataMessagesStateContextType | undefined
>(undefined);

enum DataMessagesActionType {
  ADD,
  DELETE,
  RESET,
}

interface State {
  messages: ChatDataMessage[];
  messageCount: number;
}

interface AddAction {
  type: DataMessagesActionType.ADD;
  payload: ChatDataMessage;
}

interface DeleteAction {
  type: DataMessagesActionType.DELETE;
  payload: {
    messageId: string;
    senderAttendeeId: string;
    timestamp: number;
    senderName: string;
  };
}

interface ResetAction {
  type: DataMessagesActionType.RESET;
  payload: { resetMessages: boolean };
}

type Action = AddAction | DeleteAction | ResetAction;

const getInitialState = (): State => {
  const timestamp = new Date().getTime();

  return {
    messages: [
      {
        color: "#d64220", // setting Barnes orange
        isSelf: false,
        message:
          "Hello and welcome to the Barnes Visual Experience platform! If you experience any technical issues, please contact our Student Help Desk line at 267.831.4464 or email support@barnesclasses.org",
        senderAttendeeId: "",
        senderName: "Barnes Foundation",
        timestamp,
        messageId: `${timestamp}__`,
        deleted: false,
        meetingRole: MeetingRole.Moderator,
        softBanned: false,
      },
    ],
    messageCount: 1,
  };
};

function reducer(state: State, action: Action): State {
  const { type, payload } = action;
  let messageCount = state.messageCount;

  switch (type) {
    case DataMessagesActionType.ADD:
      messageCount += 1;
      return { messages: [...state.messages, payload], messageCount };

    case DataMessagesActionType.DELETE:
      const messages = [...state.messages];
      const index = messages?.findIndex((message) => {
        return message?.messageId === payload.messageId;
      });
      const deletedMessage = messages[index];
      if (deletedMessage) {
        deletedMessage.deleted = true;
        deletedMessage.deleteData = {
          senderAttendeeId: payload.senderAttendeeId,
          timestamp: payload.timestamp,
          senderName: payload.senderName,
        };
      }
      messages[index] = deletedMessage;

      messageCount -= 1;

      return { messages, messageCount };

    case DataMessagesActionType.RESET:
      const initialMessage = getInitialState();
      return {
        messages: initialMessage.messages,
        messageCount: initialMessage.messageCount,
      };

    default:
      throw new Error("Incorrect action in DataMessagesProvider reducer");
  }
}

export const DataMessagesProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { localUserName, userColor, meetingRole } = useAppContext();
  const meetingManager = useMeetingWithRoster();
  const audioVideo = useAudioVideo();
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { filterMessage } = useFilter();

  const handler = useCallback(
    (dataMessage: DataMessage) => {
      if (!dataMessage.throttled) {
        const isSelf =
          dataMessage.senderAttendeeId ===
          meetingManager.meetingSession?.configuration.credentials?.attendeeId;

        const messageData: DataMessageInput = JSON.parse(
          new TextDecoder().decode(dataMessage.data)
        );

        const senderName = isSelf
          ? dataMessage.senderExternalUserId
          : dataMessage.json().senderName;

        if (messageData.deleted) {
          dispatch({
            type: DataMessagesActionType.DELETE,
            payload: {
              messageId: messageData.messageId,
              senderAttendeeId: dataMessage.senderAttendeeId,
              timestamp: dataMessage.timestampMs,
              senderName,
            },
          });
        } else {
          // Filter the message
          const cleanedMessage = filterMessage(messageData.message || "");

          if (isSelf) {
            dispatch({
              type: DataMessagesActionType.ADD,
              payload: {
                message: cleanedMessage,
                senderAttendeeId: dataMessage.senderAttendeeId,
                timestamp: dataMessage.timestampMs,
                senderName,
                isSelf: true,
                color: userColor,
                messageId: messageData.messageId,
                deleted: false,
                meetingRole: messageData.meetingRole,
                softBanned: messageData.softBanned,
              },
            });
          } else {
            const data = dataMessage.json();
            dispatch({
              type: DataMessagesActionType.ADD,
              payload: {
                message: cleanedMessage,
                senderAttendeeId: dataMessage.senderAttendeeId,
                timestamp: dataMessage.timestampMs,
                senderName,
                isSelf: false,
                color: data.color,
                messageId: data.messageId,
                deleted: false,
                meetingRole: messageData.meetingRole,
                softBanned: messageData.softBanned,
              },
            });
          }
        }
      } else {
        consoleNonProd("DataMessage is throttled. Please resend");
      }
    },
    [meetingManager, userColor, filterMessage]
  );

  const sendMessage = useCallback(
    (message: string, attendee: AttendeeState) => {
      if (
        !meetingManager ||
        !meetingManager.meetingSession ||
        !meetingManager.meetingSession.configuration.credentials ||
        !meetingManager.meetingSession.configuration.credentials.attendeeId ||
        !audioVideo
      ) {
        return;
      }

      const cleanedMessage = filterMessage(message);

      const timestamp = new Date().getTime();

      const senderAttendeeId =
        meetingManager.meetingSession.configuration.credentials.attendeeId;

      const payload = {
        message: cleanedMessage,
        senderName: localUserName,
        color: userColor,
        deleted: false,
        messageId: `${timestamp}__${senderAttendeeId}`,
        meetingRole,
        softBanned: attendee.softBanned,
      };

      audioVideo.realtimeSendDataMessage(
        DATA_MESSAGE_TOPIC,
        payload,
        DATA_MESSAGE_LIFETIME_MS
      );

      handler(
        new DataMessage(
          timestamp,
          DATA_MESSAGE_TOPIC,
          new TextEncoder().encode(JSON.stringify(payload)),
          senderAttendeeId,
          localUserName
        )
      );
    },
    [
      meetingManager,
      audioVideo,
      handler,
      localUserName,
      userColor,
      meetingRole,
      filterMessage,
    ]
  );

  const deleteMessage = useCallback(
    (message: ChatDataMessage, deletePermission = false) => {
      // Return if meeting manager is not initialized
      if (
        !meetingManager ||
        !meetingManager.meetingSession ||
        !meetingManager.meetingSession.configuration.credentials ||
        !meetingManager.meetingSession.configuration.credentials.attendeeId ||
        !audioVideo
      ) {
        return;
      }

      const canDeleteMessage = deletePermission || message.isSelf;

      // Check user permissions
      if (!canDeleteMessage) {
        return;
      }

      const payload = {
        messageId: message.messageId,
        deleted: true,
        senderName: localUserName,
      };

      const senderAttendeeId =
        meetingManager.meetingSession.configuration.credentials.attendeeId;

      audioVideo.realtimeSendDataMessage(
        DATA_MESSAGE_TOPIC,
        payload,
        DATA_MESSAGE_LIFETIME_MS
      );

      handler(
        new DataMessage(
          Date.now(),
          DATA_MESSAGE_TOPIC,
          new TextEncoder().encode(JSON.stringify(payload)),
          senderAttendeeId,
          localUserName
        )
      );
    },
    [meetingManager, audioVideo, handler, localUserName]
  );

  const resetMessages = useCallback(() => {
    if (
      !meetingManager ||
      !meetingManager.meetingSession ||
      !meetingManager.meetingSession.configuration.credentials ||
      !meetingManager.meetingSession.configuration.credentials.attendeeId ||
      !audioVideo
    ) {
      return;
    }

    dispatch({
      type: DataMessagesActionType.RESET,
      payload: {
        resetMessages: true,
      },
    });
  }, [meetingManager, audioVideo]);

  const value = {
    sendMessage,
    deleteMessage,
    resetMessages,
    messages: state.messages,
    messageCount: state.messageCount,
  };

  // Subscribe and unsubscribe to chat messages
  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    audioVideo.realtimeSubscribeToReceiveDataMessage(
      DATA_MESSAGE_TOPIC,
      handler
    );

    return () => {
      audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC);
    };
  }, [audioVideo, handler]);

  return (
    <DataMessagesStateContext.Provider value={value}>
      {children}
    </DataMessagesStateContext.Provider>
  );
};

export const useDataMessages = (): {
  sendMessage: (message: string, attendee: AttendeeState) => void;
  deleteMessage: (message: ChatDataMessage, deletePermissions: boolean) => void;
  resetMessages: () => void;
  messages: ChatDataMessage[];
  messageCount: number;
} => {
  const meetingManager = useMeetingWithRoster();
  const context = useContext(DataMessagesStateContext);

  if (!meetingManager || !context) {
    throw new Error(
      "Use useDataMessages hook inside DataMessagesProvider. Wrap DataMessagesProvider under MeetingProvider."
    );
  }
  return context;
};
