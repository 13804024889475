import React from "react";
import { useAppContext } from "../../../contexts/appContext";
import { Button, PRIMARY, SMALL } from "../../buttons/button";
import { MeetingRole } from "../../../contexts/types";

type MobileParticipantsToggleProps = {
  showInstructors: boolean;
  toggleInstructors: () => void;
};

export const MobileParticipantsToggle: React.FC<
  MobileParticipantsToggleProps
> = ({ showInstructors, toggleInstructors }) => {
  const {
    showParticipants,
    toggleParticipants,
    isStudentAVMeeting,
    isInstructorAVMeeting,
    meetingRole,
  } = useAppContext();
  const hideInChatOnly =
    !isInstructorAVMeeting &&
    !isStudentAVMeeting &&
    meetingRole !== MeetingRole.Presenter &&
    meetingRole !== MeetingRole.Moderator;

  return (
    <>
      {isInstructorAVMeeting && (
        <Button
          btnType={PRIMARY}
          id="mobile-instructor-toggle-button"
          text={showInstructors ? "Hide instructors" : "Show instructors"}
          customWidth="100%"
          size={SMALL}
          disabled={false}
          onClick={(e) => {
            toggleInstructors();
          }}
          icon={showInstructors ? "user-x" : "user"}
        />
      )}

      {isStudentAVMeeting && (
        <Button
          btnType={PRIMARY}
          id="mobile-student-toggle-button"
          inputClass="__more-actions-secondary"
          text={showParticipants ? "Hide students" : "Show students"}
          customWidth="100%"
          size={SMALL}
          disabled={false}
          onClick={(e) => {
            toggleParticipants();
          }}
          icon={showParticipants ? "hide-participants" : "view-participants"}
        />
      )}
    </>
  );
};
