import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "video.js/dist/video-js.css";
import classnames from "classnames";
import Player from "video.js/dist/types/player";
import Canvas from "../../components/canvas/canvas";
import Spinner from "../../components/spinner";
import { useAppContext } from "../../contexts/appContext";
import {
  ActionType,
  AttendeeState,
  CanvasMode,
  EventToggleExploreModeAction,
} from "../../contexts/types";
import {
  Board,
  BoardType,
  GenerateVttStatus,
  Meeting,
  PresentationVersion,
} from "../../types";
import { useReplayDataEvents } from "../../providers/replayDataEvents";
import { useReplayCaptionsEvents } from "../../providers/replayDataCaptions";
import { EventPayload, EventTypes } from "../../providers/types";
import { useScreenContext } from "../../contexts/screenContext";
import { VideoTile, VideoType } from "../../components/videos/videoTile";
import config from "../../utils/config";
import { useReplayDataMessages } from "../../providers/replayDataMessages";
import { Chat } from "../chat";
import { Toggle } from "../inputs/toggle";
import { HelperPopUp } from "../popUps/helperPopUp";
import { StorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { REPLAY_IDLE_TIMEOUT } from "../../constants";
import { VideoPlayer } from "./videoPlayer";
import { IconButton } from "../buttons/iconButton";
import { useDataEventHandler } from "../../hooks/useDataEventHandler";
import { ChapterList } from "./chapterList";
import { getObject } from "../../utils/s3Utils";
import { UserVideoToggle } from "../toolbars/controls/userVideoToggle";

type ReplayProps = {
  replayMeeting: Meeting;
  sendMessage?: (message: string, attendee?: AttendeeState) => void;
};

export const Replay: React.FC<ReplayProps> = ({
  replayMeeting,
  sendMessage,
}) => {
  const navigate = useNavigate();
  const { handleSetDbId, state, dispatch, showChat } = useAppContext();
  const { useMobileTools } = useScreenContext();
  const { findLastInteractiveEvent, getActionForDataEvent } =
    useDataEventHandler();

  const playerRef = useRef<Player>();
  const { events, addEvent } = useReplayDataEvents();
  const { setLocalStorage, getLocalStorage } = useLocalStorage();
  const { updateReplayChatEvents, chatEvents, messageCount } =
    useReplayDataMessages();
  const { updateCaptionEvent, replayCaptionEvents } = useReplayCaptionsEvents();
  const [presentationVersion, setPresentationVersion] = useState(0);
  const [meeting, setMeeting] = useState<Meeting>(undefined);
  const [compositedVideoUrl, setCompositedVideoUrl] = useState<string>();
  const [audioOnlyUrl, setAudioOnlyUrl] = useState<string>();
  const [eventCues, setEventCues] = useState<string>();
  const [chatCues, setChatCues] = useState<string>();
  const [chapterCues, setChapterCues] = useState<string>();
  const [captionCues, setCaptionCues] = useState<string>();
  const [replayChapters, setReplayChapters] = useState<VTTCue[]>();
  const [activeChapter, setActiveChapter] = useState(1);
  const [showToggleHelper, setShowToggleHelper] = useState(false);
  const [isMinimizedIndicator, setIsMinimizeIndicator] = useState(false);
  const [hasVTTChat, setHasVTTChat] = useState(false);
  const [hasVTTCaptions, setHasVTTCaptions] = useState(false);
  const [hasVTTChapters, setHasVTTChapters] = useState(false);
  const [showInstructors, setShowInstructors] = useState(true);
  const [showChapterList, setShowChapterList] = useState(false);
  const [presentationVersionLog, setPresentationVersionLog] =
    useState<PresentationVersion[]>();
  const [showVideo, setShowVideo] = useState(true);

  /** Populate the replay onMount and reset canvas state on unmount */
  useEffect(() => {
    if (replayMeeting) {
      let hasCaptions = false;
      let hasChat = false;
      let hasChapters = false;

      if (replayMeeting?.mediaPipelines) {
        hasCaptions =
          replayMeeting?.mediaPipelines[0]?.generateCaptionsStatus ===
          GenerateVttStatus.SUCCESS;
        setHasVTTCaptions(hasCaptions);

        hasChat =
          replayMeeting?.mediaPipelines[0]?.generateChatStatus ===
          GenerateVttStatus.SUCCESS;
        setHasVTTChat(hasChat);

        hasChapters =
          replayMeeting?.mediaPipelines[0].generateChaptersStatus ===
          GenerateVttStatus.SUCCESS;
        setHasVTTChapters(hasChapters);
      }
      setMeeting(replayMeeting);
      handleSetDbId(replayMeeting.id);

      // Get VTT track files from S3
      const concatFolder = replayMeeting.mediaPipelines[0].awsConcatPipelineId;
      setCompositedVideoUrl(
        `${config.replayCf}/${concatFolder}/m3u8/master.m3u8`
      );
      setAudioOnlyUrl(
        `${config.replayCf}/${concatFolder}/audio/${concatFolder}.mp4`
        //`${config.replayCf}/9b7b565b-5462-473c-9c8f-b4d95953f776/audio/9b7b565b-5462-473c-9c8f-b4d95953f776.mp4`
      );
      setEventCues(
        `${config.replayCf}/${concatFolder}/cues/data-events-metadata-track.vtt`
      );
      hasChat &&
        setChatCues(
          `${config.replayCf}/${concatFolder}/cues/data-chat-metadata-track.vtt`
        );
      hasChapters &&
        setChapterCues(
          `${config.replayCf}/${concatFolder}/cues/data-chapters-metadata-track.vtt`
        );
      hasCaptions &&
        setCaptionCues(
          `${config.replayCf}/${concatFolder}/cues/closed-captions-metadata-track.vtt`
        );

      // uncomment for local chat testing using hard-coded file
      // const chatCues = require("../../9426cb97-4112-4943-b8d4-a3d6785a80a4_chat-events.vtt");
      // or
      // `${config.replayCf}/9426cb97-4112-4943-b8d4-a3d6785a80a4/cues/data-chat-metadata-track-real-chat.vtt`
      // setChatCues(chatCues);
      const fetchPresentationLog = async () => {
        const resp = await getObject(
          config.concatS3Bucket,
          `${concatFolder}/logs/presentation_log.json`
        );
        // @ts-ignore
        const data = await resp.body.transformToString();
        const pvLog = JSON.parse(data);
        setPresentationVersionLog(pvLog);

        // Set initial presentation version and content from the first version in the log
        setPresentationVersion(pvLog[0].versionNumber);
        dispatch({
          type: ActionType.HANDLE_GET_PRESENTATION,
          payload: {
            currentBoard: pvLog[0]?.content[0] as any as Board,
            slides: pvLog[0]?.content as any[] as Board[],
          },
        });
      };

      fetchPresentationLog();
    } else {
      navigate(`/`);
    }
    return () => {
      dispatch({
        type: ActionType.SET_INIT_STATE,
      });
    };
  }, []);

  /** update the presentation version on an presentation version change */
  useEffect(() => {
    if (meeting && events && events.length > 0) {
      const canvasDataEv = events[events.length - 1];
      const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
      if (eventPayload.type === EventTypes.PRESENTATION_VERSION_CHANGE) {
        setPresentationVersion(eventPayload.presentationVersion);
      } else if (eventPayload?.presentationVersion !== presentationVersion) {
        setPresentationVersion(eventPayload.presentationVersion);
      }
    }
  }, [events, meeting, presentationVersion]);

  /** separate useEffect to set the slides otherwise the setPresentation loops endlessly above */
  useEffect(() => {
    const availableVersions = presentationVersionLog?.map(
      (pv) => pv.versionNumber
    );

    if (availableVersions?.includes(presentationVersion)) {
      const currentPV = presentationVersionLog.find(
        (pv) => pv.versionNumber === presentationVersion
      );

      dispatch({
        type: ActionType.UPDATE_SLIDES,
        payload: {
          slides: currentPV.content as any[] as Board[],
        },
      });
    }
  }, [dispatch, presentationVersion, presentationVersionLog]);

  /** Add on hover handler to the Focus/Explore toggle */
  useEffect(() => {
    const toggleDiv = document.getElementsByClassName(
      "replay__explore-focus"
    )[0];
    const handleMouseOver = (_e) => setShowToggleHelper(true);
    const handleMouseOut = (_e) => setShowToggleHelper(false);

    if (!toggleDiv || useMobileTools) return;

    toggleDiv?.addEventListener("mouseover", handleMouseOver);
    toggleDiv?.addEventListener("mouseout", handleMouseOut);

    return () => {
      toggleDiv?.removeEventListener("mouseover", handleMouseOver);
      toggleDiv?.removeEventListener("mouseout", handleMouseOut);
    };
  });

  const handleToggleMinimizeIndicator = () => {
    setIsMinimizeIndicator(!isMinimizedIndicator);
  };

  const handleToggleExploreMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const exploreMode: boolean = e.target.checked;
    let payload: EventToggleExploreModeAction["payload"] = { exploreMode };

    // If toggled into explore mode, the current explore mode is false, and replay explore is false
    if (exploreMode && !state.exploreMode && !state.replayExplore) {
      payload["showLaser"] = false;
      payload["laserInstructor"] = undefined;
      payload["replayExplore"] = true;
      payload["exploreMode"] = exploreMode;
      dispatch({
        type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
        payload,
      });
    }
    // If toggled into explore mode, the current explore mode is true, and replay explore is true
    else if (exploreMode && state.replayExplore && state.exploreMode) {
      dispatch({
        type: ActionType.UPDATE_REPLAY_EXPLORE,
        payload: { replayExplore: false },
      });
    }
    // If toggled out of explore mode, the current explore mode is true, and replay explore is true
    else if (!exploreMode && state.exploreMode && state.replayExplore) {
      let eventPayload: EventPayload;
      if (events && events.length > 0) {
        const canvasDataEv = events[events.length - 1];
        if (canvasDataEv) {
          eventPayload = JSON.parse(canvasDataEv?.event);
        }
      }
      payload["replayExplore"] = false;
      payload["exploreMode"] = eventPayload
        ? eventPayload.exploreMode
        : !state.exploreMode;
      dispatch({
        type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
        payload,
      });
    }
    // If toggled out of explore mode, the current explore mode is true, and replay explore is false
    else if (!exploreMode && state.exploreMode && !state.replayExplore) {
      payload["showLaser"] = false;
      payload["laserInstructor"] = undefined;
      dispatch({
        type: ActionType.UPDATE_REPLAY_EXPLORE,
        payload: { replayExplore: true },
      });
    } else {
      dispatch({
        type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
        payload,
      });
    }

    // If toggled out of explore mode, set the view to the previous event
    if (!exploreMode) {
      const dataEvent = findLastInteractiveEvent(events);

      // If an event with an interaction is found for the board, set state based on the event
      if (dataEvent) {
        const event: EventPayload = JSON.parse(dataEvent.event);

        // set state based on that event
        const action = getActionForDataEvent(
          event,
          false,
          false,
          setShowInstructors
        );

        // If there are values in the payload, dispatch the AppContext state update
        if (Object.keys(action.payload).length) {
          dispatch(action);
        }
      }
      // Otherwise reset the state to the default view
      else {
        let event: EventPayload;

        switch (state.currentBoard.type) {
          case BoardType.IIIF:
            // Add "HOME" event
            event = {
              type: EventTypes.HOME,
              leader: true,
              exploreMode,
              spotlightInstructor: state.spotlightInstructor,
              presentationVersion: state.presentationVersion,
              showLaser: state.showLaser,
              currentSlideNumber: state.currentBoard?.sortOrder,
            };
            break;

          case BoardType.PANORAMA:
            // Currently there is no home/default view on panorama boards
            break;

          case BoardType.THREEDEE:
            // Add "THREE_DEE_HOME" event
            event = {
              type: EventTypes.THREE_DEE_HOME,
              leader: true,
              exploreMode,
              spotlightInstructor: state.spotlightInstructor,
              presentationVersion: state.presentationVersion,
              showLaser: state.showLaser,
              currentSlideNumber: state.currentBoard?.sortOrder,
            };
            break;

          case BoardType.VIDEO:
            // Currently there is no home/default on video boards
            break;
        }

        if (event) {
          addEvent({
            event: JSON.stringify(event),
            isSelf: false,
            senderAttendeeId: "",
            senderName: "",
            timestamp: 0,
          });
        }
      }
    }
  };

  const handleSelectChapter = (timestamp: number, chapter: number) => {
    playerRef?.current?.currentTime(timestamp);
    setActiveChapter(chapter);
    setShowChapterList(false);
  };

  useEffect(() => {
    if (showChat) {
      setShowChapterList(false);
    }
  }, [showChat]);

  function helperBottomCalculator(): string {
    if (!state.replayExplore) return "-90px";
    if (state.replayExplore) return "-130px";
    if (state.replayExplore && isMinimizedIndicator) return "-96px";
    if (state.replayExplore) return "-120px";
    if (isMinimizedIndicator) return "-54px";
    if (!state.replayExplore && state.exploreMode) return "-120px";
    else return "-80px";
  }

  return (
    <Fragment>
      {!meeting ? (
        <Spinner />
      ) : (
        <div className="replay">
          <div className="videos">
            <div className="videos__wrapper">
              <div
                className={classnames("instructor__wrapper", "__replay", {
                  "__mobile-instructor": useMobileTools,
                  __spotlight: state.spotlightInstructor,
                  show: showVideo,
                  hide: !showVideo,
                })}
              >
                {/* Instructor video */}
                <VideoTile
                  isVideoEnabled={true}
                  dismissable={true}
                  isInstructorOrModerator={false}
                  videoType={VideoType.REPLAY}
                  speaking={false}
                  iconHex=""
                  name=""
                  handRaised={false}
                  toggleShowVideo={() => setShowVideo(false)}
                >
                  <VideoPlayer
                    videoSrc={compositedVideoUrl}
                    audioSrc={audioOnlyUrl}
                    eventCues={eventCues}
                    chatCues={chatCues}
                    chapterCues={chapterCues}
                    captionCues={captionCues}
                    handleEventCueChange={addEvent}
                    handleChatCueChange={updateReplayChatEvents}
                    handleCaptionCueChange={updateCaptionEvent}
                    setReplayChapters={setReplayChapters}
                    setActiveChapter={setActiveChapter}
                    playerRef={playerRef}
                  />
                </VideoTile>
              </div>
              <div className="icon-tool__wrapper">
                <div className="icon-tool__wrapper">
                  <div
                    className={classnames("icon-tool__inner", {
                      show: !showVideo,
                      hide: showVideo,
                    })}
                    style={{ top: "-4px", left: "4px" }}
                  >
                    <UserVideoToggle
                      toolTip="bottom"
                      showVideo={showVideo}
                      toggleShowVideo={() => setShowVideo(true)}
                      videoType={VideoType.REPLAY}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames(`replay__explore-focus`, {
              "mobile-tools": useMobileTools,
              "minimized-indicator": isMinimizedIndicator,
            })}
          >
            {/* Explore/Focus Indicator for A Path */}
            {isMinimizedIndicator ? (
              <div className="icon-tool__wrapper">
                <div className="icon-tool__inner">
                  <IconButton
                    iconName={state.exploreMode ? "explore" : "focus"}
                    desc={state.exploreMode ? "Explore" : "Focus"}
                    toolTip="none"
                    btnId="toggle-replay-explore-icon-button"
                    onClick={handleToggleMinimizeIndicator}
                  />
                </div>
              </div>
            ) : (
              <div className="replay__explore-focus--following">
                <div
                  className={classnames(
                    `replay__explore-focus--following-text`,
                    {
                      "mobile-tools": useMobileTools,
                      "minimized-indicator": isMinimizedIndicator,
                    }
                  )}
                >
                  <span className="replay__explore-focus--following-text--strong">
                    You are
                    <strong
                      className={classnames(
                        `replay__explore-focus--following-text--strong`,
                        {
                          "mobile-tools": useMobileTools,
                          "minimized-indicator": isMinimizedIndicator,
                        }
                      )}
                    >
                      {!state.replayExplore ? " following " : " not following "}
                    </strong>
                    the class
                  </span>
                  {!isMinimizedIndicator && (
                    <div
                      className={classnames("close__wrapper", {
                        active: !isMinimizedIndicator,
                        dismissed: isMinimizedIndicator,
                      })}
                    >
                      <IconButton
                        size="1rem"
                        onClick={handleToggleMinimizeIndicator}
                        iconName="dismiss"
                        desc="Close"
                        btnId="close-container-button"
                        toolTip="right"
                      />
                    </div>
                  )}
                </div>
                <Toggle
                  option1="Explore"
                  option2="Focus"
                  name="focus-explore-toggle"
                  onChange={handleToggleExploreMode}
                  icon1="focus"
                  icon2="explore"
                  disabled={state.exploreMode && !state.replayExplore}
                  checked={state.exploreMode}
                />
              </div>
            )}

            {/* Help text for the Focus/Explore Toggle */}
            <div className="replay__explore-focus-helper--container">
              {showToggleHelper && (
                <HelperPopUp
                  userMessage={
                    state.replayExplore
                      ? "You are no longer following the instructor and can explore the board freely."
                      : !state.replayExplore && state.exploreMode
                      ? "You are following the instructor. The class is in explore mode, you can explore the board freely."
                      : "You are following the instructor."
                  }
                  right={isMinimizedIndicator ? "-48px" : ""}
                  centerWidth={!isMinimizedIndicator}
                  upArrow={true}
                  bottom={helperBottomCalculator()}
                  arrowRight={isMinimizedIndicator ? "12px" : ""}
                  isTimed={true}
                  dismissTimeout={10}
                  minWidth="300px"
                />
              )}

              {!state.replayExplore &&
                !getLocalStorage(StorageKeys.followHelper) && (
                  <HelperPopUp
                    id="replay-follow-pop"
                    userMessage="You are now following the instructor. Interact with the board or click the toggle to freely explore the board."
                    bottom={isMinimizedIndicator ? "-124px" : "-160px"}
                    right={isMinimizedIndicator ? "-48px" : ""}
                    centerWidth={!isMinimizedIndicator}
                    downArrow={false}
                    isDismissible={true}
                    isTimed={true}
                    dismissTimeout={10}
                    minWidth="300px"
                    upArrow={false}
                    includeDontShowAgain={true}
                    handleSetShowAgainCheckbox={() =>
                      setLocalStorage(StorageKeys.followHelper, "true")
                    }
                  />
                )}
              {state.replayExplore &&
                !getLocalStorage(StorageKeys.exploreHelper) && (
                  <HelperPopUp
                    userMessage={`You are no longer following the instructor. To resume following click the toggle. You will automatically resume following after ${REPLAY_IDLE_TIMEOUT} seconds of inactivity.`}
                    bottom={isMinimizedIndicator ? "-144px" : "-180px"}
                    right={isMinimizedIndicator ? "-48px" : ""}
                    centerWidth={!isMinimizedIndicator}
                    downArrow={false}
                    isDismissible={true}
                    isTimed={true}
                    dismissTimeout={10}
                    minWidth="300px"
                    upArrow={false}
                    includeDontShowAgain={true}
                    handleSetShowAgainCheckbox={() =>
                      setLocalStorage(StorageKeys.exploreHelper, "true")
                    }
                  />
                )}
            </div>
          </div>
          <Canvas
            mode={CanvasMode.REPLAY}
            handleRefetchBoards={() => null}
            events={events}
            playerRef={playerRef}
            useReplayChat={hasVTTChat}
            useReplayCaptions={hasVTTCaptions}
            messages={chatEvents}
            chapters={replayChapters}
            activeChapter={activeChapter}
            replayExplore={state.replayExplore}
            replayCaptions={replayCaptionEvents}
            showInstructors={showVideo}
            toggleInstructors={() => setShowVideo(!showVideo)}
            setShowInstructors={setShowInstructors}
            handleSelectChapter={handleSelectChapter}
            maxTimeMs={replayMeeting?.mediaPipelines[0]?.videoLength}
            showChapterList={showChapterList}
            setShowChapterList={setShowChapterList}
          />
          {showChat && !useMobileTools && (
            <div className="meeting-chat-container">
              <Chat
                height="40vh"
                replay={true}
                messages={chatEvents}
                sendMessage={sendMessage}
                messageCount={messageCount}
              />
            </div>
          )}

          {showChapterList && !useMobileTools && replayChapters && (
            <div className="replay__chapter-list-container">
              <ChapterList
                chapters={replayChapters}
                onDismiss={() => setShowChapterList(false)}
                activeChapter={activeChapter}
                handleSelectChapter={handleSelectChapter}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
