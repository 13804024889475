import React, {
  Fragment,
  MutableRefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { ToolBar } from "../toolbars/toolBar";
import { IconButton } from "../buttons/iconButton";
import Player from "video.js/dist/types/player";
import { ProgressBar } from "../inputs/progressBar";
import { ThemeContext } from "../../contexts/themeContext";
import { CanvasNav } from "../toolbars/controls/canvasNav";
import { FullScreenToggle } from "../toolbars/controls/fullScreenToggle";
import { ChatToggle } from "../toolbars/controls/chatToggle";
import { ChatReplayMessage } from "../../providers/replayDataMessages";
import { ChatDataMessage } from "../../providers/dataMessages";
import { useScreenContext } from "../../contexts/screenContext";
import { MoreActionsWrapper } from "../toolbars/controls/moreActions/moreActionsWrapper";
import { MoreActionsButton } from "../toolbars/controls/moreActions/moreActionsButton";
import { MoreActionsItem } from "../toolbars/controls/moreActions/moreActionsItem";
import classnames from "classnames";
import { useAppContext } from "../../contexts/appContext";
import { ActionType, EventToggleExploreModeAction } from "../../contexts/types";
import { consoleNonProd } from "../../utils/utilityBeltUtils";
import { REPLAY_IDLE_TIMEOUT, VALID_KEYS_FOR_REPLAY } from "../../constants";
import { useFocusCheck } from "../../hooks/useFocusCheck";
import { ClosedCaptionsButton } from "../toolbars/controls/closedCaptionsButton";
import {
  CanvasDataEvent,
  EventPayload,
  EventTypes,
} from "../../providers/types";
import { VideoControls } from "./videoControls";
import { Help } from "../toolbars/help";
import { Button, PRIMARY, SECONDARY, SMALL } from "../buttons/button";
import { HelperPopUp } from "../popUps/helperPopUp";
import { StorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { useDataEventHandler } from "../../hooks/useDataEventHandler";
import { BoardType } from "../../types";
import { useReplayDataEvents } from "../../providers/replayDataEvents";
import { TimeDisplay } from "./timeDisplay";
import { ClosedCaptionsSize } from "../toolbars/controls/closedCaptionsSize";
import { tutorialLink } from "../../constants";
import { VideoReplayTimeAndState } from "./replayTypes";
import { MobileReplayTools } from "./mobileReplayTools";
import { PlaybackSpeed } from "./playbackSpeed";

type ReplayToolsProps = {
  playerRef: MutableRefObject<Player>;
  replayChat: boolean;
  hasReplayCaptions?: boolean;
  messages: ChatReplayMessage[] | ChatDataMessage[];
  messageCount: number;
  chapters: VTTCue[];
  activeChapter: number;
  replayExplore: boolean;
  showClosedCaptions: boolean;
  lastEvent: CanvasDataEvent;
  toggleClosedCaptions: () => void;
  toggleInstructions: () => void;
  events: CanvasDataEvent[];
  setShowInstructors: (show: boolean) => void;
  handleSelectChapter: (timestamp: number, chapter: number) => void;
  showVideo: boolean;
  toggleShowVideo: () => void;
  maxTimeMs?: number;
  sendVideoTimeAndState?: (replayVideoState: VideoReplayTimeAndState) => void;
  showChapterList: boolean;
  setShowChapterList: (show: boolean) => void;
  showInstructions: boolean;
  setShowInstructions: (show: boolean) => void;
};

/** Component containing the Canvas board components, instructor slide manager, and student toolbar
 * @prop {Player} playerRef - Ref object containing video player for replay mode
 * @prop {boolean} replayChat - Whether or not the meeting replay has a chat
 * @prop {messages[]} messages[] or ChatReplayMessage[] - List of messages DataMessages provider
 * @prop {boolean} replayExplore - boolean for replay explore
 */
export const ReplayTools: React.FC<ReplayToolsProps> = ({
  playerRef,
  replayChat,
  hasReplayCaptions,
  messages,
  messageCount,
  chapters,
  activeChapter,
  replayExplore,
  lastEvent,
  showClosedCaptions,
  toggleClosedCaptions,
  events,
  setShowInstructors,
  handleSelectChapter,
  showVideo,
  toggleShowVideo,
  maxTimeMs,
  sendVideoTimeAndState,
  showChapterList,
  setShowChapterList,
  showInstructions,
  setShowInstructions,
  toggleInstructions,
}) => {
  const volumePanelClassName = "replay-tools__volume-panel";

  const { playerHasFocus } = useFocusCheck();
  const { useMobileTools, isLarge } = useScreenContext();
  const [isPlaying, setIsPlaying] = useState(
    playerRef?.current ? !playerRef?.current.paused() : false
  );
  const [showVolumeBar, setShowVolumeBar] = useState(false);
  const [volume, setVolume] = useState(1);
  const [volumePercent, setVolumePercent] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const { theme } = useContext(ThemeContext);
  const [showMoreActionsMenu, setShowMoreActionsMenu] = useState(false);
  const [showSpeedPopup, setShowSpeedPopup] = useState(false);
  const [showCcSizePopup, setCcSizePopup] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [showBGPopup, setShowBGPopupup] = useState(false);

  const calculateMaxTime = () => {
    if (maxTimeMs) {
      return maxTimeMs / 1000;
    } else {
      const playerMax = playerRef?.current?.duration()
        ? playerRef?.current?.duration()
        : 1;
      const chapMax = (chapters && chapters[chapters.length - 1]?.endTime) || 1;
      const biggerMax = playerMax > chapMax ? playerMax : chapMax;
      return biggerMax;
    }
  };

  const maxTime = calculateMaxTime();
  const remainingTime = maxTime - playerRef?.current?.currentTime();

  const { state, dispatch, showChat, toggleChat } = useAppContext();
  const { setLocalStorage, getLocalStorage } = useLocalStorage();
  const { findLastInteractiveEvent, getActionForDataEvent } =
    useDataEventHandler();
  const { addEvent } = useReplayDataEvents();
  const [captionsSize, setCaptionsSize] = useState(1);

  // Set up event listeners to show volume panel on hover/mouseover
  useEffect(() => {
    if (!useMobileTools) {
      const volumePanel =
        document.getElementsByClassName(volumePanelClassName)[0];
      const handleMouseOver = (_e) => setShowVolumeBar(true);
      const handleMouseOut = (_e) => setShowVolumeBar(false);

      volumePanel.addEventListener("mouseover", handleMouseOver);
      volumePanel.addEventListener("mouseout", handleMouseOut);

      return () => {
        volumePanel.removeEventListener("mouseover", handleMouseOver);
        volumePanel.removeEventListener("mouseout", handleMouseOut);
      };
    } else return;
  }, []);

  const togglePlayPause = () => {
    if (remainingTime < 2) {
      handleSeek(0);
      playerRef.current.play();
      setIsPlaying(true);
      return;
    }
    if (isPlaying) {
      playerRef?.current.pause();
    } else {
      playerRef?.current.play();
    }

    setIsPlaying(!isPlaying);
    if (chapters) {
      sendVideoTimeAndState &&
        sendVideoTimeAndState({
          time:
            playerRef?.current?.currentTime() -
            chapters[activeChapter - 1]?.startTime,
          isPlaying: !playerRef?.current.paused(),
          volume: volume,
          speed: playbackSpeed,
        });
    }
  };

  const handleRewind = () => {
    const newTime = playerRef?.current?.currentTime() - 15;
    handleSeek(newTime);
    if (chapters) {
      sendVideoTimeAndState &&
        sendVideoTimeAndState({
          time: newTime - chapters[activeChapter - 1]?.startTime,
          isPlaying: isPlaying,
          volume: volume,
          speed: playbackSpeed,
        });
    }
  };

  const handleFastForward = () => {
    const newTime = playerRef?.current?.currentTime() + 15;
    handleSeek(newTime);
    if (chapters) {
      sendVideoTimeAndState &&
        sendVideoTimeAndState({
          time: newTime - chapters[activeChapter - 1]?.startTime,
          isPlaying: isPlaying,
          volume: volume,
          speed: playbackSpeed,
        });
    }
  };

  const handleSeek = (timestamp: number) => {
    consoleNonProd("seek!", timestamp);
    playerRef?.current?.currentTime(timestamp);
    if (chapters) {
      sendVideoTimeAndState &&
        sendVideoTimeAndState({
          time: timestamp - chapters[activeChapter - 1]?.startTime,
          isPlaying: isPlaying,
          volume: volume,
          speed: playbackSpeed,
        });
    }
  };

  const handleVolumeBarClick = (percent: number) => {
    setVolumePercent(percent);
    setVolume(percent);
    playerRef?.current.volume(percent);
    sendVideoTimeAndState &&
      sendVideoTimeAndState({
        time:
          playerRef?.current?.currentTime() -
          chapters[activeChapter - 1]?.startTime,
        isPlaying: isPlaying,
        volume: percent,
        speed: playbackSpeed,
      });
  };

  const handleToggleMute = (e?) => {
    e?.preventDefault();
    if (playerRef?.current.volume() === 0) {
      playerRef?.current.volume(volumePercent);
      setVolume(volumePercent);
      sendVideoTimeAndState &&
        sendVideoTimeAndState({
          time:
            playerRef?.current?.currentTime() -
            chapters[activeChapter - 1]?.startTime,
          isPlaying: isPlaying,
          volume: volumePercent,
          speed: playbackSpeed,
        });
    } else {
      playerRef?.current.volume(0);
      setVolume(0);
      sendVideoTimeAndState &&
        sendVideoTimeAndState({
          time:
            playerRef?.current?.currentTime() -
            chapters[activeChapter - 1]?.startTime,
          isPlaying: isPlaying,
          volume: 0,
          speed: playbackSpeed,
        });
    }
  };

  const handleOpenTutorial = () => {
    if (isPlaying) {
      playerRef?.current.pause();
      setIsPlaying(false);
    }
    window.open(tutorialLink, "_blank");
  };

  const handleSetPlaybackSpeed = (speed: number) => {
    setPlaybackSpeed(speed);
    setShowMoreActionsMenu(false);
    setCcSizePopup(false);
  };

  useEffect(() => {
    const player = playerRef?.current;
    player.playbackRate(playbackSpeed);
  }, [playbackSpeed, playerRef]);

  useEffect(() => {
    const player = playerRef?.current;
    // Add event listener for timeupdate event, which triggers while the video is playing
    function progressUpdater() {
      setCurrentTime(player?.currentTime());
    }

    player?.on("timeupdate", () => {
      // Update the current time state
      progressUpdater();
    });

    // Clean up when component unmounts
    return () => {
      if (player) {
        player?.off("timeupdate", progressUpdater); // Remove the listener on dismount to prevent memory leaks
      }
    };
  }, [remainingTime, playerRef]);

  useEffect(() => {
    const canvas = document.getElementById("board-viewer");
    const zoomIn = document.getElementById("zoom-in-button");
    const zoomOut = document.getElementById("zoom-out-button");
    const homeButton = document.getElementById("home-zoom-button");

    let inactivityTimeout;

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);

      inactivityTimeout = setTimeout(() => {
        let oldEvent: EventPayload;

        if (lastEvent?.event) {
          oldEvent = JSON.parse(lastEvent?.event);
        }

        const exploreMode = oldEvent?.exploreMode
          ? oldEvent.exploreMode
          : !state.exploreMode;

        dispatch({
          type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
          payload: {
            exploreMode,
            replayExplore: false,
          },
        });

        // If toggled out of explore mode, set the view to the previous event
        if (!exploreMode) {
          const dataEvent = findLastInteractiveEvent(events);

          // If an event with an interaction is found for the board, set state based on the event
          if (dataEvent) {
            const event: EventPayload = JSON.parse(dataEvent.event);

            // set state based on that event
            const action = getActionForDataEvent(
              event,
              false,
              false,
              setShowInstructors
            );

            // If there are values in the payload, dispatch the AppContext state update
            if (Object.keys(action.payload).length) {
              dispatch(action);
            }
          }
          // Otherwise reset the state to the default view
          else {
            let event: EventPayload;

            switch (state.currentBoard.type) {
              case BoardType.IIIF:
                // Add "HOME" event
                event = {
                  type: EventTypes.HOME,
                  leader: true,
                  exploreMode,
                  spotlightInstructor: state.spotlightInstructor,
                  presentationVersion: state.presentationVersion,
                  showLaser: state.showLaser,
                  currentSlideNumber: state.currentBoard?.sortOrder,
                };
                break;

              case BoardType.PANORAMA:
                // Currently there is no home/default view on panorama boards
                break;

              case BoardType.THREEDEE:
                // Add "THREE_DEE_HOME" event
                event = {
                  type: EventTypes.THREE_DEE_HOME,
                  leader: true,
                  exploreMode,
                  spotlightInstructor: state.spotlightInstructor,
                  presentationVersion: state.presentationVersion,
                  showLaser: state.showLaser,
                  currentSlideNumber: state.currentBoard?.sortOrder,
                };
                break;

              case BoardType.VIDEO:
                // Currently there is no home/default on video boards
                break;
            }

            if (event) {
              addEvent({
                event: JSON.stringify(event),
                isSelf: false,
                senderAttendeeId: "",
                senderName: "",
                timestamp: 0,
              });
            }
          }
        }
      }, REPLAY_IDLE_TIMEOUT * 1000); // Multiply by 1000 because const is saved in seconds
    };

    const handleUserActivity = () => {
      resetInactivityTimeout();
    };

    const canvasExplore = () => {
      if (!state.spotlightInstructor && !state.exploreMode) {
        const payload: EventToggleExploreModeAction["payload"] = {
          exploreMode: true,
          showLaser: false,
          laserInstructor: undefined,
          replayExplore: true,
        };
        dispatch({
          type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
          payload,
        });
      }
    };
    canvas?.addEventListener("click", canvasExplore);
    zoomIn?.addEventListener("click", canvasExplore);
    zoomOut?.addEventListener("click", canvasExplore);
    homeButton?.addEventListener("click", canvasExplore);

    canvas?.addEventListener("click", handleUserActivity);
    zoomIn?.addEventListener("click", handleUserActivity);
    zoomOut?.addEventListener("click", handleUserActivity);
    homeButton?.addEventListener("click", handleUserActivity);
    document?.addEventListener("keydown", handleUserActivity, false);

    canvas?.addEventListener("wheel", canvasExplore);
    canvas?.addEventListener("wheel", handleUserActivity);
    canvas?.addEventListener("touchstart", canvasExplore);
    canvas?.addEventListener("touchstart", handleUserActivity);
    canvas?.addEventListener("touchend", canvasExplore);
    canvas?.addEventListener("touchend", handleUserActivity);

    if (replayExplore) {
      resetInactivityTimeout();
    }
    return function () {
      canvas?.removeEventListener("click", canvasExplore);
      zoomIn?.removeEventListener("click", canvasExplore);
      zoomOut?.removeEventListener("click", canvasExplore);
      homeButton?.removeEventListener("click", canvasExplore);

      canvas?.removeEventListener("click", handleUserActivity);
      zoomIn?.removeEventListener("click", handleUserActivity);
      zoomOut?.removeEventListener("click", handleUserActivity);
      homeButton?.removeEventListener("click", handleUserActivity);
      document?.removeEventListener("keydown", handleUserActivity);

      canvas?.removeEventListener("wheel", canvasExplore);
      canvas?.removeEventListener("wheel", handleUserActivity);
      canvas?.removeEventListener("touchstart", canvasExplore);
      canvas?.removeEventListener("touchstart", handleUserActivity);
      canvas?.removeEventListener("touchend", canvasExplore);
      canvas?.removeEventListener("touchend", handleUserActivity);

      clearTimeout(inactivityTimeout);
    };
  }, [state.exploreMode, state.spotlightInstructor, dispatch, replayExplore]);

  useEffect(() => {
    const iiiFViewerCanvas: HTMLElement = document
      ?.getElementById("iiif-Viewer")
      ?.querySelector(".openseadragon-canvas");

    const PanoViewerContainer: HTMLElement =
      document?.getElementById("pano-viewer");

    const threeDeeViewerContainer: HTMLElement =
      document?.getElementById("threeDeeViewer");

    const videoBoardContainer: HTMLElement = document?.getElementById(
      "play-pause-video-button"
    );

    const keyCommander = (inputKey: string, event) => {
      if (VALID_KEYS_FOR_REPLAY.indexOf(inputKey) < 0) return;
      if (
        !playerHasFocus() &&
        state.replayExplore == false &&
        inputKey != "g" &&
        inputKey != "b" &&
        !state.exploreMode
      ) {
        const payload: EventToggleExploreModeAction["payload"] = {
          exploreMode: true,
          showLaser: false,
          laserInstructor: undefined,
          replayExplore: true,
        };
        dispatch({
          type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
          payload,
        });
      }
      if (playerHasFocus()) {
        // w or up arrow
        if (inputKey == "w" || inputKey == "ArrowUp") {
          setVolume(volume < 1 ? volume + 0.05 : 1);
        }
        // s or down arrow
        else if (inputKey == "s" || inputKey == "ArrowDown") {
          setVolume(volume > 0 ? volume - 0.05 : 0);
        }
        // a or left arrow
        else if (inputKey == "a" || inputKey == "ArrowLeft") {
          if (playerRef?.current) {
            handleRewind();
          }
        }
        // d or right arrow
        else if (inputKey == "d" || inputKey == "ArrowRight") {
          if (playerRef?.current) {
            handleFastForward();
          }
        }
        // x key or +
        else if (inputKey == "x" || inputKey == "=" || inputKey == "+") {
          handleToggleMute();
        }
      }
      // space bar to reset
      if (inputKey == " ") {
        const focusedElement = document?.activeElement;
        if (playerRef && focusedElement?.tagName !== "BUTTON") {
          togglePlayPause();
        }
      }
      // g key
      if (inputKey.toLowerCase() == "g") {
        setShowBGPopupup(true);
        iiiFViewerCanvas?.focus();
        PanoViewerContainer?.focus();
        threeDeeViewerContainer?.focus();
        videoBoardContainer?.focus();
      }
      // b key
      else if (inputKey.toLowerCase() == "b") {
        setShowBGPopupup(true);
        const playButton = document.getElementById(
          "video-controls__play-toggle"
        );
        playButton?.focus();
      }
    };
    function onDocumentKeyDown(event) {
      keyCommander(event?.key, event);
    }
    document.addEventListener("keydown", onDocumentKeyDown, false);

    return function () {
      document.removeEventListener("keydown", onDocumentKeyDown, false);
    };
  }, [volume, isPlaying, playerRef, togglePlayPause, playerHasFocus]);

  return useMobileTools ? (
    <MobileReplayTools
      replayChat={replayChat}
      handleSelectChapter={handleSelectChapter}
      showVideo={showVideo}
      toggleShowVideo={toggleShowVideo}
      playbackSpeed={playbackSpeed}
      handleSetPlaybackSpeed={handleSetPlaybackSpeed}
      showVolumeBar={showVolumeBar}
      handleRewind={handleRewind}
      handleFastForward={handleFastForward}
      isPlaying={isPlaying}
      activeChapter={activeChapter}
      chapters={chapters}
      handleSeek={handleSeek}
      currentTime={currentTime}
      remainingTime={remainingTime}
      togglePlayPause={togglePlayPause}
      maxTime={maxTime}
      theme={theme}
      showChapterList={showChapterList}
      setShowChapterList={setShowChapterList}
      showInstructions={showInstructions}
      setShowInstructions={setShowInstructions}
      hasReplayCaptions={hasReplayCaptions}
      messages={messages}
      messageCount={messageCount}
      showClosedCaptions={showClosedCaptions}
      toggleClosedCaptions={toggleClosedCaptions}
      toggleInstructions={toggleInstructions}
    />
  ) : (
    <div className="canvas__tools-wrapper">
      <ToolBar orientation="horizontal" className="replay-tools" isMinimizable>
        <VideoControls
          onPlayPause={togglePlayPause}
          handleRewind={handleRewind}
          handleFastForward={handleFastForward}
          isPlaying={isPlaying}
          activeChapter={activeChapter}
          chapters={chapters}
          handleSeek={handleSeek}
          currentTime={currentTime}
          remainingTime={remainingTime}
          togglePlayPause={togglePlayPause}
        />
        {showBGPopup && !getLocalStorage(StorageKeys.gbKeys) && (
          <div style={{ position: "relative", height: "0", width: "0" }}>
            <HelperPopUp
              userMessage="Pressing the 'g' key will allow you to interact with the board and pressing the 'b' key will allow you to interact with the player controls."
              bottom="30px"
              right="-260px"
              downArrow={false}
              isDismissible={false}
              isTimed={true}
              dismissTimeout={7}
              minWidth="300px"
              upArrow={false}
              includeDontShowAgain={true}
              handleSetShowAgainCheckbox={() => {
                setLocalStorage(StorageKeys.gbKeys, "true");
                setShowBGPopupup(false);
              }}
              onDismiss={() => setShowBGPopupup(false)}
            />
          </div>
        )}
        <div className="progress-bar-wrapper">
          <ProgressBar
            id="video-controls__progress-bar"
            onClick={handleSeek}
            max={maxTime}
            value={currentTime}
            disabled={false}
            theme={theme}
            chapters={chapters}
            activeChapter={activeChapter}
            slides={state.slides}
          />
          <TimeDisplay currentTime={currentTime} maxTime={maxTime} />
        </div>

        {isLarge && <span className="tool-divider" />}
        <div
          className={classnames(volumePanelClassName, {
            "volume-visible": showVolumeBar,
          })}
        >
          <IconButton
            onClick={handleToggleMute}
            iconName={volume === 0 ? "volume-x" : "volume-2"}
            desc={volume === 0 ? "Unmute" : "Mute"}
            toolTip="top"
            btnId="video-controls__mute-toggle"
          />
          {showVolumeBar && (
            <ProgressBar
              id="video-controls__volume-bar"
              onClick={handleVolumeBarClick}
              max={1}
              value={volume}
              disabled={!playerRef?.current}
              theme={theme}
            />
          )}
        </div>
        {isLarge && (
          <Fragment>
            <ClosedCaptionsButton
              component="ICON_BUTTON"
              showClosedCaptions={showClosedCaptions}
              toggleClosedCaptions={toggleClosedCaptions}
              disabled={!hasReplayCaptions}
              toolTip="top"
            />
            <span className="tool-divider" />
          </Fragment>
        )}
        <CanvasNav
          isLeader={!state.spotlightInstructor}
          exploreMode={!state.spotlightInstructor}
        />
        <MoreActionsButton
          toolTip="top"
          alignment="horizontal"
          showActionsInput={showMoreActionsMenu}
          id="replay-controls__more-actions"
          onClick={(e) => {
            setShowMoreActionsMenu(!showMoreActionsMenu);
            setShowSpeedPopup(false);
          }}
        >
          <MoreActionsWrapper
            itemClick={(e) => {
              e.preventDefault();
              setShowMoreActionsMenu(false);
              setShowSpeedPopup(false);
            }}
            upOrDown="up"
            leftOrRight="left"
          >
            {!isLarge && (
              <MoreActionsItem id="replay-tools__more-actions__cc-button">
                <ClosedCaptionsButton
                  component="BUTTON"
                  showClosedCaptions={showClosedCaptions}
                  toggleClosedCaptions={toggleClosedCaptions}
                  disabled={!hasReplayCaptions}
                  toolTip="top"
                />
              </MoreActionsItem>
            )}
            <MoreActionsItem id="replay-tools__more-actions__full-screen">
              <FullScreenToggle component="BUTTON" toolTip="top" />
            </MoreActionsItem>

            <MoreActionsItem id="replay-tools__more-actions__chat">
              <ChatToggle
                toolTip="top"
                replayMode={replayChat}
                messages={messages}
                component="BUTTON"
                emitClick={(e) => setShowChapterList(false)}
                chatMessagesCount={messageCount}
              />
            </MoreActionsItem>

            <MoreActionsItem id="replay-tools__more-actions__speed">
              <PlaybackSpeed
                playbackSpeed={playbackSpeed}
                setPlaybackSpeed={handleSetPlaybackSpeed}
              />
            </MoreActionsItem>
            {!isLarge && (
              <MoreActionsItem id="replay-tools__more-actions__help">
                <Help
                  useMobileTools={useMobileTools}
                  toggleInstructions={() =>
                    setShowInstructions(!showInstructions)
                  }
                  component="BUTTON"
                />
              </MoreActionsItem>
            )}
            <MoreActionsItem id="replay-tools__more-actions__chapters">
              <Button
                btnType={PRIMARY}
                text={`${showChapterList ? "Hide" : "View"} chapter list`}
                size={SMALL}
                id="chapter-list-button"
                onClick={(e) => {
                  setShowChapterList(!showChapterList);
                  if (showChat) toggleChat();
                }}
                icon="list"
                disabled={!chapters}
              />
            </MoreActionsItem>
            <MoreActionsItem id="replay-tools__more-actions__video-toggle">
              <Button
                btnType={PRIMARY}
                id="replay-video-toggle-button"
                text={showVideo ? "Hide video" : "Show video"}
                customWidth="100%"
                size={SMALL}
                disabled={false}
                onClick={toggleShowVideo}
                icon={showVideo ? "user-x" : "user"}
              />
            </MoreActionsItem>
            <MoreActionsItem id="replay-tools__more-actions__captions">
              <ClosedCaptionsSize
                onClick={() => {
                  setShowSpeedPopup(false);
                  setCcSizePopup(!showCcSizePopup);
                }}
                onSelectSize={(e) => {
                  setCaptionsSize(e);
                  setTimeout(() => {
                    setShowSpeedPopup(false);
                    setCcSizePopup(false);
                    setShowMoreActionsMenu(false);
                  }, 2000);
                }}
                captionsSize={captionsSize}
                showCcSizePopup={showCcSizePopup}
              />
            </MoreActionsItem>
            <MoreActionsItem id="replay-tools__more-actions__tutorial">
              <Button
                btnType={PRIMARY}
                text="Open interactive tutorial"
                size={SMALL}
                disabled={false}
                id="tutorial-button"
                onClick={handleOpenTutorial}
                icon="help"
              />
            </MoreActionsItem>
          </MoreActionsWrapper>
        </MoreActionsButton>

        {isLarge && <span className="tool-divider" />}
      </ToolBar>
    </div>
  );
};
