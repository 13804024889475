import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  AudioInputVFControl,
  DeviceLabelTriggerStatus,
  useDeviceLabelTriggerStatus,
  useLocalAudioOutput,
} from "amazon-chime-sdk-component-library-react";
import { useAppContext } from "../../../contexts/appContext";
import {
  AttendeeState,
  MeetingMode,
  MeetingRole,
} from "../../../contexts/types";
import { DARK, ThemeContext } from "../../../contexts/themeContext";
import { CameraToggle } from "../controls/cameraToggle";
import { CanvasNav } from "../controls/canvasNav";
import { ChatToggle } from "../controls/chatToggle";
import { MicToggle } from "../controls/micToggle";
import { MoreActionsButton } from "../controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "../controls/moreActions/moreActionsWrapper";
import { HandRaise } from "../controls/handRaise";
import { Chat } from "../../chat";
import { useScreenContext } from "../../../contexts/screenContext";
import classnames from "classnames";
import { ClosedCaptionsButton } from "../controls/closedCaptionsButton";
import { StorageKeys, useLocalStorage } from "../../../hooks/useLocalStorage";
import { Attendee } from "../../../types";
import { MobileParticipantsToggle } from "./mobileParticipantsToggle";
import { StudentVideos } from "../../videos/studentVideos";
import { UserVideo } from "../../videos/userVideo";
import { useDataEmotes } from "../../../providers/dataEmotes";
import variables from "../../../styles/variables.scss";
import { MoreActionsItem } from "../controls/moreActions/moreActionsItem";
import { EndMeetingControl } from "../controls/endMeetingControl";
import { Button, PRIMARY, SMALL } from "../../buttons/button";
import { ChatDataMessage } from "../../../providers/dataMessages";
import { ChatReplayMessage } from "../../../providers/replayDataMessages";
import { Help } from "../help";
import { ClosedCaptionsSize } from "../controls/closedCaptionsSize";
import {
  MobileToolDrawer,
  MobileToolRow,
  MobileToolsInner,
} from "./mobileToolDrawer";

type MobileToolsProps = {
  showClosedCaptions: boolean;
  toggleClosedCaptions: () => void;
  toggleDeviceSettings: () => void;
  showInstructors: boolean;
  toggleInstructors: () => void;
  toggleInstructions: () => void;
  messages?: ChatDataMessage[] | ChatReplayMessage[];
  sendMessage?: (message: string, attendee?: AttendeeState) => void;
  messageCount?: number;
  showChapterList: boolean;
  setShowChapterList: (show: boolean) => void;
};

/** Toolbar component for the swipe up toolbar for students on mobile */
export const MobileTools: React.FC<MobileToolsProps> = ({
  showClosedCaptions,
  toggleClosedCaptions,
  toggleDeviceSettings,
  showInstructors,
  toggleInstructors,
  toggleInstructions,
  messages,
  sendMessage,
  messageCount,
  showChapterList,
  setShowChapterList,
}) => {
  const {
    isWebAudioEnabled,
    meetingMode,
    meetingRole,
    simpleView,
    isStudentAVMeeting,
    attendeeList,
    firstName,
    lastName,
    showChat,
    setShowChat,
    toggleChat,
    showParticipants,
    setShowParticipants,
    toggleParticipants,
    setShowUserVideo,
    showUserVideo,
    expandToolBar,
    setExpandToolBar,
    state,
    meetingHasChat,
    isInstructorAVMeeting,
  } = useAppContext();
  const includeAVControls = meetingMode === MeetingMode.Attendee;
  const showCCToggle = isInstructorAVMeeting || isStudentAVMeeting;
  const hideInChatOnly =
    meetingRole !== MeetingRole.Presenter &&
    meetingRole !== MeetingRole.Moderator &&
    !isStudentAVMeeting;
  const [isLeader] = useState(meetingRole === MeetingRole.Presenter);
  const [showMoreActionsMenu, setShowMoreActionsMenu] = useState(false);
  const [toolBarHeight, setToolBarHeight] = useState(variables.toolBarHeight);
  const { getLocalStorage } = useLocalStorage();
  const locAttendee: Attendee = getLocalStorage(StorageKeys.attendee);
  const { orientation, useMobileTools } = useScreenContext();
  const permissionsStatus = useDeviceLabelTriggerStatus();
  const { raisedHands } = useDataEmotes();
  const { theme } = useContext(ThemeContext);
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const [showEndModal, setShowEndModal] = useState(false);
  const [captionsSize, setCaptionsSize] = useState(1);
  const [showCcSizePopup, setCcSizePopup] = useState(false);

  useEffect(() => {
    if (expandToolBar || showChat || showParticipants || showUserVideo) {
      setToolBarHeight(variables.mobilePortraitToolbarExpandedHeight);
    } else if (
      // expanded landscape toolbar
      (expandToolBar || showChat || showParticipants || showUserVideo) &&
      orientation === "landscape"
    ) {
      setToolBarHeight(variables.mobileLandscapeToolbarExpandedHeight);
    } else {
      // collapsed toolbar
      setToolBarHeight(variables.mobileToolbarHeight);
    }
  }, [expandToolBar, orientation, showChat, showParticipants]);

  useEffect(() => {
    // Sync up showParticipants and showUserVideo states with showVideos state
    if (showParticipants) {
      setExpandToolBar(true);
      setShowUserVideo(true);
      setShowChat(false);
    }
    if (showChat) {
      setExpandToolBar(true);
      setShowUserVideo(true);
      setShowParticipants(false);
    }
  }, [
    expandToolBar,
    setShowChat,
    setShowParticipants,
    showChat,
    showParticipants,
    toggleChat,
    toggleParticipants,
    setExpandToolBar,
    setShowUserVideo,
  ]);

  // if you are a student and in the meeting you should have audio
  useEffect(() => {
    if (!isAudioOn) {
      toggleAudio();
    }
  }, [isAudioOn, toggleAudio]);

  useEffect(() => {
    // on page load, default to collapsed toolbar
    setToolBarHeight(variables.mobileToolbarHeight);
    setShowParticipants(false);
    setShowUserVideo(false);
    setShowChat(false);
    setShowMoreActionsMenu(false);
    setExpandToolBar(false);
  }, []);

  return (
    <div
      className={classnames(`mobile-tools-wrapper --${orientation}`)}
      style={{ height: toolBarHeight, transition: "height 0.3s ease" }}
    >
      {!simpleView && (
        <MobileToolDrawer
          showSlider={true}
          setShowMoreActionsMenu={setShowMoreActionsMenu}
          setShowChapterList={setShowChapterList}
          replay={false}
        >
          <MobileToolRow
            id="live-tools"
            setShowMoreActionsMenu={setShowMoreActionsMenu}
            setShowChapterList={setShowChapterList}
            replay={false}
          >
            <CanvasNav isLeader={isLeader} exploreMode={state.exploreMode} />
            {includeAVControls && (
              <Fragment>
                {isWebAudioEnabled ? (
                  <AudioInputVFControl />
                ) : (
                  <MicToggle toolTip="top" />
                )}
                <CameraToggle toolTip="top" />
              </Fragment>
            )}
            <MoreActionsButton
              showActionsInput={showMoreActionsMenu}
              toolTip="top"
              alignment="horizontal"
              id="mobile-tools__more-actions"
              onClick={(e) => {
                setShowMoreActionsMenu(!showMoreActionsMenu);
                if (expandToolBar) {
                  setExpandToolBar(false);
                }
                if (showChat) {
                  setShowChat(false);
                }
                if (showParticipants) {
                  setShowParticipants(false);
                }
                if (showUserVideo) {
                  setShowUserVideo(false);
                }
              }}
            >
              <MoreActionsWrapper
                itemClick={(e) => {
                  e.preventDefault();
                  setShowMoreActionsMenu(false);
                }}
                upOrDown="up"
                leftOrRight="left"
              >
                <MoreActionsItem id="mobile-tools__more-actions__help">
                  <Help
                    useMobileTools={useMobileTools}
                    toggleInstructions={toggleInstructions}
                    component="BUTTON"
                  />
                </MoreActionsItem>
                {includeAVControls && (
                  <MoreActionsItem
                    text="View settings"
                    disabled={false}
                    onClick={toggleDeviceSettings}
                    id="mobile-tools__more-actions__device-settings"
                    icon="settings"
                  />
                )}
                {showCCToggle && (
                  <MoreActionsItem id="mobile-tools__more-actions__cc-button">
                    <ClosedCaptionsButton
                      component="BUTTON"
                      showClosedCaptions={showClosedCaptions}
                      toggleClosedCaptions={toggleClosedCaptions}
                      fill={
                        theme === DARK ? variables.colorCopy : variables.white
                      }
                    />
                  </MoreActionsItem>
                )}
                <MoreActionsItem id="replay-tools__more-actions__captions">
                  <ClosedCaptionsSize
                    onClick={() => {
                      setCcSizePopup(!showCcSizePopup);
                    }}
                    onSelectSize={(e) => {
                      setCaptionsSize(e);
                      setTimeout(() => {
                        setShowMoreActionsMenu(false);
                        setCcSizePopup(false);
                      }, 2000);
                    }}
                    captionsSize={captionsSize}
                    showCcSizePopup={showCcSizePopup}
                  />
                </MoreActionsItem>

                <MoreActionsItem id="mobile-tools__more-actions__hand-raise">
                  <HandRaise component="BUTTON" />
                </MoreActionsItem>

                {meetingHasChat && (
                  <MoreActionsItem id="mobile-tools__more-actions__chat">
                    <ChatToggle
                      toolTip="top"
                      messages={messages}
                      component="BUTTON"
                      chatMessagesCount={messageCount}
                    />
                  </MoreActionsItem>
                )}
                <MoreActionsItem id="mobile-tools__more-actions__participants-toggle">
                  <MobileParticipantsToggle
                    showInstructors={showInstructors}
                    toggleInstructors={toggleInstructors}
                  />
                </MoreActionsItem>
                {/* Only available for students on mobile */}
                {!isLeader && (
                  <MoreActionsItem id="mobile-tools__more-actions__end-meeting-control">
                    <Button
                      btnType={PRIMARY}
                      text="Leave meeting"
                      size={SMALL}
                      disabled={false}
                      id="mobile-leave-meeting-button"
                      onClick={() => setShowEndModal(!showEndModal)}
                      icon="log-out"
                    />
                  </MoreActionsItem>
                )}
              </MoreActionsWrapper>
            </MoreActionsButton>
          </MobileToolRow>
        </MobileToolDrawer>
      )}
      <EndMeetingControl
        showEndModal={showEndModal}
        handleDismiss={() => setShowEndModal(!showEndModal)}
      />
      <MobileToolsInner replay={false}>
        {orientation === "landscape" && (
          <>
            <div className="mobile-tools__videos --landscape">
              {(showUserVideo || showParticipants || showChat) &&
                !simpleView && (
                  <div
                    className={classnames("student__wrapper", {
                      "_no-participants": !showParticipants,
                    })}
                  >
                    <StudentVideos attendeeList={attendeeList} />
                  </div>
                )}
              {(showUserVideo || showParticipants || showChat) &&
                includeAVControls &&
                permissionsStatus !== DeviceLabelTriggerStatus.DENIED &&
                !simpleView && (
                  <UserVideo
                    name={firstName + " " + lastName}
                    iconHex={locAttendee?.iconColor?.split("_")[1]}
                    handIsRaised={raisedHands.has(locAttendee?.AttendeeId)}
                  />
                )}
            </div>
            <div className="mobile-tools__chat --landscape">
              {(showUserVideo || showParticipants || showChat) &&
                !simpleView && (
                  <Chat
                    height="22vh"
                    messages={messages}
                    sendMessage={sendMessage}
                    messageCount={messageCount}
                  />
                )}
            </div>
          </>
        )}
        {/* Portrait mobile tools */}

        {orientation === "portrait" && (
          <>
            {showParticipants && !showChat && !simpleView && (
              <div className="mobile-tools__videos --portrait">
                <div
                  className={classnames("student__wrapper", {
                    "_no-participants": !showParticipants,
                  })}
                >
                  <StudentVideos attendeeList={attendeeList} />
                </div>{" "}
              </div>
            )}
            {showUserVideo &&
              // If a user joins without audio or visual (the meeting mode check) or
              // if a user denies the browser A/V trigger this will hide their video
              includeAVControls &&
              permissionsStatus !== DeviceLabelTriggerStatus.DENIED &&
              !simpleView && (
                <UserVideo
                  name={firstName + " " + lastName}
                  iconHex={locAttendee?.iconColor?.split("_")[1]}
                  handIsRaised={raisedHands.has(locAttendee?.AttendeeId)}
                />
              )}
            {showChat && !showParticipants && !simpleView && (
              <div className="mobile-tools__chat --portrait">
                <Chat
                  height={`calc(${document?.documentElement?.style.getPropertyValue(
                    "--mobile-available-screen"
                  )} * .6 - 190px)`}
                  messages={messages}
                  sendMessage={sendMessage}
                  messageCount={messageCount}
                />
              </div>
            )}
          </>
        )}
      </MobileToolsInner>
    </div>
  );
};
