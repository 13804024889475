import React, { createContext, useContext, useReducer } from "react";
import {
  AlertActionType as ActionType,
  AlertAction as Action,
  AlertState as State,
  ShowAlertAction,
} from "./types";
import { HelperPopUp } from "../components/popUps/helperPopUp";

export type AlertContextType = {
  state: State;
  dispatch: React.Dispatch<Action>;
  showAlert: (payload: ShowAlertAction["payload"]) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

const initialState: State = {
  hasAlert: false,
  message: "Test message",
  isTimed: true,
  nextAlerts: [],
};

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.RESET:
      return initialState;

    case ActionType.SHOW_ALERT:
      // If active alert exists, add alert to nextAlerts array
      if (state.hasAlert) {
        const nextAlerts = state.nextAlerts;
        nextAlerts.push({
          message: payload.message,
          header: payload.header,
          isTimed: payload.isTimed,
          dismissTimeout: payload.dismissTimeout,
        });
        const updatedState = { ...state, nextAlerts };

        return updatedState;
      }
      // Otherwise show the new alert
      else {
        const updatedState: State = {
          ...state,
          hasAlert: true,
          message: payload.message,
          header: payload.header,
          isTimed: payload.isTimed,
          dismissTimeout: payload.dismissTimeout,
        };
        return updatedState;
      }
  }
};

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showAlert = (payload: ShowAlertAction["payload"]) => {
    dispatch({
      type: ActionType.SHOW_ALERT,
      payload,
    });
  };

  const handleDismiss = () => {
    dispatch({
      type: ActionType.RESET,
    });

    // Check for next alerts and set timeout to display them if they exist
    if (state.nextAlerts.length > 0) {
      const nextAlerts = state.nextAlerts;
      const nextAlert = nextAlerts.shift();

      const action: Action = {
        type: ActionType.SHOW_ALERT,
        payload: {
          message: nextAlert.message,
          header: nextAlert.header,
          isTimed: nextAlert.isTimed,
          dismissTimeout: nextAlert.dismissTimeout,
        },
      };

      setTimeout(() => {
        dispatch(action);
      }, 1000);
    }
  };

  return (
    <AlertContext.Provider value={{ state, dispatch, showAlert }}>
      {state.hasAlert && (
        <HelperPopUp
          id="alert"
          userMessage={state.message}
          popUpHeader={state.header}
          isTimed={state.isTimed}
          dismissTimeout={state.dismissTimeout}
          onDismiss={handleDismiss}
          isDismissible={true}
          right="8px" // Position the popup in the upper right corner of the window
          top="8px"
          position="fixed"
          minWidth="450px"
        />
      )}
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error("Use useAlert hook inside AlertProvider.");
  }

  return context;
};

/** Higher order component to enable use of the useAlert hook in class components */
export const withAlertHOC = (Component: any) => {
  const WrappedComponent = (props) => {
    const { state, dispatch, showAlert } = useAlert();

    return (
      <Component
        errorState={state}
        errorDispatch={dispatch}
        showAlert={showAlert}
        {...props}
      />
    );
  };

  WrappedComponent.displayName = "wrappedComponent";
  return WrappedComponent;
};
