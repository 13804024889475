import { gql } from "@apollo/client";
import {
  ATTENDEE_FIELDS,
  BASE_MEETING_FIELDS,
  BOARD_FIELDS,
  COURSE_FIELDS,
  MEETING_FIELDS,
  PANORAMA_FIELDS,
} from "./fragments";

export const CREATE_ATTENDEE = gql`
  ${MEETING_FIELDS}
  ${ATTENDEE_FIELDS}
  mutation CreateAttendee($input: CreateAttendeeInput) {
    createAttendee(input: $input) {
      ...AttendeeFields
      meeting {
        ...MeetingFields
      }
    }
  }
`;

export const CREATE_DEMO_MEETING = gql`
  ${MEETING_FIELDS}
  ${ATTENDEE_FIELDS}
  mutation CreateDemoMeeting($input: CreateDemoMeetingInput) {
    createDemoMeeting(input: $input) {
      meeting {
        ...MeetingFields
      }
      attendee {
        ...AttendeeFields
      }
    }
  }
`;

export const END_MEETING = gql`
  mutation EndMeeting($input: EndMeetingInput!) {
    endMeeting(input: $input) {
      status
      success
    }
  }
`;

export const ATTENDEE_LEAVE_MEETING = gql`
  mutation attendeeLeaveMeeting($id: Int) {
    attendeeLeaveMeeting(id: $id) {
      error
      status
      success
    }
  }
`;

export const CREATE_BOARD = gql`
  ${BOARD_FIELDS}
  mutation createBoard($input: CreateBoardInput) {
    createBoard(input: $input) {
      board {
        ...BoardFields
        meetingId
      }
      currentVersion
    }
  }
`;

export const DELETE_BOARD = gql`
  mutation deleteBoard($input: DeleteBoardInput) {
    deleteBoard(input: $input) {
      id
      message
      currentVersion
    }
  }
`;

export const DUPLICATE_BOARD = gql`
  ${BOARD_FIELDS}
  mutation duplicateBoard($input: DuplicateBoardInput) {
    duplicateBoard(input: $input) {
      board {
        ...BoardFields
        meetingId
      }
      currentVersion
    }
  }
`;

export const EDIT_BOARD = gql`
  mutation editBoard($input: EditBoardInput) {
    editBoard(input: $input) {
      board {
        id
        meetingId
        type
        title
        sortOrder
      }
      currentVersion
    }
  }
`;

export const CREATE_BOARD_IMAGES = gql`
  mutation createBoardImages($input: [CreateBoardImage]) {
    createBoardImages(input: $input) {
      boardImages {
        tileSource
        boardId
        x
        y
        width
        height
      }
      currentVersion
    }
  }
`;

export const DELETE_BOARD_IMAGE = gql`
  mutation deleteBoardImage($input: Int) {
    deleteBoardImage(input: $input) {
      id
      message
      currentVersion
    }
  }
`;

export const LOG_IN = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      isAuthenticated
      message
      firstName
      lastName
    }
  }
`;
export const LOG_OUT = gql`
  mutation logout($input: LogoutInput) {
    logout(input: $input) {
      isAuthenticated
      message
      firstName
      lastName
    }
  }
`;

export const CREATE_COURSE = gql`
  ${COURSE_FIELDS}
  ${BASE_MEETING_FIELDS}
  mutation createCourse($input: CreateCourseInput) {
    createCourse(input: $input) {
      ...CourseFields
      meetings {
        ...BaseMeetingFields
      }
    }
  }
`;

export const EDIT_COURSE = gql`
  ${COURSE_FIELDS}
  ${BASE_MEETING_FIELDS}
  mutation editCourse($input: EditCourseInput) {
    editCourse(input: $input) {
      ...CourseFields
      meetings {
        ...BaseMeetingFields
      }
    }
  }
`;

export const DUPLICATE_COURSE = gql`
  ${COURSE_FIELDS}
  ${BASE_MEETING_FIELDS}
  mutation duplicateCourse($input: DuplicateCourseInput) {
    duplicateCourse(input: $input) {
      ...CourseFields
      meetings {
        ...BaseMeetingFields
      }
    }
  }
`;

export const CREATE_BOARD_PANORAMA = gql`
  ${PANORAMA_FIELDS}
  mutation createBoardPanorama($input: CreateBoardPanorama) {
    createBoardPanorama(input: $input) {
      boardPanorama {
        panorama {
          ...PanoramaFields
        }
      }
      currentVersion
    }
  }
`;

export const CREATE_BOARD_VIDEO = gql`
  mutation createBoardVideo($input: CreateBoardVideoInput) {
    createBoardVideo(input: $input) {
      boardVideo {
        sourceUrl
        boardId
        start
        end
      }
      currentVersion
    }
  }
`;

export const START_AND_JOIN_MEETING = gql`
  ${MEETING_FIELDS}
  ${ATTENDEE_FIELDS}
  mutation startAndJoinMeeting($id: Int!, $input: StartAndJoinMeetingInput!) {
    startAndJoinMeeting(id: $id, input: $input) {
      attendee {
        ...AttendeeFields
      }
      meeting {
        ...MeetingFields
      }
      recordMeetingStatus
      initVersionNumber
    }
  }
`;

export const EDIT_MEETING = gql`
  mutation editMeeting($input: CreateMeetingInput) {
    editMeeting(input: $input) {
      id
      MeetingId
      title
      courseId
      meetingTime
      updatedAt
    }
  }
`;

export const ACTIVATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation activateMeeting($id: Int!) {
    activateMeeting(id: $id) {
      status
      error
      meeting {
        ...MeetingFields
      }
    }
  }
`;

export const BEGIN_RECORD_MEETING = gql`
  mutation beginRecordMeeting($id: Int!) {
    beginRecordMeeting(id: $id) {
      success
      error
      status
    }
  }
`;

export const MUTE_LOCK = gql`
  mutation toggleMuteLock($id: Int!, $muteLock: Boolean!) {
    toggleMuteLock(id: $id, muteLock: $muteLock) {
      meeting {
        muteLock
      }
    }
  }
`;

export const VIDEO_OFF_LOCK = gql`
  mutation toggleVideoOffLock($id: Int!, $videoOffLock: Boolean!) {
    toggleVideoOffLock(id: $id, videoOffLock: $videoOffLock) {
      meeting {
        videoOffLock
      }
    }
  }
`;

export const DELETE_MEETING = gql`
  ${COURSE_FIELDS}
  ${BASE_MEETING_FIELDS}
  mutation deleteMeeting($input: CreateMeetingInput) {
    deleteMeeting(input: $input) {
      ...CourseFields
      meetings {
        ...BaseMeetingFields
        courseId
      }
    }
  }
`;

export const BULK_CREATE_IMAGE_UPLOADS = gql`
  mutation bulkCreateImageUploads($input: [CreateImageUploadInput]) {
    bulkCreateImageUploads(input: $input) {
      id
      uploadUrl
      userId
    }
  }
`;

export const DUPLICATE_MEETING = gql`
  ${COURSE_FIELDS}
  ${BASE_MEETING_FIELDS}
  mutation duplicateMeeting($input: DuplicateMeetingInput) {
    duplicateMeeting(input: $input) {
      ...CourseFields
      meetings {
        ...BaseMeetingFields
        courseId
      }
    }
  }
`;

export const SET_REPLAY_RECORDING_PIPELINE = gql`
  mutation setReplayRecordingPipeline($input: SetReplayRecordingPipelineInput) {
    setReplayRecordingPipeline(input: $input) {
      meetingTime
      title
      id
      courseId
      mediaPipelines {
        id
        createdAt
        awsConcatPipelineId
        replayRecording
        processRecordingStatus
        generateChatStatus
        generateEventsStatus
        generateChaptersStatus
        generateCaptionsStatus
        attendeeLogStatus
      }
    }
  }
`;

export const START_FHD_MEETING = gql`
  ${MEETING_FIELDS}
  ${ATTENDEE_FIELDS}
  mutation startFhdMeeting($id: Int!, $input: StartAndJoinMeetingInput!) {
    startFhdMeeting(id: $id, input: $input) {
      attendee {
        ...AttendeeFields
      }
      meeting {
        ...MeetingFields
      }
      recordMeetingStatus
      initVersionNumber
    }
  }
`;

export const SOFT_BAN_ATTENDEE = gql`
  ${ATTENDEE_FIELDS}
  mutation softBanAttendee($AttendeeId: String!, $softBan: Boolean!) {
    softBanAttendee(AttendeeId: $AttendeeId, softBan: $softBan) {
      ...AttendeeFields
    }
  }
`;

export const REPLACE_MEETING_RECORDING = gql`
  mutation replaceMeetingRecording($id: Int!) {
    replaceMeetingRecording(id: $id) {
      id
      meeting {
        meetingTime
        title
        id
        courseId
        mediaPipelines {
          id
          createdAt
          awsConcatPipelineId
          replayRecording
          processRecordingStatus
          generateChatStatus
          generateEventsStatus
          generateChaptersStatus
          generateCaptionsStatus
          attendeeLogStatus
        }
      }
    }
  }
`;
