import React, { useEffect } from "react";
import classNames from "classnames";
import { CaptionType } from "../../providers/dataCaptions";
import { useScreenContext } from "../../contexts/screenContext";

type ClosedCaptionsProps = {
  className?: string;
  captions: CaptionType[];
  currentPartial?: CaptionType;
};

/** Component that contains Chime SDK and render logic for the closed captions
 * @param {ClosedCaptionsProps} props
 * @prop {string} className - Optional class name that will be applied to the component at the top level div
 * @prop {CaptionType[]} captions - pass down from replay or meeting to provide the captions
 * @prop {CaptionType} currentPartial - pass down from Meeting for the most current caption
 */
export const ClosedCaptions: React.FC<ClosedCaptionsProps> = ({
  className,
  captions,
  currentPartial,
}) => {
  const { useMobileTools } = useScreenContext();

  return (
    (currentPartial || captions) && (
      <div
        className={classNames("closed-captions", className, {
          "closed-captions__mobile": useMobileTools,
        })}
      >
        <div className="closed-captions__line closed-captions__line--current">
          {currentPartial !== undefined &&
            currentPartial.text !== undefined &&
            `${currentPartial.text}`}
        </div>
        {captions?.map((caption, index) => (
          <div
            id={"caption-" + index.toString()}
            key={index}
            className="closed-captions__line"
          >
            {caption.text}
          </div>
        ))}
      </div>
    )
  );
};
