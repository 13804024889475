import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { PageWrapper } from "../../components/pageWrapper";
import { useAuthContext } from "../../contexts/authContext";
import { useAppContext } from "../../contexts/appContext";
import { LoginInput } from "../../types";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

export const LogIn: React.FC = () => {
  const [error, setError] = useState(false);
  const { handleLogin, isAuthenticated } = useAuthContext();
  const { setFirstName, setLastName } = useAppContext();

  const onSuccess = (credentialResponse) => {
    const user: any = jwtDecode(credentialResponse.credential);
    setFirstName(user["given_name"]);
    setLastName(user["family_name"]);

    if (user) {
      const input: LoginInput = {
        email: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
        expiresAt: user.exp,
      };

      // call our login after the successful gOauth call if user is not already
      if (!isAuthenticated) handleLogin(input);
    }
  };

  const onError = () => {
    consoleNonProd("google oauth log in error");
    setError(true);
  };

  return (
    <PageWrapper className="admin">
      <div className="admin-content">
        <div className="admin-inner">
          <header>
            <h1>Admin Login</h1>
          </header>
          <main>
            <div style={{ marginBottom: "0.5rem" }}>
              Please sign in with Google using your Barnes email to get started.
            </div>
            {error ? (
              <div>
                There was an issue reaching Google for log in. Please try again
                later.
              </div>
            ) : (
              <GoogleLogin
                auto_select
                useOneTap
                onSuccess={onSuccess}
                onError={onError}
              />
            )}
          </main>
        </div>
      </div>
    </PageWrapper>
  );
};
