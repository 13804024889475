import React, { Fragment, useMemo, useState } from "react";
import { validateYouTubeEmbedUrl } from "../../utils/validators";
import { Button, PRIMARY, SECONDARY, STANDARD } from "../buttons/button";
import VideoViewer from "../canvas/videoViewer";
import { TextInput } from "../inputs/textInput";
import { ContentModalWrapper } from "./contentModalWrapper";

type AddVideoProps = {
  disabled: boolean;
  leader: boolean;
  videoSaveClick: (e: string) => void;
  initialInput?: string;
};

/** Add video url form for the Add Content Modal
 * @param {AddVideoProps} props
 * @prop {boolean} disabled - Whether or not the video url text input is disabled
 * @prop {boolean} leader - Whether or not the current user is logged in as an instructor
 * @prop {(e: string) => void} videoSaveClick - Function that is called on form submit
 */
const AddVideo: React.FC<AddVideoProps> = ({
  disabled,
  leader,
  videoSaveClick,
  initialInput,
}: AddVideoProps) => {
  const [videoUrl, setVideoUrl] = useState<string>(initialInput);
  const [videoInputValue, setVideoInputValue] = useState<string>(initialInput);

  const onVideoInputChange = ({
    currentTarget: { value: val },
  }: React.FormEvent<HTMLInputElement>): void => {
    if (videoUrl) {
      setVideoUrl("");
    }
    setVideoInputValue(val);
  };

  const validSource = useMemo(() => {
    if (videoInputValue?.indexOf("youtube") > -1) {
      return false;
    }
    if (videoInputValue?.indexOf("vimeo") > -1) {
      return false;
    } else return true;
  }, [videoInputValue]);

  const altSearch = (
    <div className="add-content__video-body__input">
      <TextInput
        disabled={disabled}
        errorMessage=""
        hasError={false}
        id="Video-url-upload"
        label="Please enter the video url"
        name="video-url-upload"
        onChange={onVideoInputChange}
        initialValue={initialInput}
      />
      <Button
        btnType={SECONDARY}
        disabled={validSource}
        size={STANDARD}
        text="Preview video"
        customWidth="150px"
        onClick={(e) => {
          if (videoInputValue?.indexOf("youtube") > -1) {
            setVideoUrl(validateYouTubeEmbedUrl(videoInputValue));
          } else setVideoUrl(videoInputValue);
        }}
        inputClass="add-content__video-body__input-button"
        id="preview-video-button"
      />
    </div>
  );

  const footer = (
    <Button
      btnType={PRIMARY}
      disabled={initialInput === videoUrl}
      size={STANDARD}
      text="Save video"
      onClick={(e) => {
        videoSaveClick(videoUrl);
      }}
      id="save-video-button"
    />
  );

  return (
    <ContentModalWrapper
      title="Video Link"
      footerChildren={footer}
      altSearchChildren={altSearch}
    >
      <div className="add-content__video-body">
        <div>
          {videoUrl ? (
            <VideoViewer
              isPreview={true}
              sourceUrl={videoUrl}
              leader={leader}
            />
          ) : (
            <div className="add-content__video-body__instructions">
              Video player is currently setup to play Youtube or Vimeo videos.
            </div>
          )}
        </div>
      </div>
    </ContentModalWrapper>
  );
};

export default AddVideo;
