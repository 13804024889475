import React, { ChangeEvent, useState } from "react";
import { DateTimePickerInput } from "../inputs/dateTimePickerInput";
import { CreateMeetingInput, Meeting } from "../../types";
import { Switch } from "../inputs/switch";

type ClassFormProps = {
  index: number;
  initMeeting: CreateMeetingInput | Meeting;
  handleUpdateMeeting: (index: number, meeting: CreateMeetingInput) => void;
  autoFocus: boolean;
  className?: string;
  newClass?: boolean;
  duplicateClass?: boolean;
};

export const ClassForm: React.FC<ClassFormProps> = ({
  index,
  initMeeting,
  handleUpdateMeeting,
  autoFocus,
  className,
  newClass,
  duplicateClass,
}) => {
  const [dateTime, setDateTime] = useState<Date>(
    initMeeting.meetingTime ? new Date(initMeeting.meetingTime) : undefined
  );
  const [meeting, setMeeting] = useState(initMeeting);

  const disabledByTime: boolean =
    meeting.meetingTime < new Date().getTime() + 300000;

  const handleOnChange = (day: Date) => {
    setDateTime(day);
    const isoDay = day?.getTime();
    const updatedMeeting = {
      ...meeting,
      meetingTime: isoDay,
    } as CreateMeetingInput;
    handleUpdateMeeting(index, updatedMeeting);
    setMeeting(updatedMeeting);
  };

  const handleToggleMeetingChat = (e: ChangeEvent<HTMLInputElement>) => {
    const newChat = e.target.checked;
    initMeeting.chat = e.target.checked;
    const updatedMeeting = {
      ...meeting,
      chat: newChat,
    } as CreateMeetingInput;
    handleUpdateMeeting(index, updatedMeeting);
    setMeeting(updatedMeeting);
  };

  const handleToggleMeetingInstAV = (e: ChangeEvent<HTMLInputElement>) => {
    const newIAV = e.target.checked;
    initMeeting.instructorAV = e.target.checked;
    const updatedMeeting = {
      ...meeting,
      instructorAV: newIAV,
    } as CreateMeetingInput;
    handleUpdateMeeting(index, updatedMeeting);
    setMeeting(updatedMeeting);
  };

  const handleToggleMeetingStudentAV = (e: ChangeEvent<HTMLInputElement>) => {
    initMeeting.studentAV = e.target.checked;
    const newSAV = e.target.checked;
    if (e.target.checked === false) {
      initMeeting.muteLock = false;
      initMeeting.videoOffLock = false;
    }
    const updatedMeeting = {
      ...meeting,
      studentAV: newSAV,
      muteLock: newSAV ? initMeeting.muteLock : false,
      videoOffLock: newSAV ? initMeeting.videoOffLock : false,
    } as CreateMeetingInput;
    handleUpdateMeeting(index, updatedMeeting);
    setMeeting(updatedMeeting);
  };

  const handleToggleMeetingMuteLock = (e: ChangeEvent<HTMLInputElement>) => {
    initMeeting.muteLock = e.target.checked;
    const newML = e.target.checked;
    const updatedMeeting = {
      ...meeting,
      muteLock: newML,
    } as CreateMeetingInput;
    handleUpdateMeeting(index, updatedMeeting);
    setMeeting(updatedMeeting);
  };

  const handleToggleMeetingVideoOffLock = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    initMeeting.videoOffLock = e.target.checked;
    const newML = e.target.checked;
    const updatedMeeting = {
      ...meeting,
      videoOffLock: newML,
    } as CreateMeetingInput;
    handleUpdateMeeting(index, updatedMeeting);
    setMeeting(updatedMeeting);
  };

  return (
    <div className={className}>
      <div className="class-form">
        <h4 className="class-form__header">Date/Time</h4>
        <div className="class-form__item" title="Meeting date and time">
          <DateTimePickerInput
            dateTime={dateTime}
            setDateTime={handleOnChange}
            disabled={disabledByTime && !newClass && !duplicateClass}
            autofocus={autoFocus}
          />
        </div>

        <section className="class-form__item--container">
          <h4 className="class-form__header class-form__head--bottom">
            Class Options
          </h4>

          <div
            className="class-form__item --switch"
            title="Include Chat for ALL participants"
          >
            <label htmlFor={"switch-c" + (index + 1)}>Include Chat</label>
            <Switch
              className="nav__switch"
              onClick={handleToggleMeetingChat}
              isOn={initMeeting.chat === true}
              id={"switch-c" + (index + 1)}
              disabled={disabledByTime && !newClass && !duplicateClass}
            />
          </div>
          <div
            className="class-form__item --switch"
            title="Include Instructor and Moderator Audio and Visual (camera and mic)"
          >
            <label htmlFor={"switch-iav" + (index + 1)}>
              Include Instructor and Moderator Audio and Video
            </label>
            <Switch
              className="nav__switch"
              onClick={handleToggleMeetingInstAV}
              isOn={initMeeting.instructorAV === true}
              id={"switch-iav" + (index + 1)}
              disabled={disabledByTime && !newClass && !duplicateClass}
            />
          </div>
          <div
            className="class-form__item --switch"
            title="Include Student Audio and Visual (camera and mic)"
          >
            <label htmlFor={"switch-sav" + (index + 1)}>
              Include Student Audio and Video
            </label>
            <Switch
              className="nav__switch"
              onClick={handleToggleMeetingStudentAV}
              isOn={initMeeting.studentAV === true}
              id={"switch-sav" + (index + 1)}
              disabled={disabledByTime && !newClass && !duplicateClass}
            />
          </div>
        </section>
        <section className="class-form__item--container">
          <h4 className="class-form__header class-form__head--bottom">
            On Join Options
          </h4>
          <div>
            <div
              className="class-form__item --switch"
              title="Mute Lock stops (only) students from unmuting their microphones"
            >
              <label htmlFor={"muteLock_" + (index + 1)}>Mute Lock</label>
              <Switch
                className="nav__switch"
                onClick={handleToggleMeetingMuteLock}
                isOn={initMeeting.muteLock === true}
                id={"muteLock_" + (index + 1)}
                disabled={
                  !initMeeting.studentAV ||
                  (disabledByTime && !newClass && !duplicateClass)
                }
              />
            </div>
            <p className="class-form__item--subtext">
              Turning on ‘Mute lock’ will turn off and disable the microphone
              for all students on joining. This can be changed in a live
              meeting.
            </p>
          </div>
          <div>
            <div
              className="class-form__item --switch"
              title="Video Off Lock stops (only) students from turning on their cameras."
            >
              <label htmlFor={"videoOffLock_" + (index + 1)}>
                Video Off Lock
              </label>
              <Switch
                className="nav__switch"
                onClick={handleToggleMeetingVideoOffLock}
                isOn={initMeeting.videoOffLock === true}
                id={"videoOffLock_" + (index + 1)}
                disabled={
                  !initMeeting.studentAV ||
                  (disabledByTime && !newClass && !duplicateClass)
                }
              />
            </div>
            <p className="class-form__item--subtext">
              Turning on ‘Video off lock’ will turn off and disable cameras for
              all students. This can be changed in a live meeting.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
