import React, { Fragment, useEffect } from "react";
import { IconButton } from "../../buttons/iconButton";
import { loadSvgsIntoCache } from "../../../utils/cacheMachine";
import { HelperPopUp } from "../../popUps/helperPopUp";
import { useLocalVideo } from "amazon-chime-sdk-component-library-react";
import { VideoType } from "../../videos/videoTile";
import { useScreenContext } from "../../../contexts/screenContext";

type UserVideoToggleProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  toggleShowVideo: () => void;
  showVideo: boolean;
  videoType: VideoType;
};

export const UserVideoToggle: React.FC<UserVideoToggleProps> = ({
  toolTip,
  toggleShowVideo,
  showVideo,
  videoType,
}) => {
  const { isVideoEnabled } = useLocalVideo();
  const { useMobileTools } = useScreenContext();

  // loads the svgs into the svg cache for a smooth switching experience
  useEffect(() => {
    loadSvgsIntoCache(["/icons/user.svg", "/icons/user-x.svg"]);
  }, []);
  return (
    <Fragment>
      <IconButton
        iconName={showVideo ? "minimize-2" : "maximize-2"}
        onClick={toggleShowVideo}
        desc={showVideo ? "Hide video" : "Show video"}
        toolTip={toolTip}
        btnId="toggle-your-video-button"
      />

      {videoType === VideoType.USER && !showVideo && isVideoEnabled && (
        <HelperPopUp
          userMessage="You are still sending your video to others in the meeting."
          bottom="50px"
          right="0px"
          downArrow={true}
          isDismissible={false}
          isTimed={true}
          minWidth="200px"
          arrowRight="4px"
        />
      )}
      {videoType === VideoType.INSTRUCTOR && !showVideo && !useMobileTools && (
        <HelperPopUp
          userMessage="Click here to show instructor video"
          bottom="-100px"
          right="-164px"
          upArrow={true}
          downArrow={null}
          isDismissible={false}
          isTimed={true}
          minWidth="200px"
          arrowLeft="4px"
          arrowRight={null}
        />
      )}
    </Fragment>
  );
};
