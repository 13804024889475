import React, { useContext, ReactHTML } from "react";
import { DARK, ThemeContext, ThemeContextType } from "../contexts/themeContext";
import classNames from "classnames";

type ThemeWrapperProps = {
  className?: string;
  elementType: keyof ReactHTML;
  render?: (context: ThemeContextType) => React.ReactElement;
  children?;
  style?: React.CSSProperties;
  id?: string;
  role?: string;
};

/**
 * HOC to provide theme to children via appending --dark modifier to supplied className.
 */
export const ThemeWrapper: React.FC<ThemeWrapperProps> = ({
  className,
  elementType,
  render,
  children,
  style,
  id,
  role,
}) => {
  const { theme, setTheme } = useContext(ThemeContext);

  // Apply additional BEM modifier if dark theme global context is set.
  const themedComponentClassName = classNames(
    "theme",
    { "theme--dark": theme === DARK },
    className
  );

  return (
    // Rendering element w/o JSX.
    // This is so we can dynamically create the root child element and append any classNames from theme.
    React.createElement(
      elementType,
      {
        className: themedComponentClassName,
        style: style,
        id,
        role,
      },
      // Render children, if there is no children fall back to render prop.
      // This is to keep context information encapsulated in our ThemeWrapper.
      children || (render && render({ theme, setTheme }))
    )
  );
};
