import { DataMessage } from "amazon-chime-sdk-js";
import React, {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useCallback,
} from "react";
import { DATA_MESSAGE_LIFETIME_MS, DATA_EVENT_TOPIC } from "../constants";
import { useAppContext } from "../contexts/appContext";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";
import {
  Action,
  CanvasDataEvent,
  DataEventsActionType,
  EventPayload,
  State,
} from "./types";
import { State as AppState } from "../contexts/types";
import { consoleNonProd } from "../utils/utilityBeltUtils";

interface DataEventsStateContextType {
  sendEvent: (eventPayload: EventPayload, appState: AppState) => void;
  events: CanvasDataEvent[];
}

const DataEventsStateContext = createContext<
  DataEventsStateContextType | undefined
>(undefined);

export const initialState: State = {
  events: [],
};

export function reducer(state: State, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case DataEventsActionType.ADD:
      return { events: [...state.events, payload] };
    default:
      throw new Error("Incorrect action in DataEventsProvider reducer");
  }
}

export const DataEventsProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { localUserName } = useAppContext();
  const meetingManager = useMeetingWithRoster();
  const [state, dispatch] = useReducer(reducer, initialState);

  const handler = useCallback(
    (dataEvent: DataMessage) => {
      if (!dataEvent.throttled) {
        const isSelf =
          dataEvent.senderAttendeeId ===
          meetingManager.meetingSession?.configuration.credentials?.attendeeId;
        if (isSelf) {
          dispatch({
            type: DataEventsActionType.ADD,
            payload: {
              event: new TextDecoder().decode(dataEvent.data),
              senderAttendeeId: dataEvent.senderAttendeeId,
              timestamp: dataEvent.timestampMs,
              senderName: dataEvent.senderExternalUserId,
              isSelf: true,
            },
          });
        } else {
          const data = dataEvent.json();
          dispatch({
            type: DataEventsActionType.ADD,
            payload: {
              event: data.message,
              senderAttendeeId: dataEvent.senderAttendeeId,
              timestamp: dataEvent.timestampMs,
              senderName: data.senderName,
              isSelf: false,
            },
          });
        }
      } else {
        consoleNonProd("DataMessage is throttled. Please resend");
      }
    },
    [meetingManager]
  );

  const sendEvent = useCallback(
    (eventPayload: EventPayload, appState: AppState) => {
      if (
        !meetingManager ||
        !meetingManager.meetingSession ||
        !meetingManager.meetingSession.configuration.credentials ||
        !meetingManager.meetingSession.configuration.credentials.attendeeId ||
        !meetingManager.audioVideo
      ) {
        consoleNonProd(
          "No meeting manager or audio video - Could not send event"
        );
        return;
      }

      const constantEvents = {
        exploreMode: appState.exploreMode,
        spotlightInstructor: appState.spotlightInstructor,
        presentationVersion: appState.presentationVersion,
        showLaser: appState.showLaser,
        currentSlideNumber: appState.currentBoard?.sortOrder,
      };
      const message = JSON.stringify({
        ...constantEvents,
        ...eventPayload,
      });
      consoleNonProd("sending event:", JSON.parse(message));
      const payload = { message, senderName: localUserName };
      const senderAttendeeId =
        meetingManager.meetingSession.configuration.credentials.attendeeId;
      meetingManager?.audioVideo?.realtimeSendDataMessage(
        DATA_EVENT_TOPIC,
        payload,
        DATA_MESSAGE_LIFETIME_MS
      );
      handler(
        new DataMessage(
          Date.now(),
          DATA_EVENT_TOPIC,
          new TextEncoder().encode(message),
          senderAttendeeId,
          localUserName
        )
      );
    },
    [meetingManager, handler, localUserName]
  );

  const value = {
    sendEvent,
    events: state.events,
  };

  useEffect(() => {
    if (!meetingManager?.audioVideo) {
      return;
    }
    meetingManager?.audioVideo?.realtimeSubscribeToReceiveDataMessage(
      DATA_EVENT_TOPIC,
      handler
    );
    return () => {
      meetingManager?.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
        DATA_EVENT_TOPIC
      );
    };
  }, [meetingManager?.audioVideo, handler]);

  return (
    <DataEventsStateContext.Provider value={value}>
      {children}
    </DataEventsStateContext.Provider>
  );
};

export const useDataEvents = (): {
  sendEvent: (eventPayload: EventPayload, appState: AppState) => void;
  events: CanvasDataEvent[];
} => {
  const meetingManager = useMeetingWithRoster();
  const context = useContext(DataEventsStateContext);

  if (!meetingManager || !context) {
    throw new Error(
      "Use useDataEvents hook inside DataEventsProvider. Wrap DataEventsProvider under MeetingProvider."
    );
  }

  return context;
};
