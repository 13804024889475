import { useCallback, useState } from "react";
import { useAppContext } from "../contexts/appContext";
import { Board } from "../types";
import { browserName } from "react-device-detect";
import { CanvasDataEvent, EventPayload, EventTypes } from "../providers/types";
import { ActionType, State } from "../contexts/types";

type UseCanvasArgs = {
  sendEvent: (eventPayload: EventPayload, appState: State) => void;
  events?: CanvasDataEvent[];
};

type UseCanvas = {
  handleSelectSlide: (sortOrder: number) => void;
  scrollToBoard: (boardNumber: number) => void;
  focusOnBoard: () => void;
  handleChangeSlide: (sortOrder: number) => void;
  selectedBoard: Board;
  setSelectedBoard: React.Dispatch<React.SetStateAction<Board>>;
  handleSetSelectedBoard: (sortOrder: number) => void;
};

export const useCanvas = ({ sendEvent, events }: UseCanvasArgs): UseCanvas => {
  const [selectedBoard, setSelectedBoard] = useState<Board>();
  const { isInstructorOrModerator, state, dispatch } = useAppContext();

  /** Handles setting selected slide state in canvas component and dispatching
   * AppContext state update
   * @param {number} sortOrder - Sort order of the selected slide
   */
  const handleSelectSlide = useCallback(
    (sortOrder: number) => {
      const board: Board = state.slides?.find(
        (board) => board.sortOrder === sortOrder
      );
      dispatch({
        type: ActionType.UPDATE_CURRENT_BOARD,
        payload: {
          currentBoard: board,
        },
      });
      setSelectedBoard(board);
    },
    [dispatch, state.slides]
  );

  /** Handles setting selected slide state in canvas component
   * @param {number} sortOrder - Sort order of the selected slide
   */
  const handleSetSelectedBoard = useCallback(
    (sortOrder: number) => {
      const board: Board = state.slides?.find(
        (board) => board.sortOrder === sortOrder
      );
      setSelectedBoard(board);
    },
    [state.slides]
  );

  /** scrolls the selected board to the center of the slides, or scrolls to the recently added board */
  const scrollToBoard = (boardNumber: number) => {
    const currentSlideEl = document.getElementById(`slide-${boardNumber}`);
    const scrollOptions: ScrollIntoViewOptions =
      browserName === "Firefox"
        ? {
            block: "nearest",
            inline: "center",
            behavior: "smooth",
          }
        : {
            block: "nearest",
            inline: "center",
          };
    currentSlideEl?.scrollIntoView(scrollOptions);
  };

  /** Sets focus on the newly selected board so any key presses go to the OSD viewer or Pano */
  const focusOnBoard = () => {
    const iiiFViewerContainer: HTMLElement = document
      ?.getElementById("iiif-Viewer")
      ?.querySelector(".openseadragon-canvas");
    const PanoViewerContainer: HTMLElement =
      document?.getElementById("pano-viewer");
    iiiFViewerContainer?.focus();
    PanoViewerContainer?.focus();
  };

  /** Handles sending slide change event, and updating state and AppContext
   * when a new slide is selected
   * @param {number} sortOrder - The sortOrder of the newly selected slide
   */
  const handleChangeSlide = (sortOrder: number) => {
    handleSelectSlide(sortOrder);
    scrollToBoard(sortOrder);
    sendEvent &&
      sendEvent(
        {
          type: EventTypes.CHANGE_SLIDE,
          leader: isInstructorOrModerator,
          currentSlideNumber: sortOrder,
          exploreMode: state.exploreMode,
        },
        state
      );

    setTimeout(() => {
      focusOnBoard();
    }, 0);
  };

  return {
    handleSelectSlide,
    scrollToBoard,
    focusOnBoard,
    handleChangeSlide,
    selectedBoard,
    setSelectedBoard,
    handleSetSelectedBoard,
  };
};
