import React from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { PageWrapper } from "../../components/pageWrapper";
import { MeetingForm } from "../../components/meetingForm";
import { CREATE_DEMO_MEETING } from "../../graphql/mutations";
import { useAppContext } from "../../contexts/appContext";
import { useAuthContext } from "../../contexts/authContext";
import { useJoinMeeting } from "../../hooks/useJoinMeeting";
import { routes } from "../../constants";
import { MeetingError, MeetingErrorCodes, Mutation } from "../../types";
import { useError } from "../../contexts/errorContext";

// Component for the /demo route
export const Demo: React.FC = () => {
  const { meetingMode, meetingId } = useAppContext();
  const { isAuthenticated } = useAuthContext();
  const { handleJoinMeeting } = useJoinMeeting();
  const navigate = useNavigate();
  const { showError } = useError();

  const [createDemoMeetingMutation, mutationResult] = useMutation(
    CREATE_DEMO_MEETING,
    {
      async onCompleted({
        createDemoMeeting: { attendee, meeting },
      }: Pick<Mutation, "createDemoMeeting">) {
        // backdoor to be an admin on a demo
        if (attendee?.firstName) {
          if (attendee.firstName.indexOf("/presenter") > 0) {
            attendee.meetingRole = 1;
            attendee.firstName = attendee.firstName.substring(
              0,
              attendee.firstName.indexOf("/presenter")
            );
          }
        }
        if (attendee && meeting) {
          await handleJoinMeeting(attendee, meeting, meetingMode);

          // Navigate to the meeting after the meeting manager starts
          // use location state here to pass meeting and attendee info to prevent issues w/ local storage clearing
          navigate(`/${routes.meeting}/${meeting.title}`, {
            state: {
              attendee,
              meeting,
              meetingMode,
            },
          });
        }
      },
      onError(error) {
        const errors = error.graphQLErrors[0].extensions
          .errors as MeetingError[];

        if (
          errors[0].code === MeetingErrorCodes.CHIME_NOT_ACTIVE ||
          errors[0].code == MeetingErrorCodes.CHIME_NOT_INITIALIZED
        ) {
          showError({
            header: "Oops! Unable to join meeting.",
            error: errors[0].message,
            details: undefined,
          });
        } else {
          showError({
            header: "Oops! Unable to join meeting.",
            error: `There was an issue finding a meeting with ID ${meetingId}`,
            details: error,
          });
        }
      },
    }
  );

  return (
    <PageWrapper className="demo">
      <MeetingForm
        formType="demo"
        onSubmit={createDemoMeetingMutation}
        mutationResult={mutationResult}
      />
    </PageWrapper>
  );
};
