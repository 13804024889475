import React, { useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { PageWrapper } from "../components/pageWrapper";
import { MeetingForm } from "../components/meetingForm";
import { useAppContext } from "../contexts/appContext";
import { useJoinMeeting } from "../hooks/useJoinMeeting";
import { CREATE_ATTENDEE } from "../graphql/mutations";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../constants";
import { MeetingError, MeetingErrorCodes, Mutation } from "../types";
import { useError } from "../contexts/errorContext";

// Component for the /join route
export const Join: React.FC = () => {
  const { meetingMode, meetingId } = useAppContext();
  const { handleJoinMeeting } = useJoinMeeting();
  const navigate = useNavigate();
  const location = useLocation();
  const { showError } = useError();

  const [createAttendeeMutation, mutationResult] = useMutation(
    CREATE_ATTENDEE,
    {
      async onCompleted({ createAttendee }: Pick<Mutation, "createAttendee">) {
        if (createAttendee) {
          const attendeeData = { ...createAttendee };
          delete attendeeData["meeting"]; // Remove meeting data from the attendee data
          await handleJoinMeeting(
            attendeeData,
            createAttendee.meeting,
            meetingMode
          );

          // Navigate to the meeting after the meeting manager starts
          // use location state here to pass meeting and attendee info to prevent issues w/ local storage clearing
          navigate(`/${routes.meeting}/${createAttendee.meeting.title}`, {
            state: {
              attendee: attendeeData,
              meeting: createAttendee.meeting,
              meetingMode,
            },
          });
        }
      },
      onError(error) {
        const errors = error.graphQLErrors[0].extensions
          .errors as MeetingError[];

        if (
          errors[0].code === MeetingErrorCodes.CHIME_NOT_ACTIVE ||
          errors[0].code == MeetingErrorCodes.CHIME_NOT_INITIALIZED
        ) {
          showError({
            header: "Oops! Unable to join meeting.",
            error: errors[0].message,
            details: undefined,
          });
        } else {
          showError({
            header: "Oops! Unable to join meeting.",
            error: `There was an issue finding a meeting with ID ${meetingId}`,
            details: error,
          });
        }
      },
    }
  );

  const closeJoinError = useCallback(() => {
    const newState = { ...location.state };
    delete newState["paramError"];
    //set the location state to the new meeting values for recording and lobby
    navigate(location.pathname, { replace: true, state: newState });
  }, [location, navigate]);

  useEffect(() => {
    if (location?.state?.paramError) {
      showError({
        header: "Oops! Unable to join meeting.",
        error: location?.state?.paramError,
        onClick1: closeJoinError,
      });
    }
  }, [location, closeJoinError, showError]);

  return (
    <PageWrapper className="join">
      <MeetingForm
        formType="join"
        onSubmit={createAttendeeMutation}
        mutationResult={mutationResult}
      />
    </PageWrapper>
  );
};
