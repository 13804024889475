import React, { useContext, useEffect, useState } from "react";
import { useAppContext } from "../../../contexts/appContext";
import { ChatDataMessage } from "../../../providers/dataMessages";
import { IconButton } from "../../buttons/iconButton";
import { ThemeContext } from "../../../contexts/themeContext";
import variables from "../../../styles/variables.scss";
import { ChatReplayMessage } from "../../../providers/replayDataMessages";
import { Button, PRIMARY, SMALL } from "../../buttons/button";

type ChatToggleProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  onClick?: (e) => void;
  emitClick?: (e) => void;
  replayMode?: boolean;
  messages?: ChatReplayMessage[] | ChatDataMessage[];
  component: "BUTTON" | "ICON_BUTTON";
  chatMessagesCount: number;
};

export const ChatToggle: React.FC<ChatToggleProps> = ({
  toolTip,
  onClick,
  emitClick,
  replayMode,
  messages,
  component,
  chatMessagesCount,
}) => {
  const { showChat, toggleChat, closedChatCount } = useAppContext();
  const [showChatCount, setShowChatCount] = useState(true);
  const [count, setCount] = useState(0);
  const { theme } = useContext(ThemeContext);
  const fillColor = variables[`${theme}_color__copy--dark`];

  useEffect(() => {
    if (
      chatMessagesCount - closedChatCount !== 0 &&
      chatMessagesCount - closedChatCount > 0
    ) {
      setShowChatCount(true);
      setCount(chatMessagesCount - closedChatCount);
    } else {
      setShowChatCount(false);
    }
  }, [closedChatCount, chatMessagesCount, count]);

  return component === "ICON_BUTTON" ? (
    <IconButton
      onClick={(e) => {
        toggleChat();
        emitClick && emitClick(e);
      }}
      iconName="message-circle"
      desc={showChat ? "Hide chat" : "View chat"}
      toolTip={toolTip}
      btnId="show-hide-chat-button"
      selected={showChat}
      fill={showChat ? fillColor : "none"}
      counter={showChatCount && count !== 0}
      counterValue={count < 10 ? count : "9+"}
      disabled={!messages || (messages?.length < 1 && replayMode)}
    />
  ) : (
    <Button
      btnType={PRIMARY}
      text={showChat ? "Hide chat" : "View chat"}
      size={SMALL}
      disabled={!messages || (messages?.length < 1 && replayMode)}
      id="show-hide-chat-button"
      onClick={(e) => {
        toggleChat();
        emitClick && emitClick(e);
      }}
      icon="message-circle"
    />
  );
};
