// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  MeetingStatus,
  useNotificationDispatch,
  Severity,
  ActionType,
  useMeetingStatus,
  useLogger,
} from "amazon-chime-sdk-component-library-react";
import { routes } from "../constants";
import { useLocalStorage } from "./useLocalStorage";
import { endFullScreen } from "../utils/utilityBeltUtils";

const useMeetingEndRedirect = () => {
  const logger = useLogger();
  const navigate = useNavigate();
  const dispatch = useNotificationDispatch();
  const meetingStatus = useMeetingStatus();
  const { clearLocalStorage } = useLocalStorage();

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      logger.info("[useMeetingEndRedirect] Meeting ended");
      dispatch({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: "The meeting was ended by another attendee",
          autoClose: true,
          replaceAll: true,
        },
      });
      clearLocalStorage();
      // exit full screen
      endFullScreen();
      navigate("/" + routes.join);
    }
  }, [meetingStatus, dispatch, logger, navigate, clearLocalStorage]);
};

export default useMeetingEndRedirect;
