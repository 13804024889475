import React, { useEffect, useRef, useState } from "react";
import OpenSeadragon from "openseadragon";
import { BoardImage, Mutation } from "../../types";
import { ToolBar } from "./../toolbars/toolBar";
import { isIOS, isIOS13 } from "react-device-detect";
import { getTileSources } from "../../utils/openSeadragonUtils";
import { DELETE_BOARD_IMAGE } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { IconButton } from "../buttons/iconButton";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

type EditIIIFViewerProps = {
  tileSources: BoardImage[];
  inputIndex: number;
  flexBasis: number;
  handleDeleteBoardComplete?: (currentVersion: number) => void;
  setIIIFRemoved: (removed: boolean) => void;
};

/** IIIFViewer Component, uses a ref to drop out of react.
 * @param {IIIFViewerProps} props
 * @prop {BoardImage[]} tileSources - Array of image sources to be used
 * @prop {boolean} leader - Whether or not the user should emit their canvas actions
 * @prop {CanvasDataEvent[]} events - List of app events from the DataEventsProvider, defaults to an empty array
 */
const EditIIIFViewer: React.FC<EditIIIFViewerProps> = ({
  tileSources,
  inputIndex,
  flexBasis,
  handleDeleteBoardComplete,
  setIIIFRemoved,
}: EditIIIFViewerProps) => {
  const ref: React.Ref<HTMLDivElement> = useRef(null);
  const [osd, setOsd] = useState<OpenSeadragon.Viewer>(undefined || null);
  const [wasClicked, setWasClicked] = useState<boolean>(false);
  const imageToEdit: BoardImage[] = [tileSources[inputIndex]];

  const [deleteBoardImageMutation] = useMutation(DELETE_BOARD_IMAGE, {
    onCompleted(data: Pick<Mutation, "deleteBoardImage">) {
      const currentVersion = data.deleteBoardImage.currentVersion;
      handleDeleteBoardComplete(currentVersion);
      setWasClicked(false);
      setIIIFRemoved(true);
    },
    onError(error) {
      consoleNonProd("Error removing images from board", error);
      setWasClicked(false);
    },
  });
  // Pass ref to useEffect, canvas for IIIF viewer will be dropped underneath the ref.
  useEffect(() => {
    const initOsd = OpenSeadragon({
      element: ref.current !== null ? ref.current : undefined,
      tileSources: getTileSources(imageToEdit),
      collectionMode: false,
      constrainDuringPan: true,
      showHomeControl: false,
      showZoomControl: false,
      showRotationControl: false,
      showFullPageControl: false,
      showReferenceStrip: true,
      mouseNavEnabled: 0,
      maxZoomLevel: 1,
      minZoomImageRatio: 1,
      defaultZoomLevel: 1,
      iOSDevice: isIOS || isIOS13,
      useCanvas: isIOS || isIOS13 ? false : true,
    });

    setOsd(initOsd);
    return () => {
      initOsd.destroy();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
    // including leader and exploreMode in the dependency array caused the OSD
    // to reinitialize every time one of those vars updated, so they had to be removed.
  }, [tileSources, ref]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      style={{
        height: flexBasis < 20 ? "50%" : "100%",
        flexBasis: flexBasis + "%",
      }}
      className="edit-iiif-container"
    >
      <ToolBar className="edit-iiif-tools" orientation={"horizontal"}>
        <IconButton
          onClick={(e) => {
            setWasClicked(true);
            deleteBoardImageMutation({
              variables: { input: imageToEdit[0].id },
            });
          }}
          disabled={wasClicked}
          iconName="trash-2"
          toolTip="top"
          desc="Remove image from board"
        />
      </ToolBar>
      <div
        id={"iiif-Viewer" + inputIndex}
        ref={ref}
        className="edit-iiif-container__viewer"
      />
    </div>
  );
};

export default EditIIIFViewer;
