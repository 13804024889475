import React from "react";
import { useAppContext } from "../../../contexts/appContext";
import { useDataEvents } from "../../../providers/dataEvents";
import { EventTypes } from "../../../providers/types";
import { IconButton } from "../../buttons/iconButton";

type MuteAllButtonProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  emitClick?: (e) => void;
};

export const MuteAllButton: React.FC<MuteAllButtonProps> = ({
  toolTip,
  emitClick,
}) => {
  const { sendEvent } = useDataEvents();
  const { isInstructorOrModerator, state } = useAppContext();

  const muteAllAction = (e) => {
    sendEvent(
      {
        type: EventTypes.MUTE_ALL,
        leader: isInstructorOrModerator,
      },
      state
    );
    emitClick(e);
  };

  return (
    <IconButton
      onClick={muteAllAction}
      iconName="mute-all"
      desc="Mute all participants"
      toolTip={toolTip}
    />
  );
};
