import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { apolloClient } from "../utils/clients/apolloClient";
import { GET_ATTENDEE } from "../graphql/queries";
import { Attendee } from "../types";
import { consoleNonProd } from "../utils/utilityBeltUtils";

export const useMeetingWithRoster = () => {
  const meetingManager = useMeetingManager();
  meetingManager.getAttendee = async (
    _attendeeId: string,
    externalId?: string
  ) => {
    consoleNonProd("fetching attendee..");
    const { loading, data, error } = await apolloClient.query({
      query: GET_ATTENDEE,
      variables: {
        externalId,
      },
    });

    if (error) return null;

    const attendee: Attendee = data?.getAttendeeByExternalId;
    return {
      ...attendee,
      meetingRole: attendee?.meetingRole,
      name: `${attendee?.firstName} ${attendee?.lastName}`,
    };
  };
  return meetingManager;
};
