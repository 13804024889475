import React, { Fragment } from "react";
import { IconButton } from "../../buttons/iconButton";

type MinimizeExpandToggleProps = {
  isActive: boolean;
  isHorizontal: boolean;
  onClick: () => void;
  className?: string;
};

export const MinimizeExpandToggle: React.FC<MinimizeExpandToggleProps> = ({
  isActive,
  isHorizontal,
  onClick,
  className,
}) => {
  return (
    <Fragment>
      {isActive ? (
        <IconButton
          iconName={isHorizontal ? "chevrons-left" : "chevrons-up"}
          desc="Minimize toolbar"
          toolTip={isHorizontal ? "top" : "bottom"}
          onClick={onClick}
          btnId="minimize-button"
          className={className}
        />
      ) : (
        <IconButton
          iconName={isHorizontal ? "chevrons-right" : "minimize"}
          desc="Expand toolbar"
          toolTip="top"
          onClick={onClick}
          btnId="expand-button"
          className={className}
        />
      )}
    </Fragment>
  );
};
