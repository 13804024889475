import {
  DeviceLabels,
  MeetingManager,
  MeetingManagerJoinOptions,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { useAppContext } from "../contexts/appContext";
import {
  ActionType,
  JoinInfoType,
  MeetingMode,
  MeetingRole,
  UpdateAttendeeSoftBanAction,
} from "../contexts/types";
import { Attendee, Meeting } from "../types";
import { useMeetingWithRoster } from "./useMeetingWithRoster";
import { useDataMessages } from "../providers/dataMessages";
import variables from "../styles/variables.scss";

type UseJoinMeeting = {
  handleJoinMeeting: (
    attendee: Attendee,
    meeting: Meeting,
    meetingMode: MeetingMode
  ) => Promise<MeetingManager>;
};

export const useJoinMeeting = (): UseJoinMeeting => {
  const meetingManager = useMeetingWithRoster();

  const {
    setMeetingId,
    setSimpleView,
    setLocalUserName,
    setFirstName,
    setLastName,
    handleSetUserColor,
    isWebAudioEnabled,
    setIsInstructorAVMeeting,
    setIsStudentAVMeeting,
    setMeetingHasChat,
    handleSetJoinInfo,
    handleSetDbId,
    handleSetMeetingMode,
    handleSetMeetingRole,
    setIsDemo,
    dispatch,
    isInstructorOrModerator,
  } = useAppContext();
  const { resetMessages } = useDataMessages();

  const handleJoinMeeting = async (
    attendee: Attendee,
    meeting: Meeting,
    meetingMode: MeetingMode
  ) => {
    const meetingData = {
      ...meeting,
      MediaPlacement: {
        AudioHostUrl: meeting.AudioHostUrl,
        SignalingUrl: meeting.SignalingUrl,
        TurnControlUrl: meeting.TurnControlUrl,
      },
    };

    const joinInfoData: JoinInfoType = {
      Attendee: attendee,
      Meeting: meetingData,
    };

    dispatch({
      type: ActionType.SET_INIT_STATE,
    });

    // Set meeting info in context
    handleSetJoinInfo(joinInfoData);
    handleSetDbId(meeting.id);
    setSimpleView(!meeting.instructorAV && !meeting.studentAV && !meeting.chat);
    setIsInstructorAVMeeting(meeting.instructorAV);
    setIsStudentAVMeeting(meeting.studentAV);
    setMeetingHasChat(meeting.chat);
    handleSetMeetingMode(meetingMode);
    setMeetingId(meeting.title);
    setIsDemo(!meeting.courseId);

    // Set attendee info in context
    handleSetUserColor(
      isInstructorOrModerator ? variables.barnesOrange : attendee?.iconColor
    );
    handleSetMeetingRole(attendee.meetingRole);
    setLocalUserName(attendee.firstName + " " + attendee.lastName);
    setFirstName(attendee.firstName);
    setLastName(attendee.lastName);
    const payload: UpdateAttendeeSoftBanAction = {
      type: ActionType.UPDATE_ATTENDEE_SOFT_BAN,
      payload: { softBan: attendee.softBanned },
    };
    dispatch(payload);

    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      joinInfoData.Meeting,
      joinInfoData.Attendee
    );

    const options: MeetingManagerJoinOptions = {
      deviceLabels:
        meetingMode === MeetingMode.Spectator
          ? DeviceLabels.None
          : DeviceLabels.AudioAndVideo,
      enableWebAudio: isWebAudioEnabled,
    };

    // Join the join and start the meeting manager
    await meetingManager.join(meetingSessionConfiguration, options);
    // sometimes the users audio devices don't want to start at the start
    // manually setting those before the start seems to sort that on normal load
    // and on refresh. setTimeout is to avoid some sort of race condition in Chime.
    const audioInputDevices =
      await meetingManager?.audioVideo?.listAudioInputDevices();
    const videoInputDevices =
      await meetingManager?.audioVideo?.listVideoInputDevices();
    setTimeout(async () => {
      if (meeting?.instructorAV) {
        // if instructors have AV let moderators and instructors turn on their input
        // otherwise do not
        if (
          joinInfoData.Attendee.meetingRole === MeetingRole.Moderator ||
          joinInfoData.Attendee.meetingRole === MeetingRole.Presenter
        ) {
          await meetingManager?.audioVideo?.startAudioInput(
            audioInputDevices.length > 0 ? audioInputDevices[0] : null
          );
        }
        await meetingManager?.audioVideo?.startVideoInput(
          videoInputDevices.length > 0 ? videoInputDevices[0] : null
        );
      }
      if (meeting?.studentAV) {
        // if students have AV let let students turn on their input
        // otherwise do not
        if (joinInfoData.Attendee.meetingRole === MeetingRole.Audience) {
          await meetingManager?.audioVideo?.startAudioInput(
            audioInputDevices?.length > 0 ? audioInputDevices[0] : null
          );
        }
        await meetingManager?.audioVideo?.startVideoInput(
          videoInputDevices.length > 0 ? videoInputDevices[0] : null
        );
      }

      await meetingManager?.audioVideo?.chooseAudioOutput(null);
    }, 1000);
    await meetingManager.start();

    return meetingManager;
  };

  return {
    handleJoinMeeting,
  };
};
