import classNames from "classnames";
import React from "react";
import { nameInitializer } from "../utils/initializer";

type AttendeeInitialsProps = {
  iconColor: string;
  name: string;
  className?: string;
  superscript?: JSX.Element;
  ariaLabel?: string;
};

/**
 * @param {AttendeeInitialsProps}
 * @prop {string} iconColor
 * @prop {string} name
 * @prop {string} className
 * @prop {JSX.Element} superscript - Element that will be added to the upper right corner of the initials box
 * @prop {string} ariaLabel
 */
export const AttendeeInitials: React.FC<AttendeeInitialsProps> = ({
  iconColor,
  name,
  className,
  superscript,
  ariaLabel,
}) => {
  return (
    <div
      className={classNames("attendee-initials", className)}
      style={{ backgroundColor: iconColor.replace("_", "#") }}
      {...(ariaLabel ? { "aria-label": ariaLabel } : {})}
    >
      {superscript && (
        <div className="attendee-initials__superscript">{superscript}</div>
      )}
      <span className="attendee-initials__text">{nameInitializer(name)}</span>
    </div>
  );
};
