import {
  useAudioVideo,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import React, { Fragment, useEffect, useState } from "react";
import { loadSvgsIntoCache } from "../../../utils/cacheMachine";
import { IconButton } from "../../buttons/iconButton";
import variables from "../../../styles/variables.scss";
import { HelperPopUp } from "../../popUps/helperPopUp";
import { StorageKeys, useLocalStorage } from "../../../hooks/useLocalStorage";
import { consoleNonProd, logger } from "../../../utils/utilityBeltUtils";
import { LOG } from "../../../constants";
import { Modal } from "../../modal/modal";
import { PopUp } from "../../popUps/popUp";
import { browserName } from "react-device-detect";
import { Link } from "../../link";
import { useAppContext } from "../../../contexts/appContext";

type MicToggleProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
};

export const MicToggle: React.FC<MicToggleProps> = ({ toolTip }) => {
  const { muted, toggleMute } = useToggleLocalMute();
  const audioVideo = useAudioVideo();
  const { getLocalStorage } = useLocalStorage();
  const [poorConnection, setPoorConnection] = useState(false);
  const [showMuteLockMessage, setShowMuteLockMessage] = useState(false);
  const [userDismissed, setUserDismissed] = useState(false);
  const [deniedModal, setDeniedModal] = useState(false);
  const [erorModal, setErrorModal] = useState(false);
  const { state, isInstructorOrModerator } = useAppContext();

  const deniedMessagePartOne =
    "To enjoy the full experience on our site you will need to update your browser settings to allow microphone access. The site requires access to your microphone so that the instructors and other participants can hear you.";
  const deniedMessagePartTwo =
    "Or please reach out to our Student Help Desk line at 267.831.4464 or email support@barnesclasses.org";
  const errorMessage =
    "Something went wrong and we cannot access your microphone. If you would like assistance please reach out to our Student Help Desk line at 267.831.4464 or email support@barnesclasses.org";

  const chromeHelpLink =
    "https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop";
  const edgeHelpLink =
    "https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857";
  const fireFoxHelpLink =
    "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions";
  const safariHelpLink =
    "https://support.apple.com/guide/mac-help/control-access-to-your-camera-mchlf6d108da/mac";

  const browserCheckForHelpLink = (): string => {
    if (browserName === "Edge") {
      return edgeHelpLink;
    }
    if (browserName === "Firefox") {
      return fireFoxHelpLink;
    }
    if (browserName === "Chrome") {
      return chromeHelpLink;
    }
    if (browserName === "Safari") {
      return safariHelpLink;
    }
  };

  const EdgePermissions = (
    <Fragment>
      {deniedMessagePartOne}
      <Link
        target="_blank"
        rel="noreferrer"
        href={browserCheckForHelpLink()}
        style={{
          margin: "1rem 0",
          display: "block",
        }}
        text={
          "Click here to learn how to enable microphone access in " +
          browserName +
          "."
        }
      />
      {deniedMessagePartTwo}
    </Fragment>
  );

  useEffect(() => {
    loadSvgsIntoCache(["/icons/mic.svg", "/icons/mic-off.svg"]);
  }, []);

  function logUserInfo(caller: string) {
    const message = {
      source: "MicToggle Chime Connection became Poor from " + caller,
      attendee: getLocalStorage(StorageKeys.attendee)?.AttendeeId,
      message: "User Agent: " + navigator?.userAgent,
    };
    logger({ type: LOG, message });
  }
  // Set mic to muted when a bad connection is discovered;
  useEffect(() => {
    audioVideo?.addObserver({
      connectionDidBecomePoor: () => {
        if (!muted) {
          audioVideo?.realtimeMuteLocalAudio();
          setPoorConnection(true);
          logUserInfo("connectionDidBecomePoo");
        }
      },
    });
    audioVideo?.addObserver({
      connectionDidSuggestStopVideo: () => {
        if (!muted) {
          audioVideo?.realtimeMuteLocalAudio();
          setPoorConnection(true);
          logUserInfo("connectionDidSuggestStopVideo");
        }
      },
    });
    audioVideo?.addObserver({
      connectionDidBecomeGood: () => {
        setPoorConnection(false);
        const message = {
          source: "MicToggle Chime Connection did become good",
          attendee: getLocalStorage(StorageKeys.attendee)?.AttendeeId,
          message: "User Agent: " + navigator?.userAgent,
        };
        logger({ type: LOG, message });
      },
    });
  }, [audioVideo, muted]);

  function getPermissions() {
    // Immediately exit fn if the attendee is soft banned
    if (state.attendee.softBanned) return;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Request microphone access
      try {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function (stream) {
            // Microphone access granted
            state.muteLock && !isInstructorOrModerator
              ? setShowMuteLockMessage(true)
              : toggleMute();
          })
          .catch(function (error) {
            // Microphone access denied or an error occurred
            consoleNonProd("Error accessing microphone:", error.message);
            if (
              error.name === "NotAllowedError" ||
              error.name === "PermissionDeniedError"
            ) {
              consoleNonProd("Microphone access denied by the user.");
              setDeniedModal(true);
              // Handle denial gracefully
            }
          });
      } catch (error) {
        setErrorModal(true);
        consoleNonProd("something went wrong accessing microphone");
      }
    } else {
      // getUserMedia is not supported so throw the error modal
      consoleNonProd("getUserMedia is not supported in this browser.");
      setErrorModal(true);
    }
  }

  return (
    <>
      <IconButton
        iconName={muted ? "mic-off" : "mic"}
        onClick={getPermissions}
        desc={muted ? "Unmute" : "Mute"}
        toolTip={toolTip}
        stroke={muted ? variables.actionRed : variables.actionGreen}
        btnId="mute-mic-button"
        selected={!muted}
        disabled={poorConnection}
      />
      {/* Modal for when user denied browser microphone access */}
      <Modal
        dismissible={true}
        display={deniedModal || erorModal}
        onDismiss={() => {
          setDeniedModal(false);
          setErrorModal(false);
        }}
      >
        <div className="__modal-content">
          <PopUp
            popUpHeader="We're sorry, but it seems that microphone access is currently denied."
            userMessage={deniedModal ? EdgePermissions : errorMessage}
            buttonText2="Okay"
            buttonType2="primary"
            onClick2={() => {
              setDeniedModal(false);
              setErrorModal(false);
            }}
          />
        </div>
      </Modal>
      {poorConnection && (
        <HelperPopUp
          userMessage="Your camera and microphone are temporarily disabled until the connection improves."
          popUpHeader="Bad connection quality"
          bottom="30px"
          right="-80px"
          downArrow={false}
          isDismissible={userDismissed ? false : true}
          onDismiss={() => {
            setUserDismissed(true);
          }}
          isTimed={userDismissed ? true : false}
          minWidth="250px"
          upArrow={false}
        />
      )}
      {showMuteLockMessage && (
        <HelperPopUp
          popUpHeader="Class microphones disabled"
          userMessage="All microphones have been temporarily disabled by the teaching team."
          bottom="30px"
          right="-80px"
          downArrow={false}
          isDismissible={true}
          onDismiss={() => {
            setShowMuteLockMessage(false);
          }}
          isTimed={true}
          dismissTimeout={8}
          minWidth="250px"
          upArrow={false}
        />
      )}
    </>
  );
};
