import React, { useReducer, createContext, useContext } from "react";
import { Action, CanvasDataEvent, DataEventsActionType, State } from "./types";

export interface DataEventsStateContextType {
  addEvent: (event: CanvasDataEvent) => void;
  events: CanvasDataEvent[];
}

const ReplayDataEventsStateContext = createContext<
  DataEventsStateContextType | undefined
>(undefined);

export const initialState: State = {
  events: [],
};

export function reducer(state: State, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case DataEventsActionType.ADD:
      return { events: [...state.events, payload] };
    default:
      throw new Error("Incorrect action in ReplayDataEventsProvider reducer");
  }
}

export const ReplayDataEventsProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addEvent = (event: CanvasDataEvent) => {
    dispatch({
      type: DataEventsActionType.ADD,
      payload: event,
    });
  };

  const value = {
    addEvent,
    events: state.events,
  };

  return (
    <ReplayDataEventsStateContext.Provider value={value}>
      {children}
    </ReplayDataEventsStateContext.Provider>
  );
};

export const useReplayDataEvents = (): {
  addEvent: (event: CanvasDataEvent) => void;
  events: CanvasDataEvent[];
} => {
  const context = useContext(ReplayDataEventsStateContext);

  if (!context) {
    throw new Error(
      "Use useDataEvents hook inside DataEventsProvider. Wrap DataEventsProvider under MeetingProvider."
    );
  }

  return { ...context };
};
