import React, { useEffect } from "react";
import {
  MeetingProvider,
  useVoiceFocus,
} from "amazon-chime-sdk-component-library-react";
import {
  AudioInputDevice,
  Device,
  VoiceFocusTransformDevice,
} from "amazon-chime-sdk-js";
import { useLocation } from "react-router-dom";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";
import { routes } from "../constants";

export const MeetingProviderWithDeviceReplacement: React.FC<{
  children: any;
}> = ({ children }) => {
  const { addVoiceFocus } = useVoiceFocus();

  const onDeviceReplacement = (
    nextDevice: string,
    currentDevice: AudioInputDevice
  ): Promise<Device | VoiceFocusTransformDevice> => {
    if (currentDevice instanceof VoiceFocusTransformDevice) {
      return addVoiceFocus(nextDevice);
    }
    return Promise.resolve(nextDevice);
  };

  const meetingConfigValue = {
    onDeviceReplacement,
  };

  return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>;
};

export const MeetingWithRosterProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const location = useLocation();
  const meetingManager = useMeetingWithRoster();

  useEffect(() => {
    if (location.pathname.includes(routes.meeting)) {
      return () => {
        meetingManager.leave();
      };
    }
  }, [location.pathname, meetingManager]);

  return <>{children}</>;
};
