/**
 * @param {number} timeInSeconds - a length of time in seconds. Will return those seconds
 * converted to minutes and seconds
 *
 */

export function formatTime(timeInSeconds) {
  const minutes = Math.trunc(timeInSeconds / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.trunc(timeInSeconds % 60)
    .toString()
    .padStart(2, "0");

  return {
    minutes: minutes,
    seconds: seconds,
  };
}

export function formatReplayTime(timeInSeconds) {
  if (typeof timeInSeconds !== "number" || timeInSeconds < 0) {
    // Handle invalid input
    return "";
  }

  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const remainingSeconds = Math.trunc(timeInSeconds % 60);

  // Add leading zeros for single-digit hours, minutes, and seconds
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  if (formattedHours !== "00") {
    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  } else {
    return formattedMinutes + ":" + formattedSeconds;
  }
}

/**
 * @return gets current time in isostring when function is called
 */
export function getCurrentTimeIso() {
  // Current time on client invocation of app
  const CURRENT_DATE = new Date();
  // convert to string for query
  const CURRENT_DATE_ISO = CURRENT_DATE.getTime();
  return CURRENT_DATE_ISO;
}

/**
 * @return gets iso string for end of current day when function is called
 */
export function getEndOfDayIso() {
  const EOD = new Date();
  EOD.setHours(23);
  const EOD_ISO = EOD.setMinutes(59);
  return EOD_ISO;
}

/**
 * @return gets iso string for beginning of current day when function is called
 */
export function getStartOfDayIso() {
  const EOD = new Date();
  EOD.setHours(0);
  const EOD_ISO = EOD.setMinutes(0);
  return EOD_ISO;
}
