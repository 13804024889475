import React from "react";
import { Link } from "react-router-dom";
import { adminRoutes, routes } from "../../constants";
import { Course } from "../../types";
import { convertUTCtoDateTime } from "../../utils/dateUtils";
import { Container } from "../container";
import Spinner from "../spinner";

type CourseTableProps = { courseMapValue: Course[]; loading?: boolean };
export const CourseTable: React.FC<CourseTableProps> = ({
  courseMapValue,
  loading,
}) => {
  const hasCourses = courseMapValue && courseMapValue.length;

  return (
    <div className="dashboard__table">
      {!loading && hasCourses ? (
        (courseMapValue as Course[]).map((course) => (
          <Container isDismissable={false} key={course.slug}>
            <Link
              to={`/${routes.admin}/${adminRoutes.course}/${course.slug}`}
              className="course-link"
            >
              <div className="course-item">
                <h3>{course.name}</h3>
                {course.meetings.length > 0 && (
                  <>
                    <p>
                      First session:{" "}
                      {convertUTCtoDateTime(course.meetings[0].meetingTime)}
                    </p>
                    <p>
                      Final Session:{" "}
                      {convertUTCtoDateTime(
                        course.meetings[course.meetings.length - 1].meetingTime
                      )}
                    </p>
                  </>
                )}
              </div>
            </Link>
            {course.meetings.length < 1 && (
              <p>
                There are no classes scheduled for this course. You can add
                class sessions{" "}
                <Link
                  to={`/${routes.admin}/${adminRoutes.course}/${course.slug}`}
                  className="add-session-link"
                >
                  here
                </Link>
                .
              </p>
            )}
          </Container>
        ))
      ) : (
        <div className="dashboard__table-spinner">
          {!loading && !hasCourses ? (
            <div className="no-courses">
              <p>No courses</p>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      )}
    </div>
  );
};
