import React from "react";
import { ZOOMABLE_BOARDS } from "../../../constants";
import { useAppContext } from "../../../contexts/appContext";
import { NavEvents } from "../../../contexts/types";
import { IconButton } from "../../buttons/iconButton";

type CanvasNavProps = {
  isLeader: boolean;
  exploreMode: boolean;
};

export const CanvasNav: React.FC<CanvasNavProps> = ({
  isLeader,
  exploreMode,
}) => {
  const { handleCanvasNav, state } = useAppContext();
  const boardCanZoom =
    ZOOMABLE_BOARDS.includes(state.currentBoard?.type) &&
    !state.spotlightInstructor;

  return (
    // For audience, canvas nav buttons are disabled in focus mode and on non iiif images
    // For leader, canvas nav buttons are disabled on iiif
    <>
      <IconButton
        onClick={() => handleCanvasNav(NavEvents.FIT)}
        disabled={(!isLeader && !exploreMode) || !boardCanZoom}
        iconName="maximize"
        desc="Fit canvas"
        toolTip="top"
        btnId="home-zoom-button"
      />
      <IconButton
        iconName="zoom-out"
        desc="Zoom out"
        toolTip="top"
        onClick={() => handleCanvasNav(NavEvents.ZOOM_OUT)}
        disabled={(!isLeader && !exploreMode) || !boardCanZoom}
        btnId="zoom-out-button"
      />
      <IconButton
        iconName="zoom-in"
        desc="Zoom in"
        toolTip="top"
        onClick={() => handleCanvasNav(NavEvents.ZOOM_IN)}
        disabled={(!isLeader && !exploreMode) || !boardCanZoom}
        btnId="zoom-in-button"
      />
    </>
  );
};
