import { Board } from "../types";

export type UpdatePresentationType = {
  action: "added" | "updated" | "deleted";
  board: number;
};

const isObject = (object: any): boolean => {
  return object != null && typeof object === "object";
};

export const objMatch = (object1: any, object2: any): boolean => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (let key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !objMatch(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const objIncluded = (board: Board, boardArray: Board[]): boolean => {
  return boardArray.some((p) => objMatch(board, p));
};

export const getChangedBoard = (boardsA: Board[], boardsB: Board[]): Board => {
  return boardsA.filter((a) => !objIncluded(a, boardsB))[0];
};

export const diffBoards = (
  prevBoards: Board[],
  newBoards: Board[]
): UpdatePresentationType => {
  if (prevBoards.length > newBoards.length) {
    const deletedBoard = getChangedBoard(prevBoards, newBoards);

    return {
      action: "deleted",
      board: deletedBoard.sortOrder,
    };
  } else if (prevBoards.length < newBoards.length) {
    const addedBoard = getChangedBoard(newBoards, prevBoards);

    return {
      action: "added",
      board: addedBoard.sortOrder,
    };
  } else {
    const updatedBoard = getChangedBoard(newBoards, prevBoards);

    return {
      action: "updated",
      board: updatedBoard.sortOrder,
    };
  }
};
