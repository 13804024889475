import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import config from "../config";

const uri = config.api + "/graphql";

// TODO: Update the client to accept headers/token for security
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri,
    fetchOptions: {
      credentials: "include",
    },
    credentials: "include",
  }),
});
