import React from "react";
import { useAppContext } from "../../../contexts/appContext";
import { useSwipeable } from "react-swipeable";
import { Icon } from "../../icon";
import { ToolBar } from "../toolBar";
import classnames from "classnames";

type MobileToolDrawerProps = {
  children: React.ReactNode;
  showSlider: boolean;
  setShowMoreActionsMenu: (show: boolean) => void;
  setShowChapterList: (show: boolean) => void;
  replay: boolean;
};

/**
 * Component for a mobile tool drawer that will slide up on swipe.
 * If you don't want to show the slider (for example if there's no content to display), set showSlider to false.
 * Must use associated child components in order to achieve intended styling.
 * @param children - Content to be rendered inside the tool drawer.
 * @param showSlider - Boolean to show or hide the slider.
 * @param setShowMoreActionsMenu - Function to toggle the display of the more actions menu.
 * @param setShowChapterList - Function to toggle the display of the chapter list.
 * @param replay - Boolean indicating if the component is in replay mode.
 */

export const MobileToolDrawer: React.FC<MobileToolDrawerProps> = ({
  children,
  showSlider,
  setShowMoreActionsMenu,
  setShowChapterList,
  replay,
}) => {
  const {
    showChat,
    setShowChat,
    expandToolBar,
    setExpandToolBar,
    setShowParticipants,
    setShowUserVideo,
  } = useAppContext();

  const handleSwipeUp = () => {
    setExpandToolBar(true);
    setShowChat(true);
    setShowMoreActionsMenu(false);
    if (!replay) {
      setShowUserVideo(true);
    }
    if (replay) {
      setShowChapterList(true);
    }
  };

  const handleSwipeDown = () => {
    setExpandToolBar(false);
    setShowChat(false);
    setShowMoreActionsMenu(false);
    if (!replay) {
      setShowParticipants(false);
      setShowUserVideo(false);
    } else {
      setShowChapterList(false);
    }
  };

  const handleTap = () => {
    if (expandToolBar) {
      setShowChapterList(false);
      setShowChat(false);
      setShowParticipants(false);
      if (!replay) {
        setShowUserVideo(false);
      }
    } else {
      setShowChat(true);
      if (!replay) {
        setShowUserVideo(true);
      }
    }
    setExpandToolBar(!expandToolBar);
    setShowMoreActionsMenu(false);
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: handleSwipeUp,
    onSwipedDown: handleSwipeDown,
    onTap: handleTap,
    onTouchStartOrOnMouseDown: ({ event }) => event.preventDefault(),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true,
    trackTouch: true,
  });

  return (
    <ToolBar
      orientation="horizontal"
      className={classnames("mobile-tools", {
        "replay-tools": replay,
      })}
    >
      {showSlider && (
        <div className="tool-row" id="swipe-grip" {...swipeHandlers}>
          <Icon name="swipe-grip" desc="" toolTip="none" />
        </div>
      )}
      {children}
    </ToolBar>
  );
};

type MobileToolRowProps = {
  children: React.ReactNode;
  id: string;
  showSlider?: boolean;
  setShowMoreActionsMenu: (show: boolean) => void;
  replay: boolean;
  setShowChapterList: (show: boolean) => void;
};

/**
 * Component for a mobile tool row.
 * Suggested maxiumum number of icons is 7, otherwise smaller screens will not fit them all in portrait orientation.
 * @param children - Content to be rendered inside the tool row.
 * @param id - The id for the tool row element.
 */

export const MobileToolRow: React.FC<MobileToolRowProps> = ({
  children,
  id,
}) => {
  return (
    <div className="tool-row" id={id}>
      {children}
    </div>
  );
};

type MobileToolsInnerProps = {
  children: React.ReactNode;
  replay: boolean;
};

/**
 * This component be used as a child of the MobileToolDrawer and
 * should wrap the content that is only visible when the drawer is opened.
 * @param children - Content to be rendered inside the tool inner.
 * @param replay - Boolean indicating if the component is in replay mode.
 */

export const MobileToolsInner: React.FC<MobileToolsInnerProps> = ({
  children,
  replay = false,
}) => {
  return (
    <div
      className={classnames("mobile-tools__inner", {
        "--replay": replay,
      })}
    >
      {children}
    </div>
  );
};
