import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ART_OBJECT_GALLERY_INCREMENT } from "../../constants";
import { SEARCH_ART_OBJECTS } from "../../graphql/queries";
import { ArtObject, Query } from "../../types";
import { Button, PRIMARY, STANDARD } from "../buttons/button";
import { ArtObjectGallery } from "./contentComponents/artObjectGallery";
import { ContentModalWrapper } from "./contentModalWrapper";

type AddArtObjectProps = {
  title: string;
  handleSubmit: (selectedInvnos: string[]) => void;
};

/** Add Content Modal body for collection object contents
 * @param {AddArtObjectProps} props
 * @prop {string} title - Modal title
 * @prop {(selectedInvnos: string[]) => void} handleSubmit - Function called on form submit
 */
export const AddArtObject: React.FC<AddArtObjectProps> = ({
  title,
  handleSubmit,
}) => {
  const [selectedInvnos, setSelectedInvnos] = useState<string[]>([]);
  const [liveObjects, setLiveObjects] = useState<ArtObject[]>([]);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>();

  const handleSetLiveObjects = async (
    artObjects: ArtObject[],
    clearObjects = false
  ) => {
    let objects: ArtObject[];

    if (clearObjects) {
      objects = artObjects;
    } else {
      objects = [...liveObjects, ...artObjects];
    }

    setLiveObjects(objects);
    setHasMoreResults(ART_OBJECT_GALLERY_INCREMENT === artObjects.length);
  };

  const queryResult = useQuery(SEARCH_ART_OBJECTS, {
    variables: {
      paginate: {
        offset: 0,
        limit: ART_OBJECT_GALLERY_INCREMENT,
      },
    },
    onCompleted: (data: Pick<Query, "searchArtObjects">) => {
      data.searchArtObjects && handleSetLiveObjects(data.searchArtObjects);
    },
  });

  const handleViewMore = async (e) => {
    const offset = liveObjects.length;
    const obj = await queryResult.fetchMore({
      variables: {
        paginate: { offset: offset, limit: ART_OBJECT_GALLERY_INCREMENT },
        term: searchTerm,
      },
    });
    handleSetLiveObjects(obj.data.searchArtObjects);
  };

  const handleSearch = async () => {
    // Fetch results based on search term
    const obj = await queryResult.fetchMore({
      variables: {
        paginate: { offset: 0, limit: ART_OBJECT_GALLERY_INCREMENT },
        term: searchTerm,
      },
    });
    handleSetLiveObjects(obj.data.searchArtObjects, true);
  };

  const handleAddContentClick = (invno: string, action: "add" | "remove") => {
    if (action === "add") {
      setSelectedInvnos([...selectedInvnos, invno]);
    }
    if (action === "remove") {
      const updatedSelectedInvnos = selectedInvnos.filter((i) => i !== invno);
      setSelectedInvnos(updatedSelectedInvnos);
    }
  };

  const footer = (
    <Button
      btnType={PRIMARY}
      disabled={selectedInvnos?.length === 0}
      size={STANDARD}
      text="Add pieces to canvas"
      onClick={() => handleSubmit(selectedInvnos)}
      id="add-art-object-button"
    />
  );

  useEffect(() => {
    let artInFocus = document?.getElementById(
      (liveObjects.length - ART_OBJECT_GALLERY_INCREMENT).toString()
    );
    artInFocus?.focus();
  }, [liveObjects]);

  return (
    <ContentModalWrapper
      title={title}
      handleSearch={handleSearch}
      searchValue={searchTerm}
      setSearchValue={setSearchTerm}
      footerChildren={footer}
      queryResult={queryResult}
    >
      <ArtObjectGallery
        liveObjects={liveObjects}
        handleAddContentClick={handleAddContentClick}
        hasMoreResults={hasMoreResults}
        handleViewMore={handleViewMore}
      />
    </ContentModalWrapper>
  );
};
