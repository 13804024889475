// Shared logic between the dataEvents and replayDataEvents contexts, providers, and hooks
import OpenSeadragon from "openseadragon";

/** ENUMERATIONS */

export enum DataEventsActionType {
  ADD,
}

export enum EventTypes {
  HOME = "home",
  CANVAS_CLICK = "canvas-click",
  CANVAS_DRAG = "canvas-drag",
  CANVAS_SCROLL = "canvas-scroll",
  CLOSE_LOBBY = "close-lobby",
  PAN = "pan",
  ZOOM = "zoom",
  PANORAMA_CLICK = "panorama-click",
  CHANGE_SLIDE = "change-slide",
  TOGGLE_EXPLORE_MODE = "toggle-explore-mode",
  THREE_DEE_DRAG = "three-dee-drag",
  // THREE_DEE_HOME - used to reset 3D board to home view in replay
  THREE_DEE_HOME = "three-dee-home",
  REFETCH_BOARDS = "refetch-boards",
  MUTE_ALL = "mute-all",
  MUTE_INDIVIDUAL = "mute-individual",
  MUTE_LOCK = "mute-lock",
  VIDEO_OFF_LOCK = "video-off-lock",
  VIDEO = "video",
  VIDEO_OFF_INDIVIDUAL = "video-off-individual",
  VIDEO_OFF_ALL = "video-off-all",
  SPOTLIGHT_INSTRUCTOR = "spotlight-instructor",
  LASER_POINTER_LOCATION = "laser-pointer-location",
  TOGGLE_LASER_POINTER = "toggle-laser-pointer",
  PRESENTATION_VERSION_CHANGE = "presentation-version-change",
  SOFT_BAN = "soft-ban",
}

/** INTERFACES */

export interface AddAction {
  type: DataEventsActionType.ADD;
  payload: CanvasDataEvent;
}

export interface CanvasDataEvent {
  event: string;
  senderAttendeeId: string;
  timestamp: number;
  senderName: string;
  isSelf: boolean;
}

export interface State {
  events: CanvasDataEvent[];
}

/** TYPES */

export type Action = AddAction;

export type EventPayload = {
  type: EventTypes;
  leader: boolean;
  zoom?: number;
  center?: OpenSeadragon.Point;
  currentSlideNumber?: number;
  exploreMode?: boolean;
  panoramaView?: PanoramaViewData;
  threeDeeData?: ThreeDeeViewData;
  laserView?: LaserViewData;
  showLaser?: boolean;
  videoData?: VideoData;
  attendeeId?: string;
  spotlightInstructor?: boolean;
  meetingLobbyActive?: boolean;
  meetingCurrentlyRecording?: boolean;
  presentationVersion?: number;
  muteLock?: boolean;
  softBanned?: boolean;
  videoOffLock?: boolean;
};

export type LaserViewData = {
  x: number;
  y: number;
  instHeight: number;
  instWidth: number;
  activeInstructor: string;
};

export type PanoramaViewData = {
  p: number;
  y: number;
  z: number;
  s: number;
};

export type ThreeDeeViewData = {
  x: number;
  y: number;
  z: number;
};

export type VideoData = {
  videoEvent: string;
  time?: number;
  keepPlaying?: boolean;
};
