import React, {
  useRef,
  useEffect,
  useState,
  ChangeEvent,
  Fragment,
} from "react";
import { isTablet } from "react-device-detect";
import { ChatDataMessage, useDataMessages } from "../providers/dataMessages";
import { convertUTCtoTime } from "../utils/dateUtils";
import classnames from "classnames";
import { firstNameFirstInitial } from "../utils/initializer";
import { Container } from "./container";
import { IconButton } from "./buttons/iconButton";
import FocusLockUI from "react-focus-lock/UI";
import { sidecar } from "use-sidecar";
import SimpleBar from "simplebar-react";
import { TextInput } from "./inputs/textInput";
import { useScreenContext } from "../contexts/screenContext";
import { useAppContext } from "../contexts/appContext";
import { ChatReplayMessage } from "../providers/replayDataMessages";
import { AttendeeState, MeetingRole } from "../contexts/types";
import { AttendeeInitials } from "./attendeeInitials";
import variables from "../styles/variables.scss";
import { Icon } from "./icon";
import { HelperPopUp } from "./popUps/helperPopUp";
import { useSoftBan } from "../hooks/useSoftBan";

// prefetch sidecar. data would be loaded, but js would not be executed
const FocusLockSidecar = sidecar(
  () => import(/* webpackPrefetch: true */ "react-focus-lock/sidecar")
);

type MessagesProps = {
  messages: ChatDataMessage[] | ChatReplayMessage[];
  messageCount: number;
  replay: boolean;
  isInstructorOrModerator: boolean;
};

type MessageProps = {
  message: ChatDataMessage | ChatReplayMessage;
  replay: boolean;
  isInstructorOrModerator: boolean;
};

const Message: React.FC<MessageProps> = ({
  message,
  replay,
  isInstructorOrModerator,
}) => {
  const { useMobileTools } = useScreenContext();
  const { deleteMessage } = useDataMessages();
  // Tracks if admin starts soft ban on attendee;
  const [startSoftBan, setStartSoftBan] = useState(false);
  const [showSoftBanConfirmation, setShowSoftBanConfirmation] = useState(false);
  const onSoftBanCompleteCallback = () => {
    setStartSoftBan(true);
    setShowSoftBanConfirmation(true);
  };

  const { handleSoftBan } = useSoftBan(onSoftBanCompleteCallback);

  // Helper fn to fix type errors between live class and replay chat messages
  const getAttribute = (
    message: ChatDataMessage | ChatReplayMessage,
    attribute: string
  ): any | null => {
    return message?.hasOwnProperty(attribute)
      ? (message as ChatDataMessage)[attribute]
      : null;
  };

  return (
    <div
      className={classnames("chat-live-area__chat-item", {
        __mobile: useMobileTools,
        __deleted: getAttribute(message, "deleted"),
        "__soft-banned": getAttribute(message, "softBanned"),
      })}
    >
      <div
        className={classnames(`chat-live-area__chat-row`, {
          "__chat-outgoing": !!getAttribute(message, "isSelf"),
          "__chat-incoming": !getAttribute(message, "isSelf"),
        })}
        title={message?.senderName}
      >
        <div
          className={classnames("chat-live-area__message-data", {
            __student:
              getAttribute(message, "meetingRole") === MeetingRole.Audience,
            __replay: replay,
          })}
          role="article"
          aria-label="Chat message"
        >
          <AttendeeInitials
            iconColor={message?.color}
            name={message?.senderName}
            className={classnames(`chat-live-area__chat-initials`, {
              "__chat-outgoing": !!getAttribute(message, "isSelf"),
              "__chat-incoming": !getAttribute(message, "isSelf"),
            })}
            ariaLabel={
              getAttribute(message, "isSelf")
                ? "Your message"
                : "Message from " + message?.senderName
            }
            superscript={
              (message as ChatDataMessage)?.softBanned &&
              isInstructorOrModerator && (
                <Icon
                  name="slash"
                  toolTip="none"
                  desc={`${message?.senderName} is soft banned: Muted microphone, disabled camera, and blocked messages`}
                  height="16px"
                  fill={variables.white}
                  stroke={variables.actionRed}
                />
              )
            }
          />
          <div className="chat-live-area__message-details">
            {" "}
            <div className="chat-live-area__chat-firstline">
              {" "}
              <span className="chat-live-area__chat-name">
                {firstNameFirstInitial(message?.senderName)}{" "}
              </span>{" "}
              <time
                className="chat-live-area__chat-time"
                dateTime={getAttribute(message, "timestamp")}
              >
                {" "}
                {getAttribute(message, "timestamp") &&
                  convertUTCtoTime(getAttribute(message, "timestamp"))}
              </time>
            </div>
            {(getAttribute(message, "meetingRole") === MeetingRole.Presenter ||
              getAttribute(message, "meetingRole") === MeetingRole.Moderator) &&
              !getAttribute(message, "isSelf") &&
              !replay && (
                <div className="chat-live-area__chat-secondline">
                  {" "}
                  <span className="chat-live-area__chat-orgname">
                    {getAttribute(message, "meetingRole") ===
                    MeetingRole.Presenter
                      ? "Instructor"
                      : "Moderator"}
                  </span>
                </div>
              )}
          </div>
        </div>
        <span
          className={classnames(`chat-live-area__chat-message`, {
            "__chat-outgoing": !!getAttribute(message, "isSelf"),
            "__chat-incoming": !getAttribute(message, "isSelf"),
          })}
        >
          {message?.message}
        </span>
        {getAttribute(message, "deleted") && (
          <span className="chat-live-area__moderation-message">
            Deleted{" "}
            {getAttribute(message, "deleteData")?.senderName &&
              `by ${getAttribute(message, "deleteData").senderName}`}{" "}
            {getAttribute(message, "deleteData")?.timestamp &&
              `at ${convertUTCtoTime(
                getAttribute(message, "deleteData")?.timestamp
              )}`}
          </span>
        )}
        {getAttribute(message, "softBanned") && (
          <span className="chat-live-area__moderation-message">
            {message?.senderName} is soft banned: their messages are not
            included in chat, and they are unable to use microphone and camera
          </span>
        )}
      </div>
      <div className="chat-live-area__moderation">
        {(isInstructorOrModerator || getAttribute(message, "isSelf")) &&
          !replay &&
          !getAttribute(message, "deleted") && (
            <IconButton
              iconName="trash"
              onClick={() =>
                deleteMessage(
                  message as ChatDataMessage,
                  isInstructorOrModerator
                )
              }
              desc="Delete message"
              size="24px"
              toolTip="left"
            />
          )}
        {isInstructorOrModerator &&
          !replay &&
          getAttribute(message, "meetingRole") === MeetingRole.Audience && (
            <IconButton
              iconName="slash"
              onClick={() =>
                handleSoftBan((message as ChatDataMessage).senderAttendeeId)
              }
              desc="Soft ban attendee: Mutes microphone, disables camera, and blocks messages"
              size="24px"
              disabled={startSoftBan}
              toolTip="left"
            />
          )}
      </div>
      {/* Helper Popup to confirm soft ban has started */}
      {startSoftBan && showSoftBanConfirmation && (
        <HelperPopUp
          userMessage="This means that they will no longer be able to turn on their microphone or camera, and their messages will be not included in the class chat. View the roster for a full list of soft banned attendees."
          popUpHeader={`${message?.senderName} has been soft banned`}
          isTimed={true}
          bottom="-90px"
          right="0"
          minWidth="225px"
        />
      )}
    </div>
  );
};

const Messages: React.FC<MessagesProps> = ({
  messages,
  messageCount,
  replay = false,
  isInstructorOrModerator,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { setClosedChatCount } = useAppContext();

  const showMessage = (message: ChatDataMessage): boolean => {
    if (!message) return false;
    if (message?.softBanned) return false;
    if (isInstructorOrModerator) return true;
    if (message?.deleted) return false;
    return true;
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 100);
    }
    setClosedChatCount(messageCount);
  }, [messages, setClosedChatCount, messageCount]);

  return (
    <div
      className="chat-live-area"
      id="chat-live-area"
      role="log"
      style={{
        overflow: isTablet ? "scroll" : "none",
      }}
    >
      {messages?.map((message, index) => {
        if (!showMessage(message)) return;

        return (
          <Message
            message={message}
            replay={replay}
            key={index}
            isInstructorOrModerator={isInstructorOrModerator}
          />
        );
      })}
      <div className="__chat-scrollRef" ref={scrollRef}></div>
    </div>
  );
};

type ChatProps = {
  className?: string;
  onDismiss?: () => void;
  sendMessage?: (message: string, attendee?: AttendeeState) => void;
  height: string;
  dismissable?: boolean;
  replay?: boolean;
  messages?: ChatDataMessage[] | ChatReplayMessage[];
  messageCount: number;
  testAdmin?: boolean;
};

/**
 * @param {ChatProps} props
 * @prop {string} className - optional classnames if you want it
 * @prop {() => Promise<void>} onDismiss - Optional function that is called when you dismiss the chat
 * @prop {string} height - Sets the height of the based on the live text area. The rest of the chat fills the space
 * so this height prop is only part of the total height. Chat heading (40px) + live area + input bar (50px)
 * @prop {boolean} replay - use replay chat
 * @prop {messages[]} ChatDataMessage[] or ChatReplayMessage[] - List of messages DataMessages provider
 * @prop {() => Promise<void>} sendMessage - Optional function that is called when you send a message
 * @prop {} sendEvent - Optional function that is called to send an event
 **/
export const Chat: React.FC<ChatProps> = ({
  className,
  onDismiss,
  sendMessage,
  height,
  dismissable = true,
  replay = false,
  messages,
  messageCount,
  testAdmin,
}) => {
  let { toggleChat, isInstructorOrModerator, state } = useAppContext();
  const [message, setMessage] = useState("");
  const { useMobileTools, isMedium } = useScreenContext();
  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  // Set isInstructorOrModerator for testing purposes
  if (testAdmin) isInstructorOrModerator = testAdmin;

  // set focus on the input when the chat opens for non-mobile devices
  useEffect(() => {
    if (!useMobileTools) {
      let focusOnMessage = document.getElementById("message");
      setTimeout(() => {
        focusOnMessage?.focus();
      }, 200);
    }
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendChat();
    }
    // stop the key event from spilling into the canvas key commands
    event.stopPropagation();
  };

  const handleSendChat = () => {
    if (message.length > 0) {
      sendMessage(message, state.attendee);
      setMessage("");
    }
  };

  return (
    <Fragment>
      <FocusLockUI
        disabled={isMedium}
        sideCar={FocusLockSidecar}
        className="chat-focus-lock"
      >
        <Container
          className={classnames("chat-container", className)}
          childrenClassName="chat-container-children"
          isDismissable={false}
        >
          <div
            className="chat-container__chat-content"
            id="chat-container__chat-content"
          >
            {(!useMobileTools || isInstructorOrModerator) && (
              <div className="chat-container__chat-header">
                <h1 className="chat-container__chat-title">Chat</h1>
                <div className="chat-container__chat-close-button">
                  {dismissable && (
                    <IconButton
                      onClick={() => {
                        toggleChat();
                        onDismiss && onDismiss();
                      }}
                      iconName="dismiss"
                      desc="Close chat"
                      btnId="close-chat-button"
                    />
                  )}
                </div>
              </div>
            )}
            <SimpleBar
              forceVisible="y"
              autoHide={true}
              style={{
                maxHeight: height,
                height: height,
                width: "calc(100% + 16px)",
              }}
              className="chat-container__simplebar"
            >
              <Messages
                messages={messages}
                replay={replay}
                messageCount={messageCount}
                isInstructorOrModerator={isInstructorOrModerator}
              />
            </SimpleBar>
            {!replay && (
              <div className="chat-container__chat-input">
                <TextInput
                  autoComplete="off"
                  initialValue={message}
                  disabled={false}
                  id="message"
                  label="Type message here..."
                  name="chat-message"
                  parentForm="message"
                  onBeforeInput={(e: any): void => {}}
                  errorMessage=""
                  hasError={false}
                  onChange={handleMessageChange}
                  onKeyDown={handleKeyPress}
                />
                <IconButton
                  onClick={handleSendChat}
                  iconName="send"
                  desc="Send"
                  type="submit"
                />
              </div>
            )}
          </div>
        </Container>
      </FocusLockUI>
    </Fragment>
  );
};
