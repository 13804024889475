import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  MobileToolDrawer,
  MobileToolRow,
  MobileToolsInner,
} from "../toolbars/mobile/mobileToolDrawer";
import { IconButton } from "../buttons/iconButton";
import { CanvasNav } from "../toolbars/controls/canvasNav";
import { MoreActionsButton } from "../toolbars/controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "../toolbars/controls/moreActions/moreActionsWrapper";
import { MoreActionsItem } from "../toolbars/controls/moreActions/moreActionsItem";
import { ClosedCaptionsButton } from "../toolbars/controls/closedCaptionsButton";
import { FullScreenToggle } from "../toolbars/controls/fullScreenToggle";
import { ChatToggle } from "../toolbars/controls/chatToggle";
import { Button, PRIMARY, SMALL } from "../buttons/button";
import { Help } from "../toolbars/help";
import { ChatReplayMessage } from "../../providers/replayDataMessages";
import { ChatDataMessage } from "../../providers/dataMessages";
import { useAppContext } from "../../contexts/appContext";
import variables from "../../styles/variables.scss";
import { useScreenContext } from "../../contexts/screenContext";
import { ProgressBar } from "../inputs/progressBar";
import { TimeDisplay } from "./timeDisplay";
import { VideoControls } from "./videoControls";
import { ChapterList } from "./chapterList";
import { Chat } from "../chat";
import { ThemeWrapper } from "../themeWrapper";
import { PlaybackSpeed } from "./playbackSpeed";

type MobileReplayToolsProps = {
  replayChat: boolean;
  hasReplayCaptions: boolean;
  messages: ChatReplayMessage[] | ChatDataMessage[];
  messageCount: number;
  chapters: VTTCue[];
  activeChapter: number;
  showClosedCaptions: boolean;
  toggleClosedCaptions: () => void;
  toggleInstructions: () => void;
  handleSelectChapter: (timestamp: number, chapter: number) => void;
  handleSetPlaybackSpeed: (speed: number) => void;
  showVideo: boolean;
  toggleShowVideo: () => void;
  playbackSpeed: number;
  showVolumeBar: boolean;
  handleRewind;
  handleFastForward;
  isPlaying;
  handleSeek;
  currentTime;
  remainingTime;
  togglePlayPause;
  maxTime;
  theme;
  showChapterList: boolean;
  setShowChapterList: (show: boolean) => void;
  showInstructions: boolean;
  setShowInstructions: (show: boolean) => void;
};

/**
 * Component for an mobile tool drawer that will slide up. Choose from orientation "horizontal" and "vertical".
 * @MobileReplayToolsProps className, orientation, children, isMinimizable
 */

export const MobileReplayTools: React.FC<MobileReplayToolsProps> = ({
  replayChat,
  hasReplayCaptions,
  messages,
  messageCount,
  chapters,
  activeChapter,
  showClosedCaptions,
  toggleClosedCaptions,
  handleSelectChapter,
  handleSetPlaybackSpeed,
  showVideo,
  toggleShowVideo,
  playbackSpeed,
  showVolumeBar,
  handleRewind,
  handleFastForward,
  isPlaying,
  handleSeek,
  currentTime,
  remainingTime,
  togglePlayPause,
  maxTime,
  theme,
  showChapterList,
  setShowChapterList,
  showInstructions,
  setShowInstructions,
}) => {
  const { state, showChat, setExpandToolBar, expandToolBar, toggleChat } =
    useAppContext();

  const [toolBarHeight, setToolBarHeight] = useState("96px");
  const { useMobileTools, orientation } = useScreenContext();
  const [showProgressBar, setShowProgressBar] = useState(true);
  const [showMoreActionsMenu, setShowMoreActionsMenu] = useState(false);
  const [showSpeedPopup, setShowSpeedPopup] = useState(false);

  const handleToggleProgressBar = (e) => {
    setShowProgressBar(!showProgressBar);
  };

  useEffect(() => {
    if (expandToolBar || showChat || showChapterList) {
      setToolBarHeight(variables.mobilePortraitToolbarExpandedHeight);
    } else if (
      // expanded landscape toolbar
      (expandToolBar || showChat || showChapterList) &&
      orientation === "landscape"
    ) {
      setToolBarHeight(variables.mobileLandscapeToolbarExpandedHeight);
    } else {
      // collapsed toolbar
      setToolBarHeight(variables.mobileReplayToolbarHeight);
    }
  }, [expandToolBar, orientation, showChat, showChapterList]);

  return (
    <div
      className={classnames(`mobile-tools-wrapper --${orientation}`)}
      style={{
        height: toolBarHeight,
        transition: "height 0.3s ease",
      }}
    >
      <MobileToolDrawer
        showSlider={Boolean(chapters || messages)}
        setShowMoreActionsMenu={setShowMoreActionsMenu}
        setShowChapterList={setShowChapterList}
        replay={true}
      >
        <MobileToolRow
          id="top-row"
          setShowMoreActionsMenu={setShowMoreActionsMenu}
          setShowChapterList={setShowChapterList}
          replay={true}
        >
          <IconButton
            onClick={handleToggleProgressBar}
            iconName="controls"
            desc={showProgressBar ? "Show video controls" : "Show progress bar"}
            btnId="video-controls-toggle"
            selected={showProgressBar}
          />
          <CanvasNav
            isLeader={!state.spotlightInstructor}
            exploreMode={!state.spotlightInstructor}
          />
          <MoreActionsButton
            toolTip="top"
            alignment="horizontal"
            showActionsInput={showMoreActionsMenu}
            id="replay-controls__more-actions"
            onClick={(e) => {
              setExpandToolBar(false);
              setShowMoreActionsMenu(!showMoreActionsMenu);
              setShowSpeedPopup(false);
              if (showChat) toggleChat();
              if (showChapterList) setShowChapterList(false);
            }}
          >
            <MoreActionsWrapper
              itemClick={(e) => {
                e.preventDefault();
                setShowMoreActionsMenu(false);
                setShowSpeedPopup(false);
              }}
              upOrDown="up"
              leftOrRight="left"
            >
              <MoreActionsItem id="replay-tools__more-actions__cc-button">
                <ClosedCaptionsButton
                  component="BUTTON"
                  showClosedCaptions={showClosedCaptions}
                  toggleClosedCaptions={toggleClosedCaptions}
                  disabled={!hasReplayCaptions}
                  toolTip="top"
                />
              </MoreActionsItem>
              <MoreActionsItem id="replay-tools__more-actions__full-screen">
                <FullScreenToggle component="BUTTON" toolTip="top" />
              </MoreActionsItem>
              <MoreActionsItem id="replay-tools__more-actions__speed">
                <PlaybackSpeed
                  playbackSpeed={playbackSpeed}
                  setPlaybackSpeed={(speed: number) => {
                    handleSetPlaybackSpeed(speed);
                    setShowMoreActionsMenu(false);
                  }}
                />
              </MoreActionsItem>
              <MoreActionsItem id="replay-tools__more-actions__chat">
                <ChatToggle
                  toolTip="top"
                  replayMode={replayChat}
                  messages={messages}
                  component="BUTTON"
                  chatMessagesCount={messageCount}
                />
              </MoreActionsItem>
              <MoreActionsItem id="replay-tools__more-actions__chapters">
                <Button
                  btnType={PRIMARY}
                  text={`${showChapterList ? "Hide" : "View"} chapter list`}
                  size={SMALL}
                  id="chapter-list-button"
                  onClick={(e) => {
                    setShowChapterList(!showChapterList);
                    if (showChat) toggleChat();
                  }}
                  icon="list"
                  disabled={!chapters}
                />
              </MoreActionsItem>
              <MoreActionsItem id="replay-tools__more-actions__video-toggle">
                <Button
                  btnType={PRIMARY}
                  id="replay-video-toggle-button"
                  text={showVideo ? "Hide video" : "Show video"}
                  customWidth="100%"
                  size={SMALL}
                  disabled={false}
                  onClick={toggleShowVideo}
                  icon={showVideo ? "user-x" : "user"}
                />
              </MoreActionsItem>
              <MoreActionsItem id="replay-tools__more-actions__help">
                <Help
                  useMobileTools={useMobileTools}
                  toggleInstructions={() =>
                    setShowInstructions(!showInstructions)
                  }
                  component="BUTTON"
                />
              </MoreActionsItem>
            </MoreActionsWrapper>
          </MoreActionsButton>
        </MobileToolRow>
        <MobileToolRow
          id="bottom-row"
          setShowMoreActionsMenu={setShowMoreActionsMenu}
          setShowChapterList={setShowChapterList}
          replay={true}
        >
          {showProgressBar ? (
            <div
              className={classnames("progress-bar-wrapper", {
                "volume-visible": showVolumeBar,
              })}
            >
              <IconButton
                onClick={togglePlayPause}
                iconName={
                  remainingTime < 2
                    ? "replayFromStart"
                    : isPlaying
                    ? "pause"
                    : "playVideo"
                }
                desc={`${
                  remainingTime < 2 ? "Replay" : isPlaying ? "Pause" : "Play"
                } video`}
                toolTip="top"
                btnId="video-controls__play-toggle"
              />
              <ProgressBar
                id="video-controls__progress-bar"
                onClick={handleSeek}
                max={maxTime}
                value={currentTime}
                disabled={false}
                theme={theme}
                chapters={chapters}
                activeChapter={activeChapter}
              />
              <TimeDisplay currentTime={currentTime} maxTime={maxTime} />
            </div>
          ) : (
            <VideoControls
              onPlayPause={togglePlayPause}
              handleRewind={handleRewind}
              handleFastForward={handleFastForward}
              isPlaying={isPlaying}
              activeChapter={activeChapter}
              chapters={chapters}
              handleSeek={handleSeek}
              currentTime={currentTime}
              remainingTime={remainingTime}
              togglePlayPause={togglePlayPause}
            />
          )}
        </MobileToolRow>
        <MobileToolsInner replay={true}>
          {/* Landscape mobile tools */}
          {orientation === "landscape" && (showChapterList || showChat) && (
            <>
              {chapters && (
                <div className="mobile-tools__chapters --landscape --replay">
                  <ChapterList
                    chapters={chapters}
                    onDismiss={() => setShowChapterList(false)}
                    activeChapter={activeChapter}
                    handleSelectChapter={handleSelectChapter}
                    height="22vh"
                  />
                </div>
              )}
              <div className="mobile-tools__chat --landscape --replay">
                <Chat
                  height="22vh"
                  messages={messages}
                  replay={true}
                  messageCount={messageCount}
                />
              </div>
            </>
          )}
          {/* Portrait mobile tools */}
          {orientation === "portrait" && (
            <>
              <div className="mobile-tools__chat --portrait --replay">
                {showChat && (
                  <Chat
                    height={`calc(${document?.documentElement?.style.getPropertyValue(
                      "--mobile-available-screen"
                    )} * .7 - 190px)`}
                    messages={messages}
                    replay={true}
                    messageCount={messageCount}
                  />
                )}
              </div>
              <div className="mobile-tools__chapters --portrait --replay">
                {showChapterList && chapters && (
                  <ChapterList
                    chapters={chapters}
                    onDismiss={() => setShowChapterList(false)}
                    activeChapter={activeChapter}
                    handleSelectChapter={handleSelectChapter}
                    height={`calc(${document?.documentElement?.style.getPropertyValue(
                      "--mobile-available-screen"
                    )} * .7 - 190px)`}
                  />
                )}
              </div>
            </>
          )}
        </MobileToolsInner>
      </MobileToolDrawer>
    </div>
  );
};
