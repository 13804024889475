import React from "react";

import { ArtObjectImage } from "./artObjectImage";
import ArtObjectOverlay from "./artObjectOverlay";

type ArtObjectComponentProps = {
  invno: string | number;
  index: number;
  caption?: string;
  subCaption?: string;
  imgAlt?: string;
  imageUrlSmall: string;
  imageUrlLarge?: string;
  handleAddContentClick: (
    invno: string | number,
    action: "add" | "remove"
  ) => void;
};

export const ArtObjectComponent: React.FC<ArtObjectComponentProps> = ({
  invno,
  index,
  caption,
  subCaption,
  imgAlt,
  imageUrlLarge,
  imageUrlSmall,
  handleAddContentClick,
}) => {
  return (
    <div className="art-object">
      <ArtObjectImage
        alt={imgAlt || subCaption}
        src={imageUrlSmall}
        backupSrc={imageUrlLarge}
      />

      <ArtObjectOverlay
        invno={invno}
        index={index}
        title={subCaption}
        caption={caption}
        handleAddContentClick={handleAddContentClick}
      />
    </div>
  );
};
