import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { IconButton } from "../buttons/iconButton";
import { formatTime } from "../../utils/timeUtiles";
import classnames from "classnames";
import { ProgressBar } from "../inputs/progressBar";
import { loadSvgsIntoCache } from "../../utils/cacheMachine";
import {
  CanvasDataEvent,
  EventPayload,
  EventTypes,
  VideoData,
} from "../../providers/types";
import { VALID_KEYS_WITH_ZOOM } from "../../constants";
import { isVideoViewEventCheck } from "../../utils/eventUtils";
import { useAppContext } from "../../contexts/appContext";
import {
  ActionType,
  CanvasMode,
  MeetingRole,
  State,
} from "../../contexts/types";
import variables from "../../styles/variables.scss";
import { useFocusCheck } from "../../hooks/useFocusCheck";
import { consoleNonProd } from "../../utils/utilityBeltUtils";
import { VideoReplayTimeAndState } from "../replay/replayTypes";

type VideoViewerProps = {
  sourceUrl: string;
  leader: boolean;
  startTime?: number;
  endTime?: number;
  isPreview?: boolean;
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  events?: CanvasDataEvent[];
  mode?: CanvasMode;
  replayState?: VideoReplayTimeAndState;
};

const VideoViewer: React.FC<VideoViewerProps> = ({
  sourceUrl,
  leader,
  startTime,
  endTime,
  isPreview = false,
  sendEvent,
  events = [],
  mode,
  replayState,
}: VideoViewerProps) => {
  const { meetingRole, state, dispatch } = useAppContext();
  const { playerHasFocus } = useFocusCheck();

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [timeState, setTimeState] = useState(undefined);
  const [maxTime, setMaxTime] = useState(undefined);
  const [ready, setReady] = useState(false);
  const [replayVideoLock, setReplayVideoLock] = useState(false);

  const playerRef = useRef(null);
  const replayerRef = useRef<ReactPlayer>(null);
  const isYouTube = sourceUrl?.includes("youtube");
  const duration = document.getElementById("duration");
  const disableButton = !leader && !state.exploreMode;

  // called once on load to present the user with time for the duration progress bar
  const durationSetup = () => {
    if (sourceUrl?.indexOf("youtube") > -1) {
      const videoDuration = Math.round(
        playerRef?.current?.getInternalPlayer()?.getDuration()
      );
      setMaxTime(videoDuration);
      const time = formatTime(videoDuration);
      if (duration) {
        duration.innerText = `${time?.minutes}:${time?.seconds}`;
        duration.setAttribute(
          "datetime",
          `${time?.minutes}m ${time?.seconds}s`
        );
      }
    } else {
      playerRef?.current
        ?.getInternalPlayer()
        ?.getDuration()
        .then(function (e: number) {
          const videoDuration = Math.round(e);
          if (videoDuration) {
            setMaxTime(videoDuration);
            const time = formatTime(videoDuration);
            if (duration) {
              duration.innerText = `${time?.minutes}:${time?.seconds}`;
              duration.setAttribute(
                "datetime",
                `${time?.minutes}m ${time?.seconds}s`
              );
            }
          }
        });
    }
  };

  const handlePausePlay = () => {
    // play route
    if (isYouTube && isPlaying) {
      playerRef?.current?.getInternalPlayer()?.pauseVideo();
    } else if (isPlaying) {
      playerRef?.current?.getInternalPlayer()?.pause();
    }
    // pause route
    if (isYouTube && !isPlaying) {
      playerRef?.current?.getInternalPlayer()?.playVideo();
    } else if (!isPlaying) {
      playerRef?.current?.getInternalPlayer()?.play();
    }
  };

  const handleRewind = () => {
    // why not just pass the isPlaying to the seekPlay? The seekTo seems to be
    // interacting with that behind the scenes so  we just want to know the
    // state before the seek. (same for the FF and direct seek)
    const playerPlaying = isPlaying;
    playerRef?.current?.seekTo(
      playerRef.current?.getCurrentTime() - 10,
      "seconds"
    );
    seekPlay(playerPlaying);
  };

  const handleFastForward = () => {
    const playerPlaying = isPlaying;
    playerRef?.current?.seekTo(
      playerRef.current?.getCurrentTime() + 10,
      "seconds"
    );
    seekPlay(playerPlaying);
  };

  const handleTimeClick = (e: number) => {
    const playerPlaying = isPlaying;
    playerRef?.current?.seekTo(e, "seconds");
    seekPlay(playerPlaying);
  };

  const seekPlay = (shouldPlay: boolean) => {
    if (shouldPlay && playerRef?.current?.getInternalPlayer()) {
      if (isYouTube) {
        playerRef?.current?.getInternalPlayer()?.playVideo();
      } else {
        playerRef?.current?.getInternalPlayer()?.play();
      }
    }
  };

  const sendVideoEvent = useCallback(
    (videoEvent: VideoData, leader: boolean, exploreMode: boolean) => {
      if (sendEvent && leader && !exploreMode) {
        sendEvent(
          {
            type: EventTypes.VIDEO,
            leader: leader,
            videoData: videoEvent,
          },
          state
        );
      }
    },
    [sendEvent, state.currentBoard]
  );

  // on first load of a video board set to focus so
  // everyone starts when instuctor plays the video
  useEffect(() => {
    if (state.exploreMode === true) {
      dispatch({
        type: ActionType.UPDATE_EXPLORE_MODE,
        payload: { exploreMode: false },
      });
      sendEvent &&
        meetingRole === MeetingRole.Presenter &&
        sendEvent(
          {
            type: EventTypes.TOGGLE_EXPLORE_MODE,
            leader: true,
            exploreMode: false,
          },
          state
        );
    }
  }, []);

  // when an instructor starts a video emit out the time and a play event
  // as long as the video is playing to keep any late arrivers or screen refreshers in sync
  // will self clear when the video stops or you navigate away from the video board
  useEffect(() => {
    let playInterval;
    if (isPlaying && leader) {
      playInterval = setInterval(() => {
        sendVideoEvent(
          {
            videoEvent: "play-pause",
            keepPlaying: true,
            time: playerRef.current?.getCurrentTime(),
          },
          leader,
          state.exploreMode
        );
      }, 10000);
    }
    return function () {
      clearInterval(playInterval);
    };
  }, [isPlaying, leader, state.exploreMode, sendVideoEvent]);

  //preload play / pause / max / min svgs
  useEffect(() => {
    loadSvgsIntoCache([
      "/icons/pause.svg",
      "/icons/playVideo.svg",
      "/icons/minimize-full.svg",
      "/icons/maximize.svg",
    ]);
  }, []);

  useEffect(() => {
    const keyCommander = (inputKey: string) => {
      if (!isPreview && !playerHasFocus()) {
        if (VALID_KEYS_WITH_ZOOM.indexOf(inputKey) < 0) return;
        // w or up arrow
        if (inputKey == "w" || inputKey == "ArrowUp") {
          setVolume(volume < 1 ? volume + 0.05 : 1);
        }
        // s or down arrow
        else if (inputKey == "s" || inputKey == "ArrowDown") {
          setVolume(volume > 0 ? volume - 0.05 : 0);
        }
        // a or left arrow
        else if (inputKey == "a" || inputKey == "ArrowLeft") {
          if (playerRef) {
            handleRewind();
            sendVideoEvent({ videoEvent: "rewind" }, leader, state.exploreMode);
          }
        }
        // d or right arrow
        else if (inputKey == "d" || inputKey == "ArrowRight") {
          if (playerRef) {
            handleFastForward();
            sendVideoEvent(
              { videoEvent: "fast-forward" },
              leader,
              state.exploreMode
            );
          }
        }
        // z key or -
        else if (inputKey == "z" || inputKey == "-") {
          setIsFullScreen(!isFullScreen);
          sendVideoEvent(
            { videoEvent: "full-screen" },
            leader,
            state.exploreMode
          );
        }
        // x key or +
        else if (inputKey == "x" || inputKey == "=" || inputKey == "+") {
          if (isMuted) {
            setVolume(0.5);
            setIsMuted(false);
          } else {
            setVolume(0);
            setIsMuted(true);
          }
        }
        // space bar to reset
        else if (inputKey == " ") {
          const focusedElement = document?.activeElement;
          if (playerRef && focusedElement?.tagName !== "BUTTON") {
            handlePausePlay();
            sendVideoEvent(
              {
                videoEvent: "play-pause",
                time: playerRef?.current?.getCurrentTime(),
              },
              leader,
              state.exploreMode
            );
          }
        }
      }
    };
    function onDocumentKeyDown(event) {
      //event.preventDefault();
      if (leader || state.exploreMode) {
        keyCommander(event?.key);
      }
    }
    document.addEventListener("keydown", onDocumentKeyDown, false);

    return function () {
      document.removeEventListener("keydown", onDocumentKeyDown, false);
    };
  }, [
    volume,
    state.exploreMode,
    leader,
    isFullScreen,
    isPlaying,
    isPreview,
    playerHasFocus,
  ]);

  useEffect(() => {
    if (events?.length) {
      const canvasDataEv = events[events.length - 1];
      const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
      if (!canvasDataEv.isSelf && isVideoViewEventCheck(eventPayload)) {
        if (eventPayload) {
          if (playerRef?.current) {
            if (eventPayload.type === EventTypes.VIDEO) {
              switch (eventPayload.videoData.videoEvent) {
                case "play-pause":
                  // play from the reoccuring play event emitter on refresh
                  if (eventPayload.videoData.keepPlaying && !isPlaying) {
                    if (playerRef?.current?.getInternalPlayer()) {
                      playerRef?.current?.seekTo(
                        eventPayload.videoData.time,
                        "seconds"
                      );
                      seekPlay(true);
                    }
                  } else if (eventPayload.videoData.keepPlaying && isPlaying) {
                    // if we are already playing ignore the event
                    break;
                  } else {
                    //otherwise handle the play pause event like normal
                    playerRef?.current?.seekTo(
                      eventPayload.videoData.time,
                      "seconds"
                    );
                    try {
                      handlePausePlay();
                    } catch (error) {
                      consoleNonProd(error);
                    }
                  }
                  break;
                case "rewind":
                  handleRewind();
                  break;
                case "fast-forward":
                  handleFastForward();
                  break;
                case "seek":
                  handleTimeClick(eventPayload.videoData.time);
                  break;
                case "full-screen":
                  setIsFullScreen(!isFullScreen);
                  break;
              }
            }
          }
        }
      }
    }
  }, [events, leader, state.exploreMode]);

  useEffect(() => {
    try {
      const player = replayerRef?.current as any;

      if (
        replayState.time >= 0 &&
        replayerRef &&
        ready &&
        player &&
        !replayVideoLock
      ) {
        if (!replayState.isPlaying) {
          player?.seekTo(replayState.time, "seconds");
          setVolume(replayState.volume);
          if (player?.player?.isPlaying) {
            isYouTube && player?.getInternalPlayer()?.pauseVideo();
            !isYouTube && player?.getInternalPlayer()?.pause();
            setIsPlaying(false);
          }
        }
        if (replayState.isPlaying) {
          player?.seekTo(replayState.time, "seconds");
          setVolume(replayState.volume);
          if (!player?.player?.isPlaying) {
            isYouTube && player?.getInternalPlayer()?.playVideo();
            !isYouTube && player?.getInternalPlayer()?.play();
            setIsPlaying(true);
          }
        }
        //hypothetically we could set the speed in the video board with
        // player?.getInternalPlayer().playbackRate(replayState.speed);
        // but it doesn't want to go for some reason
      }
    } catch (error) {}
  }, [replayState, ready, isYouTube, replayVideoLock, isPlaying]);

  useEffect(() => {
    try {
      const player = replayerRef?.current as any;
      const canvasDataEv = events[events.length - 1];
      const eventPayload: EventPayload = JSON.parse(canvasDataEv.event);
      // skipping backwards in time via chapter skip makes sure we don't play
      // when we should be pausing
      if (
        eventPayload.type === EventTypes.CHANGE_SLIDE ||
        eventPayload.type === EventTypes.TOGGLE_EXPLORE_MODE
      ) {
        if (player && ready) {
          isYouTube && player?.getInternalPlayer()?.pauseVideo();
          !isYouTube && player?.getInternalPlayer()?.pause();
          setReplayVideoLock(true);
        }
      }
      if (eventPayload?.videoData) {
        setReplayVideoLock(false);
      }
      // check the confusing logic of play-pause events
      // (why didn't we seperate them into two event names?????)
      // and pause the replay and lock it if two video events come
      // back to back and the second doesn't have keep playing
      if (events.length >= 2) {
        const prevCanvasDataEv = events[events.length - 2];
        const prevEventPayload: EventPayload = JSON.parse(
          prevCanvasDataEv.event
        );
        if (
          prevEventPayload?.videoData?.videoEvent === "play-pause" &&
          !eventPayload?.videoData?.keepPlaying
        ) {
          if (player && ready) {
            isYouTube && player?.getInternalPlayer()?.pauseVideo();
            !isYouTube && player?.getInternalPlayer()?.pause();
            setReplayVideoLock(true);
          }
        }
      }

      //hypothetically we could set the speed in the video board with
      // player?.getInternalPlayer().playbackRate(replayState.speed);
      // but it doesn't want to go for some reason
    } catch (error) {}
  }, [ready, events, isYouTube]);

  return (
    <div
      id="video-viewer-screen"
      className={classnames(`video-screen`, {
        "--full": isFullScreen,
        "--preview": isPreview,
      })}
    >
      <div
        className={classnames(`video-player-container`, {
          "--student": true,
        })}
      >
        {sourceUrl && (
          <ReactPlayer
            url={sourceUrl}
            height="100%"
            width="100%"
            ref={mode === CanvasMode.REPLAY ? replayerRef : playerRef}
            controls={false}
            playsinline={true}
            pip={false}
            muted={isMuted}
            volume={volume}
            onReady={(e) => {
              durationSetup();
              setReady(true);
            }}
            onEnded={() => {
              setIsPlaying(false);
            }}
            onProgress={(e) => {
              setTimeState(e);
            }}
            onPlay={() => {
              setIsPlaying(true);
              // call youtube to turn on the auto captions
              playerRef?.current
                ?.getInternalPlayer()
                ?.setOption("captions", "track", { languageCode: "en" });
            }}
            onPause={() => {
              setIsPlaying(false);
            }}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  cc_load_policy: 1,
                  cc: 1,
                  cc_lang_pref: "en",
                  autohide: 1,
                  modestbranding: 1,
                },
              },
            }}
          />
        )}
      </div>
      {!isPreview && sourceUrl && mode !== CanvasMode.REPLAY && (
        <div className="controls-container">
          <div className="video-controls-container">
            <IconButton
              onClick={(e) => {
                if (playerRef) {
                  handlePausePlay();
                  sendVideoEvent(
                    {
                      videoEvent: "play-pause",
                      time: playerRef.current?.getCurrentTime(),
                    },
                    leader,
                    state.exploreMode
                  );
                }
              }}
              iconName={isPlaying === true ? "pause" : "playVideo"}
              desc={isPlaying === true ? "pause" : "play"}
              disabled={disableButton}
              stroke={variables.white}
              btnId="play-pause-video-button"
            />
            <IconButton
              onClick={(e) => {
                if (playerRef) {
                  handleRewind();
                  sendVideoEvent(
                    { videoEvent: "rewind" },
                    leader,
                    state.exploreMode
                  );
                }
              }}
              iconName="rewind"
              desc="Rewind"
              disabled={disableButton}
              stroke={variables.white}
              btnId="rewind-video-button"
            />
            <IconButton
              onClick={(e) => {
                if (playerRef) {
                  handleFastForward();
                  sendVideoEvent(
                    { videoEvent: "fast-forward" },
                    leader,
                    state.exploreMode
                  );
                }
              }}
              iconName="fast-forward"
              desc="Fast forward"
              disabled={disableButton}
              stroke={variables.white}
              btnId="fast-forward-video-button"
            />

            <span className="video-controls-container__video-progress">
              {timeState && maxTime && (
                <time
                  id="time-elapsed"
                  className="video-controls-container--time"
                >
                  {formatTime(timeState?.playedSeconds).minutes +
                    ":" +
                    formatTime(timeState?.playedSeconds).seconds}
                </time>
              )}
              <div className="video-controls-container__progress-container">
                <ProgressBar
                  id="progress-bar"
                  value={timeState?.playedSeconds}
                  max={maxTime}
                  onClick={(e) => {
                    handleTimeClick(e);
                    sendVideoEvent(
                      { videoEvent: "seek", time: e },
                      leader,
                      state.exploreMode
                    );
                  }}
                  disabled={disableButton}
                />
              </div>
              {timeState && maxTime && (
                <time id="duration" className="video-controls-container--time">
                  {formatTime(maxTime).minutes +
                    ":" +
                    formatTime(maxTime).seconds}
                </time>
              )}
            </span>
          </div>
          <div className="volume-controls-container">
            <IconButton
              onClick={(e) => {
                if (isMuted) {
                  setVolume(0.5);
                  setIsMuted(false);
                } else {
                  setVolume(0);
                  setIsMuted(true);
                }
              }}
              iconName="volume-x"
              desc="Mute"
              stroke={variables.white}
              btnId="mute-video-volume-button"
            />
            <IconButton
              onClick={(e) => {
                setIsMuted(false);
                setVolume(volume > 0 ? volume - 0.05 : 0);
              }}
              iconName="volume-1"
              desc="Volume down"
              stroke={variables.white}
              btnId="lower-volume-video-button"
            />
            <span className="volume-controls-container__volume-progress">
              <div style={{ display: "inline-block", width: "100%" }}>
                <ProgressBar
                  id="volume-bar"
                  value={volume}
                  max={1}
                  onClick={(e) => setVolume(e)}
                />
              </div>
            </span>
            <IconButton
              onClick={(e) => {
                setIsMuted(false);
                setVolume(volume < 1 ? volume + 0.05 : 1);
              }}
              iconName="volume-2"
              desc="Volume up"
              stroke={variables.white}
              btnId="raise-volume-video-button"
            />
            <IconButton
              onClick={(e) => {
                setIsFullScreen(!isFullScreen);
                sendVideoEvent(
                  { videoEvent: "full-screen" },
                  leader,
                  state.exploreMode
                );
              }}
              iconName={isFullScreen ? "minimize-full" : "maximize"}
              desc="Full screen mode"
              disabled={disableButton}
              stroke={variables.white}
              btnId="set-full-screen-video-button"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoViewer;
