import React, { useEffect, useState } from "react";
import { IconButton } from "../../../buttons/iconButton";

type MoreActionsButtonProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  alignment: "horizontal" | "vertical";
  showActionsInput: boolean;
  children: JSX.Element;
  id: string;
  customIcon?: string;
  size?: "standard" | "slidePreview";
  onClick?: (e) => void;
  disabled?: boolean;
  className?: string;
  overflowContainerId?: string;
};

/** Component for the More actions menu button
 * @param {MoreActionsButtonProps} props
 * @prop {"top" | "bottom" | "right" | "left" | "none"} toolTip - Tool tip direction
 * @prop {"horizontal" | "vertical"} alignment - Direction of the button icon
 * @prop {boolean} showActionsInput - Whether or not to display the menu child component
 * @prop {JSX.Element} children - Child element(s), should be wrapped inside the
 * MoreActionsWrapper for proper styling and layout
 * @prop {string} id - Id for the IconButton component, This will be appended to "button-"
 * @prop {string} customIcon - Optional button icon to use, will default to "three dot"
 * @prop {"standard" | "slidePreview"} size - Button size, defaults to standard
 * @prop {(e) => void} onClick - Handler for on click action
 * @prop {boolean} disabled - Whether or not the button is disabled, defaults to false
 * @prop {string} className - Optional class name for the html element
 * @prop {string} overflowContainerId - Optional ID for the specified element that the tooltip should not overflow. If no ref is passed, the tooltip overflow is determined based on the browser viewport
 */
export const MoreActionsButton: React.FC<MoreActionsButtonProps> = ({
  toolTip,
  alignment,
  children,
  showActionsInput,
  size = "standard",
  customIcon,
  id,
  onClick,
  disabled = false,
  className,
  overflowContainerId,
}) => {
  const [showActions, setShowActions] = useState(showActionsInput);
  const moreActionClicked = (e) => {
    onClick(e);
  };
  const iconSize = size === "slidePreview" ? "14px" : "26px";

  useEffect(() => {
    setShowActions(showActionsInput);
  }, [showActionsInput]);

  useEffect(() => {
    setTimeout(() => {
      let firstAction = document.getElementById("more-actions-focus");
      if (firstAction) {
        firstAction.focus();
      }
    }, 300);
  }, [showActions]);

  return (
    <div className={className}>
      {showActions && <>{children}</>}
      <IconButton
        onClick={moreActionClicked}
        iconName={customIcon ? customIcon : "more-" + alignment}
        desc="More actions"
        toolTip={toolTip}
        size={iconSize}
        btnId={"more-actions-" + "button-" + id}
        disabled={disabled}
        className="more-actions-button"
        overflowContainerId={overflowContainerId}
      />
    </div>
  );
};
