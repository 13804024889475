import classNames from "classnames";
import React, { useRef, useState } from "react";

type ArtObjectImageProps = {
  alt: string;
  src: string;
  backupSrc?: string;
  className?: string;
};

/**
 * @param {ArtObjectImageProps} props
 * @prop {string} alt - Alternative text for the image
 * @prop {string} src - Image source url
 * @prop {string?} backupSrc - Optional larger size backup image source url
 * @prop {string?} className - Optional classname to apply to the img element
 */
export const ArtObjectImage: React.FC<ArtObjectImageProps> = ({
  alt,
  src,
  backupSrc,
  className,
}) => {
  const ref = useRef<HTMLImageElement>();
  const [imgSrc, setImgSrc] = useState(src);
  const [isLoaded, setIsLoaded] = useState(false);

  const revealImage = () => {
    /** Use the backupSrc (larger size image) if the image source is smaller
     * width than its container and this is our first image load
     */
    if (
      ref &&
      ref.current.getBoundingClientRect().width >
        ref.current.naturalWidth * 1.75 &&
      backupSrc &&
      !isLoaded
    ) {
      setImgSrc(backupSrc);
      setIsLoaded(true);
    }
  };

  return (
    <img
      ref={ref}
      alt={alt}
      src={imgSrc}
      onLoad={revealImage}
      onError={revealImage}
      className={classNames(className, "art-object-image")}
    />
  );
};
