import React, { createContext, useContext, useMemo, useState } from "react";
import { FeatureFlagType } from "../types";

export type FeatureFlagsContextType = {
  featureFlags: Set<FeatureFlagType>;
  setFeatureFlags: React.Dispatch<React.SetStateAction<Set<FeatureFlagType>>>;
};

const FeatureFlagsContext = createContext<FeatureFlagsContextType | undefined>(
  undefined
);

export const FeatureFlagsContextProvider: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(new Set<FeatureFlagType>());

  const providerValue: FeatureFlagsContextType = {
    featureFlags,
    setFeatureFlags,
  };

  return (
    <FeatureFlagsContext.Provider value={providerValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureContext = (): FeatureFlagsContextType => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      "Use useFeatureContext hook inside FeatureFlagsContextProvider."
    );
  }

  return context;
};
