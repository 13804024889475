export enum StorageKeys {
  attendee,
  exploreMode,
  exploreHelper,
  followHelper,
  gbKeys,
  initVersionNumber,
  meeting,
  meetingDbId,
  meetingMode,
  meetingRole,
  recordMeetingStatus,
  sid,
  webAudioEnabled,
  fhd,
}

type SetLocalStorage = (key: StorageKeys, value: any) => void;
type GetLocalStorage = (key: StorageKeys) => any;
type RemoveLocalStorageItem = (key: StorageKeys) => any;
type UseLocalStorage = {
  setLocalStorage: SetLocalStorage;
  getLocalStorage: GetLocalStorage;
  clearLocalStorage: () => void;
  removeLocalStorageItem: RemoveLocalStorageItem;
};

// fn to store in localStorage by key
const setLocalStorage: SetLocalStorage = (key, value) => {
  localStorage.setItem(JSON.stringify(key), JSON.stringify(value));
};

// fn to fetch from localStorage by key
const getLocalStorage: GetLocalStorage = (key) => {
  const item = localStorage.getItem(JSON.stringify(key));

  try {
    return item && JSON.parse(item);
  } catch {
    return item;
  }
};

// fn to remove item from local storage
const removeLocalStorageItem: RemoveLocalStorageItem = (key) => {
  localStorage.removeItem(JSON.stringify(key));
};

// fn to clear local storage
const clearLocalStorage = () => {
  localStorage.clear();
};

// hook to use in components
export const useLocalStorage = (): UseLocalStorage => {
  return {
    setLocalStorage,
    getLocalStorage,
    clearLocalStorage,
    removeLocalStorageItem,
  };
};
