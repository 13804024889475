import React, { useRef, useEffect, useState, Fragment } from "react";
import OpenSeadragon from "openseadragon";
import classnames from "classnames";
import { Board, BoardType } from "../../types";
import { MoreActionsButton } from "./controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "./controls/moreActions/moreActionsWrapper";
import { Button, PRIMARY, STANDARD } from "../buttons/button";
import { Modal } from "../modal/modal";
import { DeleteBoard } from "../deleteBoard";
import { Icon } from "../icon";
import { AddBoard } from "../addBoard";
import { getPreviewTileSources } from "../../utils/openSeadragonUtils";
import { EventPayload, EventTypes } from "../../providers/types";
import { useMutation } from "@apollo/client";
import { DUPLICATE_BOARD } from "../../graphql/mutations";
import { ModalHeader } from "../modal/modalHeader";
import { ModalBody } from "../modal/modalBody";
import { ModalFooter } from "../modal/modalFooter";
import { MoreActionsItem } from "./controls/moreActions/moreActionsItem";
import { CanvasMode, State } from "../../contexts/types";
import { useAppContext } from "../../contexts/appContext";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

export enum SlidePreviewRefetchReason {
  EDIT = "edit",
  DELETE = "delete",
  DUPLICATION = "duplication",
}

type SlidePreviewType = {
  slide: Board;
  slides?: Board[];
  handleSelectSlide: (sortOrder: number) => void;
  current: boolean;
  leader: boolean;
  handleRefetchBoards?: (
    source?: SlidePreviewRefetchReason,
    newSortOrder?: number
  ) => void;
  sendEvent?: (eventPayload: EventPayload, appState: State) => void;
  mode?: CanvasMode;
};

const IIIFPreview: React.FC<SlidePreviewType> = ({
  slide,
  handleSelectSlide,
  current,
  leader,
}) => {
  const ref: React.Ref<HTMLDivElement> = useRef(null);

  const selectSlide = (): void => {
    if (leader) {
      handleSelectSlide(slide.sortOrder);
    }
  };

  // Pass ref to useEffect, canvas for IIIF viewer will be dropped underneath the ref.
  useEffect(() => {
    // TODO: set the image source to a thumbnail image
    // See openseadragon/src/referencestrip.js:435-442 for an example

    const initOsd = OpenSeadragon({
      element: ref.current !== null ? ref.current : undefined,
      // have to do a "positive" check for an image because if the OSD thinks it's a single image it does extra work
      // and on new boards there isn't anything to operate on and OSD explodes...
      tileSources: getPreviewTileSources(slide.boardImages),
      // collection mode true and vertical are for sensible display of multi images
      collectionMode: true,
      collectionLayout: "horizontal",
      collectionRows: 1,
      showNavigator: false,
      mouseNavEnabled: 0,
      showNavigationControl: false,
      showSequenceControl: false,
      immediateRender: true,
      blendTime: 0,
      animationTime: 0,
      tabIndex: -1,
    });

    return () => {
      initOsd.destroy();
    };
  }, [slide, ref]);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        selectSlide();
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          selectSlide();
        }
      }}
      className={classnames("slide", { disabled: !leader })}
      tabIndex={0}
      id={`slide-${slide.sortOrder}`}
    >
      <div
        className={classnames("slide__preview", {
          "slide__preview-current": current,
        })}
        ref={ref}
        tabIndex={-1}
      />
      <span className="slide__title" tabIndex={-1}>
        {slide.sortOrder}. {slide.title}
      </span>
    </div>
  );
};

const PanoramaPreview: React.FC<SlidePreviewType> = ({
  slide,
  handleSelectSlide,
  current,
  leader,
}) => {
  const selectSlide = (): void => {
    if (leader) {
      handleSelectSlide(slide.sortOrder);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        selectSlide();
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          selectSlide();
        }
      }}
      className={classnames("slide", { disabled: !leader })}
      tabIndex={0}
      id={`slide-${slide.sortOrder}`}
    >
      <img
        className={classnames("slide__preview", {
          "slide__preview-current": current,
        })}
        tabIndex={-1}
        src={slide.boardPanorama?.panorama?.sourceUrl}
        alt=""
      />
      <span className="slide__title" tabIndex={-1}>
        {slide.sortOrder}. {slide.title}
      </span>
    </div>
  );
};

const ThreeDeePreview: React.FC<SlidePreviewType> = ({
  slide,
  handleSelectSlide,
  current,
  leader,
}) => {
  const selectSlide = (): void => {
    if (leader) {
      handleSelectSlide(slide.sortOrder);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        selectSlide();
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          selectSlide();
        }
      }}
      className={classnames("slide", { disabled: !leader })}
      tabIndex={0}
      id={`slide-${slide.sortOrder}`}
    >
      <div
        className={classnames("slide__preview", "slide__preview--threedee", {
          "slide__preview-current": current,
        })}
      >
        <div
          style={{ textAlign: "center", marginTop: "25px", display: "block" }}
        >
          <Icon
            name="box"
            height="35px"
            desc="3D Icon"
            stroke={current ? "#d64220" : "#282828"}
            toolTip="none"
            hoverState={false}
          />
        </div>
      </div>
      <span className="slide__title" tabIndex={-1}>
        {slide.sortOrder}. {slide.title}
      </span>
    </div>
  );
};

const VideoPreview: React.FC<SlidePreviewType> = ({
  slide,
  handleSelectSlide,
  current,
  leader,
}) => {
  const selectSlide = (): void => {
    if (leader) {
      handleSelectSlide(slide.sortOrder);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        selectSlide();
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          selectSlide();
        }
      }}
      className={classnames("slide", { disabled: !leader })}
      tabIndex={0}
      id={`slide-${slide.sortOrder}`}
    >
      <div
        className={classnames("slide__preview", "slide__preview--threedee", {
          "slide__preview-current": current,
        })}
      >
        <div
          style={{ textAlign: "center", marginTop: "25px", display: "block" }}
        >
          <Icon
            name="play"
            height="35px"
            desc="Video Icon"
            stroke={current ? "#d64220" : "#282828"}
            toolTip="none"
            hoverState={false}
          />
        </div>
      </div>
      <span className="slide__title" tabIndex={-1}>
        {slide.sortOrder}. {slide.title}
      </span>
    </div>
  );
};

export const SlidePreview: React.FC<SlidePreviewType> = ({
  slide,
  slides,
  handleSelectSlide,
  current,
  leader,
  handleRefetchBoards,
  sendEvent,
  mode,
}) => {
  const [showSlideActions, setShowSlideActions] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [deleteBoard, setDeleteBoard] = useState<boolean>(false);
  const [editBoard, setEditBoard] = useState<boolean>(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { state } = useAppContext();
  const [duplicateBoardError, setDuplicateBoardError] =
    useState<boolean>(false);
  const [duplicateBoardMutation] = useMutation(DUPLICATE_BOARD, {
    async onCompleted(data) {
      consoleNonProd(data);
      const currentVersion = data?.duplicateBoard?.currentVersion;
      if (currentVersion) {
        // send version update event
        const event: EventPayload = {
          type: EventTypes.PRESENTATION_VERSION_CHANGE,
          presentationVersion: currentVersion,
          leader: true,
        };

        sendEvent && sendEvent(event, state);
      }
      handleRefetchBoards(
        SlidePreviewRefetchReason.DUPLICATION,
        data?.duplicateBoard?.board?.sortOrder
      );
    },
    onError(error) {
      setDuplicateBoardError(true);
      setShowActionModal(true);
    },
  });

  const handlePresentationChange = (currentVersion?: number) => {
    if (currentVersion) {
      // send version update event
      const event: EventPayload = {
        type: EventTypes.PRESENTATION_VERSION_CHANGE,
        presentationVersion: currentVersion,
        leader: true,
      };

      sendEvent(event, state);
    }
  };

  const duplicatedBoard = (makeNext?: boolean) => {
    const input = {
      id: slide.id,
      makeNext: makeNext,
    };
    duplicateBoardMutation({ variables: { input } });
  };

  const clearModalStates = () => {
    setShowActionModal(false);
    setShowDuplicateModal(false);
    setDeleteBoard(false);
    setEditBoard(false);
  };
  return (
    <div className="slide__wrapper">
      {slide && slide.type === BoardType.IIIF && (
        <IIIFPreview
          slide={slide}
          handleSelectSlide={handleSelectSlide}
          current={current}
          leader={leader}
        />
      )}
      {slide && slide.type === BoardType.THREEDEE && (
        <ThreeDeePreview
          slide={slide}
          handleSelectSlide={handleSelectSlide}
          current={current}
          leader={leader}
        />
      )}
      {slide && slide.type === BoardType.PANORAMA && (
        <PanoramaPreview
          slide={slide}
          handleSelectSlide={handleSelectSlide}
          current={current}
          leader={leader}
        />
      )}
      {slide && slide.type === BoardType.VIDEO && (
        <VideoPreview
          slide={slide}
          handleSelectSlide={handleSelectSlide}
          current={current}
          leader={leader}
        />
      )}
      {slide && (
        <>
          <MoreActionsButton
            showActionsInput={showSlideActions}
            toolTip="none"
            alignment="horizontal"
            size="slidePreview"
            onClick={(e) => {
              setShowSlideActions(!showSlideActions);
            }}
            id={slide?.sortOrder?.toString()}
            disabled={mode === CanvasMode.VIEW_ONLY}
            className="slide-actions"
          >
            <MoreActionsWrapper
              upOrDown="down"
              leftOrRight="left"
              itemClick={(e) => {
                e.preventDefault();
                setShowSlideActions(!showSlideActions);
              }}
              size="slidePreview"
              inputClass="slide-actions__more-actions"
            >
              <MoreActionsItem
                text="Duplicate board"
                id={"duplicate-board-button-" + slide.sortOrder}
                onClick={(e) => {
                  setShowDuplicateModal(true);
                  setShowActionModal(true);
                  setDeleteBoard(false);
                }}
                disabled={
                  (slide.sortOrder === 0 && slides.length === 0) ||
                  mode === CanvasMode.VIEW_ONLY
                }
                icon="copy"
              />
              <MoreActionsItem
                text="Edit board details"
                id={"edit-board-button-" + slide.sortOrder}
                disabled={
                  (slide.sortOrder === 0 && slides.length === 0) ||
                  mode === CanvasMode.VIEW_ONLY
                }
                onClick={(e) => {
                  setDeleteBoard(false);
                  setShowActionModal(true);
                  setEditBoard(true);
                  setShowDuplicateModal(false);
                }}
                icon="edit-pencil"
              />
              <MoreActionsItem
                text="Delete board"
                id={"delete-board-button-" + slide.sortOrder}
                disabled={
                  (slide.sortOrder === 1 && slides.length === 1) ||
                  mode === CanvasMode.VIEW_ONLY
                }
                onClick={(e) => {
                  setEditBoard(false);
                  setShowActionModal(true);
                  setDeleteBoard(true);
                  setShowDuplicateModal(false);
                }}
                icon="trash"
              />
            </MoreActionsWrapper>
          </MoreActionsButton>
          <Modal
            dismissible={true}
            display={showActionModal}
            onDismiss={clearModalStates}
          >
            {deleteBoard && (
              <DeleteBoard
                boardInfo={slide}
                handleClose={() => setShowActionModal(false)}
                handleRefetchBoards={handleRefetchBoards}
                onCompleted={handlePresentationChange}
              />
            )}
            {editBoard && (
              <AddBoard
                handleRefetchBoards={handleRefetchBoards}
                handleSubmit={(version: number) => {
                  setShowActionModal(false);
                  handlePresentationChange(version);
                }}
                meetingId={slide.meetingId}
                sortOrder={slide.sortOrder}
                slide={slide}
                slides={slides}
                edit={true}
              />
            )}
            {duplicateBoardError && (
              <Fragment>
                <ModalHeader>
                  <h1 className="delete-board-heading">Oh no!</h1>
                </ModalHeader>
                <ModalBody>
                  <div className="delete-board-content">
                    <span>
                      There was an issue duplicating your board. Please try
                      again later.
                    </span>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    btnType={PRIMARY}
                    text="Close"
                    size={STANDARD}
                    disabled={false}
                    onClick={(e) => {
                      setDuplicateBoardError(false);
                      setShowActionModal(false);
                    }}
                    id="close-duplication-error-board-button"
                  />
                </ModalFooter>
              </Fragment>
            )}
            {showDuplicateModal && (
              <Fragment>
                <ModalFooter>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Button
                      btnType={PRIMARY}
                      text="Duplicate and add next"
                      size={STANDARD}
                      disabled={false}
                      onClick={(e) => {
                        duplicatedBoard(true);
                        setShowActionModal(false);
                        setShowDuplicateModal(false);
                      }}
                      id="close-duplication-error-board-button"
                    />
                    <Button
                      btnType={PRIMARY}
                      text="Duplicate and add at end"
                      size={STANDARD}
                      disabled={false}
                      onClick={(e) => {
                        duplicatedBoard();
                        setShowActionModal(false);
                        setShowDuplicateModal(false);
                      }}
                      id="close-duplication-error-board-button"
                    />
                  </div>
                </ModalFooter>
              </Fragment>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};
