import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from "graphql";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type ArtObject = {
  __typename?: "ArtObject";
  artistPrefix?: Maybe<Scalars["String"]>;
  artistSuffix?: Maybe<Scalars["String"]>;
  bibliography?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["String"]>;
  creditLine?: Maybe<Scalars["String"]>;
  culture?: Maybe<Scalars["String"]>;
  curatorialApproval?: Maybe<Scalars["String"]>;
  deathDate?: Maybe<Scalars["String"]>;
  dimensions?: Maybe<Scalars["String"]>;
  displayDate?: Maybe<Scalars["String"]>;
  ensembleIndex?: Maybe<Scalars["String"]>;
  exhHistory?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  imageOriginalSecret?: Maybe<Scalars["String"]>;
  imageSecret?: Maybe<Scalars["String"]>;
  invno?: Maybe<Scalars["String"]>;
  longDescription?: Maybe<Scalars["String"]>;
  medium?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Scalars["String"]>;
  objRightsTypeId?: Maybe<Scalars["String"]>;
  onview?: Maybe<Scalars["String"]>;
  people?: Maybe<Scalars["String"]>;
  publishedProvenance?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Attendee = {
  __typename?: "Attendee";
  AttendeeId?: Maybe<Scalars["String"]>;
  ExternalUserId?: Maybe<Scalars["String"]>;
  JoinToken?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Float"];
  deviceInfo?: Maybe<DeviceInfo>;
  firstName: Scalars["String"];
  iconColor?: Maybe<IconColor>;
  id: Scalars["Int"];
  lastName: Scalars["String"];
  meeting?: Maybe<Meeting>;
  meetingId?: Maybe<Scalars["Int"]>;
  meetingRole?: Maybe<Scalars["Int"]>;
  softBanned: Scalars["Boolean"];
  updatedAt: Scalars["Float"];
};

export type Board = {
  __typename?: "Board";
  boardImages?: Maybe<Array<Maybe<BoardImage>>>;
  boardPanorama?: Maybe<BoardPanorama>;
  boardThreedee?: Maybe<BoardThreedee>;
  boardVideo?: Maybe<BoardVideo>;
  caption?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Float"];
  id: Scalars["Int"];
  meeting?: Maybe<Meeting>;
  meetingId?: Maybe<Scalars["Int"]>;
  sortOrder?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<BoardType>;
  updatedAt: Scalars["Float"];
};

export type BoardImage = {
  __typename?: "BoardImage";
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars["Int"]>;
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  imageUpload?: Maybe<ImageUpload>;
  imageUploadId?: Maybe<Scalars["Int"]>;
  tileSource?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Int"]>;
  x?: Maybe<Scalars["Int"]>;
  y?: Maybe<Scalars["Int"]>;
};

export type BoardPanorama = {
  __typename?: "BoardPanorama";
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  panorama?: Maybe<Panorama>;
  panoramaId?: Maybe<Scalars["Int"]>;
};

export type BoardPanoramaReplay = {
  __typename?: "BoardPanoramaReplay";
  Panorama?: Maybe<Panorama>;
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  panoramaId?: Maybe<Scalars["Int"]>;
};

export type BoardThreedee = {
  __typename?: "BoardThreedee";
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  scale?: Maybe<Scalars["Float"]>;
  sourceUrl: Scalars["String"];
};

export enum BoardType {
  IIIF = "IIIF",
  PANORAMA = "PANORAMA",
  THREEDEE = "THREEDEE",
  VIDEO = "VIDEO",
}

export type BoardVideo = {
  __typename?: "BoardVideo";
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars["Int"]>;
  end?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  sourceUrl: Scalars["String"];
  start?: Maybe<Scalars["Int"]>;
};

export enum ChapterPreviewType {
  IIIF_MANIFEST = "IIIF_MANIFEST",
  IMAGE_URL = "IMAGE_URL",
  THREEDEE = "THREEDEE",
  VIDEO = "VIDEO",
}

export type Course = {
  __typename?: "Course";
  createdAt: Scalars["Float"];
  id: Scalars["Int"];
  meetings?: Maybe<Array<Maybe<Meeting>>>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Float"];
};

export type CreateAttendeeInput = {
  deviceInfo?: InputMaybe<DeviceInfoInput>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  meetingRole?: InputMaybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export type CreateBoardImage = {
  boardId: Scalars["Int"];
  height?: InputMaybe<Scalars["Int"]>;
  imageUploadId?: InputMaybe<Scalars["Int"]>;
  tileSource?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Int"]>;
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type CreateBoardInput = {
  boardImages?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  boardPanorama?: InputMaybe<Scalars["String"]>;
  boardThreedee?: InputMaybe<Scalars["String"]>;
  boardVideo?: InputMaybe<Scalars["String"]>;
  caption?: InputMaybe<Scalars["String"]>;
  meetingId?: InputMaybe<Scalars["Int"]>;
  sortOrder: Scalars["Int"];
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<BoardType>;
};

export type CreateBoardPanorama = {
  boardId: Scalars["Int"];
  panoramaId?: InputMaybe<Scalars["Int"]>;
};

export type CreateBoardThreedee = {
  boardId: Scalars["Int"];
  scale?: InputMaybe<Scalars["Float"]>;
  sourceUrl: Scalars["String"];
};

export type CreateBoardVideoInput = {
  boardId: Scalars["Int"];
  end?: InputMaybe<Scalars["Int"]>;
  sourceUrl: Scalars["String"];
  start?: InputMaybe<Scalars["Int"]>;
};

export type CreateCourseInput = {
  meetings?: InputMaybe<Array<InputMaybe<CreateMeetingInput>>>;
  name?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type CreateDemoMeetingInput = {
  attendee?: InputMaybe<CreateAttendeeInput>;
  courseId?: InputMaybe<Scalars["Int"]>;
  meetingTime?: InputMaybe<Scalars["Float"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type CreateDemoMeetingResponse = {
  __typename?: "CreateDemoMeetingResponse";
  attendee?: Maybe<Attendee>;
  meeting?: Maybe<Meeting>;
};

export type CreateImageUploadInput = {
  birthDate?: InputMaybe<Scalars["Float"]>;
  caption?: InputMaybe<Scalars["String"]>;
  classification?: InputMaybe<Scalars["String"]>;
  creditLine?: InputMaybe<Scalars["String"]>;
  culture?: InputMaybe<Scalars["String"]>;
  deathDate?: InputMaybe<Scalars["Float"]>;
  dimensions?: InputMaybe<Scalars["String"]>;
  displayDate?: InputMaybe<Scalars["String"]>;
  medium?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  people?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<MetaDataPermissions>;
  title?: InputMaybe<Scalars["String"]>;
  uploadUrl: Scalars["String"];
  visualDescription?: InputMaybe<Scalars["String"]>;
};

export type CreateMeetingInput = {
  chat: Scalars["Boolean"];
  courseId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  instructorAV: Scalars["Boolean"];
  meetingTime?: InputMaybe<Scalars["Float"]>;
  muteLock: Scalars["Boolean"];
  studentAV: Scalars["Boolean"];
  title?: InputMaybe<Scalars["String"]>;
  videoOffLock?: InputMaybe<Scalars["Boolean"]>;
};

export type CreatePanorama = {
  back: Scalars["String"];
  bottom: Scalars["String"];
  collection?: InputMaybe<PanoramaCollection>;
  front: Scalars["String"];
  left: Scalars["String"];
  name: Scalars["String"];
  right: Scalars["String"];
  sourceUrl: Scalars["String"];
  top: Scalars["String"];
};

export type CreateUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type CreateUserResponse = {
  __typename?: "CreateUserResponse";
  createdAt?: Maybe<Scalars["Float"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
  updatedAt?: Maybe<Scalars["Float"]>;
};

export type DateCourseInput = {
  endDate?: InputMaybe<Scalars["Float"]>;
  startDate?: InputMaybe<Scalars["Float"]>;
};

export type DeleteBoardInput = {
  id: Scalars["Int"];
};

export type DeleteChimeAttendeeResponse = {
  __typename?: "DeleteChimeAttendeeResponse";
  error?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  success: Scalars["Boolean"];
};

export type DeleteMeetingResponse = {
  __typename?: "DeleteMeetingResponse";
  course?: Maybe<Course>;
};

export type DeletedBoard = {
  __typename?: "DeletedBoard";
  boardError?: Maybe<Scalars["Boolean"]>;
  boardImagesError?: Maybe<Scalars["Boolean"]>;
  boardPanoramaError?: Maybe<Scalars["Boolean"]>;
  boardVideoError?: Maybe<Scalars["Boolean"]>;
  currentVersion?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
};

export type DeletedBoardImage = {
  __typename?: "DeletedBoardImage";
  currentVersion?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
};

export type DeletedCourse = {
  __typename?: "DeletedCourse";
  id?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
};

export type DeviceInfo = {
  __typename?: "DeviceInfo";
  browserName?: Maybe<Scalars["String"]>;
  browserVersion?: Maybe<Scalars["String"]>;
  deviceModel?: Maybe<Scalars["String"]>;
  deviceType?: Maybe<Scalars["String"]>;
  deviceVendor?: Maybe<Scalars["String"]>;
  engine?: Maybe<Scalars["String"]>;
  os?: Maybe<Scalars["String"]>;
  osVersion?: Maybe<Scalars["String"]>;
  userAgent?: Maybe<Scalars["String"]>;
};

export type DeviceInfoInput = {
  browserName?: InputMaybe<Scalars["String"]>;
  browserVersion?: InputMaybe<Scalars["String"]>;
  deviceModel?: InputMaybe<Scalars["String"]>;
  deviceType?: InputMaybe<Scalars["String"]>;
  deviceVendor?: InputMaybe<Scalars["String"]>;
  engine?: InputMaybe<Scalars["String"]>;
  os?: InputMaybe<Scalars["String"]>;
  osVersion?: InputMaybe<Scalars["String"]>;
  userAgent?: InputMaybe<Scalars["String"]>;
};

export type DuplicateBoardInput = {
  id: Scalars["Int"];
  makeNext?: InputMaybe<Scalars["Boolean"]>;
};

export type DuplicateCourseInput = {
  id: Scalars["Int"];
  meetingDates?: InputMaybe<Array<InputMaybe<CreateMeetingInput>>>;
  name?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type DuplicateMeetingInput = {
  chat: Scalars["Boolean"];
  instructorAV: Scalars["Boolean"];
  meetingId: Scalars["Int"];
  meetingTime?: InputMaybe<Scalars["Float"]>;
  muteLock: Scalars["Boolean"];
  studentAV: Scalars["Boolean"];
  videoOffLock?: InputMaybe<Scalars["Boolean"]>;
};

export type EditBoardInput = {
  caption?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  sortOrder?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<BoardType>;
};

export type EditCourseInput = {
  id: Scalars["Int"];
  meetings?: InputMaybe<Array<InputMaybe<CreateMeetingInput>>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type EndMeetingInput = {
  id: Scalars["Int"];
  setReplayRecording?: InputMaybe<Scalars["Boolean"]>;
};

export type EndMeetingResponse = {
  __typename?: "EndMeetingResponse";
  error?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type FeatureFlag = {
  __typename?: "FeatureFlag";
  active: Scalars["Boolean"];
  createdAt: Scalars["Float"];
  id: Scalars["Int"];
  name: Scalars["String"];
  updatedAt: Scalars["Float"];
};

export enum FeatureFlagType {
  /** DEMO = Turns on/off unauthenticated access to the /demo endpoint */
  DEMO = "DEMO",
  /** DUPLICATE_COURSE = Adds the ability to duplicate a course */
  DUPLICATE_COURSE = "DUPLICATE_COURSE",
  /** EXAMPLE_OFF_FEATURE = Example feature that is turned off */
  EXAMPLE_OFF_FEATURE = "EXAMPLE_OFF_FEATURE",
  /** EXAMPLE_ON_FEATURE = Example feature that is turned on */
  EXAMPLE_ON_FEATURE = "EXAMPLE_ON_FEATURE",
  /** FHD_RECORDING = Adds the ability to record async content in full HD */
  FHD_RECORDING = "FHD_RECORDING",
  /** LASER_POINTER = Adds the in-class laser pointer tool */
  LASER_POINTER = "LASER_POINTER",
  /** LOBBY = Adds student lobby feature */
  LOBBY = "LOBBY",
  /** MODERATION = Adds the moderator role for meeting attendees */
  MODERATION = "MODERATION",
  /**
   * RECORDING_PROMPT = Adds the prompt when a class is ended for all to set
   * replay recording and start processing the recording for replay
   */
  RECORDING_PROMPT = "RECORDING_PROMPT",
  /**
   * RECORDING_SET_PREVIOUS = Allows users to be able to set the replay recording
   * for previously recorded classes
   */
  RECORDING_SET_PREVIOUS = "RECORDING_SET_PREVIOUS",
  /** REPLAY = Allows meeting replay for classes */
  REPLAY = "REPLAY",
  /** REPLAY_DEMO = Shows replay demo option to admin menu */
  REPLAY_DEMO = "REPLAY_DEMO",
  /** USER_PARAMS = Adds firstName and lastName query params to the /meeting URL */
  USER_PARAMS = "USER_PARAMS",
}

export enum GenerateVttStatus {
  /**
   * ERROR = there was an error while generating VTT files
   * MediaPipelines with ERROR status will be retried.
   */
  ERROR = "ERROR",
  /** ERROR_MISSING_FILES = one or more files that are required to generate VTT were missing. */
  ERROR_MISSING_FILES = "ERROR_MISSING_FILES",
  /** IN_PROGRESS = the pipeline data has been sent to the VTT generation service */
  IN_PROGRESS = "IN_PROGRESS",
  /**
   * MANUAL_UPDATE = a file that has been manually updated
   * MediaPipelines with MANUAL_UPDATE status will skip re-running the job
   */
  MANUAL_UPDATE = "MANUAL_UPDATE",
  /** PENDING = no action has been taken to generate VTT files */
  PENDING = "PENDING",
  /** QUEUED = the pipeline has been queued for processing */
  QUEUED = "QUEUED",
  /** SUCCESS = VTT files were successfully generated */
  SUCCESS = "SUCCESS",
}

export enum IconColor {
  _0091EA = "_0091EA",
  _00ACC1 = "_00ACC1",
  _00CED1 = "_00CED1",
  _04A0C4 = "_04A0C4",
  _038D3E = "_038D3E",
  _0457A2 = "_0457A2",
  _0F3765 = "_0F3765",
  _1A44AF = "_1A44AF",
  _1E863C = "_1E863C",
  _2C1276 = "_2C1276",
  _3A6B00 = "_3A6B00",
  _3CB371 = "_3CB371",
  _4B371C = "_4B371C",
  _5C0E32 = "_5C0E32",
  _6A2D80 = "_6A2D80",
  _6A7BCB = "_6A7BCB",
  _6D1D00 = "_6D1D00",
  _6DAE43 = "_6DAE43",
  _6F0400 = "_6F0400",
  _7A19AB = "_7A19AB",
  _8E003C = "_8E003C",
  _18A278 = "_18A278",
  _135B7F = "_135B7F",
  _253B6A = "_253B6A",
  _285E73 = "_285E73",
  _316C8B = "_316C8B",
  _511AAC = "_511AAC",
  _620F53 = "_620F53",
  _900C3F = "_900C3F",
  _1004B3 = "_1004B3",
  _1627FD = "_1627FD",
  _2962FF = "_2962FF",
  _3752FA = "_3752FA",
  _5082C2 = "_5082C2",
  _7295CE = "_7295CE",
  _8352FD = "_8352FD",
  _9370DB = "_9370DB",
  _13277C = "_13277C",
  _87001B = "_87001B",
  _191279 = "_191279",
  _581845 = "_581845",
  _712868 = "_712868",
  _AB47BC = "_AB47BC",
  _AB1960 = "_AB1960",
  _AC0062 = "_AC0062",
  _B50053 = "_B50053",
  _BE1C38 = "_BE1C38",
  _C2005F = "_C2005F",
  _C70039 = "_C70039",
  _DC2176 = "_DC2176",
  _E65883 = "_E65883",
  _EC407A = "_EC407A",
  _F08080 = "_F08080",
  _FF6F61 = "_FF6F61",
  _FF367F = "_FF367F",
  _FF1493 = "_FF1493",
  _FF3587 = "_FF3587",
  _FFBF00 = "_FFBF00",
  _FFC300 = "_FFC300",
  _FFD700 = "_FFD700",
}

export enum IiiFConversionStatus {
  /**
   * ERROR = there was an error while converting the image to IIIF
   * Conversion of images with ERROR status will be retried.
   */
  ERROR = "ERROR",
  /** IN_PROGRESS = the image data has been sent to the IIIF conversion service */
  IN_PROGRESS = "IN_PROGRESS",
  /** PENDING = no action has been taken to convert the image to IIIF */
  PENDING = "PENDING",
  /** QUEUED = the image has been queued for processing */
  QUEUED = "QUEUED",
  /**
   * SIZE_ERROR = there was an error because the uploaded image was too small to convert to IIIF
   * Conversion of images that have a size error will not be retried.
   */
  SIZE_ERROR = "SIZE_ERROR",
  /** SUCCESS = image was successfully converted to IIIF */
  SUCCESS = "SUCCESS",
}

export type IiifConversionReqBody = {
  __typename?: "IiifConversionReqBody";
  outputBucket: Scalars["String"];
  outputName?: Maybe<Scalars["String"]>;
  sourceURL: Scalars["String"];
};

export type IiifConversionResponse = {
  __typename?: "IiifConversionResponse";
  message?: Maybe<Scalars["String"]>;
  statusCode: Scalars["Int"];
  tileOutputPath?: Maybe<Scalars["String"]>;
};

export type ImageUpload = {
  __typename?: "ImageUpload";
  birthDate?: Maybe<Scalars["Float"]>;
  caption?: Maybe<Scalars["String"]>;
  classification?: Maybe<Scalars["String"]>;
  creditLine?: Maybe<Scalars["String"]>;
  culture?: Maybe<Scalars["String"]>;
  deathDate?: Maybe<Scalars["Float"]>;
  dimensions?: Maybe<Scalars["String"]>;
  displayDate?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  iiifConversionStatus?: Maybe<IiiFConversionStatus>;
  medium?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Scalars["String"]>;
  people?: Maybe<Scalars["String"]>;
  permissions?: Maybe<MetaDataPermissions>;
  thumbnailConversionStatus?: Maybe<ThumbnailConversionStatus>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
  tileSource?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  uploadUrl: Scalars["String"];
  userId: Scalars["Int"];
  visualDescription?: Maybe<Scalars["String"]>;
};

export type Job = {
  __typename?: "Job";
  force?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  name: JobName;
  skipConcat?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
  taskId?: Maybe<Scalars["String"]>;
  videoLength?: Maybe<Scalars["JSON"]>;
};

export enum JobName {
  GENERATE_ATTENDEE_LOG = "GENERATE_ATTENDEE_LOG",
  GENERATE_CAPTIONS_VTT = "GENERATE_CAPTIONS_VTT",
  GENERATE_CHAPTERS_VTT = "GENERATE_CHAPTERS_VTT",
  GENERATE_CHAT_LOG = "GENERATE_CHAT_LOG",
  GENERATE_CHAT_VTT = "GENERATE_CHAT_VTT",
  GENERATE_EVENTS_VTT = "GENERATE_EVENTS_VTT",
  GENERATE_PRESENTATION_LOG = "GENERATE_PRESENTATION_LOG",
  IIIF_CONVERSION = "IIIF_CONVERSION",
  PROCESS_RECORDING = "PROCESS_RECORDING",
  PROCESS_RECORDING_SKIP_CONCAT = "PROCESS_RECORDING_SKIP_CONCAT",
  THUMBNAIL_CONVERSION = "THUMBNAIL_CONVERSION",
  UPDATE_PROCESS_RECORDING_STATUS = "UPDATE_PROCESS_RECORDING_STATUS",
}

export type JobResponse = {
  __typename?: "JobResponse";
  message: Scalars["String"];
  statusCode: Scalars["Int"];
};

export type LoginInput = {
  email?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["Float"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type LoginResponse = {
  __typename?: "LoginResponse";
  firstName?: Maybe<Scalars["String"]>;
  isAuthenticated: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  permissions?: Maybe<PermissionLevels>;
};

export type LogoutInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type LogoutResponse = {
  __typename?: "LogoutResponse";
  firstName?: Maybe<Scalars["String"]>;
  isAuthenticated: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type MediaPipeline = {
  __typename?: "MediaPipeline";
  arn?: Maybe<Scalars["String"]>;
  attendeeLogStatus: GenerateVttStatus;
  awsConcatPipelineId?: Maybe<Scalars["String"]>;
  awsPipelineId?: Maybe<Scalars["String"]>;
  chatLogStatus: GenerateVttStatus;
  createdAt: Scalars["Float"];
  folderName: Scalars["String"];
  generateCaptionsStatus: GenerateVttStatus;
  generateChaptersStatus: GenerateVttStatus;
  generateChatStatus: GenerateVttStatus;
  generateEventsStatus: GenerateVttStatus;
  id: Scalars["Int"];
  meeting?: Maybe<Meeting>;
  meetingId: Scalars["Int"];
  presentationLogStatus: GenerateVttStatus;
  processRecordingStatus: ProcessRecordingStatus;
  replayRecording?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["Float"];
  videoLength?: Maybe<Scalars["Int"]>;
};

export type Meeting = {
  __typename?: "Meeting";
  AudioFallbackUrl?: Maybe<Scalars["String"]>;
  AudioHostUrl?: Maybe<Scalars["String"]>;
  EventIngestionUrl?: Maybe<Scalars["String"]>;
  ExternalMeetingId?: Maybe<Scalars["String"]>;
  MediaPipelines?: Maybe<Array<Maybe<MediaPipeline>>>;
  MediaRegion?: Maybe<Scalars["String"]>;
  MeetingArn?: Maybe<Scalars["String"]>;
  MeetingId?: Maybe<Scalars["String"]>;
  ScreenDataUrl?: Maybe<Scalars["String"]>;
  ScreenSharingUrl?: Maybe<Scalars["String"]>;
  ScreenViewingUrl?: Maybe<Scalars["String"]>;
  SignalingUrl?: Maybe<Scalars["String"]>;
  TurnControlUrl?: Maybe<Scalars["String"]>;
  attendees?: Maybe<Array<Maybe<Attendee>>>;
  boards?: Maybe<Array<Maybe<Board>>>;
  chat?: Maybe<Scalars["Boolean"]>;
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["Float"];
  currentlyRecording?: Maybe<Scalars["Boolean"]>;
  deletedAt?: Maybe<Scalars["Float"]>;
  id: Scalars["Int"];
  instructorAV?: Maybe<Scalars["Boolean"]>;
  lobbyActive?: Maybe<Scalars["Boolean"]>;
  mediaPipelines?: Maybe<Array<Maybe<MediaPipeline>>>;
  meetingTime?: Maybe<Scalars["Float"]>;
  muteLock: Scalars["Boolean"];
  presentationVersions?: Maybe<Array<Maybe<PresentationVersion>>>;
  studentAV?: Maybe<Scalars["Boolean"]>;
  title: Scalars["String"];
  updatedAt: Scalars["Float"];
  videoOffLock: Scalars["Boolean"];
};

export type MeetingError = {
  __typename?: "MeetingError";
  code: MeetingErrorCodes;
  message: Scalars["String"];
};

export enum MeetingErrorCodes {
  /** CHIME_NOT_ACTIVE = Chime has been started for this meeting, but is no longer active */
  CHIME_NOT_ACTIVE = "CHIME_NOT_ACTIVE",
  /** CHIME_NOT_INITIALIZED = Chime has not been started for this meeting */
  CHIME_NOT_INITIALIZED = "CHIME_NOT_INITIALIZED",
  /** NOT_FOUND = Meeting was not found */
  NOT_FOUND = "NOT_FOUND",
}

export enum MetaDataPermissions {
  PUBLIC = "PUBLIC",
  USER = "USER",
}

export type Mutation = {
  __typename?: "Mutation";
  /** Finds an existing meeting and creates a Media Capture Pipeline to initialize recording */
  activateMeeting?: Maybe<RecordMeetingResponse>;
  /** Deletes attendee information in Chime and removes attendee info from session storage */
  attendeeLeaveMeeting?: Maybe<DeleteChimeAttendeeResponse>;
  /** Finds an existing meeting and creates a Media Capture Pipeline to initialize recording */
  beginRecordMeeting?: Maybe<RecordMeetingResponse>;
  bulkCreateImageUploads?: Maybe<Array<Maybe<ImageUpload>>>;
  createAndStartMeeting?: Maybe<Meeting>;
  /** Creates new attendee in the chime client and joins meeting that has already been started */
  createAttendee?: Maybe<Attendee>;
  createBoard?: Maybe<UpdateBoardResponse>;
  createBoardImages?: Maybe<UpdateBoardImageResponse>;
  createBoardPanorama?: Maybe<UpdateBoardPanoramaResponse>;
  createBoardThreedee?: Maybe<UpdateBoardThreedeeResponse>;
  createBoardVideo?: Maybe<UpdateBoardVideoResponse>;
  createCourse?: Maybe<Course>;
  /**
   * Creates new meeting with demo deck and starts the meeting in chime client
   * Then creates new attendee associated with the meeting
   */
  createDemoMeeting?: Maybe<CreateDemoMeetingResponse>;
  createMeeting?: Maybe<Meeting>;
  createPanorama?: Maybe<Panorama>;
  createUser?: Maybe<CreateUserResponse>;
  deleteBoard?: Maybe<DeletedBoard>;
  deleteBoardImage?: Maybe<DeletedBoardImage>;
  deleteCourse?: Maybe<DeletedCourse>;
  deleteMeeting?: Maybe<Course>;
  duplicateBoard?: Maybe<UpdateBoardResponse>;
  duplicateCourse?: Maybe<Course>;
  duplicateMeeting?: Maybe<Course>;
  editBoard?: Maybe<UpdateBoardResponse>;
  editCourse?: Maybe<Course>;
  editMeeting?: Maybe<Meeting>;
  /** End meeting in chime client and stop meeting recording */
  endMeeting?: Maybe<EndMeetingResponse>;
  login?: Maybe<LoginResponse>;
  logout?: Maybe<LogoutResponse>;
  /**
   * Queues job to process recording for a video that has been separately uploaded
   * to S3 and will replace the recording created by the Chime meeting
   */
  replaceMeetingRecording?: Maybe<MediaPipeline>;
  root?: Maybe<Scalars["String"]>;
  setReplayRecordingPipeline?: Maybe<Meeting>;
  softBanAttendee?: Maybe<Attendee>;
  /** Starts meeting in chime client and joins meeting as the authenticated user */
  startAndJoinMeeting?: Maybe<StartMeetingResponse>;
  /** Bulk operation to generate VTT files for pipelines */
  startBulkGenerateVttFiles?: Maybe<Array<Maybe<MediaPipeline>>>;
  startBulkIiiFConversion?: Maybe<Array<Maybe<ImageUpload>>>;
  /** Bulk operation to start media concatenation pipelines */
  startBulkMediaPipelineConcat?: Maybe<Array<Maybe<RecordMeetingResponse>>>;
  startBulkThumbnailConversion?: Maybe<Array<Maybe<ImageUpload>>>;
  /** Starts FHD meeting in chime client and joins meeting as the authenticated user */
  startFhdMeeting?: Maybe<StartMeetingResponse>;
  /** Finds an existing media pipeline and starts a Concatenation Pipeline to process meeting recordings */
  startMediaPipelineConcat?: Maybe<RecordMeetingResponse>;
  /** Finds an existing meeting and toggles the muteLock state */
  toggleMuteLock?: Maybe<MuteLockResponse>;
  /** Locks or unlocks video lock for all students */
  toggleVideoOffLock?: Maybe<VideoOffLockResponse>;
};

export type MutationActivateMeetingArgs = {
  id: Scalars["Int"];
};

export type MutationAttendeeLeaveMeetingArgs = {
  id?: InputMaybe<Scalars["Int"]>;
};

export type MutationBeginRecordMeetingArgs = {
  id: Scalars["Int"];
};

export type MutationBulkCreateImageUploadsArgs = {
  input?: InputMaybe<Array<InputMaybe<CreateImageUploadInput>>>;
};

export type MutationCreateAndStartMeetingArgs = {
  input?: InputMaybe<CreateMeetingInput>;
};

export type MutationCreateAttendeeArgs = {
  input?: InputMaybe<CreateAttendeeInput>;
};

export type MutationCreateBoardArgs = {
  input?: InputMaybe<CreateBoardInput>;
};

export type MutationCreateBoardImagesArgs = {
  input?: InputMaybe<Array<InputMaybe<CreateBoardImage>>>;
};

export type MutationCreateBoardPanoramaArgs = {
  input?: InputMaybe<CreateBoardPanorama>;
};

export type MutationCreateBoardThreedeeArgs = {
  input?: InputMaybe<CreateBoardThreedee>;
};

export type MutationCreateBoardVideoArgs = {
  input?: InputMaybe<CreateBoardVideoInput>;
};

export type MutationCreateCourseArgs = {
  input?: InputMaybe<CreateCourseInput>;
};

export type MutationCreateDemoMeetingArgs = {
  input?: InputMaybe<CreateDemoMeetingInput>;
};

export type MutationCreateMeetingArgs = {
  input?: InputMaybe<CreateMeetingInput>;
};

export type MutationCreatePanoramaArgs = {
  input?: InputMaybe<CreatePanorama>;
};

export type MutationCreateUserArgs = {
  input?: InputMaybe<CreateUserInput>;
};

export type MutationDeleteBoardArgs = {
  input?: InputMaybe<DeleteBoardInput>;
};

export type MutationDeleteBoardImageArgs = {
  input?: InputMaybe<Scalars["Int"]>;
};

export type MutationDeleteCourseArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteMeetingArgs = {
  input?: InputMaybe<CreateMeetingInput>;
};

export type MutationDuplicateBoardArgs = {
  input?: InputMaybe<DuplicateBoardInput>;
};

export type MutationDuplicateCourseArgs = {
  input?: InputMaybe<DuplicateCourseInput>;
};

export type MutationDuplicateMeetingArgs = {
  input?: InputMaybe<DuplicateMeetingInput>;
};

export type MutationEditBoardArgs = {
  input?: InputMaybe<EditBoardInput>;
};

export type MutationEditCourseArgs = {
  input?: InputMaybe<EditCourseInput>;
};

export type MutationEditMeetingArgs = {
  input?: InputMaybe<CreateMeetingInput>;
};

export type MutationEndMeetingArgs = {
  input?: InputMaybe<EndMeetingInput>;
};

export type MutationLoginArgs = {
  input?: InputMaybe<LoginInput>;
};

export type MutationLogoutArgs = {
  input?: InputMaybe<LogoutInput>;
};

export type MutationReplaceMeetingRecordingArgs = {
  id: Scalars["Int"];
};

export type MutationSetReplayRecordingPipelineArgs = {
  input?: InputMaybe<SetReplayRecordingPipelineInput>;
};

export type MutationSoftBanAttendeeArgs = {
  AttendeeId: Scalars["String"];
  softBan: Scalars["Boolean"];
};

export type MutationStartAndJoinMeetingArgs = {
  id: Scalars["Int"];
  input: StartAndJoinMeetingInput;
};

export type MutationStartBulkGenerateVttFilesArgs = {
  job: JobName;
  limit?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Array<InputMaybe<GenerateVttStatus>>>;
};

export type MutationStartBulkIiiFConversionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type MutationStartBulkMediaPipelineConcatArgs = {
  paginate: Paginate;
};

export type MutationStartBulkThumbnailConversionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type MutationStartFhdMeetingArgs = {
  id: Scalars["Int"];
  input: StartAndJoinMeetingInput;
};

export type MutationStartMediaPipelineConcatArgs = {
  id: Scalars["Int"];
};

export type MutationToggleMuteLockArgs = {
  id: Scalars["Int"];
  muteLock?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationToggleVideoOffLockArgs = {
  id: Scalars["Int"];
  videoOffLock?: InputMaybe<Scalars["Boolean"]>;
};

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export type Paginate = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type Panorama = {
  __typename?: "Panorama";
  back: Scalars["String"];
  bottom: Scalars["String"];
  collection?: Maybe<PanoramaCollection>;
  front: Scalars["String"];
  id: Scalars["Int"];
  left: Scalars["String"];
  name: Scalars["String"];
  right: Scalars["String"];
  sourceUrl: Scalars["String"];
  top: Scalars["String"];
};

export enum PanoramaCollection {
  BARNES = "BARNES",
  UPLOADS = "UPLOADS",
}

export enum PermissionLevels {
  ADMIN = "ADMIN",
  DEMO = "DEMO",
  STUDENT = "STUDENT",
}

export type PresentationVersion = {
  __typename?: "PresentationVersion";
  content?: Maybe<Array<Maybe<ReplayBoard>>>;
  createdAt: Scalars["Float"];
  id: Scalars["Int"];
  meeting?: Maybe<Meeting>;
  meetingId: Scalars["Int"];
  updatedAt: Scalars["Float"];
  versionNumber: Scalars["Int"];
};

export type ProcessRecordingReqBody = {
  __typename?: "ProcessRecordingReqBody";
  attendeeIds: Array<Scalars["String"]>;
  concatFolder: Scalars["String"];
  concatRecordingBucket: Scalars["String"];
  local?: Maybe<Scalars["Boolean"]>;
  mediaPipelineId: Scalars["Int"];
  queue: Scalars["String"];
  recordingAccessPoint: Scalars["String"];
  recordingFolder: Scalars["String"];
  skipConcat: Scalars["Boolean"];
  taskId: Scalars["String"];
};

export enum ProcessRecordingStatus {
  /** ERROR = there was an error while processing recording */
  ERROR = "ERROR",
  /** ERROR_TASK = there was an error initializing the Fargate task */
  ERROR_TASK = "ERROR_TASK",
  /** IN_PROGRESS = the pipeline data has been sent to processing task */
  IN_PROGRESS = "IN_PROGRESS",
  /**
   * MANUAL_UPDATE = a file that has been manually updated
   * MediaPipelines with MANUAL_UPDATE status will skip re-running the job
   */
  MANUAL_UPDATE = "MANUAL_UPDATE",
  /** PENDING = no action has been taken to process recording */
  PENDING = "PENDING",
  /** QUEUED = the pipeline has been queued for processing */
  QUEUED = "QUEUED",
  /**
   * REPLACED = the video used to create the HLS files has been replaced and no
   * longer uses the original Chime meeting recording
   */
  REPLACED = "REPLACED",
  /** SUCCESS = Recording was successfully processed */
  SUCCESS = "SUCCESS",
}

export type Query = {
  __typename?: "Query";
  checkIsActiveFeature?: Maybe<FeatureFlag>;
  getAllActiveFeatureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  getAllAttendees?: Maybe<Array<Maybe<Attendee>>>;
  getAllBoards?: Maybe<Array<Maybe<Board>>>;
  getAllCourses?: Maybe<Array<Maybe<Course>>>;
  getAllFeatureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  getAllImageUploads?: Maybe<Array<Maybe<ImageUpload>>>;
  getAllMeetings?: Maybe<Array<Maybe<Meeting>>>;
  getAllPanoramas?: Maybe<Array<Maybe<Panorama>>>;
  getAllUsers?: Maybe<Array<User>>;
  getAllVideos?: Maybe<Array<Maybe<BoardVideo>>>;
  getAttendee?: Maybe<Attendee>;
  getAttendeeByExternalId?: Maybe<Attendee>;
  getAuthenticatedUser?: Maybe<LoginResponse>;
  getBoard?: Maybe<Board>;
  getBoardPanorama?: Maybe<BoardPanorama>;
  getBoardVideo?: Maybe<BoardVideo>;
  getCourse?: Maybe<Course>;
  getCourseBySlug?: Maybe<Course>;
  getCourseMeetings?: Maybe<Array<Maybe<Meeting>>>;
  getCoursesByMeetingDates?: Maybe<Array<Maybe<Course>>>;
  getImageUploadByUserId?: Maybe<Array<Maybe<ImageUpload>>>;
  getMeeting?: Maybe<Meeting>;
  getMeetingBoards?: Maybe<Array<Maybe<Board>>>;
  getMeetingBoardsByMeetingTitle?: Maybe<Array<Maybe<Board>>>;
  getMeetingByTitle?: Maybe<Meeting>;
  getPanorama?: Maybe<Panorama>;
  getPresentationVersion?: Maybe<PresentationVersion>;
  getPresentationVersionsForMeeting?: Maybe<Array<Maybe<PresentationVersion>>>;
  getReplayMeeting?: Maybe<Meeting>;
  getUser?: Maybe<User>;
  root?: Maybe<Scalars["String"]>;
  searchArtObjects?: Maybe<Array<Maybe<ArtObject>>>;
  searchImageUploads?: Maybe<Array<Maybe<ImageUpload>>>;
};

export type QueryCheckIsActiveFeatureArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryGetAttendeeArgs = {
  id: Scalars["Int"];
};

export type QueryGetAttendeeByExternalIdArgs = {
  externalId: Scalars["String"];
};

export type QueryGetBoardArgs = {
  id: Scalars["Int"];
};

export type QueryGetBoardPanoramaArgs = {
  id: Scalars["Int"];
};

export type QueryGetBoardVideoArgs = {
  id: Scalars["Int"];
};

export type QueryGetCourseArgs = {
  id: Scalars["Int"];
};

export type QueryGetCourseBySlugArgs = {
  slug: Scalars["String"];
};

export type QueryGetCourseMeetingsArgs = {
  id: Scalars["Int"];
  sortBy?: InputMaybe<SortBy>;
};

export type QueryGetCoursesByMeetingDatesArgs = {
  input?: InputMaybe<DateCourseInput>;
};

export type QueryGetImageUploadByUserIdArgs = {
  userId: Scalars["Int"];
};

export type QueryGetMeetingArgs = {
  id: Scalars["Int"];
};

export type QueryGetMeetingBoardsArgs = {
  meetingId: Scalars["Int"];
  sortBy?: InputMaybe<SortBy>;
};

export type QueryGetMeetingBoardsByMeetingTitleArgs = {
  meetingTitle: Scalars["String"];
  sortBy?: InputMaybe<SortBy>;
};

export type QueryGetMeetingByTitleArgs = {
  replayMeetingPipeline?: InputMaybe<Scalars["Boolean"]>;
  title: Scalars["String"];
};

export type QueryGetPanoramaArgs = {
  id: Scalars["Int"];
};

export type QueryGetPresentationVersionArgs = {
  id: Scalars["Int"];
};

export type QueryGetPresentationVersionsForMeetingArgs = {
  meetingId: Scalars["Int"];
};

export type QueryGetReplayMeetingArgs = {
  title: Scalars["String"];
};

export type QueryGetUserArgs = {
  id: Scalars["Int"];
};

export type QuerySearchArtObjectsArgs = {
  paginate?: InputMaybe<Paginate>;
  term?: InputMaybe<Scalars["String"]>;
};

export type QuerySearchImageUploadsArgs = {
  paginate?: InputMaybe<Paginate>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  term?: InputMaybe<Scalars["String"]>;
};

export type RecordMeetingResponse = {
  __typename?: "RecordMeetingResponse";
  error?: Maybe<Scalars["String"]>;
  initVersionNumber?: Maybe<Scalars["Int"]>;
  mediaPipeline?: Maybe<MediaPipeline>;
  meeting?: Maybe<Meeting>;
  status?: Maybe<RecordMeetingStatus>;
  success?: Maybe<Scalars["Boolean"]>;
};

export enum RecordMeetingStatus {
  /** ERROR = there was an error when initializing the recording */
  ERROR = "ERROR",
  /** IN_PROGRESS = the recording has already been initialized */
  IN_PROGRESS = "IN_PROGRESS",
  /** STARTED = the recording was initialized in this server action */
  STARTED = "STARTED",
}

export type ReplayBoard = {
  __typename?: "ReplayBoard";
  BoardImages?: Maybe<Array<Maybe<ReplayBoardImage>>>;
  BoardPanorama?: Maybe<BoardPanoramaReplay>;
  BoardThreedee?: Maybe<BoardThreedee>;
  BoardVideo?: Maybe<BoardVideo>;
  caption?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Float"];
  id: Scalars["Int"];
  meeting?: Maybe<Meeting>;
  meetingId?: Maybe<Scalars["Int"]>;
  sortOrder?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<BoardType>;
  updatedAt: Scalars["Float"];
};

export type ReplayBoardImage = {
  __typename?: "ReplayBoardImage";
  ImageUpload?: Maybe<ImageUpload>;
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars["Int"]>;
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  imageUploadId?: Maybe<Scalars["Int"]>;
  tileSource?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Int"]>;
  x?: Maybe<Scalars["Int"]>;
  y?: Maybe<Scalars["Int"]>;
};

export type SessionCookie = {
  __typename?: "SessionCookie";
  maxAge?: Maybe<Scalars["Int"]>;
};

export type SessionObject = {
  __typename?: "SessionObject";
  attendeeId?: Maybe<Scalars["Int"]>;
  cookie?: Maybe<SessionCookie>;
  initialized: Scalars["Boolean"];
  isAuthenticated: Scalars["Boolean"];
  permissions?: Maybe<PermissionLevels>;
  userId?: Maybe<Scalars["Int"]>;
};

export type SetReplayRecordingPipelineInput = {
  id: Scalars["Int"];
  mediaPipelineId?: InputMaybe<Scalars["Int"]>;
};

export type SortBy = {
  field: Scalars["String"];
  order: Order;
};

export type StartAndJoinMeetingInput = {
  deviceInfo?: InputMaybe<DeviceInfoInput>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  userRole?: InputMaybe<Scalars["Int"]>;
};

export type StartMeetingResponse = {
  __typename?: "StartMeetingResponse";
  attendee?: Maybe<Attendee>;
  error?: Maybe<Scalars["String"]>;
  initVersionNumber?: Maybe<Scalars["Int"]>;
  meeting?: Maybe<Meeting>;
  recordMeetingStatus?: Maybe<RecordMeetingStatus>;
  startMeetingSuccess?: Maybe<Scalars["Boolean"]>;
};

export type TableStringFilterInput = {
  beginsWith?: InputMaybe<Scalars["String"]>;
  between?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contains?: InputMaybe<Scalars["String"]>;
  eq?: InputMaybe<Scalars["String"]>;
  ge?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  le?: InputMaybe<Scalars["String"]>;
  lt?: InputMaybe<Scalars["String"]>;
  ne?: InputMaybe<Scalars["String"]>;
  notContains?: InputMaybe<Scalars["String"]>;
};

export enum ThumbnailConversionStatus {
  /**
   * ERROR = there was an error while generating the thumbnail image
   * Conversion of images with ERROR status will be retried.
   */
  ERROR = "ERROR",
  /** IN_PROGRESS = the image data has been sent to the thumbnail generation service */
  IN_PROGRESS = "IN_PROGRESS",
  /** PENDING = no action has been taken to generate image thumbnail */
  PENDING = "PENDING",
  /** QUEUED = the image has been queued for processing */
  QUEUED = "QUEUED",
  /** SUCCESS = thumbnail image was successfully generated */
  SUCCESS = "SUCCESS",
}

export type UpdateBoardImageResponse = {
  __typename?: "UpdateBoardImageResponse";
  boardImages?: Maybe<Array<Maybe<BoardImage>>>;
  currentVersion?: Maybe<Scalars["Int"]>;
};

export type UpdateBoardPanoramaResponse = {
  __typename?: "UpdateBoardPanoramaResponse";
  boardPanorama?: Maybe<BoardPanorama>;
  currentVersion?: Maybe<Scalars["Int"]>;
};

export type UpdateBoardResponse = {
  __typename?: "UpdateBoardResponse";
  board?: Maybe<Board>;
  currentVersion?: Maybe<Scalars["Int"]>;
};

export type UpdateBoardThreedeeResponse = {
  __typename?: "UpdateBoardThreedeeResponse";
  boardThreedee?: Maybe<BoardThreedee>;
  currentVersion?: Maybe<Scalars["Int"]>;
};

export type UpdateBoardVideoResponse = {
  __typename?: "UpdateBoardVideoResponse";
  boardVideo?: Maybe<BoardVideo>;
  currentVersion?: Maybe<Scalars["Int"]>;
};

export type User = {
  __typename?: "User";
  createdAt?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  lastName?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Float"]>;
};

export type MuteLockResponse = {
  __typename?: "muteLockResponse";
  error?: Maybe<Scalars["String"]>;
  meeting?: Maybe<Meeting>;
};

export type VideoOffLockResponse = {
  __typename?: "videoOffLockResponse";
  error?: Maybe<Scalars["String"]>;
  meeting?: Maybe<Meeting>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ArtObject: ResolverTypeWrapper<ArtObject>;
  Attendee: ResolverTypeWrapper<Attendee>;
  Board: ResolverTypeWrapper<Board>;
  BoardImage: ResolverTypeWrapper<BoardImage>;
  BoardPanorama: ResolverTypeWrapper<BoardPanorama>;
  BoardPanoramaReplay: ResolverTypeWrapper<BoardPanoramaReplay>;
  BoardThreedee: ResolverTypeWrapper<BoardThreedee>;
  BoardType: BoardType;
  BoardVideo: ResolverTypeWrapper<BoardVideo>;
  Boolean: ResolverTypeWrapper<Scalars["Boolean"]>;
  ChapterPreviewType: ChapterPreviewType;
  Course: ResolverTypeWrapper<Course>;
  CreateAttendeeInput: CreateAttendeeInput;
  CreateBoardImage: CreateBoardImage;
  CreateBoardInput: CreateBoardInput;
  CreateBoardPanorama: CreateBoardPanorama;
  CreateBoardThreedee: CreateBoardThreedee;
  CreateBoardVideoInput: CreateBoardVideoInput;
  CreateCourseInput: CreateCourseInput;
  CreateDemoMeetingInput: CreateDemoMeetingInput;
  CreateDemoMeetingResponse: ResolverTypeWrapper<CreateDemoMeetingResponse>;
  CreateImageUploadInput: CreateImageUploadInput;
  CreateMeetingInput: CreateMeetingInput;
  CreatePanorama: CreatePanorama;
  CreateUserInput: CreateUserInput;
  CreateUserResponse: ResolverTypeWrapper<CreateUserResponse>;
  DateCourseInput: DateCourseInput;
  DeleteBoardInput: DeleteBoardInput;
  DeleteChimeAttendeeResponse: ResolverTypeWrapper<DeleteChimeAttendeeResponse>;
  DeleteMeetingResponse: ResolverTypeWrapper<DeleteMeetingResponse>;
  DeletedBoard: ResolverTypeWrapper<DeletedBoard>;
  DeletedBoardImage: ResolverTypeWrapper<DeletedBoardImage>;
  DeletedCourse: ResolverTypeWrapper<DeletedCourse>;
  DeviceInfo: ResolverTypeWrapper<DeviceInfo>;
  DeviceInfoInput: DeviceInfoInput;
  DuplicateBoardInput: DuplicateBoardInput;
  DuplicateCourseInput: DuplicateCourseInput;
  DuplicateMeetingInput: DuplicateMeetingInput;
  EditBoardInput: EditBoardInput;
  EditCourseInput: EditCourseInput;
  EndMeetingInput: EndMeetingInput;
  EndMeetingResponse: ResolverTypeWrapper<EndMeetingResponse>;
  FeatureFlag: ResolverTypeWrapper<FeatureFlag>;
  FeatureFlagType: FeatureFlagType;
  Float: ResolverTypeWrapper<Scalars["Float"]>;
  GenerateVTTStatus: GenerateVttStatus;
  IconColor: IconColor;
  IiiFConversionStatus: IiiFConversionStatus;
  IiifConversionReqBody: ResolverTypeWrapper<IiifConversionReqBody>;
  IiifConversionResponse: ResolverTypeWrapper<IiifConversionResponse>;
  ImageUpload: ResolverTypeWrapper<ImageUpload>;
  Int: ResolverTypeWrapper<Scalars["Int"]>;
  JSON: ResolverTypeWrapper<Scalars["JSON"]>;
  Job: ResolverTypeWrapper<Job>;
  JobName: JobName;
  JobResponse: ResolverTypeWrapper<JobResponse>;
  LoginInput: LoginInput;
  LoginResponse: ResolverTypeWrapper<LoginResponse>;
  LogoutInput: LogoutInput;
  LogoutResponse: ResolverTypeWrapper<LogoutResponse>;
  MediaPipeline: ResolverTypeWrapper<MediaPipeline>;
  Meeting: ResolverTypeWrapper<Meeting>;
  MeetingError: ResolverTypeWrapper<MeetingError>;
  MeetingErrorCodes: MeetingErrorCodes;
  MetaDataPermissions: MetaDataPermissions;
  Mutation: ResolverTypeWrapper<{}>;
  Order: Order;
  Paginate: Paginate;
  Panorama: ResolverTypeWrapper<Panorama>;
  PanoramaCollection: PanoramaCollection;
  PermissionLevels: PermissionLevels;
  PresentationVersion: ResolverTypeWrapper<PresentationVersion>;
  ProcessRecordingReqBody: ResolverTypeWrapper<ProcessRecordingReqBody>;
  ProcessRecordingStatus: ProcessRecordingStatus;
  Query: ResolverTypeWrapper<{}>;
  RecordMeetingResponse: ResolverTypeWrapper<RecordMeetingResponse>;
  RecordMeetingStatus: RecordMeetingStatus;
  ReplayBoard: ResolverTypeWrapper<ReplayBoard>;
  ReplayBoardImage: ResolverTypeWrapper<ReplayBoardImage>;
  SessionCookie: ResolverTypeWrapper<SessionCookie>;
  SessionObject: ResolverTypeWrapper<SessionObject>;
  SetReplayRecordingPipelineInput: SetReplayRecordingPipelineInput;
  SortBy: SortBy;
  StartAndJoinMeetingInput: StartAndJoinMeetingInput;
  StartMeetingResponse: ResolverTypeWrapper<StartMeetingResponse>;
  String: ResolverTypeWrapper<Scalars["String"]>;
  TableStringFilterInput: TableStringFilterInput;
  ThumbnailConversionStatus: ThumbnailConversionStatus;
  UpdateBoardImageResponse: ResolverTypeWrapper<UpdateBoardImageResponse>;
  UpdateBoardPanoramaResponse: ResolverTypeWrapper<UpdateBoardPanoramaResponse>;
  UpdateBoardResponse: ResolverTypeWrapper<UpdateBoardResponse>;
  UpdateBoardThreedeeResponse: ResolverTypeWrapper<UpdateBoardThreedeeResponse>;
  UpdateBoardVideoResponse: ResolverTypeWrapper<UpdateBoardVideoResponse>;
  User: ResolverTypeWrapper<User>;
  muteLockResponse: ResolverTypeWrapper<MuteLockResponse>;
  videoOffLockResponse: ResolverTypeWrapper<VideoOffLockResponse>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ArtObject: ArtObject;
  Attendee: Attendee;
  Board: Board;
  BoardImage: BoardImage;
  BoardPanorama: BoardPanorama;
  BoardPanoramaReplay: BoardPanoramaReplay;
  BoardThreedee: BoardThreedee;
  BoardVideo: BoardVideo;
  Boolean: Scalars["Boolean"];
  Course: Course;
  CreateAttendeeInput: CreateAttendeeInput;
  CreateBoardImage: CreateBoardImage;
  CreateBoardInput: CreateBoardInput;
  CreateBoardPanorama: CreateBoardPanorama;
  CreateBoardThreedee: CreateBoardThreedee;
  CreateBoardVideoInput: CreateBoardVideoInput;
  CreateCourseInput: CreateCourseInput;
  CreateDemoMeetingInput: CreateDemoMeetingInput;
  CreateDemoMeetingResponse: CreateDemoMeetingResponse;
  CreateImageUploadInput: CreateImageUploadInput;
  CreateMeetingInput: CreateMeetingInput;
  CreatePanorama: CreatePanorama;
  CreateUserInput: CreateUserInput;
  CreateUserResponse: CreateUserResponse;
  DateCourseInput: DateCourseInput;
  DeleteBoardInput: DeleteBoardInput;
  DeleteChimeAttendeeResponse: DeleteChimeAttendeeResponse;
  DeleteMeetingResponse: DeleteMeetingResponse;
  DeletedBoard: DeletedBoard;
  DeletedBoardImage: DeletedBoardImage;
  DeletedCourse: DeletedCourse;
  DeviceInfo: DeviceInfo;
  DeviceInfoInput: DeviceInfoInput;
  DuplicateBoardInput: DuplicateBoardInput;
  DuplicateCourseInput: DuplicateCourseInput;
  DuplicateMeetingInput: DuplicateMeetingInput;
  EditBoardInput: EditBoardInput;
  EditCourseInput: EditCourseInput;
  EndMeetingInput: EndMeetingInput;
  EndMeetingResponse: EndMeetingResponse;
  FeatureFlag: FeatureFlag;
  Float: Scalars["Float"];
  IiifConversionReqBody: IiifConversionReqBody;
  IiifConversionResponse: IiifConversionResponse;
  ImageUpload: ImageUpload;
  Int: Scalars["Int"];
  JSON: Scalars["JSON"];
  Job: Job;
  JobResponse: JobResponse;
  LoginInput: LoginInput;
  LoginResponse: LoginResponse;
  LogoutInput: LogoutInput;
  LogoutResponse: LogoutResponse;
  MediaPipeline: MediaPipeline;
  Meeting: Meeting;
  MeetingError: MeetingError;
  Mutation: {};
  Paginate: Paginate;
  Panorama: Panorama;
  PresentationVersion: PresentationVersion;
  ProcessRecordingReqBody: ProcessRecordingReqBody;
  Query: {};
  RecordMeetingResponse: RecordMeetingResponse;
  ReplayBoard: ReplayBoard;
  ReplayBoardImage: ReplayBoardImage;
  SessionCookie: SessionCookie;
  SessionObject: SessionObject;
  SetReplayRecordingPipelineInput: SetReplayRecordingPipelineInput;
  SortBy: SortBy;
  StartAndJoinMeetingInput: StartAndJoinMeetingInput;
  StartMeetingResponse: StartMeetingResponse;
  String: Scalars["String"];
  TableStringFilterInput: TableStringFilterInput;
  UpdateBoardImageResponse: UpdateBoardImageResponse;
  UpdateBoardPanoramaResponse: UpdateBoardPanoramaResponse;
  UpdateBoardResponse: UpdateBoardResponse;
  UpdateBoardThreedeeResponse: UpdateBoardThreedeeResponse;
  UpdateBoardVideoResponse: UpdateBoardVideoResponse;
  User: User;
  muteLockResponse: MuteLockResponse;
  videoOffLockResponse: VideoOffLockResponse;
};

export type ArtObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["ArtObject"] = ResolversParentTypes["ArtObject"]
> = {
  artistPrefix?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  artistSuffix?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  bibliography?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  birthDate?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  creditLine?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  culture?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  curatorialApproval?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deathDate?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  dimensions?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  displayDate?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  ensembleIndex?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  exhHistory?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  imageOriginalSecret?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  imageSecret?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  invno?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  longDescription?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  medium?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  objRightsTypeId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  onview?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  people?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  publishedProvenance?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  shortDescription?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  title?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Attendee"] = ResolversParentTypes["Attendee"]
> = {
  AttendeeId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  ExternalUserId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  JoinToken?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  deviceInfo?: Resolver<
    Maybe<ResolversTypes["DeviceInfo"]>,
    ParentType,
    ContextType
  >;
  firstName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  iconColor?: Resolver<
    Maybe<ResolversTypes["IconColor"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  meetingRole?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  softBanned?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Board"] = ResolversParentTypes["Board"]
> = {
  boardImages?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["BoardImage"]>>>,
    ParentType,
    ContextType
  >;
  boardPanorama?: Resolver<
    Maybe<ResolversTypes["BoardPanorama"]>,
    ParentType,
    ContextType
  >;
  boardThreedee?: Resolver<
    Maybe<ResolversTypes["BoardThreedee"]>,
    ParentType,
    ContextType
  >;
  boardVideo?: Resolver<
    Maybe<ResolversTypes["BoardVideo"]>,
    ParentType,
    ContextType
  >;
  caption?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes["BoardType"]>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoardImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["BoardImage"] = ResolversParentTypes["BoardImage"]
> = {
  board?: Resolver<Maybe<ResolversTypes["Board"]>, ParentType, ContextType>;
  boardId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  imageUpload?: Resolver<
    Maybe<ResolversTypes["ImageUpload"]>,
    ParentType,
    ContextType
  >;
  imageUploadId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  tileSource?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  width?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  x?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoardPanoramaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["BoardPanorama"] = ResolversParentTypes["BoardPanorama"]
> = {
  board?: Resolver<Maybe<ResolversTypes["Board"]>, ParentType, ContextType>;
  boardId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  panorama?: Resolver<
    Maybe<ResolversTypes["Panorama"]>,
    ParentType,
    ContextType
  >;
  panoramaId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoardPanoramaReplayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["BoardPanoramaReplay"] = ResolversParentTypes["BoardPanoramaReplay"]
> = {
  Panorama?: Resolver<
    Maybe<ResolversTypes["Panorama"]>,
    ParentType,
    ContextType
  >;
  board?: Resolver<Maybe<ResolversTypes["Board"]>, ParentType, ContextType>;
  boardId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  panoramaId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoardThreedeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["BoardThreedee"] = ResolversParentTypes["BoardThreedee"]
> = {
  board?: Resolver<Maybe<ResolversTypes["Board"]>, ParentType, ContextType>;
  boardId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  scale?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  sourceUrl?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoardVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["BoardVideo"] = ResolversParentTypes["BoardVideo"]
> = {
  board?: Resolver<Maybe<ResolversTypes["Board"]>, ParentType, ContextType>;
  boardId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  sourceUrl?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Course"] = ResolversParentTypes["Course"]
> = {
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  meetings?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Meeting"]>>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDemoMeetingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["CreateDemoMeetingResponse"] = ResolversParentTypes["CreateDemoMeetingResponse"]
> = {
  attendee?: Resolver<
    Maybe<ResolversTypes["Attendee"]>,
    ParentType,
    ContextType
  >;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["CreateUserResponse"] = ResolversParentTypes["CreateUserResponse"]
> = {
  createdAt?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteChimeAttendeeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["DeleteChimeAttendeeResponse"] = ResolversParentTypes["DeleteChimeAttendeeResponse"]
> = {
  error?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteMeetingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["DeleteMeetingResponse"] = ResolversParentTypes["DeleteMeetingResponse"]
> = {
  course?: Resolver<Maybe<ResolversTypes["Course"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedBoardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["DeletedBoard"] = ResolversParentTypes["DeletedBoard"]
> = {
  boardError?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  boardImagesError?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  boardPanoramaError?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  boardVideoError?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  currentVersion?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedBoardImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["DeletedBoardImage"] = ResolversParentTypes["DeletedBoardImage"]
> = {
  currentVersion?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedCourseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["DeletedCourse"] = ResolversParentTypes["DeletedCourse"]
> = {
  id?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["DeviceInfo"] = ResolversParentTypes["DeviceInfo"]
> = {
  browserName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  browserVersion?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deviceModel?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deviceType?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deviceVendor?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  engine?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  os?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  osVersion?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  userAgent?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EndMeetingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["EndMeetingResponse"] = ResolversParentTypes["EndMeetingResponse"]
> = {
  error?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureFlagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["FeatureFlag"] = ResolversParentTypes["FeatureFlag"]
> = {
  active?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IiifConversionReqBodyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["IiifConversionReqBody"] = ResolversParentTypes["IiifConversionReqBody"]
> = {
  outputBucket?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  outputName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  sourceURL?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IiifConversionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["IiifConversionResponse"] = ResolversParentTypes["IiifConversionResponse"]
> = {
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  statusCode?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tileOutputPath?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageUploadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["ImageUpload"] = ResolversParentTypes["ImageUpload"]
> = {
  birthDate?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  classification?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  creditLine?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  culture?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  deathDate?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  dimensions?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  displayDate?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  iiifConversionStatus?: Resolver<
    Maybe<ResolversTypes["IiiFConversionStatus"]>,
    ParentType,
    ContextType
  >;
  medium?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  people?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  permissions?: Resolver<
    Maybe<ResolversTypes["MetaDataPermissions"]>,
    ParentType,
    ContextType
  >;
  thumbnailConversionStatus?: Resolver<
    Maybe<ResolversTypes["ThumbnailConversionStatus"]>,
    ParentType,
    ContextType
  >;
  thumbnailUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  tileSource?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  title?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  uploadUrl?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  visualDescription?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["JSON"], any> {
  name: "JSON";
}

export type JobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Job"] = ResolversParentTypes["Job"]
> = {
  force?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes["JobName"], ParentType, ContextType>;
  skipConcat?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  videoLength?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["JobResponse"] = ResolversParentTypes["JobResponse"]
> = {
  message?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  statusCode?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["LoginResponse"] = ResolversParentTypes["LoginResponse"]
> = {
  firstName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  isAuthenticated?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  lastName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  permissions?: Resolver<
    Maybe<ResolversTypes["PermissionLevels"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogoutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["LogoutResponse"] = ResolversParentTypes["LogoutResponse"]
> = {
  firstName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  isAuthenticated?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  lastName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaPipelineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["MediaPipeline"] = ResolversParentTypes["MediaPipeline"]
> = {
  arn?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  attendeeLogStatus?: Resolver<
    ResolversTypes["GenerateVTTStatus"],
    ParentType,
    ContextType
  >;
  awsConcatPipelineId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  awsPipelineId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  chatLogStatus?: Resolver<
    ResolversTypes["GenerateVTTStatus"],
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  folderName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  generateCaptionsStatus?: Resolver<
    ResolversTypes["GenerateVTTStatus"],
    ParentType,
    ContextType
  >;
  generateChaptersStatus?: Resolver<
    ResolversTypes["GenerateVTTStatus"],
    ParentType,
    ContextType
  >;
  generateChatStatus?: Resolver<
    ResolversTypes["GenerateVTTStatus"],
    ParentType,
    ContextType
  >;
  generateEventsStatus?: Resolver<
    ResolversTypes["GenerateVTTStatus"],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  meetingId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  presentationLogStatus?: Resolver<
    ResolversTypes["GenerateVTTStatus"],
    ParentType,
    ContextType
  >;
  processRecordingStatus?: Resolver<
    ResolversTypes["ProcessRecordingStatus"],
    ParentType,
    ContextType
  >;
  replayRecording?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  videoLength?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeetingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Meeting"] = ResolversParentTypes["Meeting"]
> = {
  AudioFallbackUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  AudioHostUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  EventIngestionUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  ExternalMeetingId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  MediaPipelines?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["MediaPipeline"]>>>,
    ParentType,
    ContextType
  >;
  MediaRegion?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  MeetingArn?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  MeetingId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  ScreenDataUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  ScreenSharingUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  ScreenViewingUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  SignalingUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  TurnControlUrl?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  attendees?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Attendee"]>>>,
    ParentType,
    ContextType
  >;
  boards?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Board"]>>>,
    ParentType,
    ContextType
  >;
  chat?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  course?: Resolver<Maybe<ResolversTypes["Course"]>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  currentlyRecording?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  deletedAt?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  instructorAV?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  lobbyActive?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  mediaPipelines?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["MediaPipeline"]>>>,
    ParentType,
    ContextType
  >;
  meetingTime?: Resolver<
    Maybe<ResolversTypes["Float"]>,
    ParentType,
    ContextType
  >;
  muteLock?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  presentationVersions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["PresentationVersion"]>>>,
    ParentType,
    ContextType
  >;
  studentAV?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  videoOffLock?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeetingErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["MeetingError"] = ResolversParentTypes["MeetingError"]
> = {
  code?: Resolver<ResolversTypes["MeetingErrorCodes"], ParentType, ContextType>;
  message?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Mutation"] = ResolversParentTypes["Mutation"]
> = {
  activateMeeting?: Resolver<
    Maybe<ResolversTypes["RecordMeetingResponse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationActivateMeetingArgs, "id">
  >;
  attendeeLeaveMeeting?: Resolver<
    Maybe<ResolversTypes["DeleteChimeAttendeeResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationAttendeeLeaveMeetingArgs>
  >;
  beginRecordMeeting?: Resolver<
    Maybe<ResolversTypes["RecordMeetingResponse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationBeginRecordMeetingArgs, "id">
  >;
  bulkCreateImageUploads?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ImageUpload"]>>>,
    ParentType,
    ContextType,
    Partial<MutationBulkCreateImageUploadsArgs>
  >;
  createAndStartMeeting?: Resolver<
    Maybe<ResolversTypes["Meeting"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateAndStartMeetingArgs>
  >;
  createAttendee?: Resolver<
    Maybe<ResolversTypes["Attendee"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateAttendeeArgs>
  >;
  createBoard?: Resolver<
    Maybe<ResolversTypes["UpdateBoardResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateBoardArgs>
  >;
  createBoardImages?: Resolver<
    Maybe<ResolversTypes["UpdateBoardImageResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateBoardImagesArgs>
  >;
  createBoardPanorama?: Resolver<
    Maybe<ResolversTypes["UpdateBoardPanoramaResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateBoardPanoramaArgs>
  >;
  createBoardThreedee?: Resolver<
    Maybe<ResolversTypes["UpdateBoardThreedeeResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateBoardThreedeeArgs>
  >;
  createBoardVideo?: Resolver<
    Maybe<ResolversTypes["UpdateBoardVideoResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateBoardVideoArgs>
  >;
  createCourse?: Resolver<
    Maybe<ResolversTypes["Course"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateCourseArgs>
  >;
  createDemoMeeting?: Resolver<
    Maybe<ResolversTypes["CreateDemoMeetingResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateDemoMeetingArgs>
  >;
  createMeeting?: Resolver<
    Maybe<ResolversTypes["Meeting"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateMeetingArgs>
  >;
  createPanorama?: Resolver<
    Maybe<ResolversTypes["Panorama"]>,
    ParentType,
    ContextType,
    Partial<MutationCreatePanoramaArgs>
  >;
  createUser?: Resolver<
    Maybe<ResolversTypes["CreateUserResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationCreateUserArgs>
  >;
  deleteBoard?: Resolver<
    Maybe<ResolversTypes["DeletedBoard"]>,
    ParentType,
    ContextType,
    Partial<MutationDeleteBoardArgs>
  >;
  deleteBoardImage?: Resolver<
    Maybe<ResolversTypes["DeletedBoardImage"]>,
    ParentType,
    ContextType,
    Partial<MutationDeleteBoardImageArgs>
  >;
  deleteCourse?: Resolver<
    Maybe<ResolversTypes["DeletedCourse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCourseArgs, "id">
  >;
  deleteMeeting?: Resolver<
    Maybe<ResolversTypes["Course"]>,
    ParentType,
    ContextType,
    Partial<MutationDeleteMeetingArgs>
  >;
  duplicateBoard?: Resolver<
    Maybe<ResolversTypes["UpdateBoardResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationDuplicateBoardArgs>
  >;
  duplicateCourse?: Resolver<
    Maybe<ResolversTypes["Course"]>,
    ParentType,
    ContextType,
    Partial<MutationDuplicateCourseArgs>
  >;
  duplicateMeeting?: Resolver<
    Maybe<ResolversTypes["Course"]>,
    ParentType,
    ContextType,
    Partial<MutationDuplicateMeetingArgs>
  >;
  editBoard?: Resolver<
    Maybe<ResolversTypes["UpdateBoardResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationEditBoardArgs>
  >;
  editCourse?: Resolver<
    Maybe<ResolversTypes["Course"]>,
    ParentType,
    ContextType,
    Partial<MutationEditCourseArgs>
  >;
  editMeeting?: Resolver<
    Maybe<ResolversTypes["Meeting"]>,
    ParentType,
    ContextType,
    Partial<MutationEditMeetingArgs>
  >;
  endMeeting?: Resolver<
    Maybe<ResolversTypes["EndMeetingResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationEndMeetingArgs>
  >;
  login?: Resolver<
    Maybe<ResolversTypes["LoginResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationLoginArgs>
  >;
  logout?: Resolver<
    Maybe<ResolversTypes["LogoutResponse"]>,
    ParentType,
    ContextType,
    Partial<MutationLogoutArgs>
  >;
  replaceMeetingRecording?: Resolver<
    Maybe<ResolversTypes["MediaPipeline"]>,
    ParentType,
    ContextType,
    RequireFields<MutationReplaceMeetingRecordingArgs, "id">
  >;
  root?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  setReplayRecordingPipeline?: Resolver<
    Maybe<ResolversTypes["Meeting"]>,
    ParentType,
    ContextType,
    Partial<MutationSetReplayRecordingPipelineArgs>
  >;
  softBanAttendee?: Resolver<
    Maybe<ResolversTypes["Attendee"]>,
    ParentType,
    ContextType,
    RequireFields<MutationSoftBanAttendeeArgs, "AttendeeId" | "softBan">
  >;
  startAndJoinMeeting?: Resolver<
    Maybe<ResolversTypes["StartMeetingResponse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationStartAndJoinMeetingArgs, "id" | "input">
  >;
  startBulkGenerateVttFiles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["MediaPipeline"]>>>,
    ParentType,
    ContextType,
    RequireFields<MutationStartBulkGenerateVttFilesArgs, "job">
  >;
  startBulkIiiFConversion?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ImageUpload"]>>>,
    ParentType,
    ContextType,
    Partial<MutationStartBulkIiiFConversionArgs>
  >;
  startBulkMediaPipelineConcat?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["RecordMeetingResponse"]>>>,
    ParentType,
    ContextType,
    RequireFields<MutationStartBulkMediaPipelineConcatArgs, "paginate">
  >;
  startBulkThumbnailConversion?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ImageUpload"]>>>,
    ParentType,
    ContextType,
    Partial<MutationStartBulkThumbnailConversionArgs>
  >;
  startFhdMeeting?: Resolver<
    Maybe<ResolversTypes["StartMeetingResponse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationStartFhdMeetingArgs, "id" | "input">
  >;
  startMediaPipelineConcat?: Resolver<
    Maybe<ResolversTypes["RecordMeetingResponse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationStartMediaPipelineConcatArgs, "id">
  >;
  toggleMuteLock?: Resolver<
    Maybe<ResolversTypes["muteLockResponse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationToggleMuteLockArgs, "id">
  >;
  toggleVideoOffLock?: Resolver<
    Maybe<ResolversTypes["videoOffLockResponse"]>,
    ParentType,
    ContextType,
    RequireFields<MutationToggleVideoOffLockArgs, "id">
  >;
};

export type PanoramaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Panorama"] = ResolversParentTypes["Panorama"]
> = {
  back?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  bottom?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  collection?: Resolver<
    Maybe<ResolversTypes["PanoramaCollection"]>,
    ParentType,
    ContextType
  >;
  front?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  left?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  right?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  sourceUrl?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  top?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PresentationVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["PresentationVersion"] = ResolversParentTypes["PresentationVersion"]
> = {
  content?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ReplayBoard"]>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  meetingId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  versionNumber?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessRecordingReqBodyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["ProcessRecordingReqBody"] = ResolversParentTypes["ProcessRecordingReqBody"]
> = {
  attendeeIds?: Resolver<
    Array<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  concatFolder?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  concatRecordingBucket?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType
  >;
  local?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  mediaPipelineId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  queue?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  recordingAccessPoint?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType
  >;
  recordingFolder?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  skipConcat?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  taskId?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Query"] = ResolversParentTypes["Query"]
> = {
  checkIsActiveFeature?: Resolver<
    Maybe<ResolversTypes["FeatureFlag"]>,
    ParentType,
    ContextType,
    Partial<QueryCheckIsActiveFeatureArgs>
  >;
  getAllActiveFeatureFlags?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["FeatureFlag"]>>>,
    ParentType,
    ContextType
  >;
  getAllAttendees?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Attendee"]>>>,
    ParentType,
    ContextType
  >;
  getAllBoards?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Board"]>>>,
    ParentType,
    ContextType
  >;
  getAllCourses?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Course"]>>>,
    ParentType,
    ContextType
  >;
  getAllFeatureFlags?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["FeatureFlag"]>>>,
    ParentType,
    ContextType
  >;
  getAllImageUploads?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ImageUpload"]>>>,
    ParentType,
    ContextType
  >;
  getAllMeetings?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Meeting"]>>>,
    ParentType,
    ContextType
  >;
  getAllPanoramas?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Panorama"]>>>,
    ParentType,
    ContextType
  >;
  getAllUsers?: Resolver<
    Maybe<Array<ResolversTypes["User"]>>,
    ParentType,
    ContextType
  >;
  getAllVideos?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["BoardVideo"]>>>,
    ParentType,
    ContextType
  >;
  getAttendee?: Resolver<
    Maybe<ResolversTypes["Attendee"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAttendeeArgs, "id">
  >;
  getAttendeeByExternalId?: Resolver<
    Maybe<ResolversTypes["Attendee"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAttendeeByExternalIdArgs, "externalId">
  >;
  getAuthenticatedUser?: Resolver<
    Maybe<ResolversTypes["LoginResponse"]>,
    ParentType,
    ContextType
  >;
  getBoard?: Resolver<
    Maybe<ResolversTypes["Board"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetBoardArgs, "id">
  >;
  getBoardPanorama?: Resolver<
    Maybe<ResolversTypes["BoardPanorama"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetBoardPanoramaArgs, "id">
  >;
  getBoardVideo?: Resolver<
    Maybe<ResolversTypes["BoardVideo"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetBoardVideoArgs, "id">
  >;
  getCourse?: Resolver<
    Maybe<ResolversTypes["Course"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCourseArgs, "id">
  >;
  getCourseBySlug?: Resolver<
    Maybe<ResolversTypes["Course"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCourseBySlugArgs, "slug">
  >;
  getCourseMeetings?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Meeting"]>>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCourseMeetingsArgs, "id">
  >;
  getCoursesByMeetingDates?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Course"]>>>,
    ParentType,
    ContextType,
    Partial<QueryGetCoursesByMeetingDatesArgs>
  >;
  getImageUploadByUserId?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ImageUpload"]>>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetImageUploadByUserIdArgs, "userId">
  >;
  getMeeting?: Resolver<
    Maybe<ResolversTypes["Meeting"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetMeetingArgs, "id">
  >;
  getMeetingBoards?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Board"]>>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetMeetingBoardsArgs, "meetingId">
  >;
  getMeetingBoardsByMeetingTitle?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["Board"]>>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetMeetingBoardsByMeetingTitleArgs, "meetingTitle">
  >;
  getMeetingByTitle?: Resolver<
    Maybe<ResolversTypes["Meeting"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetMeetingByTitleArgs, "title">
  >;
  getPanorama?: Resolver<
    Maybe<ResolversTypes["Panorama"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPanoramaArgs, "id">
  >;
  getPresentationVersion?: Resolver<
    Maybe<ResolversTypes["PresentationVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPresentationVersionArgs, "id">
  >;
  getPresentationVersionsForMeeting?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["PresentationVersion"]>>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPresentationVersionsForMeetingArgs, "meetingId">
  >;
  getReplayMeeting?: Resolver<
    Maybe<ResolversTypes["Meeting"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetReplayMeetingArgs, "title">
  >;
  getUser?: Resolver<
    Maybe<ResolversTypes["User"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetUserArgs, "id">
  >;
  root?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  searchArtObjects?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ArtObject"]>>>,
    ParentType,
    ContextType,
    Partial<QuerySearchArtObjectsArgs>
  >;
  searchImageUploads?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ImageUpload"]>>>,
    ParentType,
    ContextType,
    Partial<QuerySearchImageUploadsArgs>
  >;
};

export type RecordMeetingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["RecordMeetingResponse"] = ResolversParentTypes["RecordMeetingResponse"]
> = {
  error?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  initVersionNumber?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  mediaPipeline?: Resolver<
    Maybe<ResolversTypes["MediaPipeline"]>,
    ParentType,
    ContextType
  >;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  status?: Resolver<
    Maybe<ResolversTypes["RecordMeetingStatus"]>,
    ParentType,
    ContextType
  >;
  success?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReplayBoardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["ReplayBoard"] = ResolversParentTypes["ReplayBoard"]
> = {
  BoardImages?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["ReplayBoardImage"]>>>,
    ParentType,
    ContextType
  >;
  BoardPanorama?: Resolver<
    Maybe<ResolversTypes["BoardPanoramaReplay"]>,
    ParentType,
    ContextType
  >;
  BoardThreedee?: Resolver<
    Maybe<ResolversTypes["BoardThreedee"]>,
    ParentType,
    ContextType
  >;
  BoardVideo?: Resolver<
    Maybe<ResolversTypes["BoardVideo"]>,
    ParentType,
    ContextType
  >;
  caption?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes["BoardType"]>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReplayBoardImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["ReplayBoardImage"] = ResolversParentTypes["ReplayBoardImage"]
> = {
  ImageUpload?: Resolver<
    Maybe<ResolversTypes["ImageUpload"]>,
    ParentType,
    ContextType
  >;
  board?: Resolver<Maybe<ResolversTypes["Board"]>, ParentType, ContextType>;
  boardId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  imageUploadId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  tileSource?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  width?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  x?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionCookieResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["SessionCookie"] = ResolversParentTypes["SessionCookie"]
> = {
  maxAge?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["SessionObject"] = ResolversParentTypes["SessionObject"]
> = {
  attendeeId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  cookie?: Resolver<
    Maybe<ResolversTypes["SessionCookie"]>,
    ParentType,
    ContextType
  >;
  initialized?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  isAuthenticated?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  permissions?: Resolver<
    Maybe<ResolversTypes["PermissionLevels"]>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartMeetingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["StartMeetingResponse"] = ResolversParentTypes["StartMeetingResponse"]
> = {
  attendee?: Resolver<
    Maybe<ResolversTypes["Attendee"]>,
    ParentType,
    ContextType
  >;
  error?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  initVersionNumber?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  recordMeetingStatus?: Resolver<
    Maybe<ResolversTypes["RecordMeetingStatus"]>,
    ParentType,
    ContextType
  >;
  startMeetingSuccess?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBoardImageResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateBoardImageResponse"] = ResolversParentTypes["UpdateBoardImageResponse"]
> = {
  boardImages?: Resolver<
    Maybe<Array<Maybe<ResolversTypes["BoardImage"]>>>,
    ParentType,
    ContextType
  >;
  currentVersion?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBoardPanoramaResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateBoardPanoramaResponse"] = ResolversParentTypes["UpdateBoardPanoramaResponse"]
> = {
  boardPanorama?: Resolver<
    Maybe<ResolversTypes["BoardPanorama"]>,
    ParentType,
    ContextType
  >;
  currentVersion?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBoardResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateBoardResponse"] = ResolversParentTypes["UpdateBoardResponse"]
> = {
  board?: Resolver<Maybe<ResolversTypes["Board"]>, ParentType, ContextType>;
  currentVersion?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBoardThreedeeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateBoardThreedeeResponse"] = ResolversParentTypes["UpdateBoardThreedeeResponse"]
> = {
  boardThreedee?: Resolver<
    Maybe<ResolversTypes["BoardThreedee"]>,
    ParentType,
    ContextType
  >;
  currentVersion?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBoardVideoResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["UpdateBoardVideoResponse"] = ResolversParentTypes["UpdateBoardVideoResponse"]
> = {
  boardVideo?: Resolver<
    Maybe<ResolversTypes["BoardVideo"]>,
    ParentType,
    ContextType
  >;
  currentVersion?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["User"] = ResolversParentTypes["User"]
> = {
  createdAt?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  firstName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MuteLockResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["muteLockResponse"] = ResolversParentTypes["muteLockResponse"]
> = {
  error?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoOffLockResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["videoOffLockResponse"] = ResolversParentTypes["videoOffLockResponse"]
> = {
  error?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  meeting?: Resolver<Maybe<ResolversTypes["Meeting"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ArtObject?: ArtObjectResolvers<ContextType>;
  Attendee?: AttendeeResolvers<ContextType>;
  Board?: BoardResolvers<ContextType>;
  BoardImage?: BoardImageResolvers<ContextType>;
  BoardPanorama?: BoardPanoramaResolvers<ContextType>;
  BoardPanoramaReplay?: BoardPanoramaReplayResolvers<ContextType>;
  BoardThreedee?: BoardThreedeeResolvers<ContextType>;
  BoardVideo?: BoardVideoResolvers<ContextType>;
  Course?: CourseResolvers<ContextType>;
  CreateDemoMeetingResponse?: CreateDemoMeetingResponseResolvers<ContextType>;
  CreateUserResponse?: CreateUserResponseResolvers<ContextType>;
  DeleteChimeAttendeeResponse?: DeleteChimeAttendeeResponseResolvers<ContextType>;
  DeleteMeetingResponse?: DeleteMeetingResponseResolvers<ContextType>;
  DeletedBoard?: DeletedBoardResolvers<ContextType>;
  DeletedBoardImage?: DeletedBoardImageResolvers<ContextType>;
  DeletedCourse?: DeletedCourseResolvers<ContextType>;
  DeviceInfo?: DeviceInfoResolvers<ContextType>;
  EndMeetingResponse?: EndMeetingResponseResolvers<ContextType>;
  FeatureFlag?: FeatureFlagResolvers<ContextType>;
  IiifConversionReqBody?: IiifConversionReqBodyResolvers<ContextType>;
  IiifConversionResponse?: IiifConversionResponseResolvers<ContextType>;
  ImageUpload?: ImageUploadResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Job?: JobResolvers<ContextType>;
  JobResponse?: JobResponseResolvers<ContextType>;
  LoginResponse?: LoginResponseResolvers<ContextType>;
  LogoutResponse?: LogoutResponseResolvers<ContextType>;
  MediaPipeline?: MediaPipelineResolvers<ContextType>;
  Meeting?: MeetingResolvers<ContextType>;
  MeetingError?: MeetingErrorResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Panorama?: PanoramaResolvers<ContextType>;
  PresentationVersion?: PresentationVersionResolvers<ContextType>;
  ProcessRecordingReqBody?: ProcessRecordingReqBodyResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RecordMeetingResponse?: RecordMeetingResponseResolvers<ContextType>;
  ReplayBoard?: ReplayBoardResolvers<ContextType>;
  ReplayBoardImage?: ReplayBoardImageResolvers<ContextType>;
  SessionCookie?: SessionCookieResolvers<ContextType>;
  SessionObject?: SessionObjectResolvers<ContextType>;
  StartMeetingResponse?: StartMeetingResponseResolvers<ContextType>;
  UpdateBoardImageResponse?: UpdateBoardImageResponseResolvers<ContextType>;
  UpdateBoardPanoramaResponse?: UpdateBoardPanoramaResponseResolvers<ContextType>;
  UpdateBoardResponse?: UpdateBoardResponseResolvers<ContextType>;
  UpdateBoardThreedeeResponse?: UpdateBoardThreedeeResponseResolvers<ContextType>;
  UpdateBoardVideoResponse?: UpdateBoardVideoResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  muteLockResponse?: MuteLockResponseResolvers<ContextType>;
  videoOffLockResponse?: VideoOffLockResponseResolvers<ContextType>;
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
