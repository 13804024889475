import { useMutation } from "@apollo/client";
import { SOFT_BAN_ATTENDEE } from "../graphql/mutations";
import { EventTypes } from "../providers/types";
import { useRoster } from "../providers/rosterProvider";
import { useAppContext } from "../contexts/appContext";
import { Mutation, MutationSoftBanAttendeeArgs } from "../types";
import { useDataEvents } from "../providers/dataEvents";

type SoftBanHook = {
  handleSoftBan: (attendeeId: string) => void;
  handleUndoSoftBan: (attendeeId: string) => void;
  loading: boolean;
};

export const useSoftBan = (onCompleteCallback?: Function): SoftBanHook => {
  const { state } = useAppContext();
  const { refreshAttendee } = useRoster();
  const { sendEvent } = useDataEvents();

  const [softBanAttendeeMutation, { loading }] = useMutation(
    SOFT_BAN_ATTENDEE,
    {
      onCompleted(data: Pick<Mutation, "softBanAttendee">) {
        const attendee = data.softBanAttendee;
        // send event to ban attendee
        sendEvent(
          {
            type: EventTypes.SOFT_BAN,
            leader: true,
            attendeeId: attendee.AttendeeId,
            softBanned: attendee.softBanned,
          },
          state
        );

        // Refresh the attendee in the roster from the db
        refreshAttendee(
          attendee.AttendeeId,
          attendee.ExternalUserId,
          attendee.softBanned
        );
        onCompleteCallback();
      },
      onError(error) {},
    }
  );

  const handleSoftBan = (attendeeId: string) => {
    const variables: MutationSoftBanAttendeeArgs = {
      AttendeeId: attendeeId,
      softBan: true,
    };
    softBanAttendeeMutation({ variables });
  };

  const handleUndoSoftBan = (attendeeId: string) => {
    const variables: MutationSoftBanAttendeeArgs = {
      AttendeeId: attendeeId,
      softBan: false,
    };
    softBanAttendeeMutation({ variables });
  };

  return { handleSoftBan, handleUndoSoftBan, loading };
};
