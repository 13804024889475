// Adapted from https://github.com/aws/amazon-chime-sdk-component-library-react/blob/fd5a38765899b24cfd29bff3695a79c8fd47c011/src/providers/RosterProvider/index.tsx#L21

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DefaultModality } from "amazon-chime-sdk-js";
import {
  RosterAttendeeType,
  RosterType,
  useAudioVideo,
  useRosterState,
} from "amazon-chime-sdk-component-library-react";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";

interface RosterContextValue {
  roster: RosterType;
  refreshAttendee: (
    chimeAttendeeId: string,
    externalUserId: string,
    softBanned: boolean
  ) => void;
}

const RosterContext = React.createContext<RosterContextValue | null>(null);

export const RosterProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const meetingManager = useMeetingWithRoster();
  const audioVideo = useAudioVideo();
  const rosterRef = useRef<RosterType>({});
  let { roster: chimeRoster } = useRosterState();
  const [roster, setRoster] = useState<RosterType>(chimeRoster);

  const rosterUpdate = useCallback(
    async (
      chimeAttendeeId: string,
      present: boolean,
      externalUserId?: string
    ): Promise<void> => {
      if (!present) {
        delete rosterRef.current[chimeAttendeeId];

        setRoster((currentRoster: RosterType) => {
          const { [chimeAttendeeId]: _, ...rest } = currentRoster;
          return { ...rest };
        });

        return;
      }

      const attendeeId = new DefaultModality(chimeAttendeeId).base();
      if (attendeeId !== chimeAttendeeId) {
        return;
      }

      const inRoster = rosterRef.current[chimeAttendeeId];
      if (inRoster) {
        return;
      }

      let attendee: RosterAttendeeType = { chimeAttendeeId };

      if (externalUserId) {
        attendee.externalUserId = externalUserId;
      }

      rosterRef.current[attendeeId] = attendee;

      // Update the roster first before waiting to fetch attendee info
      setRoster((oldRoster) => ({
        ...oldRoster,
        [attendeeId]: attendee,
      }));

      if (meetingManager.getAttendee) {
        const externalData = await meetingManager.getAttendee(
          attendeeId,
          externalUserId
        );

        // Make sure that the attendee is still on the roster
        if (!rosterRef.current[attendeeId]) {
          return;
        }
        attendee = { ...attendee, ...externalData };
        setRoster((oldRoster) => ({
          ...oldRoster,
          [attendeeId]: attendee,
        }));
      }
    },
    [meetingManager]
  );

  const refreshAttendee = useCallback(
    async (
      chimeAttendeeId: string,
      externalUserId: string,
      softBanned: boolean
    ) => {
      const attendeeId = new DefaultModality(chimeAttendeeId).base();
      if (attendeeId !== chimeAttendeeId) {
        return;
      }

      let attendee: RosterAttendeeType = { chimeAttendeeId };

      if (externalUserId) {
        attendee.externalUserId = externalUserId;
      }

      rosterRef.current[attendeeId] = attendee;

      // Update the roster first before waiting to fetch attendee info
      setRoster((oldRoster) => ({
        ...oldRoster,
        [attendeeId]: attendee,
      }));

      if (meetingManager.getAttendee) {
        const externalData = await meetingManager.getAttendee(
          attendeeId,
          externalUserId
        );

        // Make sure that the attendee is still on the roster
        if (!rosterRef.current[attendeeId]) {
          return;
        }
        attendee = { ...attendee, ...externalData };
        attendee["softBanned"] = softBanned;
        setRoster((oldRoster) => ({
          ...oldRoster,
          [attendeeId]: attendee,
        }));
      }
    },
    [meetingManager]
  );

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdate);

    return () => {
      setRoster({});
      rosterRef.current = {};
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdate);
    };
  }, [audioVideo, rosterUpdate]);

  const value = useMemo(
    () => ({
      roster,
      refreshAttendee,
    }),
    [roster, refreshAttendee]
  );

  return (
    <RosterContext.Provider value={value}>{children}</RosterContext.Provider>
  );
};

export function useRoster(): RosterContextValue {
  const state = useContext(RosterContext);

  if (!state) {
    throw new Error("userRoster must be used within RosterProvider");
  }

  return state;
}
