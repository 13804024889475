import React, { Fragment, MutableRefObject } from "react";
import { ReplayTools } from "../replay/replayTools";
import { useScreenContext } from "../../contexts/screenContext";
import { Help } from "../toolbars/help";
import classNames from "classnames";
import { ClosedCaptions } from "./closedCaptions";
import { CaptionType } from "../../providers/dataCaptions";
import { VideoReplayTimeAndState } from "../replay/replayTypes";
import { ChatReplayMessage } from "../../providers/replayDataMessages";
import Player from "video.js/dist/types/player";
import { CanvasDataEvent } from "../../providers/types";

type ReplayCanvasToolsProps = {
  activeChapter: number;
  chapters: VTTCue[];
  currentPartial: CaptionType;
  events: CanvasDataEvent[];
  handleSelectChapter: (timestamp: number, chapter: number) => void;
  maxTimeMs: number;
  messageCount: number;
  messages: ChatReplayMessage[];
  playerRef: MutableRefObject<Player>;
  replayCaptions: CaptionType[];
  replayExplore: boolean;
  setShowChapterList: (show: boolean) => void;
  setShowInstructions: (showInstructions: boolean) => void;
  setShowInstructors: (show: boolean) => void;
  showChapterList: boolean;
  showClosedCaptions: boolean;
  showInstructions: boolean;
  showInstructors: boolean;
  toggleClosedCaptions: () => void;
  toggleInstructions: () => void;
  toggleInstructors: () => void;
  updateSharedData: (replayVideoState: VideoReplayTimeAndState) => void;
  useReplayCaptions: boolean;
  useReplayChat: boolean;
};

export const ReplayCanvasTools: React.FC<ReplayCanvasToolsProps> = ({
  activeChapter,
  chapters,
  currentPartial,
  events,
  handleSelectChapter,
  maxTimeMs,
  messageCount,
  messages,
  playerRef,
  replayCaptions,
  replayExplore,
  setShowChapterList,
  setShowInstructions,
  setShowInstructors,
  showChapterList,
  showClosedCaptions,
  showInstructions,
  showInstructors,
  toggleClosedCaptions,
  toggleInstructions,
  toggleInstructors,
  updateSharedData,
  useReplayCaptions,
  useReplayChat,
}) => {
  const { useMobileTools, isLarge } = useScreenContext();

  return (
    <Fragment>
      {!useMobileTools && isLarge && (
        // Help for !isLarge is included in the ReplayTools component as part of the more actions menu
        <Help
          useMobileTools={useMobileTools}
          toggleInstructions={toggleInstructions}
          component="ICON_BUTTON"
        />
      )}

      {showClosedCaptions && (
        <ClosedCaptions
          className={classNames("closed-captions__replay", {
            "closed-captions__replay--mobile": useMobileTools,
          })}
          captions={replayCaptions}
          currentPartial={currentPartial}
        />
      )}

      <ReplayTools
        playerRef={playerRef}
        replayChat={useReplayChat}
        hasReplayCaptions={useReplayCaptions}
        chapters={chapters}
        activeChapter={activeChapter}
        messages={messages}
        replayExplore={replayExplore}
        toggleClosedCaptions={toggleClosedCaptions}
        showClosedCaptions={showClosedCaptions}
        lastEvent={
          events && events.length > 0 ? events[events?.length - 1] : undefined
        }
        showInstructions={showInstructions}
        setShowInstructions={setShowInstructions}
        events={events}
        setShowInstructors={setShowInstructors}
        handleSelectChapter={handleSelectChapter}
        showChapterList={showChapterList}
        setShowChapterList={setShowChapterList}
        showVideo={showInstructors}
        toggleShowVideo={toggleInstructors}
        maxTimeMs={maxTimeMs}
        sendVideoTimeAndState={updateSharedData}
        toggleInstructions={toggleInstructions}
        messageCount={messageCount}
      />
    </Fragment>
  );
};
