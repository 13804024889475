import React, { useState } from "react";
import { TextInput } from "../../components/inputs/textInput";
import { Button, PRIMARY, STANDARD } from "../../components/buttons/button";
import { LoginInput } from "../../types";
import { useAuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

export const BackDoor: React.FC = () => {
  const { handleLogin } = useAuthContext();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async () => {
    const input: LoginInput = {
      email: username,
      firstName: "VXP",
      lastName: "Test",
    };

    const resp = await handleLogin(input);
    consoleNonProd(resp);
    if (resp.isAuthenticated) {
      navigate(routes.admin);
    } else {
      navigate(routes.join);
    }
  };

  return (
    <div>
      <TextInput
        id="username"
        label="Username"
        name="username"
        initialValue={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={false}
        errorMessage=""
        hasError={false}
      />
      <Button
        btnType={PRIMARY}
        size={STANDARD}
        onClick={onSubmit}
        id="back-door-login"
      >
        Log in
      </Button>
    </div>
  );
};
