import { useAppContext } from "../contexts/appContext";
import { Attendee, BoardType } from "../types";
import { CanvasDataEvent, EventPayload, EventTypes } from "../providers/types";
import {
  Action,
  ActionType,
  HandleIncomingEventAction,
} from "../contexts/types";
import { StorageKeys, useLocalStorage } from "./useLocalStorage";
import { useRoster } from "../providers/rosterProvider";

type UseDataEventHandler = {
  findLastInteractiveEvent: (
    events: CanvasDataEvent[]
  ) => CanvasDataEvent | undefined;
  getActionForDataEvent: (
    event: EventPayload,
    isVideoEnabled: boolean,
    muted: boolean,
    setShowInstructors: (show: boolean) => void,
    handleSelectSlide?: (sortOrder: number) => void,
    toggleMute?: () => void,
    toggleVideo?: () => Promise<void>
  ) => Action;
};

export const useDataEventHandler = (): UseDataEventHandler => {
  const { isInstructorOrModerator, state, meetingRole } = useAppContext();
  const { getLocalStorage } = useLocalStorage();
  const locAttendee: Attendee = getLocalStorage(StorageKeys.attendee);
  const { refreshAttendee, roster } = useRoster();

  /** Finds the last data event where there was a canvas interaction for the current board
   * @param {CanvasDataEvent[]} events - All data events
   * @returns {CanvasDataEvent}
   */
  const findLastInteractiveEvent = (
    events: CanvasDataEvent[]
  ): CanvasDataEvent | undefined => {
    // Get current board type and number
    const board = state.currentBoard;
    const boardType = board.type;
    const slideNumber = board.sortOrder;

    // Find last event with an interaction for that board
    const clickEventIndex = events.findLastIndex((eventStr) => {
      const event: EventPayload = JSON.parse(eventStr.event);
      const isCurrentBoard = event.currentSlideNumber === slideNumber;

      // If the event does not match the current board, it is not the one we are looking for
      if (!isCurrentBoard) return false;

      // Find the last canvas interaction for each board type
      switch (boardType) {
        case BoardType.IIIF:
          const iiifInteractions = [
            EventTypes.CANVAS_CLICK,
            EventTypes.CANVAS_SCROLL,
          ];
          return iiifInteractions.includes(event.type);
        case BoardType.PANORAMA:
          return event.type === EventTypes.PANORAMA_CLICK;
        case BoardType.THREEDEE:
          return event.type === EventTypes.THREE_DEE_DRAG;
        case BoardType.VIDEO:
          return event.type === EventTypes.VIDEO;
      }
    });

    return events[clickEventIndex];
  };

  /** Parses a data event into a AppContext state dispatch action
   * @param {EventPayload} event - Data event to parse
   * @param {boolean} isVideoEnabled - Whether or not user camera is on
   * @param {boolean} muted - Whether or not the user is muted
   * @param {(show: boolean) => void} setShowInstructors - Callback fn to toggle the instructor video
   * @param {() => void} toggleMute - Callback fn to toggle user microphone
   * @param {() => Promise<void>} toggleVideo - Callback fn to toggle user camera
   * @returns {Action}
   */
  const getActionForDataEvent = (
    event: EventPayload,
    isVideoEnabled: boolean,
    muted: boolean,
    setShowInstructors: (show: boolean) => void,
    handleSelectSlide?: (sortOrder: number) => void,
    toggleMute?: () => void,
    toggleVideo?: () => Promise<void>
  ): Action => {
    const payload = {} as HandleIncomingEventAction["payload"];
    // app state events
    if (
      event?.type !== EventTypes.SPOTLIGHT_INSTRUCTOR &&
      event.spotlightInstructor !== state.spotlightInstructor
    ) {
      event.spotlightInstructor &&
        setShowInstructors &&
        setShowInstructors(true);
      payload["replayExplore"] = false;
      payload["spotlightInstructor"] = event?.spotlightInstructor;
      payload["exploreMode"] = false;
      payload["showLaser"] = false;
      payload["laserInstructor"] = undefined;
    }
    if (
      event?.type !== EventTypes.CHANGE_SLIDE &&
      event?.currentSlideNumber !== state.currentBoard?.sortOrder
    ) {
      handleSelectSlide && handleSelectSlide(event.currentSlideNumber);
      payload["replayExplore"] = false;
    }
    if (
      event?.type !== EventTypes.TOGGLE_LASER_POINTER &&
      event?.type !== EventTypes.LASER_POINTER_LOCATION &&
      (event?.showLaser !== state.showLaser || event.exploreMode)
    ) {
      // grab the laser from the event state only if:
      // it's in focus and there is a laser instructor
      if (
        event?.laserView?.activeInstructor &&
        !event.spotlightInstructor &&
        !event.exploreMode
      ) {
        payload["showLaser"] = event?.showLaser;
        payload["laserInstructor"] = event?.laserView?.activeInstructor;
      } else if (state.replayExplore || event.exploreMode) {
        payload["showLaser"] = false;
        payload["laserInstructor"] = undefined;
      }
    }
    if (
      event?.type !== EventTypes.TOGGLE_EXPLORE_MODE &&
      !state.replayExplore &&
      event?.exploreMode !== state.exploreMode
    ) {
      payload["exploreMode"] = event?.exploreMode;
    }

    // event itself
    if (event.leader) {
      switch (event.type) {
        case EventTypes.CHANGE_SLIDE:
        case EventTypes.VIDEO:
          handleSelectSlide && handleSelectSlide(event.currentSlideNumber);
          payload["replayExplore"] = false;

          break;

        case EventTypes.TOGGLE_EXPLORE_MODE:
          if (!state.replayExplore) {
            payload["exploreMode"] = event.exploreMode;

            if (event.exploreMode) {
              payload["showLaser"] = false;
              payload["laserInstructor"] = undefined;
            }
          }
          if (event.exploreMode) {
            payload["replayExplore"] = false;
          }
          break;

        case EventTypes.TOGGLE_LASER_POINTER:
          if (!state.replayExplore) {
            payload["showLaser"] = event.showLaser;
            payload["laserInstructor"] = event?.laserView?.activeInstructor;
          } else {
            payload["showLaser"] = false;
            payload["laserInstructor"] = undefined;
          }
          break;

        case EventTypes.LASER_POINTER_LOCATION:
          payload["showLaser"] = true;
          payload["laserInstructor"] = event?.laserView?.activeInstructor;

          if (state.replayExplore) {
            payload["showLaser"] = false;
          }
          break;

        case EventTypes.MUTE_ALL:
          // only mute students
          if (!isInstructorOrModerator) {
            if (!muted) {
              toggleMute();
            }
          }
          break;

        case EventTypes.VIDEO_OFF_ALL:
          // only turn off student videos
          if (!isInstructorOrModerator) {
            if (isVideoEnabled) {
              toggleVideo();
            }
          }
          break;

        case EventTypes.MUTE_INDIVIDUAL:
          if (event?.attendeeId === locAttendee?.AttendeeId) {
            if (!muted) {
              toggleMute();
            }
          }
          break;

        case EventTypes.MUTE_LOCK:
          // only mute students
          if (!isInstructorOrModerator) {
            if (!muted) {
              toggleMute();
            }
          }
          payload["muteLock"] = event.muteLock;
          break;

        case EventTypes.VIDEO_OFF_LOCK:
          // only mute students
          if (!isInstructorOrModerator) {
            if (isVideoEnabled) {
              toggleVideo();
            }
          }
          payload["videoOffLock"] = event.videoOffLock;
          break;

        case EventTypes.VIDEO_OFF_INDIVIDUAL:
          if (event?.attendeeId === locAttendee?.AttendeeId && isVideoEnabled) {
            toggleVideo();
          }
          break;

        case EventTypes.SPOTLIGHT_INSTRUCTOR:
          if (event.spotlightInstructor) {
            setShowInstructors && setShowInstructors(true);
            payload["spotlightInstructor"] = event.spotlightInstructor;
            payload["exploreMode"] = false;
          }
          break;

        case EventTypes.SOFT_BAN:
          // Refresh the roster for this attendee
          if (roster[event?.attendeeId]) {
            refreshAttendee(
              event?.attendeeId,
              roster[event?.attendeeId]?.externalUserId,
              event.softBanned
            );
          }

          if (
            event?.attendeeId === locAttendee?.AttendeeId &&
            !isInstructorOrModerator
          ) {
            if (!muted) toggleMute();

            if (isVideoEnabled) toggleVideo();

            return {
              type: ActionType.UPDATE_ATTENDEE_SOFT_BAN,
              payload: { softBan: event.softBanned },
            };
          }
          break;
      }
    }

    return { type: ActionType.HANDLE_INCOMING_EVENT, payload };
  };

  return {
    findLastInteractiveEvent,
    // @ts-ignore
    getActionForDataEvent,
  };
};
