import React, { useContext, useEffect, useState } from "react";
import { DARK, ThemeContext } from "../../contexts/themeContext";
import { IconButton } from "../buttons/iconButton";
import { Button } from "../buttons/button";
import classnames from "classnames";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

type HelperPopUpProps = {
  userMessage?: string;
  popUpHeader?: string;
  isDismissible?: boolean;
  onDismiss?: (e) => void;
  isTimed?: boolean;
  dismissTimeout?: number;
  bottom?: string;
  right: string;
  top?: string;
  centerWidth?: boolean;
  downArrow?: boolean;
  upArrow?: boolean;
  leftArrow?: boolean;
  rightArrow?: boolean;
  minWidth?: string;
  arrowRight?: string;
  arrowLeft?: string;
  buttonText?: string;
  buttonDisabled?: boolean;
  onBtnClick?: (e) => void;
  includeDontShowAgain?: boolean;
  handleSetShowAgainCheckbox?: (showAgain: boolean) => void;
  id?: string;
  position?: "absolute" | "fixed";
};

/**
 * Component used to display a pop up message
 *
 * @param {HelperPopUpProps} props
 * @prop {string} userMessage - the message to display in the helper popup
 * @prop {string} popUpHeader - optional header for the message if you want it
 * @prop {boolean} isDismissible - optional prop if the popup should have an x to close
 * @prop {event} onDismiss - function called on dismiss either by the timer or clicking the x
 * @prop {boolean} isTimed - optional prop if the popup should self-close after a time, if isTimed then includeDontShowAgain must be false
 * @prop {number} dismissTimeout - optional prop to change the time for the self close defaults 5 seconds - prop is in seconds
 * @prop {string} bottom - prop for the absolute placement from the top of the 0 x 0 parent container div
 * @prop {string} top - prop for the absolute placement from the bottom of the 0 x 0 parent container div
 * @prop {string} right - prop for the absolute placement from the 0 x 0 parent container div
 * @prop {boolean} centerWidth - optional prop if you want to auto center the helper width wise, send an empty string for the right prop as well
 * @prop {boolean} upArrow - optional prop if you want an arrow from the top
 * @prop {boolean} downArrow - optional prop if you want an arrow from the bottom
 * @prop {boolean} leftArrow - optional prop if you want an arrow from the left
 * @prop {boolean} rightArrow - optional prop if you want an arrow from the right
 * @prop {string} minWidth - optional header for the message if you want it
 * @prop {string} buttonText - optional prop to have a small primary button
 * @prop {boolean} buttonDisabled - optional prop to have the button be disabled
 * @prop {event} onBtnClick - function called on button press
 * @prop {boolean} includeDontShowAgain - Controls the "Don't Show Again" checkbox state (required if using "Don't Show Again" feature), if isTimed then includeDontShowAgain must be false
 * @prop {function} handleSetShowAgainCheckbox - Function to handle the "Don't Show Again" checkbox state (required if using "Don't Show Again" feature).
 * @prop {"absolute" | "fixed"} position - Optional css position for the popup
 */

export const HelperPopUp: React.FC<HelperPopUpProps> = ({
  userMessage,
  popUpHeader,
  isDismissible,
  onDismiss,
  isTimed,
  dismissTimeout = 5,
  bottom,
  right,
  top,
  centerWidth,
  upArrow,
  downArrow,
  leftArrow,
  rightArrow,
  minWidth,
  arrowRight,
  arrowLeft,
  buttonText,
  buttonDisabled,
  onBtnClick,
  includeDontShowAgain,
  handleSetShowAgainCheckbox,
  id,
  position = "absolute",
}) => {
  const { theme } = useContext(ThemeContext);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [dismissed, setDimissed] = useState(false);
  const timeoutInMilliseconds = dismissTimeout * 1000;

  document.documentElement.style.setProperty(
    "--timer-fade-time",
    `${dismissTimeout * 0.9}s`
  );

  // Event handler for the checkbox
  const handleDontShowAgainChange = (e) => {
    const isChecked = e.target.checked;
    setDontShowAgain(isChecked);
    setDimissed(isChecked);
    // Handle the "Don't Show Again" checkbox state in the parent component
    setTimeout(() => {
      handleSetShowAgainCheckbox(isChecked);
    }, timeoutInMilliseconds);
  };

  const handleDismissPopup = (e) => {
    setDimissed(true);
    consoleNonProd("handleDismissPopup clicked, dismissed is", dismissed);
    setTimeout((e) => {
      consoleNonProd("setTimout triggered");
      onDismiss && onDismiss(e);
    }, timeoutInMilliseconds);
  };

  /** On mount, set the auto-dismiss timeout if it is a timed popup */
  useEffect(() => {
    // if the popup is timed it will display for two seconds and then self destroy
    // if we want to pass in a timed amount we can add that prop and we'll have to pass
    // in a variable to the scss fade animation as well for the time on that
    if (!dontShowAgain && isTimed && onDismiss) {
      setTimeout((e) => {
        onDismiss(e);
      }, timeoutInMilliseconds);
    }
  }, []);

  return (
    <div
      className={classnames("helper-pop-up-container", {
        "fade-out": dismissed,
      })}
      id={id}
    >
      <section
        className={classnames(
          "helper-pop-up-content",
          {
            "helper-pop-up-content__timed": isTimed,
            "helper-pop-up-content__center-width": centerWidth,
          },
          `--${position}`
        )}
        style={{ bottom, top, right, minWidth }}
      >
        <div
          className="helper-pop-up__message-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          {popUpHeader && (
            <h3 style={{ marginTop: isDismissible ? "1rem" : "0" }}>
              {popUpHeader}
            </h3>
          )}
          {userMessage && (
            <p
              id={"popup-message-" + id}
              className={classnames("helper-pop-up-content__message", {
                "helper-pop-up-content__message--dismiss":
                  isDismissible && !popUpHeader,
              })}
            >
              {userMessage}
            </p>
          )}
          {buttonText && (
            <Button
              disabled={buttonDisabled}
              onClick={onBtnClick}
              btnType="primary"
              size="small"
            >
              {buttonText}
            </Button>
          )}
        </div>
        {upArrow && (
          <div
            className={classnames("pop-up-arrow pop-up-arrow__top", {
              "pop-up-arrow__top--dark": theme === DARK,
            })}
            style={{ right: arrowRight, left: arrowLeft }}
          ></div>
        )}
        {downArrow && (
          <div
            className={classnames("pop-up-arrow pop-up-arrow__down", {
              "pop-up-arrow__down--dark": theme === DARK,
            })}
            style={{ right: arrowRight, left: arrowLeft }}
          ></div>
        )}
        {leftArrow && (
          <div
            className={classnames("pop-up-arrow pop-up-arrow__left", {
              "pop-up-arrow__left--dark": theme === DARK,
            })}
          ></div>
        )}
        {rightArrow && (
          <div
            className={classnames("pop-up-arrow pop-up-arrow__right", {
              "pop-up-arrow__right--dark": theme === DARK,
            })}
          ></div>
        )}
        {isDismissible && (
          <div className="helper-pop-up-container__close-wrapper">
            <IconButton
              size="1rem"
              onClick={handleDismissPopup}
              iconName="dismiss"
              desc="Close"
              btnId="close-helper-popup-button"
            />
          </div>
        )}
        {includeDontShowAgain && (
          <label htmlFor="dontShowAgainCheckbox">
            <input
              type="checkbox"
              id="dontShowAgainCheckbox"
              checked={dontShowAgain}
              onChange={handleDontShowAgainChange}
            />
            Don&apos;t show this message again.
          </label>
        )}
      </section>
    </div>
  );
};
