import React, { useCallback } from "react";
import { TextInput } from "./textInput";
import { Button, PRIMARY, STANDARD } from "../buttons/button";

type SearchBarProps = {
  handleSearch: () => void;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * Component for an VxP search bar
 * @SearchBarProps handleSearch, value, setValue
 */

export const SearchBar: React.FC<SearchBarProps> = ({
  handleSearch,
  value,
  setValue,
}) => {
  /** onChange Handler for search input
   * @param {React.FormEvent<HTMLInputElement>} - event passed from input onChange.
   */
  const onChange = ({
    currentTarget: { value: val },
  }: React.FormEvent<HTMLInputElement>): void => {
    setValue(val);
  };

  /** Submit search
   * @param {SyntheticEvent?} - optional event, depending if this was from button or from enter press.
   */
  const handleSubmitSearch = useCallback(
    (e): void => {
      // Prevent default onClick.
      e.preventDefault();
      // Call search fn passed from parent component
      handleSearch();
    },
    [handleSearch]
  );

  /** Handler enter key press to submit search form
   * @param {React.KeyboardEvent} e Key down event
   */
  const searchOnEnter = useCallback(
    (e: React.KeyboardEvent): void => {
      if (e.key === "Enter" && value) {
        handleSubmitSearch(e);
      }
    },
    [handleSubmitSearch, value]
  );

  return (
    <div className="search__searchbar">
      <TextInput
        disabled={false}
        id="searchBarInput"
        label="Search by keyword, artist, and more"
        name="meetingId"
        parentForm="searchInput"
        onBeforeInput={(e: any): void => {}}
        errorMessage=""
        hasError={false}
        onChange={onChange}
        onKeyDown={searchOnEnter}
      />
      <Button
        btnType={PRIMARY}
        disabled={false}
        size={STANDARD}
        text="Search"
        onClick={handleSubmitSearch}
        inputClass="button-container"
        customWidth="150px"
        id="submit-search-button"
      />
    </div>
  );
};
