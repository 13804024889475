import { useMemo } from "react";
import {
  DataSet,
  RegExpMatcher,
  TextCensor,
  englishDataset,
  englishRecommendedTransformers,
  pattern,
} from "obscenity";
import {
  customBlacklistedTerms,
  customWhitelistedTerms,
} from "../utils/customFilteredWords";

export const useFilter = () => {
  const { matcher, censor } = useMemo(() => {
    // Build the custom dataset from the default English dataset
    const blacklistedTerms = new DataSet().addAll(englishDataset);
    // Add our custom terms
    customBlacklistedTerms.forEach((term) => {
      blacklistedTerms.addPhrase((phrase) =>
        phrase
          // setMetadata() sets the metadata of the phrase
          .setMetadata({ originalWord: term })
          // addPattern() associates a pattern with the phrase
          .addPattern(pattern`${term}`)
      );
    });

    const matcher = new RegExpMatcher({
      ...blacklistedTerms.build(),
      ...englishRecommendedTransformers,
    });

    const censor = new TextCensor();
    return { matcher, censor };
  }, []);

  const filterMessage = (message: string) => {
    // Split the message into words
    const words = message.split(/\b/);

    // Process each word individually
    const filteredWords = words.map((word) => {
      // Check if the word is whitelisted
      const isWhitelisted = customWhitelistedTerms.some((whitelistWord) =>
        new RegExp(`\\b${whitelistWord}\\b`, "i").test(word)
      );

      if (isWhitelisted) {
        // If the word is whitelisted, return it as is
        return word;
      }

      // Otherwise, apply filtering
      const matches = matcher.getAllMatches(word);
      return censor.applyTo(word, matches);
    });

    // Reassemble the message
    return filteredWords.join("");
  };

  return { filterMessage };
};
