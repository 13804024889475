import React, { useContext } from "react";
import classnames from "classnames";
import { Icon } from "../icon";
import { DARK, ThemeContext } from "../../contexts/themeContext";

// Button Type Options
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const TERTIARY = "tertiary";
export type ButtonType = typeof PRIMARY | typeof SECONDARY | typeof TERTIARY;

// Button Size Options
export const STANDARD = "standard";
export const SMALL = "small";
type ButtonSize = typeof STANDARD | typeof SMALL;

type ButtonProps = {
  btnType?: ButtonType;
  disabled?: boolean;
  size?: ButtonSize;
  text?: string;
  icon?: string;
  inputClass?: string;
  id?: string;
  customWidth?: string;
  hoverState?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  stroke?: string;
  fill?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

const iconSizeConverter = (inputSize: ButtonSize) => {
  return inputSize === STANDARD ? "24px" : "14px";
};

/** Component for an VxP branded button
 * @param {ButtonProps} props
 * @prop {ButtonType} btnType - Type of button styling, defaults to "primary" and other options are "secondary" and "tertiary"
 * @prop {boolean} disabled - Whether or not the button is disabled, defaults to false
 * @prop {ButtonSize} size - Button width, defaults to "standard" and other option is "small"
 * @prop {string} text - Optional text that will display in the button
 * @prop {string} icon - Optional name fo the icon that will display in the button
 * @prop {string} inputClass - Optional class name to apply additional styles
 * @prop {string} id - Optional unique ID for the button
 * @prop {string} customWidth - Optional width to override default values, should be formatted as a string, eg "125px"
 * @prop {boolean} hoverState - Optional hoverstate value to pass to icons
 * @prop {(e: React.MouseEvent<HTMLButtonElement>) => void} onClick - Event handler for mouse click
 * @prop { React.ButtonHTMLAttributes<HTMLButtonElement>["type"]} type - The `type` attribute for the button element
 */

export const Button: React.FC<ButtonProps> = ({
  btnType = PRIMARY,
  disabled = false,
  size = STANDARD,
  text,
  icon,
  inputClass,
  id,
  customWidth,
  onClick,
  children,
  hoverState = false,
  stroke,
  fill,
  type = "button",
}) => {
  const { theme } = useContext(ThemeContext);
  const disabledStrokeColor = theme === DARK ? "B8B4B3" : "#282828";
  const iconDisableStrokeColor = theme === DARK ? "#5e5e5e" : "#dcdcdc";
  return (
    <div className="btn-container">
      <button
        className={classnames(`btn-${btnType}`, size, inputClass)}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        style={{ width: customWidth }}
        type={type}
        id={id}
      >
        <div className="btn-inner">
          {icon && (
            <div className="btn-icon">
              <Icon
                name={icon}
                desc={icon}
                toolTip="none"
                stroke={disabled ? disabledStrokeColor : stroke}
                disabledStroke={disabled ? iconDisableStrokeColor : ""}
                disabled={disabled}
                fill={fill}
                height={iconSizeConverter(size)}
                isPrimaryButton={btnType === "primary"}
                hoverState={hoverState}
              />
            </div>
          )}
          <div
            className={classnames("btn-text", {
              "btn-text__icon": icon,
            })}
          >
            {text}
            {children}
          </div>
        </div>
      </button>
    </div>
  );
};
