import React, { Fragment, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { StorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { Attendee } from "../../types";
import variables from "../../styles/variables.scss";
import { MeetingRole, Participant } from "../../contexts/types";
import { useAppContext } from "../../contexts/appContext";
import { useScreenContext } from "../../contexts/screenContext";
import { StudentVideo } from "./studentVideo";
import { IconButton } from "../buttons/iconButton";

type VideosProps = {
  attendeeList: Participant[];
};

export const StudentVideos: React.FC<VideosProps> = ({ attendeeList }) => {
  const { getLocalStorage } = useLocalStorage();
  const [showMoreAttendees, setShowMoreAttendees] = useState<boolean>(true);
  const [showMoreMax, setShowMoreMax] = useState<number>(4);
  const locAttendee: Attendee = getLocalStorage(StorageKeys.attendee);
  const { useMobileTools, orientation } = useScreenContext();
  const { toggleParticipants } = useAppContext();

  let AttendeeId = "0"; // Defaulting to "0" for testing purposes
  AttendeeId = locAttendee?.AttendeeId;

  // Set up roster and tiles for testing purposes
  let height =
    useMobileTools && orientation === "portrait"
      ? `calc(${document?.documentElement?.style.getPropertyValue(
          "--mobile-available-screen"
        )} * .6 - 105px)`
      : "118px";

  // if mobile, don't set a showMoreMax
  useEffect(() => {
    setShowMoreMax(useMobileTools ? 100 : 4);
  }, []);

  // Sorting the attendeeList to move Moderators to the end
  const sortedAttendeeList = [...attendeeList].sort((a, b) => {
    if (
      a.meetingRole === MeetingRole.Moderator &&
      b.meetingRole !== MeetingRole.Moderator
    ) {
      return 1;
    }
    if (
      a.meetingRole !== MeetingRole.Moderator &&
      b.meetingRole === MeetingRole.Moderator
    ) {
      return -1;
    }
    return 0;
  });

  return (
    <Fragment>
      {!useMobileTools && (
        <div className="relative-wrapper">
          <IconButton
            iconName="dismiss"
            desc="Hide student videos"
            toolTip="bottom"
            onClick={toggleParticipants}
            className="videos__students-dismiss"
            size="20px"
            btnId="toggle-student-video-button"
          />
        </div>
      )}
      <SimpleBar
        forceVisible={true}
        autoHide={false}
        style={{ height: height }}
      >
        <div className="student-videos__container">
          {/* Dismiss/Minimize button */}
          {sortedAttendeeList?.map((student, index) => {
            // Do not display the video for the local user
            if (student?.AttendeeId === AttendeeId) return null;
            if (index > showMoreMax && showMoreAttendees) {
              return null;
            }
            const tileId = student?.tileId;
            const meetingRole = student?.meetingRole;
            const attendee = student || {};
            // Do not display video if attendee data is empty
            if (!attendee) return null;

            let { name, iconColor }: any = attendee;
            const iconHex: string = iconColor?.replace("_", "#"); // grabs the user color from context
            return (
              (meetingRole === MeetingRole.Audience ||
                meetingRole === MeetingRole.Moderator) && (
                <StudentVideo
                  name={name}
                  tileId={tileId}
                  iconHex={iconHex}
                  key={index}
                  participant={student}
                />
              )
            );
          })}
          {attendeeList?.length - 1 > showMoreMax && (
            // I'm not sure if this is how we want to handle the view more videos
            // so I just did inline styles if we do want to do it this way I'll class it up
            <button
              id="view-more-videos-button"
              style={{
                width: "160px",
                height: "98px",
                border: "none",
                background: "#000",
                color: variables.white,
                fontSize: "1.25rem",
                minWidth: "160px",
              }}
              onClick={() => {
                setShowMoreAttendees(!showMoreAttendees);
              }}
            >
              {showMoreAttendees
                ? "+" + (attendeeList?.length - 1 - showMoreMax)
                : "Show fewer videos"}
            </button>
          )}
        </div>
      </SimpleBar>
    </Fragment>
  );
};
