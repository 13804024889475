import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAudioVideo } from "amazon-chime-sdk-component-library-react";
import { Transcript, TranscriptResult } from "amazon-chime-sdk-js";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";
import { useRoster } from "./rosterProvider";

enum ActionType {
  ADD_CAPTION,
  ADD_PARTIAL,
}

export interface CaptionType {
  text: string;
}

interface CaptionsContextType {
  currentPartial: CaptionType | undefined;
  captions: CaptionType[];
}

interface State {
  partials: CaptionType[];
  captions: CaptionType[];
}

interface AddPartialAction {
  type: ActionType.ADD_PARTIAL;
  payload: CaptionType;
}

interface AddCaptionAction {
  type: ActionType.ADD_CAPTION;
  payload: CaptionType;
}

type Action = AddPartialAction | AddCaptionAction;

const initialState: State = {
  partials: [],
  captions: [],
};

function reducer(state: State, action: Action): State {
  const { type, payload } = action;

  switch (type) {
    case ActionType.ADD_CAPTION:
      return {
        captions: [payload, ...state.captions],
        partials: [{ text: undefined }, ...state.partials],
      };
    case ActionType.ADD_PARTIAL:
      return { ...state, partials: [payload, ...state.partials] };
    default:
      throw new Error(
        `Incorrect action in DataCaptionsProvider reducer: ${type}`
      );
  }
}

const CaptionsContext = createContext<CaptionsContextType | undefined>(
  undefined
);

export const DataCaptionsProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const audioVideo = useAudioVideo();
  const { roster } = useRoster();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [transcripts, setTranscripts] = useState<TranscriptResult[]>([]);

  // Sets the subscribe to transcribe event callback when the audio video hook initializes
  useEffect(() => {
    if (!audioVideo) return;

    audioVideo.transcriptionController?.subscribeToTranscriptEvent(
      (transcriptEvent) => {
        setTranscripts((transcriptEvent as Transcript).results);
      }
    );

    return () => {
      audioVideo.transcriptionController?.unsubscribeFromTranscriptEvent(
        (_) => null
      );
    };
  }, [audioVideo]);

  useEffect(() => {
    if (transcripts && transcripts.length) {
      if (transcripts[0].alternatives[0].transcript) {
        const attendee =
          roster[transcripts[0].alternatives[0].items[0].attendee.attendeeId];
        const line = transcripts[0].alternatives[0].transcript;
        let type = ActionType.ADD_PARTIAL;

        if (!transcripts[0].isPartial) {
          type = ActionType.ADD_CAPTION;
        }

        dispatch({
          type,
          payload: {
            text: attendee ? attendee.name + ": " + line : "Attendee: " + line,
          },
        });
      }
    }
  }, [roster, transcripts]);

  const value: CaptionsContextType = {
    captions: state.captions,
    currentPartial: state.partials[0],
  };

  return (
    <CaptionsContext.Provider value={value}>
      {children}
    </CaptionsContext.Provider>
  );
};

export const useCaptions = (): CaptionsContextType => {
  const context = useContext(CaptionsContext);
  const meetingManager = useMeetingWithRoster();

  if (!context || !meetingManager) {
    throw new Error(
      "Use useCaptions hook inside DataCaptionsProvider. Wrap DataCaptionsProvider under MeetingProvider."
    );
  }

  return context;
};
