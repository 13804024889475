/**
 * @param {string} name - Any string with a space for a name i.e. Randy Hall
 * @returns {string} - the names initials i.e. RH
 */

export const nameInitializer = (name: string) => {
  if (name && name.charAt(name.indexOf(" ") + 1) && name.indexOf(" ") > -1) {
    name =
      name.charAt(0).toLocaleUpperCase() +
      name.charAt(name.indexOf(" ") + 1).toLocaleUpperCase();
    return name;
  } else {
    return name?.charAt(0);
  }
};

/**
 * @param {string} name - Any string with a space for a name i.e. Randy Hall
 * @returns {string} - the names initials i.e. Randy H.
 */

export const firstNameFirstInitial = (name: string) => {
  if (name && name.charAt(name.indexOf(" ") + 1) && name.indexOf(" ") > -1) {
    name =
      name.substring(0, name.indexOf(" ")) +
      " " +
      name.charAt(name.indexOf(" ") + 1) +
      ".";
    return name;
  }
  if (name === undefined) {
    return ".";
  } else {
    return name + ".";
  }
};
