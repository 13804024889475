import React, { Fragment, useState } from "react";
import { TextInput } from "../inputs/textInput";
import {
  Course,
  CreateMeetingInput,
  DuplicateCourseInput,
  Mutation,
} from "../../types";
import { DateTimePickerInput } from "../inputs/dateTimePickerInput";
import { Button } from "../buttons/button";
import { useMutation } from "@apollo/client";
import { DUPLICATE_COURSE } from "../../graphql/mutations";
import Spinner from "../spinner";
import { validateSlug } from "../../utils/validators";

type DuplicateCourseProps = {
  courseName: string;
  courseSlug: string;
  courseId: number;
  meetings: CreateMeetingInput[];
  onClassDuplication?: (course: Course) => void;
  onCancel?: () => void;
};

export const DuplicateCourse: React.FC<DuplicateCourseProps> = ({
  courseName,
  courseSlug,
  courseId,
  meetings,
  onClassDuplication,
  onCancel,
}) => {
  const [copyMeetings, setCopyMeetings] = useState(meetings);
  const [slugError, setSlugError] = useState(false);
  const [newName, setNewName] = useState(courseName);
  const [newSlug, setNewSlug] = useState(courseSlug);
  const [duplicationErrorMessage, setDuplicationErrorMessage] = useState("");
  const [slugLengthError, setSlugLengthError] = useState(false);
  const [duplicateCourseMutation, mutationResult] = useMutation(
    DUPLICATE_COURSE,
    {
      onCompleted(data: Pick<Mutation, "duplicateCourse">) {
        const course = data.duplicateCourse;
        onClassDuplication(course);
      },
      onError(error) {
        if (error?.message?.indexOf("slug_key") > 0) {
          setSlugError(true);
        }
        setDuplicationErrorMessage(error.message);
      },
    }
  );

  const handleOnChange = (day: Date, index: number) => {
    const isoDay = day?.getTime();
    let oldMeeting = copyMeetings[index];
    oldMeeting.meetingTime = isoDay;
    const updatedMeetings = copyMeetings.map((meeting, i) => {
      if (i === index) {
        return oldMeeting;
      }
      return meeting;
    });
    setCopyMeetings(updatedMeetings);
  };

  const handleNameChange = (event) => {
    setNewName(event.target.value);
  };

  const validateURLSlug = (slug: string) => {
    const valid = validateSlug(slug);
    setSlugError(!valid);
  };

  const handleSlugChange = (event) => {
    setNewSlug(event.target.value);

    const valid = validateSlug(event.target.value);
    setSlugLengthError(event?.target?.value?.length > 45);
    setSlugError(!valid);
  };

  const pastCourseChecker = (dateToTest: number, index?: number) => {
    // if they forget to update an old meeting time set the time to 1 hour from
    // now to give them wiggle room to be able to adjust it
    let tempTimeadditon: number = 1;
    if (index && index > 0) {
      tempTimeadditon = index * index;
    }
    if (dateToTest < new Date().getTime()) {
      return new Date(Date.now() + tempTimeadditon * (60 * 60 * 1000));
    } else return new Date(dateToTest);
  };

  const handleSubmit = () => {
    let input;
    let dupMeetings = [];
    // operate on the meetings array and make new title and time
    copyMeetings.forEach((meeting, index) => {
      meeting.meetingTime = pastCourseChecker(
        meeting.meetingTime,
        index + 1
      ).getTime();
      dupMeetings.push(meeting);
    });
    const editInput: DuplicateCourseInput = {
      name: newName,
      id: courseId,
      slug: newSlug,
      meetingDates: dupMeetings,
    };
    input = editInput;

    duplicateCourseMutation({ variables: { input } });
  };
  return (
    <div className="__modal-content">
      <form className="course-form">
        <h2>Course Information</h2>
        <TextInput
          label="Course Title"
          name="name"
          id="name"
          initialValue={newName}
          hasError={false}
          errorMessage="A name is required to create a new course. It must be at least 3 characters long"
          required={true}
          disabled={false}
          onChange={handleNameChange}
        />
        Please note that the URL slug must be unique. When duplicating courses,
        it is suggested to add the year and season to the previous slug. For
        example &quot;Picasso-and-Renoir&quot;, might become
        &quot;Picasso-and-Renoir-spring-{new Date().getFullYear()}&quot;. Once a
        slug has been set it cannot be updated.
        <TextInput
          label="URL Slug"
          name="slug"
          id="slug"
          initialValue={newSlug}
          hasError={slugError}
          maxLength={46}
          maxLengthMessage="Slug must be less then 46 characters in length"
          errorMessage="Please enter a valid slug using only alphanumeric characters and dashes, eg: 'course-123'. Also the slug cannot end with a dash"
          required={true}
          disabled={false}
          onChange={handleSlugChange}
          onBlur={(e) => validateURLSlug(e.target.value)}
          onLengthError={(e) => setSlugLengthError(e)}
        />
        <h2>Class Sessions</h2>
        {copyMeetings &&
          copyMeetings.map((meeting, index) => (
            <Fragment key={index}>
              Class Session {index + 1} starting time
              <DateTimePickerInput
                dateTime={pastCourseChecker(meeting.meetingTime, index + 1)}
                setDateTime={(e) => handleOnChange(e, index)}
                disabled={false}
                autofocus={false}
                minDate={new Date()}
              />
              <br />
            </Fragment>
          ))}
        {duplicationErrorMessage && <div>{duplicationErrorMessage}</div>}
        <div className="duplicate-course-wrapper">
          <Button
            text={mutationResult.loading ? "" : "Save course"}
            disabled={
              mutationResult.loading ||
              newSlug === courseSlug ||
              slugLengthError ||
              slugError
            }
            onClick={handleSubmit}
            id={"save-duplicate-course-button"}
          >
            {mutationResult.loading && <Spinner />}
          </Button>
          <Button
            text={mutationResult.loading ? "" : "Cancel"}
            disabled={mutationResult.loading}
            onClick={onCancel}
            id={"cancel-duplicate-course-button"}
          >
            {mutationResult.loading && <Spinner />}
          </Button>
        </div>
      </form>
    </div>
  );
};
