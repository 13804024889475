import React, { useReducer, createContext, useContext } from "react";
import { CaptionType } from "./dataCaptions";

export interface DataCaptionsStateContextType {
  updateCaptionEvent: (event: CaptionType[]) => void;
  replayCaptionEvents: CaptionType[];
}

enum DataCaptionsActionType {
  UPDATE,
}

const ReplayDataCaptionsStateContext = createContext<
  DataCaptionsStateContextType | undefined
>(undefined);

export interface State {
  replayCaptionEvents: CaptionType[];
}

interface AddCaptionAction {
  type: DataCaptionsActionType.UPDATE;
  payload: CaptionType[];
}

type Action = AddCaptionAction;

const initialState: State = {
  replayCaptionEvents: [],
};

export function reducer(state: State, action: Action): State {
  const { type, payload } = action;
  switch (type) {
    case DataCaptionsActionType.UPDATE:
      return { replayCaptionEvents: payload };
    default:
      throw new Error(
        "Incorrect action in ReplayCaptionsEventsProvider reducer"
      );
  }
}

export const ReplayCaptionsEventsProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateCaptionEvent = (event: CaptionType[]) => {
    dispatch({
      type: DataCaptionsActionType.UPDATE,
      payload: event,
    });
  };

  const value = {
    updateCaptionEvent,
    replayCaptionEvents: state.replayCaptionEvents,
  };

  return (
    <ReplayDataCaptionsStateContext.Provider value={value}>
      {children}
    </ReplayDataCaptionsStateContext.Provider>
  );
};

export const useReplayCaptionsEvents = (): {
  updateCaptionEvent: (event: CaptionType[]) => void;
  replayCaptionEvents: CaptionType[];
} => {
  const context = useContext(ReplayDataCaptionsStateContext);

  if (!context) {
    throw new Error(
      "Use useReplayCaptionsEvents hook inside DataReplayCaptionsEventsProvider. Wrap DataReplayCaptionsEventsProvider under MeetingProvider."
    );
  }

  return { ...context };
};
