import React, { Fragment } from "react";
import { EditPresentationTools } from "../toolbars/editPresentationTools";
import { SlideManager } from "../toolbars/slideManager";
import { CanvasMode } from "../../contexts/types";

type EditCanvasToolsProps = {
  editBoardIIIF: boolean;
  focusOnBoard: () => void;
  handleChangeSlide: (sortOrder: number) => void;
  handleRefetchBoards: (newSelectedBoardNumber?: number) => void;
  handleSelectSlide: (sortOrder: number) => void;
  handleSetSelectedBoard: (sortOrder: number) => void;
  iiifRemoved: boolean;
  scrollToBoard: (boardNumber: number) => void;
  setEditBoardIIIF: (editBoardIIIF: boolean) => void;
  setIIIFRemoved: (iiifRemoved: boolean) => void;
  showEditTools: boolean;
};

export const EditCanvasTools: React.FC<EditCanvasToolsProps> = ({
  editBoardIIIF,
  focusOnBoard,
  handleChangeSlide,
  handleRefetchBoards,
  handleSelectSlide,
  handleSetSelectedBoard,
  iiifRemoved,
  scrollToBoard,
  setEditBoardIIIF,
  setIIIFRemoved,
  showEditTools,
}) => {
  return (
    <Fragment>
      {showEditTools && (
        <EditPresentationTools
          handleRefetchBoards={handleRefetchBoards}
          mode={CanvasMode.EDIT}
          editBoardIIIF={editBoardIIIF}
          setEditBoardIIIF={setEditBoardIIIF}
          setIIIFRemoved={setIIIFRemoved}
          handleChangeSlide={handleSetSelectedBoard}
        />
      )}

      {/* Slide Manager */}
      <div className="canvas__tools-wrapper __leader">
        <SlideManager
          handleSelectSlide={handleSelectSlide}
          handleRefetchBoards={handleRefetchBoards}
          mode={CanvasMode.EDIT}
          iiifRemoved={iiifRemoved}
          setIIIFRemoved={setIIIFRemoved}
          handleChangeSlide={handleChangeSlide}
          scrollToBoard={scrollToBoard}
          focusOnBoard={focusOnBoard}
        />
      </div>
    </Fragment>
  );
};
