import React from "react";
import { useAppContext } from "../../../contexts/appContext";
import { useDataEvents } from "../../../providers/dataEvents";
import { EventTypes } from "../../../providers/types";
import { IconButton } from "../../buttons/iconButton";
import { StorageKeys, useLocalStorage } from "../../../hooks/useLocalStorage";
import { Attendee } from "../../../types";
import { ActionType } from "../../../contexts/types";

type LaserButtonProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  emitClick?: (e) => void;
};

export const LaserPointerButton: React.FC<LaserButtonProps> = ({
  toolTip,
  emitClick,
}) => {
  const { sendEvent } = useDataEvents();
  const { isInstructorOrModerator, state, dispatch } = useAppContext();
  const { getLocalStorage } = useLocalStorage();
  const locAttendee: Attendee = getLocalStorage(StorageKeys.attendee);

  const laserAction = (e) => {
    // Set default payload for dispatch and sending event
    const eventPayload = {
      laserInstructor: locAttendee?.AttendeeId,
      showLaser: state.showLaser,
    };
    let dispatchAction = ActionType.EVENT_TOGGLE_LASER_POINTER;

    // If laser is off, then turn it on and assign user as the laser instructor
    if (!state.showLaser && state.laserInstructor !== locAttendee?.AttendeeId) {
      eventPayload.showLaser = true;
    }
    // if the laser is on...
    else if (state.showLaser) {
      // ...and the user is not the laser instructor, then update laser instructor to user
      if (state.laserInstructor !== locAttendee?.AttendeeId) {
        dispatchAction = ActionType.UPDATE_LASER_INSTRUCTOR;
      }
      // ...and the user is the laser instructor, then turn off the laser
      else {
        eventPayload.showLaser = false;
        eventPayload.laserInstructor = undefined;
      }
    }

    // Update state and send event
    dispatch({ type: dispatchAction, payload: eventPayload });
    sendEvent(
      {
        type: EventTypes.TOGGLE_LASER_POINTER,
        showLaser: eventPayload.showLaser,
        leader: isInstructorOrModerator,
        exploreMode: eventPayload.showLaser ? false : state.exploreMode,
        laserView: {
          activeInstructor: eventPayload.laserInstructor,
          x: undefined,
          y: undefined,
          instHeight: undefined,
          instWidth: undefined,
        },
      },
      state
    );

    emitClick && emitClick(e);
  };

  return (
    <IconButton
      onClick={laserAction}
      iconName="laser"
      desc={
        state.showLaser && locAttendee?.AttendeeId !== state.laserInstructor
          ? "Laser pointer is active by another instructor, click to take control"
          : state.showLaser
          ? "Laser pointer"
          : "Laser pointer"
      }
      toolTip={toolTip}
      size="28px"
      selected={state.showLaser}
    />
  );
};
