import { MutationResult } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  Course,
  CreateCourseInput,
  CreateMeetingInput,
  EditCourseInput,
  Meeting,
} from "../../types";
import { validateText, validateSlug } from "../../utils/validators";
import { ApolloErrorsList } from "../apolloErrorsList";
import { Button, SECONDARY } from "../buttons/button";
import { TextInput } from "../inputs/textInput";
import Spinner from "../spinner";
import { ClassSession } from "./classSession";
import { Modal } from "../modal/modal";
import { DuplicateCourse } from "./duplicateCourse";
import { Container } from "../container";
import { ClassForm } from "./classForm";

type OnSubmitArgs = {
  variables: {
    input: CreateCourseInput | EditCourseInput;
  };
};

type CourseFormProps = Pick<Course, "name" | "slug" | "meetings"> & {
  id?: number;
  createMode: boolean;
  onSubmit: (OnSubmitArgs) => void;
  mutationResult: MutationResult;
  handleSetCourseInfo?: (course: Course) => void;
  handleSetMeetingInfo?: (meeting: Meeting) => void;
};

/** Form for creating or editing a course
 * @param {CourseFormProps} props
 * @prop {string} name - Optional course name
 * @prop {string} slug - Optional course slug
 * @prop {number} id - Optional course id
 * @prop {Meeting[]} meetings - Optional array of meetings associated with the course
 * @prop {boolean} createMode - Whether or not the form is being used to create (true) or edit (false) a course, defaults to false
 * @prop {(OnSubmitArgs) => void} onSubmit - Function that handles the submit action for the form
 * @prop {(course: Course) => void} handleSetCourseInfo - Function that handles setting the course info in the parent component
 * @prop {(meeting: Meeting) => void} handleSetMeetingInfo  - Function that handles setting an update value for a single meeting in the parent component
 * @prop {MutationResult} mutationResult - State returned from the mutation in the form of { loading: boolean; error: ApolloError; data: any }
 */
export const CourseForm: React.FC<CourseFormProps> = ({
  name: initName,
  slug: initSlug,
  meetings: initMeetings,
  id,
  createMode = true,
  onSubmit,
  mutationResult,
  handleSetCourseInfo,
  handleSetMeetingInfo,
}) => {
  const { loading, error, data } = mutationResult;
  const [name, setName] = useState<string>(initName || "");
  const [nameError, setNameError] = useState(false);
  const [slug, setSlug] = useState<string>(initSlug || "");
  const [slugError, setSlugError] = useState(false);
  const [slugLengthError, setSlugLengthError] = useState(false);
  const [meetings, setMeetings] =
    useState<(CreateMeetingInput | Meeting)[]>(initMeetings);
  const [classHasBeenEdited, setClassHasBeenEdited] = useState<boolean>(false);
  const [duplicateModal, setDuplicateModal] = useState<boolean>(false);

  useEffect(() => {
    setName(initName);
  }, [initName]);

  useEffect(() => {
    setMeetings(initMeetings);
  }, [initMeetings]);

  const formatMeetingFormInput = () => {
    const meetingInput: CreateMeetingInput[] =
      meetings &&
      meetings.map((m) => ({
        title: m.title,
        meetingTime: m.meetingTime,
        id: m.id,
        courseId: id,
        instructorAV: m.instructorAV,
        studentAV: m.studentAV,
        chat: m.chat,
        muteLock: m.muteLock,
        videoOffLock: m.videoOffLock,
      }));

    return meetingInput;
  };

  // Validators
  const validateName = (name: string) => {
    const valid = validateText(name, 3);
    setNameError(!valid);
  };
  const validateURLSlug = (slug: string) => {
    const valid = validateSlug(slug);
    setSlugError(!valid);
  };

  // Form Handlers
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
    const valid = validateSlug(event.target.value);
    setSlugLengthError(event?.target?.value?.length > 45);
    setSlugError(!valid);
  };

  const handleAddMeeting = () => {
    const updatedMeetings = [
      ...meetings,
      {
        instructorAV: true,
        studentAV: true,
        chat: true,
        muteLock: false,
        videoOffLock: false,
      },
    ];
    setMeetings(updatedMeetings);
  };

  const handleCancelAddMeeting = () => {
    const updatedMeetings = [...meetings];
    updatedMeetings.splice(-1, 1);
    setMeetings(updatedMeetings);
  };

  const handleUpdateMeeting = (index: number, meeting: CreateMeetingInput) => {
    const updatedMeetings = [...meetings];
    updatedMeetings[index] = { ...updatedMeetings[index], ...meeting };
    setMeetings(updatedMeetings);
  };

  const handleSubmit = () => {
    let input;

    if (createMode) {
      const createInput: CreateCourseInput = {
        name,
        slug,
        meetings: formatMeetingFormInput(),
      };
      input = createInput;
    } else {
      const editInput: EditCourseInput = {
        name,
        id,
        meetings: formatMeetingFormInput(),
      };
      input = editInput;
    }
    onSubmit({ variables: { input } });
  };

  useEffect(() => {
    setClassHasBeenEdited(false);
    if (initMeetings && !createMode) {
      meetings?.forEach((meeting, index) => {
        if (
          meeting.meetingTime !== initMeetings[index]?.meetingTime ||
          meeting.instructorAV !== initMeetings[index]?.instructorAV ||
          meeting.studentAV !== initMeetings[index]?.studentAV ||
          meeting.chat !== initMeetings[index]?.chat ||
          meeting.muteLock !== initMeetings[index]?.muteLock ||
          meeting.videoOffLock !== initMeetings[index]?.videoOffLock
        ) {
          setClassHasBeenEdited(true);
        }
      });
    }
  }, [meetings, initMeetings, setClassHasBeenEdited, createMode]);

  return (
    <Container isDismissable={false}>
      <div className="dashboard-container">
        {meetings ? (
          <div className="dashboard-inner">
            <form className="course-form">
              <h3>Course Information</h3>
              <TextInput
                label="Course Title"
                name="name"
                id="name"
                initialValue={initName}
                hasError={nameError}
                errorMessage="A name is required to create a new course. It must be at least 3 characters long"
                required={true}
                disabled={false}
                onChange={handleNameChange}
                onBlur={(e) => validateName(e.target.value)}
              />

              <TextInput
                label="URL Slug"
                name="slug"
                id="slug"
                initialValue={initSlug}
                hasError={slugError}
                maxLength={46}
                maxLengthMessage="Slug must be less then 46 characters in length"
                errorMessage="Please enter a valid slug using only alphanumeric characters and dashes, eg: 'course-123'. Also the slug cannot end with a dash"
                required={true}
                disabled={!createMode}
                onChange={handleSlugChange}
                onBlur={(e) => validateURLSlug(e.target.value)}
                onLengthError={(e) => setSlugLengthError(e)}
              />

              <h3>Class Sessions</h3>
              {/* Temporarily disable the meeting date pickers on edit mode */}
              {meetings.map((meeting, index) =>
                createMode ? (
                  <ClassForm
                    key={index}
                    index={index}
                    initMeeting={meeting}
                    handleUpdateMeeting={handleUpdateMeeting}
                    autoFocus={initMeetings?.length !== meetings.length}
                  />
                ) : (
                  <ClassSession
                    key={index}
                    index={index}
                    initMeeting={meeting}
                    handleUpdateMeeting={handleUpdateMeeting}
                    disabled={
                      !createMode && initMeetings?.length !== meetings.length
                    }
                    createMode={createMode}
                    autoFocus={initMeetings?.length !== meetings.length}
                    handleSetCourseInfo={handleSetCourseInfo}
                    handleSetMeetingInfo={handleSetMeetingInfo}
                    handleSubmitUpdateCourse={handleSubmit}
                  />
                )
              )}

              {!createMode && !meetings.length && (
                <div>No classes have been added to this course</div>
              )}

              <div
                style={{ display: "flex", gap: "16px" }}
                className="course-form__add-cancel"
              >
                <Button
                  icon="plus"
                  onClick={handleAddMeeting}
                  text="Add class session"
                  btnType={SECONDARY}
                  id="add-a-class-button"
                />
                {initMeetings?.length !== meetings.length && (
                  <Button
                    text="Cancel"
                    btnType={SECONDARY}
                    onClick={handleCancelAddMeeting}
                    id="cancel-edit-course-button"
                  >
                    {loading && <Spinner />}
                  </Button>
                )}
              </div>
              <div className="course-form__footer">
                <Button
                  text={createMode ? "Create course" : "Update course"}
                  disabled={
                    slugLengthError ||
                    slugError ||
                    nameError ||
                    loading ||
                    (initName === name && !classHasBeenEdited)
                  }
                  onClick={handleSubmit}
                  id={
                    createMode ? "create-course-button" : "update-course-button"
                  }
                >
                  {loading && <Spinner />}
                </Button>
                {!createMode && (
                  <Button
                    text={"Duplicate course"}
                    btnType={SECONDARY}
                    disabled={
                      slugLengthError ||
                      slugError ||
                      nameError ||
                      loading ||
                      classHasBeenEdited
                    }
                    onClick={() => setDuplicateModal(!duplicateModal)}
                    id="duplicate-course-button"
                  >
                    {loading && <Spinner />}
                  </Button>
                )}

                {error?.graphQLErrors && error?.graphQLErrors.length && (
                  <div className="it-error-message">
                    <span>
                      The following errors prevented this course from being
                      created:
                    </span>
                    <ApolloErrorsList error={error} />
                  </div>
                )}

                {data?.createCourse?.name && (
                  <div>
                    Course {createMode ? "created" : "updated"} successfully!
                  </div>
                )}
              </div>
            </form>
            <Modal
              dismissible={true}
              display={duplicateModal}
              onDismiss={() => setDuplicateModal(false)}
            >
              <DuplicateCourse
                courseName={name}
                courseSlug={slug}
                courseId={id}
                meetings={formatMeetingFormInput()}
                onClassDuplication={(course) => {
                  setDuplicateModal(false);
                  handleSetCourseInfo(course);
                }}
                onCancel={() => setDuplicateModal(false)}
              />
            </Modal>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </Container>
  );
};
