import React, { Fragment, useState } from "react";
import { useDataEvents } from "../../../providers/dataEvents";
import { CanvasDataEvent, EventPayload } from "../../../providers/types";
import { IconButton } from "../../buttons/iconButton";
import { HelperPopUp } from "../../popUps/helperPopUp";
import { useAppContext } from "../../../contexts/appContext";
import {
  ActionType,
  EventToggleExploreModeAction,
} from "../../../contexts/types";

type RepeatLastEventProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
  emitClick?: (e) => void;
  lastEvent: CanvasDataEvent;
};

export const RepeatLastEventButton: React.FC<RepeatLastEventProps> = ({
  toolTip,
  emitClick,
  lastEvent,
}) => {
  const { sendEvent } = useDataEvents();
  const [showSynchPopUp, setSyncPopUp] = useState<boolean>(false);
  const { state, dispatch } = useAppContext();
  const repeatLastEvent = (e) => {
    if (lastEvent) {
      let event: EventPayload = JSON.parse(lastEvent?.event);
      // if the event is from another instructor set your states explore mode
      // from the event to match the senders exploremode
      if (!lastEvent.isSelf && state?.slides) {
        let payload: EventToggleExploreModeAction["payload"] = {
          exploreMode: event.exploreMode,
        };
        // if the events current board doesn't match yours then update the current board in state
        if (
          state?.currentBoard.sortOrder !==
          state?.slides[event?.currentSlideNumber - 1].sortOrder
        ) {
          payload["currentBoard"] = state.slides[event.currentSlideNumber - 1];
        }
        // dispatch the new state if anything needs updating
        if (
          state.exploreMode !== event.exploreMode ||
          payload["currentBoard"]
        ) {
          dispatch({
            type: ActionType.EVENT_TOGGLE_EXPLORE_MODE,
            payload,
          });
        }
      }
      sendEvent(event, state);
    }
    emitClick(e);
    setSyncPopUp(true);
  };

  return (
    <Fragment>
      <IconButton
        onClick={repeatLastEvent}
        iconName="sync"
        desc="Sync"
        toolTip={toolTip}
        size="30px"
      />
      {showSynchPopUp && (
        <HelperPopUp
          userMessage="Synced!"
          bottom="0px"
          right="40px"
          rightArrow={true}
          isDismissible={false}
          isTimed={true}
          onDismiss={(e) => {
            setSyncPopUp(false);
          }}
        />
      )}
    </Fragment>
  );
};
