import React, { ChangeEvent, Fragment } from "react";
import { TextInput } from "../inputs/textInput";
import { useAppContext } from "../../contexts/appContext";

interface joinMeetingNameProps {
  firstNameError: boolean;
  lastNameError: boolean;
  updateFirstNameError: (arg: boolean) => void;
  updateLastNameError: (arg: boolean) => void;
}

export const JoinFormName: React.FC<joinMeetingNameProps> = ({
  firstNameError,
  lastNameError,
  updateFirstNameError,
  updateLastNameError,
}) => {
  const { setFirstName, setLastName, firstName, lastName } = useAppContext();

  return (
    <Fragment>
      <TextInput
        disabled={false}
        errorMessage="Please enter a valid First Name"
        id="firstNameInput"
        label="First Name"
        name="firstNameInput"
        parentForm="joinForm"
        hasError={firstNameError}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          updateFirstNameError(firstNameError);
          setFirstName(e.target.value);
          if (firstNameError) {
            updateFirstNameError(false);
          }
        }}
        onBeforeInput={(e: any): void => {}}
        initialValue={firstName}
      />
      <TextInput
        disabled={false}
        errorMessage="Please enter a valid Last Name"
        id="lastNameInput"
        label="Last Name"
        name="lastNameInput"
        parentForm="joinForm"
        hasError={lastNameError}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          updateLastNameError(lastNameError);
          setLastName(e.target.value);
          if (lastNameError) {
            updateLastNameError(false);
          }
        }}
        onBeforeInput={(e: any): void => {}}
        initialValue={lastName}
      />
    </Fragment>
  );
};
